import { MAX_BUTTON_FONT_SIZE, MIN_BUTTON_FONT_SIZE } from '@/grid/constants';
import { clamp } from '@/grid/utils/clamp';

const round2dec = n => Math.round(100 * n) / 100;

export function calcInputSizes (size, fontSize, rounding = 1) {
  let hMul;
  let buttonPad;
  if (size === 'large') {
    hMul = 2.6;
    fontSize = fontSize || 19;
    buttonPad = fontSize * 1.6;
  }
  else if (size === 'medium') {
    hMul = 2.2;
    fontSize = fontSize || 17;
    buttonPad = fontSize * 1.3;
  }
  else { // small (default)
    hMul = 1.8;
    fontSize = fontSize || 15;
    buttonPad = fontSize;
  }
  fontSize = clamp(MIN_BUTTON_FONT_SIZE, fontSize || 0, MAX_BUTTON_FONT_SIZE);

  const height = Math.round(fontSize * hMul);
  return {
    fontSize,
    padding: hMul,
    buttonPadding: buttonPad,
    height: height,
    indent: 3 + 5 * hMul,
    borderRadius: round2dec((Math.max(0, Math.min(1, rounding / 2)) * height)),
  };
}
