import React from 'react';
import Textbox from '@borgar/textbox';

const defaultFont = 'Nunito, sans-serif';
const chartTitleFontSize = '20px/1.2';
const chartTitleFontWeight = 500;
const chartSubtitleFontSize = '16px/1.2';
const chartSubtitleFontWeight = 400;

const join = arr => {
  return arr.filter(Boolean).join(' ');
};

const tb = new Textbox({
  createElement: React.createElement,
});

/**
 * Compute a chart's dimension data
 *
 * @param {number} chartWidth The chart width
 * @param {import('@/grid/chart/utils/axes/Axis').Axis[]} axis The axis
 * @param {string} chartTitle The chart's title
 * @param {string} chartSubtitle The chart's subtitle
 * @param {number} [height] The height of the chart
 * @param {string} [font] The font used by title and subtitle
 * @return {Object} { description_of_the_return_value }
 */
export default function prepDimensions (
  chartWidth,
  axis,
  chartTitle,
  chartSubtitle,
  height = undefined,
  font = undefined,
) {
  const ax = {};
  axis.forEach(a => (ax[a.orient] = a));
  // axis affecting height
  const axisTop = ax.top;
  const axisBottom = ax.bottom;
  // axis affecting width
  const axisLeft = ax.left;
  const axisRight = ax.right;

  // base margins
  const margin = {
    top: 6,
    bottom: 6,
    left: 6,
    right: 20,
  };

  // plot area dimensions
  const plotHeight = height || Math.min(500, chartWidth * 0.45);

  if (axisLeft) {
    axisLeft.range([ plotHeight, 0 ]);
    margin.left = axisLeft.width;
  }
  if (axisRight) {
    axisRight.range([ plotHeight, 0 ]);
    margin.right = axisRight.width;
  }

  // plot area dimensions
  const plotWidth = chartWidth - margin.left - margin.right;

  if (axisTop) {
    axisTop.range([ 0, plotWidth ]);
    margin.top = axisTop.height;
  }
  if (axisBottom) {
    axisBottom.range([ 0, plotWidth ]);
    margin.bottom = axisBottom.height;
  }

  // space for axis label (if it's in the top corner)
  let needTopAxisPadding = 0;
  if (axisLeft && axisLeft.title && axisLeft.style.titlePosition === 'corner') {
    needTopAxisPadding = 10;
  }
  else if (axisRight && axisRight.title && axisRight.style.titlePosition === 'corner') {
    needTopAxisPadding = 10;
  }
  margin.top += needTopAxisPadding;

  // prep title if needed
  let title = null;
  if (chartTitle) {
    tb.font(join([ chartTitleFontWeight, chartTitleFontSize, (font || defaultFont) ]));
    tb.width(chartWidth);
    title = tb.linebreak(chartTitle);
    margin.top += title.height;
  }

  let subtitle = null;
  if (chartSubtitle) {
    const subtitleBox = new Textbox({
      font: join([ chartSubtitleFontWeight, chartSubtitleFontSize, (font || defaultFont) ]),
      width: chartWidth,
      createElement: React.createElement,
    });
    subtitle = subtitleBox.linebreak(chartSubtitle);
    margin.top += subtitle.height;
  }

  if ((chartTitle || chartSubtitle) && needTopAxisPadding) {
    // space between title & axis label
    margin.top += 5;
  }

  return { margin, width: plotWidth, height: plotHeight, title, subtitle };
}

