import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@/grid/Tooltip';

import styles from './ButtonStateIcon.module.scss';

const iconRenderers = {
  spinner: () => (
    <svg
      role="img"
      height={26}
      viewBox="0 0 16 16"
      shapeRendering="geometricPrecision"
      data-testid="spinner-icon"
      >
      <path
        d="M 12 8 A 4 4 0 1 1 9 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        stroke="currentColor"
        >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 8 8"
          to="360 8 8"
          dur="1s"
          repeatCount="indefinite"
          />
      </path>
    </svg>
  ),
  checkmark: () => (
    <svg
      role="img"
      height={26}
      viewBox="0 0 16 16"
      shapeRendering="geometricPrecision"
      data-testid="checkmark-icon"
      >
      <path
        d="M4.5,8.3 L7,10.8 L11.5,6.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        fill="none"
        stroke="currentColor"
        />
    </svg>
  ),
  warning: () => (
    <svg
      role="img"
      width={26}
      height={26}
      viewBox="0 0 24 24"
      data-testid="warning-icon"
      >
      <path
        fill="currentColor"
        d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3M12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1m1 4h-2v-2h2z"
        />
    </svg>
  ),
};

export default function ButtonStateIcon ({ message, icon }) {
  const [ hovering, setHovering ] = React.useState(false);

  return (message && iconRenderers[icon] ? (
    <span
      className={styles.helper}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      >
      <Tooltip
        hoverNode={message}
        cursorPosition={hovering ? 'static' : null}
        gap={6}
        showAnchor
        >
        {iconRenderers[icon]()}
      </Tooltip>
    </span>
  ) : null);
}

ButtonStateIcon.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};
