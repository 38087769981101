import { range } from 'd3-array';

import { isBlankCell } from '../../utils/isBlankCell.js';
import { hasNumVal, interpolateGaps, stackSeries as _stackSeries } from './index.js';

/* XXX: Refactor toSeries and add tests
 * This was forked out of AreaCharts to be able to have consistent "series" data for
 * other layered charts. It should have unit test but does not as it is a beast to test
 * with these options in there.
 *
 * What we would like to do is to remove the options so the interface looks like:
 *
 * series = toSeries(...)
 * series = spanGaps(series)
 * series = stackSeries(series)
 *
 * This would also make spanGaps and stackSeries more easily testable on their own.
 */

export function toSeries (table, size, dir, { spanGaps = false, stackSeries = false } = {}) {
  let valueaxisDim = [];
  const series = range(size[1]).map(() => []);

  const getFact = (ser, cat) => {
    const cell = (dir === 'row') ? table[ser][cat] : table[cat][ser];
    const fact = {
      series: ser,
      category: cat,
      cell: cell,
      base: null,
      /** @type {any} */v: null,
    };
    if (isBlankCell(cell)) {
      // cell is "blank"
      fact.v = null;
      fact.gap = true;
    }
    else if (hasNumVal(cell)) {
      // cell has numeric value
      fact.v = cell.v;
    }
    else {
      // cell contains text or some non-number value
      fact.v = 0;
    }
    return fact;
  };

  // convert table to series
  const [ mX, mY ] = size;
  for (let x = 0; x < mX; x++) {
    for (let y = 0; y < mY; y++) {
      const fact = getFact(y, x);
      valueaxisDim.push(fact);
      series[y].push(fact);
    }
  }

  // if the gap handling is "span", we need to manually interpolate missing values
  if (spanGaps) {
    interpolateGaps(series);
  }

  // compute stacking props if needed
  if (stackSeries) {
    valueaxisDim = _stackSeries(series, size);
  }

  return [ series, valueaxisDim ];
}
