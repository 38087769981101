import React from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { elementVisibility, exprWorkbook, titleLabel } from '../propsData';
import Wrapper from '../Wrapper';

import styles from './source.module.scss';

const elementOptions = {
  expr: exprWorkbook,
  label: titleLabel,
  visible: elementVisibility,
};

const noFocus = e => {
  e.preventDefault();
};

export default function GridSource (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  let error;
  let workbook;
  if (!exprWorkbook.isSet(props)) {
    // if workbook prop is not set, use first workbook
    workbook = props.model.getWorkbook();
  }
  else {
    // if workbook prop is set, try to read that workbook
    const ref = exprWorkbook.parse(props.expr);
    if (ref) {
      workbook = props.model.getWorkbook(ref.workbookName);
    }
  }
  if (workbook) {
    const type = String(workbook.type).toLowerCase();
    if (type === 'native' || type === 'grid') {
      workbook = null;
      error = 'Invalid workbook';
    }
  }
  else {
    error = 'No workbook connected';
  }

  const disabled = !workbook;
  let href = '#';
  const target = '_blank';
  let filename = '';
  if (!disabled) {
    filename = workbook.filename;
    const cloudProvider = workbook.cloud_connection?.cloud_drive_provider;
    if (cloudProvider === 'google' || cloudProvider === 'url') {
      href = workbook.cloud_connection.href;
    }
    else {
      href = `/download/${props.documentId}/${workbook.id}`;
    }
  }

  const onClick = e => {
    if (disabled || props.isEditor) {
      noFocus(e);
    }
    props.track('interact', { elementType: 'sourcelink' });
  };

  const iconSize = 24;
  return (
    <Wrapper className={styles.source} {...props}>
      <a
        href={href}
        rel="noopener,noreferrer"
        className={disabled ? styles.disabled : undefined}
        aria-disabled={disabled ? true : undefined}
        target={target}
        onMouseDown={disabled ? noFocus : undefined}
        onClick={onClick}
        tabIndex={disabled ? -1 : undefined}
        >
        <span className={styles.icon}>
          <svg width={iconSize} height={iconSize} viewBox="0 0 40 40" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.1852 5.30719C23.9967 5.11093 23.7362 5 23.4641 5H9.83023C8.79976 5.02907 7.98344 5.93718 8.00026 7.03575V32.8076C7.98344 33.9062 8.79976 34.8143 9.83023 34.8434H30.17C31.2005 34.8143 32.0168 33.9062 32 32.8076V13.8439C32 13.5857 31.9001 13.3374 31.7211 13.1511L24.1852 5.30719ZM23.5902 7.65376L29.5901 13.9422H23.5902V7.65376ZM10.0001 7.13159V32.7116H29.9999V15.6583H22V7.13159H10.0001Z" />
            <rect x="15" y="19" width="10.1111" height="7" stroke="currentColor" />
            <line x1="15" y1="22.5" x2="25.1111" y2="22.5" stroke="currentColor" />
            <line x1="20.1665" y1="19" x2="20.1665" y2="26" stroke="currentColor" />
          </svg>
        </span>
        <span>{error || titleLabel.read(props) || filename}</span>
      </a>
    </Wrapper>
  );
}

GridSource.options = elementOptions;
GridSource.requiredOption = 'expr';
GridSource.propTypes = {
  isEditor: PropTypes.bool,
  parentKey: PropTypes.string,
  error: PropTypes.string,
  documentId: PropTypes.string,
  expr: PropTypes.string,
  track: PropTypes.func,
  model: modelProp,
};
