import React from 'react';
import { extent, range } from 'd3-array';
import { scaleOrdinal, scaleSqrt } from 'd3-scale';
import PropTypes from 'prop-types';

import { getCursorPosition } from '../../utils/offset';
import modelProp from '../modelProp';
import {
  axisClip,
  axisFormat,
  axisMax,
  axisMin,
  axisMinBubble,
  axisReverse,
  axisTitle,
  axisType,
  blanks,
  chartColors,
  data as dataExpr,
  dataCategories,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  layoutWidth,
  legendScatter,
  legendVisible,
  optAnnotationsScatter,
  scatterLabels,
  seriesOrientation,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { enumProp, lowerCase, printCell, validExpr } from '../utils';
import { isBlankCell } from '../utils/isBlankCell';
import BaseChart from './BaseChart';
import Annotations, { prepAnnotations } from './layers/Annotations';
import DotLayer from './layers/DotLayer';
import HoverLayer from './layers/HoverLayer';
import { getLabelArray, hasNumVal, prepCategories, prepLabels } from './utils';
import { ValueAxis } from './utils/ChartAxis';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';

const gapTypes = {
  gap: 'gap',
  zero: 'zero',
  span: 'span',
};

const options = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  labels: scatterLabels,
  legend: legendScatter,
  legendVisible,
  format: format,
  blanks: blanks,
  dir: seriesOrientation,
  visible: elementVisibility,
  chartColors: chartColors,
  categories: dataCategories,
  annotations: optAnnotationsScatter,
  size: layoutWidth,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisValue2: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisBubble: {
    title: axisTitle,
    format: axisFormat,
    min: axisMinBubble,
    max: axisMax,
  },
};

export default class ScatterPlot extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
  };

  static chartType = 'scatter';
  static options = options;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    const table = dataExpr.read(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      dir = w < h ? 'col' : 'row';
    }
    const size = dir === 'row' ? [ w, h ] : [ h, w ];
    const locale /** @type {string} */ = props.locale;

    const labelCells = getLabelArray(scatterLabels.read(props), dir);
    const gapHandling = enumProp(blanks.read(props), gapTypes, 'gap');
    // On a scatter plot, the legend data is used for the x/y/z axis titles. The legend itself comes
    // from the categories (if there are any).
    const axisTitles = prepLabels(legendScatter.read(props), dir, undefined, locale);

    const categories = prepCategories(dataCategories.read(props), dir, undefined, locale, size[0]);
    const uniqueCategories = Array.from(new Set(categories));

    const series = size[1];
    const depth  = size[0];

    const data = [];
    let switch_x_to_serial = false;
    // pack the series into facts
    const axis = series === 1 ? [ 'y' ] : [ 'x', 'y', 'z' ];
    /** @type {{ x: object[], y: object[], z: object[] }} */
    const domain = { x: [], y: [], z: [] };
    if (series) {
      for (let i = 0; i < depth; i++) {
        const label = (labelCells && labelCells[i]) ? printCell(labelCells[i], undefined, locale) : null;
        const categoryValue = categories?.[i] ?? null;
        let isOK = true;
        const fact = { index: i, label: label, category: categoryValue };
        for (let j = 0; j < Math.min(3, series); j++) {
          /** @type {import('@/grid/types').Cellish | null} */
          let cell = (dir === 'row') ? table[j][i] : table[i][j];
          const axisName = axis[j];
          if (isBlankCell(cell)) {
            cell = null;
          }
          const nanCell = cell && !hasNumVal(cell);
          if (!cell || nanCell) {
            cell = (gapHandling === 'zero') ? { v: 0 } : null;
          }
          if (cell) {
            fact[axisName] = {
              v: cell.v,
              z: cell.z,
              id: cell.id,
              F: cell.F,
              sheetIndex: cell.sheetIndex,
            };
            domain[axisName].push(cell);
          }
          else if (axisName === 'x' && nanCell) {
            // keep fact but overwrite x (in the next step)
            switch_x_to_serial = true;
          }
          else {
            // this is unplottable so discard
            isOK = false;
            break;
          }
        }
        if (isOK) {
          data.push(fact);
        }
      }
    }

    // in case there is only one series (no X), or one of the values of X was bad
    // we overwrite the x series with an ordinal sequence
    const dotPlotMode = series === 1 || switch_x_to_serial;
    if (dotPlotMode) {
      domain.x = [];
      data.forEach(fact => {
        fact.x = { v: fact.index + 1 };
        domain.x.push(fact.x);
      });
    }

    // TODO: Excel has a mode where if one of the values of X is bad, it switches the scale to ordinal

    const dom = extent(domain.z, d => Math.abs(d.v * 1));
    if (props.axisBubble) {
      const axisBubble = { model: props.model, locale: props.locale, ...props.axisBubble };
      const min = axisMinBubble.read(axisBubble);
      const max = axisMax.read(axisBubble);
      if (min != null && isFinite(min) && min < dom[0]) {
        dom.push(min);
      }
      if (max != null && isFinite(max) && max > dom[1]) {
        dom.push(max);
      }
    }
    const zScale = scaleSqrt().domain(extent(dom));
    zScale.zero = 0;

    return {
      size,
      data: [ data ],
      z: zScale,
      dotPlotMode: dotPlotMode,
      axisTitles,
      uniqueCategories,
      annotations: prepAnnotations(optAnnotationsScatter.read(props)),
      legendVisible: legendVisible.isSet(props) ? legendVisible.read(props) : uniqueCategories.length > 0,
      axisValue: ValueAxis.prepare({
        props,
        axisName: 'axisValue',
        data: domain.y,
        locale: locale,
        defaultTitle: axisTitles && axisTitles[1] || '',
        orient: 'left',
      }),
      axisValue2: ValueAxis.prepare({
        props,
        axisName: 'axisValue2',
        data: domain.x,
        locale: locale,
        defaultTitle: axisTitles && axisTitles[0] || '',
        orient: 'bottom',
      }),
      axisBubble: ValueAxis.prepare({
        props,
        axisName: 'axisBubble',
        data: domain.z,
        locale: locale,
        defaultTitle: axisTitles && axisTitles[2] || '',
        orient: 'none',
      }),
      modelId: props.model && props.model.lastWrite,
      format: format.read(props),
      lastLocale: locale,
    };
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }
    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    const outerWidth = this.state.width;
    const { theme, chartTheme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { locale } = props;

    // --- data/props ---
    const { z, dir, format, axisValue, axisValue2, axisBubble, legendVisible, uniqueCategories } = this.state;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const palette = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);
    const colorScale = scaleOrdinal(palette).domain(range(uniqueCategories));

    const legend = Legend.prepare({
      labels: uniqueCategories.map(c => [ { v: c } ]),
      dir,
      length: uniqueCategories.length,
      locale: locale,
      style: chartTheme?.legend,
    });
    legend.forEach(d => (d.color = colorScale(d.text)));
    legend.maxWidth = outerWidth;

    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme?.footnote,
    });

    axisValue.applyStyle(chartTheme.valueAxis);
    axisValue2.applyStyle(chartTheme.valueAxis);

    // --- dimensions ---
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth,
      [ axisValue, axisValue2 ],
      chartTitle,
      chartSubtitle,
      undefined,
      theme.chartFontStack,
    );
    margin.bottom += !legendVisible ? 0 : (chartTheme.legend?.margin ?? 0) + legend.height;
    footnote.maxWidth = outerWidth - margin.right;
    z.range([ 2.5, Math.min(width, height) * 0.075 ]);

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    const hasNegativeZValue = d => d.z && d.z.v < 0;
    const prepHover = (fact, cursorPosition) => {
      const xKey = [ axisValue2.title ].filter(Boolean).join(': ');
      const yKey = [ axisValue.title ].filter(Boolean).join(': ');
      const zKey = [ 'Size', axisBubble.title ].filter(Boolean).join(': ');
      return {
        title: fact.label,
        subtitle: fact.category,
        swatch: colorScale(fact.category),
        dimspec: {
          [xKey || 'X axis']: printCell(fact.x, format || axisValue2.format, locale),
          [yKey || 'Y axis']: printCell(fact.y, format || axisValue.format, locale),
          [zKey || 'Size axis']: printCell(fact.z, format || axisBubble.format, locale),
        },
        cursorPosition,
      };
    };

    // because "bubbles" are allowed to overflow plot area we have to filter
    // them rather than use a clip layer...
    let series = this.state.data;
    if (axisValue2.isClipped || axisValue.isClipped) {
      const [ xMin, xMax ] = axisValue2.domain();
      const [ yMin, yMax ] = axisValue.domain();
      series = [
        series[0].filter(d => !(d.x.v < xMin || d.x.v > xMax || d.y.v < yMin || d.y.v > yMax)),
      ];
    }

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Scatter Plot"
        role="img"
        >
        {title && <g transform="translate(1,0)" fill={theme.color}>{title.render()}</g>}
        {subtitle && <g transform={`translate(1,${title?.height ?? 0})`} fill={theme.color}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`}>
          <Grid
            styles={chartTheme}
            x={axisValue2}
            y={axisValue}
            />
          <DotLayer
            series={series}
            x={d => axisValue2.scale(d.x.v)}
            y={d => axisValue.scale(d.y.v)}
            r={d => (d.z ? z(Math.abs(d.z.v)) : 4)}
            opacity={0.45}
            stroke={d => (hasNegativeZValue(d) ? colorScale(d.category) : null)}
            strokeWidth={1.7}
            fill={d => (hasNegativeZValue(d) ? theme.background : colorScale(d.category))}
            />
          <HoverLayer
            width={width}
            height={height}
            series={series}
            x={d => axisValue2.scale(d.x.v)}
            y={d => axisValue.scale(d.y.v)}
            r={d => (d.z ? z(Math.abs(d.z.v)) : 4)}
            stroke={d => (hasNegativeZValue(d) ? colorScale(d.category) : null)}
            strokeWidth={1.7}
            fill={d => (hasNegativeZValue(d) ? theme.background : colorScale(d.category))}
            onPoint={(fact, e) => {
              this.setState({
                hover: prepHover(fact, getCursorPosition(e)),
              });
            }}
            onUnPoint={() => {
              this.setState({ hover: null });
            }}
            />
          <Annotations
            styles={chartTheme}
            x={axisValue2}
            y={axisValue}
            data={this.state.annotations}
            />
          <ValueAxis
            data={axisValue}
            />
          <ValueAxis
            data={axisValue2}
            y={height}
            />
        </g>
        <Footnote
          data={footnote}
          y={footnoteY}
          />
        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
