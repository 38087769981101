import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { calcInputSizes } from '@/grid/utils/calcInputSizes';

import styles from './BaseInput.module.scss';

export default function BaseInput ({ disabled, value, type, error, options, ...props }) {
  const dynamicStyleOuter = {};
  const dynamicStyleInner = {};
  const dim = calcInputSizes(props.size, null, 1);
  dynamicStyleInner.fontSize = Math.round(dim.fontSize) + 'px';
  dynamicStyleInner.lineHeight = (dim.height - 2) + 'px';
  dynamicStyleInner.height = (dim.height - 2) + 'px';
  dynamicStyleInner.paddingLeft = dim.indent + 'px';
  dynamicStyleOuter.height = dim.height + 'px';

  const listId = Math.floor(Math.random() * 1e8).toString(36) + ':list';

  let sizeClass = styles.small;
  if (props.size === 'medium') {
    sizeClass = styles.medium;
  }
  else if (props.size === 'large') {
    sizeClass = styles.large;
  }

  return (
    <span
      className={csx(disabled && styles.disabled, styles.inputWrap, sizeClass, 'inputWrap')}
      style={dynamicStyleOuter}
      >
      {type === 'select' ? (
        <select
          style={dynamicStyleInner}
          value={value === -1 ? '' : value}
          onChange={props.onChange}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          disabled={disabled}
          >
          {error
            ? (<option>{error}</option>)
            : (
              <>
                {value === -1 && <option key="blank" value="">{'\u00A0'}</option>}
                {options.map(d => (
                  <option key={d.index} value={d.value}>{d.title}</option>
                ))}
              </>
            )}
        </select>
      ) : (
        <input
          style={dynamicStyleInner}
          // data-slate-editor attribute added because of a bug in slate
          // https://github.com/ianstormtaylor/slate/issues/3426#issuecomment-573939245
          data-slate-editor
          type="text"
          ref={props.inputRef}
          value={error || value || ''}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={props.onChange}
          disabled={disabled}
          list={options ? listId : undefined}
          />
      )}
      {props.children}
      {/* datalist is rendered last so sibling selectors work */}
      {type !== 'select' && options && (
        <datalist id={listId}>
          {options.map(d => <option key={d.index} value={d.value} />)}
        </datalist>
      )}
    </span>
  );
}

BaseInput.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  type: PropTypes.oneOf([ 'select', 'text', 'number', 'boolean', 'datetime' ]),
  size: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    title: PropTypes.string,
  })),
  title: PropTypes.string,
};
