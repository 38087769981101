import React from 'react';
import { dateFromSerial, dateToSerial, format } from 'numfmt';
import PropTypes from 'prop-types';

import CalendarButton from './CalendarButton';
import { formatOptions } from './constants';
import { todayAsSerial } from './utils';

import styles from './Calendar.module.scss';

const weeks = [ 0, 1, 2, 3, 4, 5 ];
const days = [ 0, 1, 2, 3, 4, 5, 6 ];

export default function Calendar ({ anchorDate, selectedDate, locale, firstDay, onSelect, isSelectable }) {
  const opts = { locale, nbsp: true, ...formatOptions };
  const [ y, m ] = dateFromSerial(anchorDate);
  const firstOfMonth = dateToSerial([ y, m, 1 ]) ?? 0;
  const firstOfNextMonth = dateToSerial([ y, m + 1, 1 ]) ?? 0;
  const startDate = firstOfMonth - (6 + firstOfMonth + 7 - firstDay) % 7;
  const selectDate = e => onSelect(+e.target.dataset.date);
  const today = todayAsSerial();
  return (
    <table className={styles.calendar}>
      <thead>
        <tr>
          <th>{format('ddd', startDate + 0, opts)}</th>
          <th>{format('ddd', startDate + 1, opts)}</th>
          <th>{format('ddd', startDate + 2, opts)}</th>
          <th>{format('ddd', startDate + 3, opts)}</th>
          <th>{format('ddd', startDate + 4, opts)}</th>
          <th>{format('ddd', startDate + 5, opts)}</th>
          <th>{format('ddd', startDate + 6, opts)}</th>
        </tr>
      </thead>
      <tbody>
        {weeks.map(w => (
          <tr key={w}>
            {days.map(d => (startDate + d + w * 7)).map(d => {
              return (
                <td key={d}>
                  <CalendarButton
                    date={d}
                    onClick={selectDate}
                    isSelectable={isSelectable}
                    today={today}
                    currentMonth={d >= firstOfMonth && d < firstOfNextMonth}
                    selectedDate={selectedDate}
                    />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

Calendar.propTypes = {
  anchorDate: PropTypes.number,
  selectedDate: PropTypes.number,
  locale: PropTypes.string,
  firstDay: PropTypes.number,
  onSelect: PropTypes.func,
  isSelectable: PropTypes.func,
};

Calendar.defaultProps = {
  onSelect: () => {},
  locale: 'us-EN',
  firstDay: 0,
};
