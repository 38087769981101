import React from 'react';
import PropTypes from 'prop-types';

import { getCursorPosition, relativeMouse } from '@/utils/offset';

import modelProp from '../modelProp';
import {
  axisClip,
  axisDisabled,
  axisFormat,
  axisMax,
  axisMin,
  axisReverse,
  axisTitle,
  axisType,
  chartColors,
  colorByPoint,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  exprColumns,
  exprLines,
  format,
  formatLines,
  interpolate,
  layoutWidth,
  legendCols,
  legendLines,
  legendVisible,
  seriesOrientation,
  stacked,
  valueLabels,
  xAxisLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { lowerCase, printCell, validExpr } from '../utils';
import { clamp } from '../utils/clamp';
import BaseChart from './BaseChart';
import Clip from './layers/Clip';
import ColumnLayer from './layers/ColumnLayer';
import DotLayer from './layers/DotLayer';
import { closest } from './layers/HoverLayer';
import LineLayer from './layers/LineLayer';
import { getColorScale, isEmptyRange, prepLabels, readSingleSeries } from './utils';
import { DataAxis, ValueAxis } from './utils/ChartAxis';
import ChartLabel from './utils/ChartLabel';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';
import { toSeries } from './utils/toSeries';

const elementOptions = {
  expr: exprColumns,
  exprLines: exprLines,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  stacked: stacked,
  labels: xAxisLabels,
  legend: legendCols,
  legendLines: legendLines,
  legendVisible: legendVisible,
  format: format,
  formatLines: formatLines,
  colorByPoint: colorByPoint,
  dir: seriesOrientation,
  visible: elementVisibility,
  chartColors: chartColors,
  interpolate: interpolate,
  size: layoutWidth,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisValue2: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
    disabled: axisDisabled,
  },
  axisDim: {
    title: axisTitle,
    format: axisFormat,
    reverse: axisReverse,
  },
};

export default class ComboChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
  };

  static chartType = 'column';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    /** @type {string} */
    const locale = props.locale;
    const axXformat = props.axisDim && format.read({ model: props.model, locale: props.locale, ...props.axisDim });
    const labelData = xAxisLabels.read(props);
    const isStacked = stacked.read(props);

    let dir = lowerCase(seriesOrientation.read(props));
    let size = null;
    let seriesCols = [];
    let seriesLine = [];
    let valueaxisDim = [];
    let valueaxis2Dim = [];
    const haveLineData = validExpr(props.exprLines);

    const axisProps = { model: props.model, ...props.axisValue2 };
    const axis2Disabled = axisDisabled.read(axisProps);

    // read data for columns
    {
      const table = exprColumns.readCropped(props);
      const w = table[0].length - (table.emptyRight || 0);
      const h = table.length - (table.emptyBottom || 0);
      if (!dir || dir === 'auto') {
        dir = w < h ? 'col' : 'row';
      }
      size = dir === 'row' ? [ w, h ] : [ h, w ];
      [ seriesCols, valueaxisDim ] = toSeries(
        table,
        size,
        dir,
        { stackSeries: isStacked },
      );
    }

    // read data for lines
    if (exprLines.isSet(props)) {
      const table = exprLines.readCropped(props);
      const w = table[0].length - (table.emptyRight || 0);
      let h = table.length - (table.emptyBottom || 0);
      if (w * h === 0) {
        // if either width or height is zero: the range is empty
        h = 0;
      }
      const lineSize = dir === 'row' ? [ w, h ] : [ h, w ];
      const lineOffs = size[1];
      [ seriesLine, valueaxis2Dim ] = toSeries(
        table,
        lineSize,
        dir,
      );
      seriesLine.forEach(series => {
        series.forEach(d => {
          d.series += lineOffs;
        });
      });

      if (axis2Disabled) {
        // if the secondary axis is disabled we use a single domain for both axis
        valueaxisDim.push(...valueaxis2Dim);
      }

      size = [
        Math.max(size[0], lineSize[0]), // max series length
        size[1] + lineSize[1], // number of series
      ];
    }

    const labels = prepLabels(labelData, dir, axXformat, locale);

    // read legend data and join into a single "table"
    const legendRawCols = readSingleSeries(legendCols.read(props));
    const legendRawLines = readSingleSeries(legendLines.read(props));
    /** @type {object[][]} */
    const legendRaw = [ [] ];
    const numSeries = seriesCols.length + seriesLine.length;
    for (let i = 0; i < numSeries; i++) {
      const label = (i < seriesCols.length)
        ? legendRawCols[i]
        : legendRawLines[i - seriesCols.length];
      if (label != null) {
        legendRaw[0][i] = label;
      }
    }

    const legend = Legend.prepare({
      labels: legendRaw,
      length: numSeries,
      locale: props.locale,
    });
    legend.forEach((label, i) => {
      if (i >= seriesCols.length) {
        label.symbol = 'line';
      }
    });

    return {
      legend,
      labels,
      size,
      series: seriesCols,
      seriesLine: seriesLine,
      isStacked,
      haveLineData,
      legendVisible: legendVisible.isSet(props)
        ? legendVisible.read(props)
        : !isEmptyRange(legendRaw),
      valueAxis: ValueAxis.prepare({
        props,
        data: valueaxisDim,
        locale: locale,
        orient: 'left',
        needZero: true,
      }),
      valueAxis2: ValueAxis.prepare({
        props,
        data: axis2Disabled ? valueaxisDim : valueaxis2Dim,
        locale: locale,
        orient: 'right',
        needZero: axis2Disabled,
        axisName: 'axisValue2',
      }),
      dimAxis: DataAxis.prepare({
        props,
        length: size[0],
        labels: labelData,
        locale: locale,
        orient: 'bottom',
      }),
      formatCols: format.read(props),
      formatLines: formatLines.read(props),
      modelId: props.model && props.model.lastWrite,
      lastLocale: locale,
    };
  }

  isDefined = d => {
    return d.v != null;
  };

  transformX = d => {
    return this.state.dimAxis.scale(d.category);
  };

  transformY = d => {
    return this.state.valueAxis2.scale(d.v);
  };

  transformY2 = d => {
    return this.state.valueAxis2.scale(d.v);
  };

  valueLabel = d => {
    return String(d.v);
  };

  onMouseMove = e => {
    const { dimAxis, seriesLine, formatCols, formatLines } = this.state;
    const m = relativeMouse(e, e.target);
    const mpos = [ m.left || 0, m.top || 0 ];
    // find the closest point in the line/series data
    const pt = closest(
      seriesLine,
      mpos,
      this.transformX,
      this.transformY,
      dimAxis.bandwidth() * 0.5,
      this.isDefined,
    );
    // if we're close to a point, use that
    if (pt) {
      this.onPoint(pt, e, formatLines);
    }
    // if we're on top of a rect, and away from a point use the last known rect point
    else if (this.lastRectPoint) {
      this.onPoint(this.lastRectPoint, e, formatCols);
    }
    // else, we don't show any hover
    else {
      this.onUnpoint();
    }
  };

  onPoint = (d, e, format) => {
    const { labels, size, legend } = this.state;
    this.setState({
      focusKnot: d,
      hover: {
        title: labels ? labels[d.category] : String(d.category + 1),
        dimspec: {
          [legend[d.series].text]: printCell(d.cell, format, this.props.locale),
        },
        simple: size[1] === 1,
        cursorPosition: getCursorPosition(e),
      },
    });
  };

  onUnpoint = () => {
    this.setState({ focusKnot: null, hover: null });
  };

  onPointRect = d => {
    this.lastRectPoint = d;
  };

  onUnpointRect = () => {
    this.lastRectPoint = null;
  };

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }
    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    // --- data/props ---
    const { chartTheme, theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const {
      legend, legendVisible, valueAxis, valueAxis2, dimAxis,
      isStacked, haveLineData, seriesLine,
    } = this.state;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const palette = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);
    const _colorScale = getColorScale({
      byPoint: colorByPoint.read(props),
      backgroundColor: theme.backgroundColor,
      palette,
    });
    const colorScale = d => _colorScale(d.series, d.category);

    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme?.footnote,
    });

    dimAxis.applyStyle(chartTheme.dataAxis);
    valueAxis.applyStyle(chartTheme.valueAxis);
    valueAxis2.applyStyle(chartTheme.valueAxis);

    // --- dimensions ---
    const outerWidth = this.state.width;
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth,
      [ dimAxis, valueAxis, valueAxis2 ],
      chartTitle,
      chartSubtitle,
      undefined,
      theme.chartFontStack,
    );

    legend.maxWidth = outerWidth;
    margin.bottom += !legendVisible ? 0 : (chartTheme.legend?.margin ?? 0) + legend.height;
    legend.forEach((d, i) => (d.color = colorScale({ series: i })));
    footnote.maxWidth = outerWidth - margin.right;

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    dimAxis.range([ 0, width ]);
    valueAxis.range([ height, 0 ]);
    valueAxis2.range([ height, 0 ]);

    legend.applyStyle(chartTheme.legend);

    const invalidChart = valueAxis.error || valueAxis2.error;

    const pointDensity = dimAxis.size / dimAxis.domain().length;
    const useDots = pointDensity > 30 && seriesLine.length < 9;
    const lineWidth = clamp(1.2, 1 + 0.3 * (pointDensity ** 0.5), 2.8);

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Combo Chart"
        role="img"
        >
        {title && <g transform="translate(1,0)" fill={theme.color}>{title.render()}</g>}
        {subtitle && <g transform={`translate(1,${title?.height ?? 0})`} fill={theme.color}>{subtitle.render()}</g>}
        <g
          transform={`translate(${margin.left},${margin.top})`}
          pointerEvents="all"
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onUnpoint}
          >
          {/* needed to get all pointer events */}
          <rect
            width={width}
            height={height}
            fill="none"
            pointerEvents="all"
            />
          <Grid
            styles={chartTheme}
            x={dimAxis}
            y={valueAxis}
            />
          {invalidChart && (
            <g>
              <ChartLabel
                y={0}
                x={width / 2}
                height={height}
                width={width - (width * 0.15)}
                align="center"
                vAlign="middle"
                color={theme.color}
                size={14}
                text={'Invalid data: ' + invalidChart}
                />
              <rect />
            </g>
          )}
          <Clip
            id={'clip-' + props.id}
            active={valueAxis.isClipped}
            width={width}
            height={height}
            >
            <ColumnLayer
              visible={!invalidChart}
              series={this.state.series}
              x={dimAxis.scale}
              y={valueAxis.scale}
              fill={colorScale}
              overlap={isStacked ? 1 : 0}
              bandWidth={dimAxis.bandwidth()}
              zero={valueAxis.zero}
              onPoint={this.onPointRect}
              onUnPoint={this.onUnpointRect}
              // Layers have the ability to show labels but we have not
              // designed how this should work for column charts. This is
              // currently disabled until we do.
              showValues={valueLabels.NONE}
              styles={chartTheme}
              label={this.valueLabel}
              width={width}
              height={height}
              />
          </Clip>
          <Clip
            id={'clip2-' + props.id}
            active={valueAxis2.isClipped}
            width={width}
            height={height}
            >
            <LineLayer
              visible={haveLineData}
              width={width}
              series={seriesLine}
              x={this.transformX}
              y={this.transformY2}
              colorScale={colorScale}
              interpolate={interpolate.read(props)}
              defined={this.isDefined}
              lineWidth={lineWidth}
              />
            <DotLayer
              visible={haveLineData && useDots}
              width={width} // to trigger reflow
              series={seriesLine}
              x={this.transformX}
              y={this.transformY2}
              r={d => (
                d === this.state.focusKnot
                  ? lineWidth * 1.8
                  : lineWidth * 1.2
              )}
              fill={colorScale}
              stroke="none"
              defined={this.isDefined}
              />
          </Clip>
          <DataAxis
            data={dimAxis}
            y={height}
            />
          <ValueAxis
            data={valueAxis}
            />
          <ValueAxis
            data={valueAxis2}
            x={width}
            />
        </g>
        <Footnote
          data={footnote}
          y={footnoteY}
          />
        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
