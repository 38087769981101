import React from 'react';
import PropTypes from 'prop-types';

import TimeKeeper from '@/grid/TimeKeeper';

import modelProp from '../modelProp';
import {
  autoplay,
  commonButtonUI,
  disabled,
  elementVisibility,
  interval,
  loop,
  max as maxOpt,
  min as minOpt,
  targetCell,
  timerValue as valueOpt,
} from '../propsData';
import { isNumber, validExpr } from '../utils';
import BaseButton from './common/BaseButton';

const timers = new TimeKeeper();

const elementOptions = {
  visible: elementVisibility,
  expr: targetCell,
  interval: interval,
  value: valueOpt,
  loop: loop,
  min: minOpt,
  max: maxOpt,
  autoplay: autoplay,
  disabled: disabled,
  ...commonButtonUI,
};

const PATH_PLAY = 'M5,0 L13,8 L5,16 z';
const PATH_PAUSE = 'M2,1 L6.5,1 L6.5,15 L2,15 L2,1 M9.5,1 L14,1 L14,15 L9.5,15 L9.5,1';

const viewOnlyMessage = 'Timer is never active when document is being edited.';

export default class GridTimer extends React.PureComponent {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    thumbnailMode: PropTypes.bool,
    isEditor: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    expr: PropTypes.string,
    track: PropTypes.func,
  };

  static chartType = 'timer';
  static options = elementOptions;
  static requiredOption = 'expr';
  static isInput = true;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (!props.isEditor && props.model && props.model.lastWrite === state.modelId) {
      return null;
    }

    const model = props.model;
    const timerInterval = interval.read(props) || 1;

    // keep the current play state by default (it may come from a button click)
    let playing = state.playing;
    const autoplayValue = autoplay.read(props);
    if (autoplayValue !== state.lastAutoplay || playing == null) {
      // prop has changed, we'll change the play state
      playing = autoplayValue;
    }

    return {
      visible: elementVisibility.read(props),
      freq: timerInterval,
      playing: playing,
      lastAutoplay: autoplayValue,
      modelId: model.lastWrite,
      isDisabled: !validExpr(props.expr) || disabled.read(props),
    };
  }

  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    // timers are never assigned in thumb-mode as we don't want any timers to fire
    if (!this.props.thumbnailMode) {
      timers.addTimer(this.tick, this.state.freq);
    }
  }

  componentWillUnmount () {
    timers.removeTimer(this.tick);
  }

  write (value, recalculate = false) {
    const props = this.props;
    const currValue = targetCell.read(props)?.v;
    if (value !== currValue) {
      targetCell.write(props, value, false);
    }
    // For each round of timers we perform all writes to the model with recalc
    // skipped, batching up the writes. After the last write we must always
    // call recalc to deal with the consequences. There may be no actual writes
    // happening because we skip identical values, but there may still be
    // volatile cells that should update every tick.
    if (recalculate) {
      props.model.recalculate();
    }
  }

  seekStart (recalculate = false) {
    const minValue = minOpt.read(this.props) ?? 1;
    const maxValue = maxOpt.read(this.props) ?? 1;
    // Counting down? Then the start is the maximum value. Otherwise the start is the minimum value.
    const value = minValue + maxValue < 0 ? maxValue : minValue;
    return this.write(value, recalculate);
  }

  /**
   * @param {number} timerIndex This timer's index in the list of timers for the current tick
   * @param {number} numTimers The number of timers running this tick
   */
  tick = (timerIndex, numTimers) => {
    const props = this.props;
    const { playing } = this.state;

    if (!playing || props.isEditor || !props.expr) {
      return;
    }

    let value = null;
    let step = null;
    const isLastOfCycle = timerIndex === numTimers - 1;

    if (valueOpt.isSet(this.props)) {
      value = valueOpt.read(this.props);
    }
    else {
      const valueCell = targetCell.read(props);
      value = valueCell && valueCell.v;
      // in counter mode we reset the value if is is not a number
      if (!isNumber(value)) {
        return this.seekStart(isLastOfCycle);
      }
      // in counter mode we need a step size to increment by
      step = 1;
    }

    if (isNumber(value)) {
      const min = minOpt.read(props);
      const max = maxOpt.read(props);
      if (step != null) {
        value = (min || 0) + (step * Math.round((value - (min || 0)) / step)) + step;
      }
      if (min != null && value < min) {
        return this.seekStart(isLastOfCycle);
      }
      if (max != null && value > max) {
        if (loop.read(props)) {
          return this.seekStart(isLastOfCycle);
        }
        else {
          value = max;
          this.setState({ playing: !playing });
        }
      }
      // timer is run in a lower fidelity to reduce floating point artifacts
      value = +(value.toFixed(13));
    }

    // finally, write the value back to the model
    this.write(value, isLastOfCycle);
  };

  isPlaying = () => {
    return this.state.playing;
  };

  togglePlaying = () => {
    const playing = this.state.playing;
    if (!playing) {
      // only send an event for "started playing"
      this.props.track('interact', { elementType: 'timer' });
    }
    // special case: if play is at the end and button is clicked, move to min
    const props = this.props;
    if (!playing && props.expr) {
      const valueCell = targetCell.read(props);
      const value = valueCell && valueCell.v;
      const max = this.state.max;
      if (isNumber(max) && value >= max) {
        this.seekStart();
      }
    }
    this.setState({ playing: !playing });
  };

  render () {
    const props = this.props;
    const isEditor = props.isEditor;
    const htmlTitle = isEditor ? viewOnlyMessage : null;
    if (!this.state.visible && !isEditor) {
      return null;
    }
    return (
      <BaseButton
        {...props}
        label={
          <svg
            viewBox="0 0 16 16"
            style={{
              verticalAlign: '-4%',
              height: '0.8em',
              width: '0.8em',
            }}
            >
            <path d={this.state.playing ? PATH_PAUSE : PATH_PLAY} fill="currentColor" />
          </svg>
        }
        title={htmlTitle}
        disabled={this.state.isDisabled}
        onClick={this.props.isEditor ? undefined : this.togglePlaying}
        />
    );
  }
}
