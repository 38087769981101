/* eslint-disable react/prop-types */
import React from 'react';
import csx from 'classnames';

import getID from '@/grid/utils/uid';
import { isMobile } from '@/utils';

import styles from './ScrollPane.module.scss';

// A scroll step is 40px in Mac OS
const MOVE_STEP = 40;

// reusable event suppression function
function onSuppress (e) {
  if (e.cancelable) {
    e.stopPropagation();
    e.preventDefault();
  }
}

/**
 * @typedef {object} ScrollPos
 * @prop {number} left
 * @prop {number} top
 */

/**
 * @typedef {object} ScrollPaneProps
 * @prop {string} [className]
 * @prop {object} [style]
 * @prop {number} [maxHeight]
 * @prop {number} [offsetTop]
 * @prop {number} [offsetBottom]
 * @prop {Function} [onResize]
 * @prop {(ScrollPos) => {}} [onScroll]
 */

/*
** ScrollPane represents a scrollable container that will show a scrollbar
** regardless of device. This is to solve a problem where content (esp. tables)
** overflow "off screen" without indication that there is more there.
**
** Implementation tries to replicated Mac behaviour for the bar but sticks to
** native scrolling, prefering to only replace or add the native scrollbar
** with own widget.
*/
export default class ScrollPane extends React.PureComponent {
  /** @param {ScrollPaneProps} props */
  constructor (props) {
    super(props);
    this.state = {
      id: getID(),
      showBarX: false,
      showBarY: false,
      posX: null,
      posY: null,
      startX: 0,
      startY: 0,
      dragVertical: null,
    };
    /** @type {HTMLDivElement | null} */
    this.thumb = null;
    /** @type {HTMLDivElement | null} */
    this.pane = null;
    this.isMobile = isMobile();
    this.thumbXRef = React.createRef();
    this.trackXRef = React.createRef();
    this.thumbYRef = React.createRef();
    this.trackYRef = React.createRef();
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize);

    this.observer = new MutationObserver(this.onResize);
    this.observer.observe(document, {
      childList: true,
      attributes: true,
      subtree: true,
      attributeFilter: [ 'style', 'class' ],
    });

    this.thumb?.addEventListener('touchstart', onSuppress, { passive: false });
    this.onScroll();
  }

  componentDidUpdate (_, lastState) {
    this.onScroll();
    this.lastWidth = this.pane?.clientWidth;

    if (!lastState.showBarX && this.state.showBarX) {
      this.thumb?.addEventListener('touchstart', onSuppress, { passive: false });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onScroll);
    this.observer?.disconnect();
    this.thumb?.removeEventListener('touchstart', onSuppress);
  }

  onResize = () => {
    if (this.lastWidth !== this.pane?.clientWidth) {
      this.onScroll();
      this.lastWidth = this.pane?.clientWidth;
      if (this.props.onResize) {
        this.props.onResize(this.lastWidth);
      }
    }
  };

  onScrollUpdate ({
    offsetStart = 0,
    offsetEnd = 0,
    scrollPos,
    contentSize,
    containerSize,
    thumbElm,
    trackElm,
  }) {
    const trackSize = containerSize - offsetStart - offsetEnd;
    // determine what needs to update
    const scrollMax = contentSize - containerSize;
    // do we even need a scrollbar?
    if (contentSize === containerSize) {
      // thumb element will be removed so detach the event listener
      thumbElm?.removeEventListener('touchstart', onSuppress, { passive: false });
      return false;
    }
    else if (!thumbElm) {
      return true;
    }
    else {
      // update scrollbar UI
      const percentMoved = scrollPos / scrollMax;
      const percentInView = containerSize / contentSize;
      const thumbSize = percentInView * containerSize;
      trackElm.setAttribute('aria-valuenow', Math.round(percentMoved * 1000) / 10);

      const isVertical = trackElm?.dataset.orientation === 'vertical';
      const movePercent = percentMoved * ((containerSize - thumbSize) / containerSize) * 100 + '%';
      if (isVertical) {
        thumbElm.style.top = movePercent;
        thumbElm.style.height = percentInView * 100 + '%';
        trackElm.style.top = offsetStart + 'px';
        trackElm.style.height = trackSize + 'px';
      }
      else {
        thumbElm.style.left = movePercent;
        thumbElm.style.width = percentInView * 100 + '%';
        trackElm.style.left = offsetStart + 'px';
        trackElm.style.width = trackSize + 'px';
      }
    }
  }

  onScroll = event => {
    if (!this.pane) {
      return;
    }
    // attempt to update left/right scrollbar
    const showBarX = this.onScrollUpdate({
      scrollPos: this.pane.scrollLeft,
      contentSize: this.pane.scrollWidth,
      containerSize: this.pane.offsetWidth,
      thumbElm: this.thumbXRef?.current,
      trackElm: this.trackXRef?.current,
    });
    // attempt to update up/down scrollbar
    const showBarY = this.onScrollUpdate({
      offsetStart: this.props.offsetTop,
      offsetEnd: this.props.offsetBottom,
      scrollPos: this.pane.scrollTop,
      contentSize: this.pane.scrollHeight,
      containerSize: this.pane.offsetHeight,
      thumbElm: this.thumbYRef?.current,
      trackElm: this.trackYRef?.current,
    });
    // if either X or Y scrollbar updated, set the state
    /** @type {undefined | { showBarX?: boolean, showBarY?: boolean }} */
    let newState;
    if (showBarX != null) {
      newState = {};
      newState.showBarX = showBarX;
    }
    if (showBarY != null) {
      newState = newState || {};
      newState.showBarY = showBarY;
    }
    if (newState) {
      this.setState(newState);
    }
    // we only callback on real scroll events (else we get infinite loops)
    if (this.props.onScroll && event) {
      this.props.onScroll({
        left: this.pane.scrollLeft,
        top: this.pane.scrollTop,
      });
    }
  };

  onDragStart = e => {
    e.preventDefault();
    const isVertical = e.currentTarget.dataset.orientation === 'vertical';
    this.setState({
      posX: (e.touches ? e.touches[0] : e).pageX,
      startX: this.thumbXRef.current?.offsetLeft,
      posY: (e.touches ? e.touches[0] : e).pageY,
      startY: this.thumbYRef.current?.offsetTop,
      dragVertical: isVertical,
    });
    document.addEventListener('pointermove', this.onDrag);
    document.addEventListener('pointerup', this.onDragEnd);
    document.addEventListener('selectstart', onSuppress);
    if (window.self !== window.top) { // iframe?
      document.addEventListener('pointerleave', this.onDragEnd);
    }
  };

  onDragEnd = () => {
    this.setState({ posX: null, posY: null });
    document.removeEventListener('pointermove', this.onDrag);
    document.removeEventListener('pointerup', this.onDragEnd);
    document.removeEventListener('selectstart', onSuppress);
    if (window.self !== window.top) { // iframe?
      document.removeEventListener('pointerleave', this.onDragEnd);
    }
  };

  onDrag = e => {
    const { pane } = this;
    if (!pane) {
      return;
    }
    const ev = e.touches ? e.touches[0] : e;
    // up/down movement
    if (this.trackYRef.current && this.state.dragVertical) {
      const deltaY = this.state.posY ? ev.pageY - this.state.posY : 0;
      const currY = this.state.startY + deltaY;
      const maxYDist = (
        this.trackYRef.current.clientHeight -
        this.thumbYRef.current.clientHeight
      );
      const y = Math.min(maxYDist, Math.max(currY, 0));
      const topMax = pane.scrollHeight - pane.offsetHeight;
      pane.scrollTop = topMax * (y / maxYDist);
    }
    // left/right movement
    else if (this.trackXRef.current) {
      const deltaX = this.state.posX ? ev.pageX - this.state.posX : 0;
      const currX = this.state.startX + deltaX;
      const maxXDist = (
        this.trackXRef.current.clientWidth -
        this.thumbXRef.current.clientWidth
      );
      const x = Math.min(maxXDist, Math.max(currX, 0));
      const leftMax = pane.scrollWidth - pane.offsetWidth;
      pane.scrollLeft = leftMax * (x / maxXDist);
    }
  };

  onClick = e => {
    // this detects on which side of the thumb the click was made and scrolls
    // the panel by one MOVE_STEP in that direction
    if (e.target === e.currentTarget) {
      let moveX = 0;
      let moveY = 0;
      // is vertical?
      if (e.target.dataset.orientation === 'vertical') {
        const trackElm = this.trackYRef.current;
        const thumbElm = this.thumbYRef.current;
        const my = e.clientY - trackElm.getBoundingClientRect().top;
        const ty = parseFloat(thumbElm.style.top) / 100 * trackElm.offsetHeight;
        moveY = my < ty ? -1 : 1;
      }
      else {
        const trackElm = this.trackXRef.current;
        const thumbElm = this.thumbXRef.current;
        const mx = e.clientX - trackElm.getBoundingClientRect().left;
        const tx = parseFloat(thumbElm.style.left) / 100 * trackElm.offsetWidth;
        moveX = mx < tx ? -1 : 1;
      }
      if (this.pane) {
        this.pane.scrollTo({
          left: this.pane.scrollLeft + moveX * MOVE_STEP,
          top: this.pane.scrollTop + moveY * MOVE_STEP,
          behavior: 'smooth',
        });
      }
    }
  };

  /**
   * @param {number} x
   * @param {number} y
   */
  scrollTo (x, y) {
    if (this.pane) {
      this.pane.scrollTo(x, y);
    }
  }

  renderScrollBar (isVertical = false) {
    const orientation = isVertical ? 'vertical' : 'horizontal';
    return (
      <div
        className={csx(styles.scrollBarTrack, isVertical ? styles.vertical : styles.horizontal)}
        ref={isVertical ? this.trackYRef : this.trackXRef}
        role="scrollbar"
        aria-controls={this.state.id}
        aria-orientation={orientation}
        aria-valuenow={0}
        data-orientation={orientation}
        // click and focus behavior doesn't make sense for mobile
        tabIndex={this.isMobile ? undefined : -1}
        onClick={this.isMobile ? undefined : this.onClick}
        >
        <div
          className={styles.scrollBarThumb}
          onPointerDown={this.onDragStart}
          onTouchStart={onSuppress}
          data-orientation={orientation}
          ref={isVertical ? this.thumbYRef : this.thumbXRef}
          >
          <div />
        </div>
      </div>
    );
  }

  render () {
    const { maxHeight = 0, offsetTop = 0, offsetBottom = 0, className, style } = this.props;
    return (
      <div className={csx(styles.scrollAreaWrap)} style={style}>
        <div
          className={csx(className, styles.scrollArea)}
          style={{ maxHeight: maxHeight ? (maxHeight + offsetTop + offsetBottom) + 'px' : undefined }}
          ref={elm => (this.pane = elm)}
          id={this.state.id}
          onScroll={this.onScroll}
          >
          {this.props.children}
        </div>
        {this.state.showBarX && this.renderScrollBar(false)}
        {this.state.showBarY && this.renderScrollBar(true)}
      </div>
    );
  }
}
