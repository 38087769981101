import { getFormatDateInfo, getFormatInfo } from 'numfmt';

import { isNumber, leastSignificant, mostSignificant } from '../utils';

/**
 * Return the 1/100th of either min or max depending on the signature of an
 * input number. If the appropriate bound is not a valid number, return null
 */
const minMax = (v, min, max) => {
  const scaleBase = v < 0 ? min : max;
  return isNumber(scaleBase)
    ? 10 ** Math.floor(Math.log10(Math.abs(scaleBase)) - 2)
    : null;
};

/**
 * Return the appropriate value step based on a number format, if format can
 * be used to determine such a step.
 */
const getFormatStep = formatInfo => {
  if (formatInfo.type !== 'general') {
    const base = Math.floor(Math.log10(formatInfo.scale));
    return 10 ** (-base - formatInfo.maxDecimals);
  }
  return null;
};

/**
 * Return a step value to use when the user hasn't provided one.
 *
 * In situations where the user hasn't explicitly set the component's step
 * value, we need to choose a good value ourselves. Here the step value is
 * chosen based on the initial value and the current.
 *
 * @param value {number|null} The current value the element holds
 * @param formatString {string} An Excel numberformat string
 * @param [min] {number|null} The minimum value allowed for the range
 * @param [max] {number|null} The maximum value allowed for the range
 *
 * @return The automatic step.
 */
export function getAutoStep (value, formatString, min = null, max = null) {
  const formatInfo = getFormatInfo(formatString);
  // special case where date formats step by 1
  if (formatInfo.type === 'date' || formatInfo.type === 'datetime') {
    // xxx: can auto step step by more when date is more granular?
    return 1;
  }
  else if (formatInfo.type === 'time') {
    const dateInfo = getFormatDateInfo(formatString);
    if (dateInfo.seconds) {
      return 1 / 86400;
    }
    else if (dateInfo.minutes) {
      return 1 / 1440;
    }
    return 1 / 24;
  }

  const stepBase = Math.abs(value ?? 0);
  // numbers section: (0-1)
  if (stepBase < 1 && stepBase > 0) {
    return (
      // use format if we have that
      getFormatStep(formatInfo) ||
      // use 1/10 of min/max if present
      minMax(value, min, max) ||
      // step on the most significant digit (0.02 => 0.01)
      10 ** mostSignificant(stepBase)
    );
  }
  // numbers section: 0 || [1-1000)
  else if (!stepBase || stepBase < 1000) {
    return (
      // use format if we have that
      getFormatStep(formatInfo) ||
      // use 1/10 of min/max if present
      minMax(value, min, max) ||
      // step values with fractions on 0.1, else step on 1
      (stepBase % 1 ? 0.1 : 1)
    );
  }
  // numbers section: [1000-∞]
  else {
    // for scaled numbers we defer to the format ("0.00,,K")
    if (formatInfo.scale !== 1) {
      return getFormatStep(formatInfo) || 1;
    }
    // we want to step 10000 and 11000 on 1000's, but not 110000
    let ls = leastSignificant(stepBase);
    if (ls === mostSignificant(stepBase)) {
      ls--;
    }
    return 10 ** ls;
  }
}
