import React, { useContext } from 'react';
import csx from 'classnames';
import { getLocale } from 'numfmt';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { boldText, codeText, color, data, elementVisibility, fontSize, format, italicText, optAlignValue, optInlineTypeText, optWidthAuto, titleLabel } from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { printCell } from '../utils';
import { alignmentFromCell } from '../utils/alignmentFromCell';
import Wrapper from '../Wrapper';

import styles from './text.module.scss';

const elementOptions = {
  expr: data,
  format: format,
  visible: elementVisibility,
  bold: boldText,
  italic: italicText,
  title: titleLabel,
  code: codeText,
  fontSize: fontSize,
  color: color,
  width: optWidthAuto,
  inline: optInlineTypeText,
  align: optAlignValue,
};

const lineHeights = {
  col: 1,
  row: 1.2,
  text: '19px',
};

export default function GridText (props) {
  const { theme } = useContext(ThemesContext);
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  const classes = csx(
    styles.text,
    props.isEditor && styles.outlined,
  );

  const inlineMode = optInlineTypeText.read(props);

  let textAlign = /** @type {'left' | 'right' | 'center'} */(optAlignValue.read(props));

  let str = 'Formula output';
  if (data.isSet(props)) {
    const cells = data.read(props);
    const fmtStr = format.read(props);
    const listSeparator = getLocale(props.locale)?.decimal === ',' ? '; ' : ', ';
    if (cells && !cells.reduce) {
      str = String(cells);
    }
    else {
      textAlign = textAlign || alignmentFromCell(cells && cells[0] && cells[0][0]);
      str = (cells || [])
        .reduce((a, d) => a.concat(d), []) // flatten the array of cells
        .map(d => printCell(d, fmtStr, props.locale))
        .join(listSeparator);
    }
  }

  const fsize = fontSize.isSet(props) && fontSize.read(props);

  // nest the output in the same nesting order slate does: em > code > strong > [text]
  /** @type {import('react').ReactNode}*/
  let inner = str.trimEnd();
  if (boldText.read(props)) {
    inner = <strong>{inner}</strong>;
  }
  if (codeText.read(props)) {
    inner = <code>{inner}</code>;
  }
  if (italicText.read(props)) {
    inner = <em>{inner}</em>;
  }

  const width = optWidthAuto.read(props);

  const style = {
    fontSize: fsize ? fsize + 'px' : undefined,
    lineHeight: fsize ? lineHeights[inlineMode] || 1 : undefined,
    color: theme.resolveColor(color.read(props)),
    textAlign: textAlign,
  };

  return (
    <Wrapper
      {...props}
      className={classes}
      label={titleLabel.read(props)}
      inlineMode={inlineMode}
      inlineWidth={width}
      >
      <span
        data-testid={props.testId}
        className={csx(
          styles.value,
          !str && styles.emptyText,
          'output',
          (inlineMode === 'text' && !width) && styles.inline,
        )}
        style={style}
        >
        {inner}
      </span>
    </Wrapper>
  );
}

GridText.options = elementOptions;
GridText.requiredOption = 'expr';
GridText.propTypes = {
  fontSize: PropTypes.string,
  testId: PropTypes.string,
  isEditor: PropTypes.bool,
  parentKey: PropTypes.string,
  error: PropTypes.string,
  model: modelProp.isRequired,
  locale: PropTypes.string,
};
