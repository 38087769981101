import { packID, unpackID } from './intId.js';

/** @type {import('./borderStyles.js').BorderDef} */
const BORDER_NONE = { style: 'none', color: 'none' };
/** @type {import('./borderStyles.js').BorderDef} */
export const BORDER_GRID = { style: 'hair', color: '#d8d9e5' };

export const JOIN_N = 1;
export const JOIN_W = 2;
export const JOIN_S = 4;
export const JOIN_E = 8;

/**
 * @typedef {'T' | 'L' | 'B' | 'R'} BorderSide
 */

/**
 * @typedef {object} BorderStoreItem
 * @property {import('./borderStyles.js').BorderDef} [T]
 * @property {import('./borderStyles.js').BorderDef} [L]
 */

export class Borders {
  /** @type {BorderSide} */
  static TOP = 'T';
  /** @type {BorderSide} */
  static LEFT = 'L';
  /** @type {BorderSide} */
  static BOTTOM = 'B';
  /** @type {BorderSide} */
  static RIGHT = 'R';

  constructor () {
    /** @type {Map<number, BorderStoreItem>} */
    this._ = new Map();
  }

  clear () {
    this._.clear();
  }

  /**
   * @param {[ number, number ]} cellPos
   * @param {BorderSide} direction
   * @param {import('./borderStyles.js').BorderDef | null} style
   */
  set (cellPos, direction, style) {
    const [ x, y ] = cellPos;
    if (direction === Borders.BOTTOM) {
      this.set([ x, y + 1 ], Borders.TOP, style);
    }
    else if (direction === Borders.RIGHT) {
      this.set([ x + 1, y ], Borders.LEFT, style);
    }
    else if (direction === Borders.LEFT || direction === Borders.TOP) {
      const key = packID(cellPos[0], cellPos[1]);
      let item = this._.get(key);
      if (!item) {
        item = {
          [Borders.TOP]: BORDER_NONE,
          [Borders.LEFT]: BORDER_NONE,
        };
        this._.set(key, item);
      }
      if (style == null || style.style === 'none') {
        item[direction] = BORDER_NONE;
      }
      else {
        item[direction] = style;
      }
    }
  }

  /**
   * @param {number[]} cellPos
   * @param {string} direction
   */
  get (cellPos, direction) {
    if (direction === Borders.BOTTOM) {
      return this.get([ cellPos[0], cellPos[1] + 1 ], Borders.TOP);
    }
    else if (direction === Borders.RIGHT) {
      return this.get([ cellPos[0] + 1, cellPos[1] ], Borders.LEFT);
    }
    else if (direction === Borders.LEFT || direction === Borders.TOP) {
      const key = packID(cellPos[0], cellPos[1]);
      const def = this._.get(key);
      if (def && def[direction]) {
        return def[direction];
      }
    }
    return BORDER_NONE;
  }

  /**
   * Determines if a border has been set to something that is not "none" or "hair".
   *
   * @param {[ number, number ]} cellPos
   * @param {BorderSide} direction
   * @return {boolean}
   */
  isSet (cellPos, direction) {
    const s = this.get(cellPos, direction).style;
    return s !== 'none' && s !== 'hair';
  }

  /**
   * Returns a join bitmask for a corner in a cell grid.
   *
   * @param {number} x
   * @param {number} y
   * @return {number}
   */
  getJoin (x, y) {
  // position is a top-left cell corner
    let join = 0;
    // is there a border going up?
    if (this.isSet([ x, y ], Borders.TOP)) {
      join |= JOIN_E;
    }
    if (this.isSet([ x, y ], Borders.LEFT)) {
      join |= JOIN_S;
    }
    if (this.isSet([ x - 1, y ], Borders.TOP)) {
      join |= JOIN_W;
    }
    if (this.isSet([ x, y - 1 ], Borders.LEFT)) {
      join |= JOIN_N;
    }
    return join;
  }

  /**
   * @param {(
   *  pos: [ number, number ],
   *  direction: BorderSide,
   *  style: import('./borderStyles.js').BorderDef,
   * ) => void} callback The callback
   */
  forEach (callback) {
    for (const [ key, val ] of this._.entries()) {
      const pos = unpackID(key);
      const topBorder = val[Borders.TOP];
      if (topBorder && topBorder.color !== 'none' && topBorder.style !== 'none') {
        callback(pos, Borders.TOP, topBorder);
      }
      const leftBorder = val[Borders.LEFT];
      if (leftBorder && leftBorder.color !== 'none' && leftBorder.style !== 'none') {
        callback(pos, Borders.LEFT, leftBorder);
      }
    }
  }

  /**
   * @param {[number, number][]} boundaries
   */
  fillGridLines (boundaries) {
    const [ left, top ] = boundaries[0];
    const [ right, bottom ] = boundaries[1];
    for (let x = left; x <= right; x++) {
      for (let y = top; y <= bottom; y++) {
        this.set([ x, y ], Borders.TOP, BORDER_GRID);
        this.set([ x, y ], Borders.LEFT, BORDER_GRID);
      }
    }
  }
}
