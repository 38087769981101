import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Checkmark.module.scss';

function preventFocus (e) {
  e.preventDefault();
}

export default function Checkmark (props) {
  const isChecked = props.checked && !props.indeterminate;

  const classNames = csx(
    styles.checkmark,
    props.className,
    props.type === 'radio' && styles.radio,
  );

  const innerClassNames = csx(
    props.checked && styles.checked,
    props.disabled && styles.disabled,
    props.indeterminate && styles.mixed,
  );

  /** @type {'true'|'false'|'mixed'} */
  let ariaMode = isChecked ? 'true' : 'false';
  if (props.indeterminate) {
    ariaMode = 'mixed';
  }
  const focusable = props.focusable && !props.disabled;
  const size = props.size;

  return (
    <span
      aria-checked={ariaMode}
      aria-disabled={props.disabled ? true : undefined}
      className={classNames}
      style={size ? { width: size + 'px', height: size + 'px' } : {}}
      id={props.id}
      onClick={props.disabled ? null : props.onClick}
      onMouseDown={props.disabled ? preventFocus : props.onMouseDown}
      onKeyPress={e => {
        if (!props.disabled && props.onClick && (e.charCode === 32 || e.charCode === 13)) {
          e.preventDefault();
          props.onClick();
        }
      }}
      role={props.type}
      tabIndex={focusable ? 0 : -1}
      >
      {props.type === 'checkbox' ? (
        <span className={csx(styles.box, innerClassNames)}>
          <span className={styles.fill} />
          <svg
            className={styles.mark}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 16 16"
            shapeRendering="geometricPrecision"
            >
            {isChecked ? (
              <path
                d="M4.5,8.3 L7,10.8 L11.5,6.3"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill="none"
                />
            ) : null}
          </svg>
        </span>
      ) : (
        <span className={csx(styles.disc, innerClassNames)}>
          <span className={styles.fill} />
          <svg
            className={styles.mark}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 16 16"
            shapeRendering="geometricPrecision"
            >
            {isChecked ? (
              <circle
                cx="8"
                cy="8"
                r="4"
                stroke="none"
                />
            ) : null}
          </svg>
        </span>
      )}
    </span>
  );
}

Checkmark.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf([ 'checkbox', 'radio' ]),
  focusable: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

Checkmark.defaultProps = {
  size: 20,
  type: 'checkbox',
  focusable: true,
  checked: false,
  disabled: false,
  indeterminate: false,
};
