import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { commonButtonUI, disabled, elementVisibility, readCell, targetValue, titleLabel, writeCell } from '../propsData';
import BaseButton from './common/BaseButton';

const elementOptions = {
  title: titleLabel,
  visible: elementVisibility,
  writeCell: writeCell,
  readCell: readCell,
  targetValue: targetValue,
  disabled: disabled,
  ...commonButtonUI,
};

export default function GridGoalseekButton (props) {
  const title = titleLabel.read(props) || 'Calculate';

  let error = '';
  if (!writeCell.isSet(props)) {
    error = 'Missing a cell to change';
  }
  else if (!readCell.isSet(props)) {
    error = 'Missing goal cell';
  }
  else if (!props.canUse('can_use_goal_seek')) {
    error = 'Restricted element';
  }
  const isDisabled = !props.model || disabled.read(props) || !!error;

  const onClick = useCallback(() => {
    if (isDisabled) {
      return;
    }
    props.track('interact', { elementType: 'goalseekbutton' });

    const writeCellRef = writeCell.read(props);
    const readCellRef = readCell.read(props);
    const value = targetValue.read(props);
    try {
      const result = props.model.goalSeek(writeCellRef, readCellRef, value);
      if (result instanceof Error) {
        // TODO: Show some indicator if goalseek failed, left as future work
      }
    }
    catch (e) {
      props.onError(e);
    }
  }, [ isDisabled, props ]);

  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  return (
    <BaseButton
      {...props}
      label={error || title}
      disabled={isDisabled}
      onClick={onClick}
      />
  );
}

GridGoalseekButton.options = elementOptions;
GridGoalseekButton.requiredOption = null;
GridGoalseekButton.propTypes = {
  isEditor: PropTypes.bool,
  model: modelProp.isRequired,
  canUse: PropTypes.func,
  track: PropTypes.func,
  onError: PropTypes.func,
};
