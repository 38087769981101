import React from 'react';
import { range } from 'd3-array';
import { scaleOrdinal } from 'd3-scale';
import { area, curveBasis, curveLinear, curveMonotoneX, curveStep, curveStepAfter, curveStepBefore, line } from 'd3-shape';
import PropTypes from 'prop-types';

import { getCursorPosition } from '../../utils/offset';
import modelProp from '../modelProp';
import {
  axisClip,
  axisFormat,
  axisMax,
  axisMin,
  axisReverse,
  axisTitle,
  axisType,
  blanks,
  chartColors,
  data as dataExpr,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  interpolate,
  layoutWidth,
  legend as legendOption,
  legendVisible,
  optAnnotations,
  seriesOrientation,
  stacked,
  xAxisLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { enumProp, lowerCase, printCell, validExpr } from '../utils';
import BaseChart from './BaseChart';
import Annotations, { prepAnnotations } from './layers/Annotations';
import Clip from './layers/Clip';
import HoverLayer from './layers/HoverLayer';
import { isEmptyRange, prepLabels } from './utils';
import { DataAxis, ValueAxis } from './utils/ChartAxis';
import ChartLabel from './utils/ChartLabel';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';
import { toSeries } from './utils/toSeries.js';

const elementOptions = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  stacked: stacked,
  labels: xAxisLabels,
  legend: legendOption,
  size: layoutWidth,
  legendVisible: legendVisible,
  format: format,
  dir: seriesOrientation,
  blanks: blanks,
  interpolate: interpolate,
  visible: elementVisibility,
  chartColors: chartColors,
  annotations: optAnnotations,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisDim: {
    title: axisTitle,
    format: axisFormat,
    reverse: axisReverse,
  },
};

const interpolationTypes = {
  'linear': curveLinear,
  'step': curveStep,
  'step-after': curveStepAfter,
  'step-before': curveStepBefore,
  'monotone': curveMonotoneX,
  'basis': curveBasis,
};

const gapTypes = {
  gap: 'gap',
  zero: 'zero',
  span: 'span',
};

export default class AreaChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
  };

  static chartType = 'line';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    let table = dataExpr.readCropped(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      dir = w < h ? 'col' : 'row';
    }
    let size = dir === 'row' ? [ w, h ] : [ h, w ];

    const locale = props.locale;
    const axXformat = props.axisDim && format.read({ model: props.model, locale: props.locale, ...props.axisDim });

    const labelData = xAxisLabels.read(props);
    const labels = prepLabels(labelData, dir, axXformat, locale);

    const rawValues = [];
    if (!validExpr(props.expr)) {
      const l = labels ? labels.length : 0;
      size = [ l, 1 ];
      // eslint-disable-next-line newline-per-chained-call
      table = Array(l).fill(0).map(() => []);
    }

    const gapHandling = enumProp(blanks.read(props), gapTypes, 'gap');
    const isStacked = stacked.read(props);

    const [ series, valueaxisDim ] = toSeries(
      table,
      size,
      dir,
      { spanGaps: gapHandling === 'span', stackSeries: isStacked },
    );

    const legendRaw = legendOption.read(props);
    return {
      labels,
      dir,
      size,
      series,
      rawValues,
      gapHandling,
      isStacked,
      legendRaw,
      annotations: prepAnnotations(optAnnotations.read(props)),
      legendVisible: legendVisible.isSet(props) ? legendVisible.read(props) : !isEmptyRange(legendRaw),
      valueAxis: ValueAxis.prepare({
        props,
        data: valueaxisDim,
        locale: locale,
        orient: 'left',
        needZero: true,
      }),
      dimAxis: DataAxis.prepare({
        props,
        length: size[0],
        labels: labelData,
        locale: locale,
        orient: 'bottom',
      }),
      format: format.read(props),
      modelId: props.model && props.model.lastWrite,
      lastLocale: locale,
    };
  }

  render () {
    const props = this.props;

    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    const k = (props.parentKey || props.id) + ':';

    // --- data/props ---
    const { theme, chartTheme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { dir, legendRaw, legendVisible, size, series, valueAxis, dimAxis, isStacked, gapHandling, labels, format } = this.state;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const interpolation = enumProp(
      interpolate.read(props),
      interpolationTypes,
      'linear',
    );

    const palette = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);
    const legend = Legend.prepare({
      labels: legendRaw,
      dir,
      length: size[1],
      locale: props.locale,
      style: chartTheme?.legend,
    });
    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme?.footnote,
    });

    // --- axis ---
    const colorScale = scaleOrdinal(palette).domain(range(size[1]));
    legend.forEach((d, i) => (d.color = colorScale(i)));
    valueAxis.applyStyle(chartTheme.valueAxis);
    dimAxis.applyStyle(chartTheme.dataAxis);

    // --- dimensions ---
    const outerWidth = this.state.width;
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth, [ dimAxis, valueAxis ], chartTitle, chartSubtitle, undefined, theme.chartFontStack,
    );
    legend.maxWidth = outerWidth;
    footnote.maxWidth = outerWidth - margin.right;

    margin.bottom += legendVisible ? (chartTheme.legend?.margin ?? 0) + legend.height : 0;

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    // path
    let areaLine;
    let areaFill;
    const gapHandler = gapHandling === 'gap' || gapHandling === 'span'
      ? d => d.v != null
      : true;
    if (isStacked) {
      areaFill = area()
        .curve(interpolation)
        .x(d => dimAxis.scale(d.category))
        .y0(d => valueAxis.scale(d.base || valueAxis.zero))
        .y1(d => valueAxis.scale(d.base + d.v))
        .defined(gapHandler);
    }
    else {
      areaFill = area()
        .curve(interpolation)
        .x(d => dimAxis.scale(d.category))
        .y0(valueAxis.scale(valueAxis.zero))
        .y1(d => valueAxis.scale(d.v || 0))
        .defined(gapHandler);
      areaLine = line()
        .curve(interpolation)
        .x(d => dimAxis.scale(d.category))
        .y(d => valueAxis.scale(d.v || 0))
        .defined(gapHandler);
    }

    // stacked charts that have more than 1 series and cross 0
    // can't be rendered, so we need to detect those
    let invalidChart = valueAxis.error;
    if (!invalidChart && isStacked && series.length > 1) {
      const [ a, b ] = valueAxis.domain();
      invalidChart = (a < 0) !== (b <= 0)
        ? "Stacked area charts won't function when two or more series have positive and negative values."
        : null;
    }

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Area Chart"
        role="img"
        >
        {title && <g fill={theme.color} transform="translate(1,0)">{title.render()}</g>}
        {subtitle && <g fill={theme.color} transform={`translate(1,${title?.height ?? 0})`}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`} style={{ display: '' }}>
          <g pointerEvents={invalidChart ? 'none' : 'all'}>
            <Grid
              styles={chartTheme}
              x={dimAxis}
              y={valueAxis}
              />
            <Clip
              id={'clip-' + props.id}
              active={valueAxis.isClipped}
              width={width}
              height={height}
              >
              {invalidChart ? (
                <g>
                  <ChartLabel
                    y={0}
                    x={width / 2}
                    height={height}
                    width={width - (width * 0.15)}
                    align="center"
                    vAlign="middle"
                    color={theme.color}
                    size={14}
                    text={'Invalid data: ' + invalidChart}
                    />
                  <rect />
                </g>
              ) : (
                <g fill="none" strokeWidth="2">
                  {series.map((s, i) => (
                    <g key={k + 'line' + i}>
                      {areaLine && (
                        <path
                          d={areaLine(s)}
                          stroke={colorScale(i)}
                          />
                      )}
                      {areaFill && (
                        <path
                          d={areaFill(s)}
                          fill={colorScale(i)}
                          fillOpacity={isStacked ? 1 : 0.25}
                          />
                      )}
                    </g>
                  ))}
                </g>
              )}

              <Annotations
                visible={!invalidChart}
                styles={chartTheme}
                x={dimAxis}
                y={valueAxis}
                data={this.state.annotations}
                />

              <HoverLayer
                visible={!invalidChart}
                width={width}
                height={height}
                series={series}
                defined={d => !d.gap}
                shape={isStacked ? 'line' : 'circle'}
                r={isStacked ? d => {
                  const y0 = valueAxis.scale(d.base || valueAxis.zero);
                  const y1 = valueAxis.scale(d.base + d.v);
                  return (y1 - y0) / 2;
                } : 4}
                fill={d => colorScale(d.series)}
                stroke={d => {
                  return (isStacked)
                    ? theme.textColorFor(colorScale(d.series))
                    : theme.background;
                }}
                x={d => dimAxis.scale(d.category)}
                y={d => {
                  if (isStacked) {
                    const y0 = valueAxis.scale(d.base || valueAxis.zero);
                    const y1 = valueAxis.scale(d.base + d.v);
                    return y0 + (y1 - y0) / 2;
                  }
                  return valueAxis.scale(d.v);
                }}
                onPoint={(fact, e) => {
                  const { series, category, cell } = fact;
                  let textValue = printCell(cell, format, this.props.locale);
                  if (cell && !/\S/.test(textValue)) {
                    textValue = '"' + textValue + '"';
                  }
                  this.setState({
                    hover: {
                      title: labels ? labels[category] : String(category + 1),
                      dimspec: { [legend[series].text]: textValue },
                      simple: size[1] === 1,
                      cursorPosition: getCursorPosition(e),
                    },
                  });
                }}
                onUnPoint={() => {
                  this.setState({ hover: null });
                }}
                />

            </Clip>
          </g>

          <DataAxis
            data={dimAxis}
            y={height}
            />

          <ValueAxis
            data={valueAxis}
            />
        </g>

        <Footnote
          data={footnote}
          y={footnoteY}
          />

        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
