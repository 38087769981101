import React from 'react';
import PropTypes from 'prop-types';

import { deepCompare, functor } from '@/grid/utils';

export default class DotLayer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
    r: PropTypes.oneOfType([ PropTypes.func, PropTypes.number ]),
    fill: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]).isRequired,
    stroke: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
    strokeWidth: PropTypes.number,
    defined: PropTypes.oneOfType([ PropTypes.func, PropTypes.bool ]),
    series: PropTypes.array,
    opacity: PropTypes.oneOfType([ PropTypes.func, PropTypes.number ]),
    onPoint: PropTypes.func,
    onUnPoint: PropTypes.func,
  };

  static defaultProps = {
    visible: true,
    defined: true,
    strokeWidth: 1.5,
    r: 3,
  };

  constructor (props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate (nextProps, nextState) {
    // if this was invisible and will be, don't bother
    if (!nextProps.visible && !this.props.visible) {
      return false;
    }
    return deepCompare(this, nextProps, nextState);
  }

  render () {
    const { className, series, visible, x, y, r, opacity, fill, stroke, strokeWidth, defined } = this.props;
    if (!visible) {
      return null;
    }
    const _r = functor(r, 3);
    const _opacity = functor(opacity, 1);
    const _fill = functor(fill, 'currentColor');
    const _stroke = functor(stroke, 'currentColor');
    const _defined = functor(defined, true);

    return (
      <g fill="none" className={className}>
        {series.map((data, i) => {
          return (
            <g key={i}>
              {data.map((d, j) => {
                if (!d || !_defined(d)) {
                  return null;
                }
                const fillColor = _fill(d);
                const strokeColor = _stroke(d);
                const alphaValue = _opacity(d);
                return (
                  <circle
                    pointerEvents="all"
                    key={j}
                    fillOpacity={fillColor ? alphaValue : undefined}
                    fill={fillColor}
                    strokeOpacity={strokeColor ? alphaValue : undefined}
                    strokeWidth={strokeColor ? strokeWidth : undefined}
                    stroke={strokeColor}
                    cx={x(d)}
                    cy={y(d)}
                    r={_r(d)}
                    onMouseMove={e => this.props.onPoint?.(d, e)}
                    onMouseLeave={this.props.onUnPoint}
                    />
                );
              })}
            </g>
          );
        })}
      </g>
    );
  }
}
