import React, { Fragment } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { isValidHttpUrl } from '@grid-is/browser-utils';

import { elementVisibility, tooltip, tooltipFill, tooltipLabel } from '../propsData';
import { ThemesContext } from '../ThemesContext';
import Tooltip from '../Tooltip';
import isEmoji from '../utils/isEmoji';
import Wrapper from '../Wrapper';

import styles from './tooltip.module.scss';

const elementOptions = {
  visible: elementVisibility,
  tooltip: tooltip,
  title: tooltipLabel,
  fill: tooltipFill,
};

// count the number of codepoints in a string
const strLenClass = str => {
  let count = 0;
  // for-of/Array.from will operate on codepoints rather than bytes
  // this is still not perfect (graphemes) but it is good enough
  for (const c of str) { // eslint-disable-line
    count++;
    if (count > 3) {
      break;
    }
  }
  if (count === 0) {
    return styles.len0;
  }
  if (count === 1) {
    return styles.len1;
  }
  if (count === 2) {
    return styles.len2;
  }
  return styles.len3;
};

const urlify = bodyText => {
  return bodyText.split(' ').map((text, i) => {
    if (isValidHttpUrl(text)) {
      let suffix = ' ';
      let url = text;
      const lastChar = url.slice(-1);
      const punctuationMarks = [ '!', '.', ',' ];
      if (punctuationMarks.includes(lastChar)) {
        url = url.slice(0, -1);
        suffix = lastChar + suffix;
      }
      return (
        <Fragment key={url + '-' + i}>
          <a target="_blank" rel="noopener noreferrer" href={url.trim()}>{url.trim()}</a>{suffix}
        </Fragment>);
    }
    return text + ' ';
  });
};

export default class GridTooltip extends React.Component {
  static propTypes = {
    element: PropTypes.object,
    isEditor: PropTypes.bool,
    testId: PropTypes.string,
  };

  static options = elementOptions;
  static contextType = ThemesContext;

  constructor (props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  onMouseEnter = () => {
    this.showTooltip();
  };

  onMouseLeave = () => {
    setTimeout(() => this.hideTooltip(), 300);
  };

  onBlur = e => {
    if (!e.target.getAttribute('data-tooltip')) {
      this.hideTooltip();
    }
  };

  showTooltip = () => {
    this.setState({ showTooltip: true });
  };

  hideTooltip = () => {
    this.setState({ showTooltip: false });
  };

  render () {
    const props = this.props;
    const { showTooltip } = this.state;
    const { theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);

    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const hoverText = tooltip.read(props);
    const label = (tooltipLabel.read(props) || '?').trim();
    const fill = theme.resolveColor(tooltipFill.read(props));
    const unsetText = props.isEditor ? 'Please provide a tooltip text' : '';

    return (
      <Wrapper {...props} className={styles.tooltipContainer}>
        <span
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onFocus={this.onMouseEnter}
          onBlur={this.onBlur}
          tabIndex={0}
          data-tooltip
          >
          <Tooltip
            hoverNode={tooltip.isSet(props) ? urlify(hoverText) : unsetText}
            cursorPosition={showTooltip ? 'static' : null}
            showAnchor
            >
            <sup
              ref={elm => (this.tooltip = elm)}
              data-testid={props.testId}
              className={csx(
                styles.tooltipHelper,
                fill && styles.filled,
                strLenClass(label),
                isEmoji(label) && styles.emoji,
              )}
              >
              <span>{label}</span>
            </sup>
          </Tooltip>
        </span>
      </Wrapper>
    );
  }
}
