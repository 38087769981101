/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import { Reference } from '@grid-is/apiary';

import DataError from '../DataError';
import { elementVisibility, expSheet, layoutWidth, optHighlightColor, optHighlightRef, optSheetHeight, optSheetTabs, optSheetWidth, showHeaders } from '../propsData';
import { validExpr } from '../utils';
import Wrapper from '../Wrapper';
import { resolveRenderContext } from './sheetui/resolveRenderContext';
import { SheetGrid } from './sheetui/SheetGrid';

import styles from './sheet.module.scss';

const MAX_SIZE_COL = 16384;
const MAX_SIZE_ROW = 1048576;

const elementOptions = {
  [expSheet.name]: expSheet,
  [showHeaders.name]: showHeaders,
  [elementVisibility.name]: elementVisibility,
  [layoutWidth.name]: layoutWidth,
  // Sheet can take these extra options which we don't expose in the UI
  // [optSheetTabs.name]: optSheetTabs,
  // [optHighlightRef.name]: optHighlightRef,
  // [optSheetHeight.name]: optSheetHeight,
  // [optSheetWidth.name]: optSheetWidth,
};

/**
 * @param {HTMLElement} parent
 * @param {HTMLElement} child
 * @param {boolean} setFocus
 */
function scrollIntoView (parent, child, setFocus = false) {
  const p = parent.getBoundingClientRect();
  const b = child.getBoundingClientRect();
  let left = null;
  const leftEdge = b.left - p.left;
  const rightEdge = (p.left + parent?.clientWidth) - b.right;
  if (leftEdge < 0) {
    left = parent.scrollLeft + leftEdge - 25;
  }
  else if (rightEdge < 0) {
    left = parent.scrollLeft - rightEdge + 25;
  }
  if (left != null) {
    parent?.scrollTo({ left: left, behavior: 'smooth' });
  }
  setFocus && child.focus();
}

/**
 * @param {object} props
 * @param {boolean} props.visible
 * @param {string[]} props.items
 * @param {function} props.onChange
 * @param {string} [props.current]
 * @return ReactNode
 */
function Tabs ({ visible, items, current, onChange }) {
  /** @type {import('react').MutableRefObject<HTMLDivElement | null>} */
  const tabs = useRef(null);
  if (!visible || !items.length) {
    return null;
  }
  if (current == null) {
    current = items[0];
  }
  return (
    <div role="tablist" className={styles.tabs} ref={tabs}>
      {items.map((item, index) => (
        <button
          className={styles.tab + (current === item ? ' ' + styles.current : ' ')}
          key={item}
          type="button"
          role="tab"
          aria-selected={current === item}
          tabIndex={current === item ? 0 : -1}
          onClick={e => {
            onChange(item);
            // @ts-expect-error
            tabs.current && scrollIntoView(tabs.current, e.target);
          }}
          onKeyDown={e => {
            let nth = -1;
            if (e.key === 'ArrowLeft' && items[index - 1]) {
              nth = index - 1;
            }
            if (e.key === 'ArrowRight' && items[index + 1]) {
              nth = index + 1;
            }
            if (nth >= 0) {
              e.preventDefault();
              onChange(items[nth]);
              if (tabs.current) {
                const button = tabs.current.getElementsByTagName('button')[nth];
                scrollIntoView(tabs.current, button, true);
              }
            }
          }}
          >
          {item}
        </button>
      ))}
    </div>
  );
}

/**
 * @param {object} props
 * @param {string} props.expr
 * @param {Model} props.model
 * @param {boolean} [props.isEditor]
 * @param {string} [props.locale]
 * @param {string} [props.highlight]
 * @param {string} [props.highlightColor]
 * @param {string|number} [props.height]
 * @param {string|number} [props.width]
 * @return ReactNode
 */
export default function GridSheet (props) {
  /** @type {Model} */
  const model = props.model;
  const ref = expSheet.read(props);
  const okRef = ref instanceof Reference;
  const defaultSheet = okRef ? model.resolveSheet(ref.sheetName, ref.workbookName) : null;
  const [ currentTab, setCurrentTab ] = useState(defaultSheet?.name || '');
  const showTabs = optSheetTabs.read(props);

  const defaultSheetName = defaultSheet ? defaultSheet.name : '';
  useEffect(() => {
    if (!showTabs && defaultSheetName !== currentTab) {
      setCurrentTab(defaultSheetName);
    }
  }, [ showTabs, defaultSheetName, currentTab ]);

  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  let error = null;
  if (!validExpr(props.expr)) {
    error = {};
  }
  if (!okRef) {
    error = { title: 'Invalid reference', message: 'Data is not a valid reference' };
  }
  const workbook = ref && model.getWorkbook(ref.workbookName);
  if (!workbook) {
    error = { title: 'Invalid reference', message: 'Referenced workbook does not exist' };
  }
  else if (!workbook.getSheet(ref.sheetName)) {
    error = { title: 'Invalid reference', message: 'The referenced sheet does not exist' };
  }
  // this should be impossible to trigger, Apiary does not allow references bigger than this maximum
  else if (ref.height > MAX_SIZE_ROW || ref.width > MAX_SIZE_COL) {
    const max = (ref.height > MAX_SIZE_ROW) ? MAX_SIZE_ROW : MAX_SIZE_COL;
    const dim = (max === MAX_SIZE_ROW) ? 'high' : 'wide';
    error = { title: 'Too many cells', message: `The Sheet element may be at most ${max} cells ${dim}. Use the Table element for more.` };
  }
  if (!workbook || error) {
    return (
      <Wrapper className={styles.table} {...props}>
        <DataError
          title={error?.title}
          message={error?.message}
          {...props}
          />
      </Wrapper>
    );
  }

  const viewRef = resolveRenderContext({
    model,
    reference: ref,
    sheetName: currentTab,
  });

  return (
    <Wrapper className={styles.sheet} type="sheet" {...props}>
      <SheetGrid
        locale={props.locale}
        headers={showHeaders.read(props)}
        range={viewRef}
        workbook={model.getWorkbook(viewRef.workbookName)}
        highlight={optHighlightRef.read(props)}
        highlightColor={optHighlightColor.read(props)}
        maxWidth={optSheetWidth.read(props)}
        maxHeight={optSheetHeight.read(props)}
        hasTabs={showTabs}
        />
      <Tabs
        visible={showTabs}
        current={currentTab}
        items={(
          workbook.getSheets()
            .filter(d => !d.hidden)
            .map(d => d.name)
        )}
        onChange={setCurrentTab}
        />
    </Wrapper>
  );
}

GridSheet.options = elementOptions;
GridSheet.requiredOption = 'expr';
