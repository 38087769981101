import React, { useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { color, optDebugOffset } from '../propsData';
import { ThemesContext } from '../ThemesContext';
import Wrapper from '../Wrapper';

import styles from './debugline.module.scss';

const elementOptions = {
  [color.name]: color,
  [optDebugOffset.name]: optDebugOffset,
};

export default function GridDebugLine (props) {
  const { theme } = useContext(ThemesContext);
  const lineRef = useRef(/** @type {null | HTMLElement} */(null));

  const update = useCallback(function () {
    const curr = lineRef?.current;
    if (curr) {
      curr.style.left = '';
      const box = curr.getBoundingClientRect();
      curr.style.left = (-box.left) + 'px';
      curr.style.width = (document.body.clientWidth - 20) + 'px';
    }
  }, [ lineRef ]);

  useEffect(() => {
    update();
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, [ update ]);

  const style = {
    color: theme.resolveColor(color.read(props)),
    top: optDebugOffset.read(props) + 'px',
  };

  return (
    <Wrapper className={styles.debugline} {...props}>
      <i className={styles.line} style={style} ref={lineRef} />
      {props.isEditor ? (
        <span style={{ color: style.color }} className={styles.handle} />
      ) : null}
    </Wrapper>
  );
}

GridDebugLine.options = elementOptions;
GridDebugLine.propTypes = {
  isEditor: PropTypes.bool,
  model: modelProp.isRequired,
};
