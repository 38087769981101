import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { getViewport } from '@grid-is/browser-utils';
import { fullScreenElement } from '@grid-is/custom-hooks/src/useFullScreen';

import { ThemesContext } from '@/grid/ThemesContext';
import { getOffsetParent, getPosition, relativeMouse } from '@/utils/offset';

import styles from './Tooltip.module.scss';

// Tooltips should not be rendered closer to the viewport edge than this number.
const viewportPadding = 6;

export default function Tooltip (props) {
  const { theme } = useContext(ThemesContext);
  const { hoverNode, cursorPosition, showAnchor, className, gap, sliderShift, sliderWidthAdjust } = props;

  /** @type {React.MutableRefObject<HTMLElement | null>} */
  const targetRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLElement | null>} */
  const bubbleRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLElement | null>} */
  const anchorRef = useRef(null);

  useEffect(() => {
    if (bubbleRef.current && targetRef.current && !cursorPosition) {
      bubbleRef.current.style.display = 'none';
    }
    else if (bubbleRef.current && targetRef.current && cursorPosition) {
      const bubbleStyle = bubbleRef.current.style;
      bubbleStyle.display = '';
      bubbleStyle.left = '-1000px'; // prevent this from affecting viewport width

      const tRect = targetRef.current.getBoundingClientRect();
      const cRect = getOffsetParent(bubbleRef.current).getBoundingClientRect();
      const bubbleHeight = bubbleRef.current.clientHeight;
      const bubbleWidth = bubbleRef.current.clientWidth;
      const x = tRect.left - cRect.left;
      const y = tRect.top - cRect.top;

      if (cursorPosition === 'static') {
        const { width: viewportWidth } = getViewport();
        const minX = -cRect.left + viewportPadding;
        const maxX = (-cRect.left + viewportWidth - (viewportPadding * 2)) - bubbleWidth;
        let anchorX = bubbleWidth / 2;
        const wMul = sliderShift == null ? 0.5 : sliderShift;
        const wAdj = sliderWidthAdjust || 0;
        let left = x + (-wAdj * 0.5) + ((tRect.width + wAdj) * wMul - bubbleWidth / 2);

        if (left < minX) {
          anchorX = (bubbleWidth / 2) - (minX - left);
          left = minX;
        }
        else if (left > maxX) {
          anchorX = (bubbleWidth / 2) - (maxX - left);
          left = maxX;
        }
        if (anchorRef.current) {
          anchorRef.current.style.left = anchorX + 'px';
        }
        bubbleStyle.top = (y - bubbleHeight - gap) + 'px';
        bubbleStyle.left = left + 'px';
      }
      else {
        const p = getPosition(targetRef.current);
        const mouse = relativeMouse(cursorPosition, targetRef.current);
        const mouseX = (mouse.left || 0) + p.left + x;
        const mouseY = (mouse.top || 0) + p.top + y;
        bubbleStyle.left = (mouse.left || 0) > (tRect.width / 2)
          ? (mouseX - bubbleWidth - 15) + 'px'
          : (mouseX + 15) + 'px';
        bubbleStyle.top = (mouse.top || 0) > (tRect.height / 2)
          ? (mouseY - bubbleHeight - 10) + 'px'
          : (mouseY + 10) + 'px';
      }
    }
  }, [ anchorRef, cursorPosition, gap, hoverNode, sliderShift, sliderWidthAdjust ]);

  const bubble = (
    <span
      role="tooltip"
      style={/** @type {React.CSSProperties} */({
        'display': 'none',
        '--tooltip-background': theme.color,
        '--tooltip-color': theme.textColorFor(theme.color),
        '--tooltip-link-color': theme.linkColorFor(theme.color),
        '--tooltip-font': theme.baseFontStack,
      })}
      className={csx(
        styles.tooltip,
        className,
        showAnchor ? styles.withAnchor : null,
      )}
      ref={bubbleRef}
      >
      {hoverNode}
      <span
        className={csx(styles.anchor, !showAnchor && styles.hide)}
        ref={anchorRef}
        />
    </span>
  );

  // component accepts a single child as a target
  let subTree = null;
  if (props.children) {
    const child = React.Children.only(props.children);
    // Maintain the existing ref property after cloning the element.
    // https://github.com/facebook/react/issues/8873
    subTree = React.cloneElement(child, {
      ref: node => {
        // Keep our own reference to the element ...
        targetRef.current = node;
        // ... while also calling the original ref, if any
        const { ref } = child;
        if (typeof ref === 'function') {
          ref(node);
        }
        else if (ref !== null) {
          ref.current = node;
        }
      },
    });
  }

  const container = (
    props.portalContainer ||
    fullScreenElement() ||
    document.body
  );
  return (
    <>
      {subTree}
      {cursorPosition && container ? ReactDOM.createPortal(bubble, container) : null}
    </>
  );
}

Tooltip.propTypes = {
  portalContainer: PropTypes.element,
  className: PropTypes.string,
  sliderShift: PropTypes.number,
  sliderWidthAdjust: PropTypes.number,
  children: PropTypes.node,
  cursorPosition: PropTypes.oneOfType([
    PropTypes.exact({
      // eslint-disable-next-line react/no-unused-prop-types
      clientX: PropTypes.number.isRequired,
      // eslint-disable-next-line react/no-unused-prop-types
      clientY: PropTypes.number.isRequired,
    }),
    PropTypes.string,
  ]),
  showAnchor: PropTypes.bool,
  hoverNode: PropTypes.node.isRequired,
  gap: PropTypes.number,
};

Tooltip.defaultProps = {
  showAnchor: false,
  gap: 10,
};
