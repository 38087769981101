/**
 * Clamps a middle value within a range of values between a defined minimum
 * bound and a maximum bound. The function is modelled on the CSS function
 * of the same name: https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
 *
 * @param {number} min Minimum allowed value
 * @param {number} val Preferred value
 * @param {number} max Maximum allowed value
 * @return {number}
 */
export function clamp (min, val, max) {
  return Math.max(min, Math.min(max, val));
}
