import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import modelProp from '@/grid/modelProp';
import {
  disabled,
  elementVisibility,
  format,
  max,
  min,
  optAlignValue,
  optInlineType,
  optWidthAuto,
  step,
  targetCell,
  titleLabel,
} from '@/grid/propsData';
import Tooltip from '@/grid/Tooltip';
import { printCell, validExpr } from '@/grid/utils';
import { alignmentFromCell } from '@/grid/utils/alignmentFromCell';
import { getAutoStep } from '@/grid/utils/getAutoStep';
import stepNormalize from '@/grid/utils/stepNormalize';
import Wrapper from '@/grid/Wrapper';

import styles from './tangle.module.scss';

const elementOptions = {
  expr: targetCell,
  format,
  min,
  max,
  step,
  disabled,
  visible: elementVisibility,
  title: titleLabel,
  inline: optInlineType,
  width: optWidthAuto,
  align: optAlignValue,
};

/**
 * React component for an inline interactive value within a Grid document.
 *
 * This is often called a "tangle", after Bret Victor's original implementation.
 * The component allows someone reading a document to alter the value by
 * dragging it left and right with their mouse (or finger on mobile). Altering
 * the value will change the underlying cell's value --- and this allows the
 * reader to play with the document's parameters.
 *
 * @see http://worrydream.com/Tangle/
 */
export default class GridTangle extends React.PureComponent {
  static options = elementOptions;
  static requiredOption = 'expr';
  static isInput = true;

  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    expr: PropTypes.string,
    isEditor: PropTypes.bool,
    locale: PropTypes.string,
    track: PropTypes.func,
  };

  static getDerivedStateFromProps (props) {
    const hasTarget = targetCell.isSet(props);
    const cell = targetCell.read(props);
    const fmtStr = format.read(props);
    let title = 'Interactive value';
    let isValid = true;
    let valueNow;
    if (!hasTarget) {
      isValid = false;
    }
    else if (cell && typeof cell.v === 'number') {
      valueNow = cell.v;
      title = printCell(cell, fmtStr, props.locale);
    }
    else if (cell && 'f' in cell && cell.v == null) {
      // A target cell's been selected but it has no value.
      title = printCell({ v: 0 }, fmtStr, props.locale);
    }
    else if (validExpr(props.expr)) {
      title = 'Target cell has non-numeric value';
      isValid = false;
    }
    const textAlign = optAlignValue.read(props) || alignmentFromCell(cell);
    return {
      title,
      numberFormat: fmtStr || cell.z,
      alignment: textAlign,
      cell,
      valueNow,
      min: min.read(props),
      max: max.read(props),
      step: step.read(props),
      isDisabled: disabled.read(props),
      hasTarget,
      isValid,
    };
  }

  constructor (props) {
    super(props);

    this.state = { interacting: false, showTooltip: false };
    this.spanRef = React.createRef();
  }

  componentDidMount () {
    const elm = this.spanRef.current;
    if (elm) {
      elm.addEventListener('selectstart', this.preventDefault);
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1166044
      elm.addEventListener('dragstart', this.preventDefault);
      elm.addEventListener('touchstart', this.preventDefault, { passive: false });
    }
  }

  componentWillUnmount () {
    const elm = this.spanRef.current;
    if (elm) {
      elm.removeEventListener('selectstart', this.preventDefault);
      elm.removeEventListener('dragstart', this.preventDefault);
      elm.removeEventListener('touchstart', this.preventDefault, { passive: false });
    }
  }

  onInteractStart = event => {
    event.preventDefault();
    const cell = targetCell.read(this.props);
    this.setState({
      interacting: true,
      showTooltip: false,
      startX: event.clientX,
      value: cell?.v || 0,
    });
    event.target.setPointerCapture(event.pointerId);
    this.props.track('interact', { elementType: 'tangle' });
  };

  onInteractEnd = event => {
    this.setState({ interacting: false });
    event.target.releasePointerCapture(event.pointerId);
  };

  onDragMovement = event => {
    if (this.state.interacting) {
      const { min, max, step, startX, numberFormat } = this.state;

      // anchorValue can be null if the initial cell was blank.
      // So we use the first non-zero value that we encounter.
      if (!this.anchorValue && this.state.value) {
        this.anchorValue = this.state.value;
      }
      const moveSize = (event.shiftKey && !step)
        ? 1
        : step || getAutoStep(this.anchorValue, numberFormat, min, max);

      const distance = event.clientX - startX;
      const delta = 6; // Drag distance between steps.
      const power = 1.2;
      let stepValue = Math.abs(distance) ** power * Math.sign(distance) * moveSize / delta;
      stepValue = Math.floor(stepValue / moveSize) * moveSize;

      let value = this.state.value + stepValue;
      value = stepNormalize(value, min, max, step);
      if (this.state.cell.v !== value) {
        targetCell.write(this.props, value);
      }
    }
  };

  onPointerEnter = () => {
    this.setState({ showTooltip: true });
  };

  onPointerLeave = () => {
    this.setState({ showTooltip: false });
  };

  /**
   * Reusable function to prevent default event action.
   *
   * Used to remove default behaviour for "selectstart" and "ontouchstart" event
   * listeners. We need to stop the browser from selecting text when the user is
   * interacting with a tangle instance. React doesn't support the onSelectStart
   * event, and so we need to handle it using refs instead.
   *
   * > We probably shouldn't add it [support for onSelectStart] just yet, because
   * > people will use it and expect it to work across platforms, and blame React
   * > when it doesn't
   *
   * --- https://github.com/facebook/react/issues/16521
   */
  preventDefault = e => {
    if (!this.state.isDisabled) {
      e.preventDefault();
    }
  };

  render () {
    const props = this.props;
    const { isValid, isDisabled, min, max, valueNow, title, hasTarget, alignment } = this.state;

    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const blockEvents = !isValid || isDisabled || !hasTarget;
    const inlineMode = optInlineType.read(props);
    const width = optWidthAuto.read(props);

    const cls = csx(
      styles.tangle,
      !isValid ? styles.invalid : '',
      isDisabled ? styles.disabled : '',
    );
    return (
      <Wrapper
        {...props}
        className={cls}
        inlineMode={inlineMode}
        label={titleLabel.read(props)}
        inlineWidth={width}
        isDisabled={isDisabled}
        >
        <span
          className={csx(
            styles.outerValue,
            (inlineMode === 'text' && !width) && styles.inline,
          )}
          style={{ textAlign: alignment }}
          >
          <Tooltip
            cursorPosition={this.state.showTooltip ? 'static' : null}
            showAnchor
            gap={6}
            hoverNode="Click and drag!"
            >
            <span
              className={styles.value + ' output'}
              role="slider"
              aria-valuemax={max}
              aria-valuemin={min}
              aria-valuenow={blockEvents ? 'unset' : valueNow}
              aria-disabled={blockEvents ? 'true' : undefined}
              onPointerDown={blockEvents ? undefined : this.onInteractStart}
              onPointerUp={blockEvents ? undefined : this.onInteractEnd}
              onPointerMove={blockEvents ? undefined : this.onDragMovement}
              onPointerEnter={blockEvents ? undefined : this.onPointerEnter}
              onPointerLeave={blockEvents ? undefined : this.onPointerLeave}
              ref={this.spanRef}
              >
              {title || '\u00a0'}
            </span>
          </Tooltip>
        </span>
      </Wrapper>
    );
  }
}
