// @ts-nocheck
import { format as formatValue, getLocale, isDateFormat, isPercentFormat, isTextFormat } from 'numfmt';

import { parseDateTime } from '@grid-is/apiary';

const TIME_FORMAT_WITH_SEC = 'HH:MM:SS';
const DATE_FORMAT_MONTH_FIRST = 'MM/DD/YYYY';
const DATE_FORMAT_DAY_FIRST = 'DD/MM/YYYY';
const EPSILON_HALF_SECOND = 1.0 / 60 / 60 / 24 / 2;

function monthFirst (locale) {
  // US english is considered the default english
  return locale === 'en-US' || locale === 'en';
}

export function getLocaleDateEditingFormat (locale, date) {
  if (typeof date === 'number' && date >= 0 && date < 1) {
    // don't include date part if it is 0, that really represents an intra-day time without a date part.
    return TIME_FORMAT_WITH_SEC;
  }
  let format = monthFirst(locale) ? DATE_FORMAT_MONTH_FIRST : DATE_FORMAT_DAY_FIRST;
  if (typeof date === 'number' && Math.abs(date % 1) >= EPSILON_HALF_SECOND) {
    format += ' ' + TIME_FORMAT_WITH_SEC;
  }
  return format;
}

/**
 * Parse the an input value based on the cell format and document locale, and return the result as a valid
 * spreadsheet cell value.
 */
export function parseInputValue (value, format, locale = 'en-US') {
  if (typeof value !== 'string' || !value) {
    return value;
  }
  if (value[0] === "'") {
    // starts with a single-quote character, which in Excel explicitly selects string treatment, so follow that
    return value.slice(1);
  }
  // a text number format must be respected
  // input that is "pure whitespace" must stay intact
  if (isTextFormat(format) || (value && value.trim() === '')) {
    return value;
  }
  // booleans
  if (/^(true|false)$/i.test(value)) {
    return value.toLowerCase() === 'true';
  }
  // TODO: errors: "#VALUE!" => Apiary value error
  // percentages
  let isPercent = isPercentFormat(format);
  let localeNumberRepr = (value || '').trim();
  if (/%\s*$/.test(localeNumberRepr)) {
    isPercent = true;
    localeNumberRepr = localeNumberRepr.replace(/\s*%\s*$/, '');
  }
  // first try to recognize as a number, then date, then fall back on returning the string as-is
  const { group, decimal } = getLocale(locale);
  const ungrouped = localeNumberRepr.replace(new RegExp(`\\${group}(\\d{3})(?!\\d)`, 'g'), '$1');
  const cleanedValue = new RegExp(`\\${group}|\\${decimal}.*\\${decimal}`).test(ungrouped)
    ? 'nope' // bogus grouping, e.g. 10.00.000, or decimal marker appears more than once; don't treat this as a number
    : ungrouped.replace(new RegExp('\\' + decimal, 'g'), '.');
  if (isFinite(cleanedValue)) {
    return +cleanedValue * (isPercent ? 0.01 : 1);
  }

  const date = parseDateTime(value.replace(/\s/g, ' '), !monthFirst(locale), false);
  return (date instanceof Error) ? value : date;
}

/**
 * Determine the editing format based on the cell format and document locale, and return the cell value
 * represented in that editing format.
 *
 * For date formats, the editing format is the short format MM/DD/YYYY or DD/MM/YYYY, depending on the day-month
 * ordering preference of the document locale. Note that the specific cell format has no effect, other than by being
 * recognized as a date format in the first place.
 *
 * For percentage formats, the editing format is a fixed-point representation of the cellValue times a hundred, with
 * up to 15 digits after the decimal marker but trailing zero decimal digits stripped, and a % concatenated at the
 * end. The decimal marker is that of the document locale.
 *
 * For any other cell format, the editing format is as for percentage formats, without the multiplication by a hundred
 * and the concatenation of the % symbol.
 *
 * Regardless of cell format, if the cell value is not a number, then the editing value is simply the unchanged cell
 * value if it is a string, or `TRUE` or `FALSE` if it is a boolean.
 *
 * @param {unknown} cellValue
 * @param {string} [format]
 * @param {string} [locale]
 * @returns {string}
 */
export function renderInputValue (cellValue, format, locale = 'en-US') {
  let editingValue = '';
  if (cellValue === true) {
    editingValue = 'TRUE';
  }
  else if (cellValue === false) {
    editingValue = 'FALSE';
  }
  else if (typeof cellValue === 'string') {
    editingValue = cellValue;
    if (typeof parseInputValue(editingValue, format, locale) === 'number' ||
        typeof parseInputValue(editingValue, format, locale) === 'boolean') {
      // this string would be parsed as a number/date, so prepend single-quote in editing format to prevent that
      editingValue = "'" + editingValue;
    }
  }
  else if (cellValue && !isFinite(cellValue)) {
    editingValue = String(cellValue);
  }

  if (typeof cellValue === 'number' && format && cellValue >= 0 && isDateFormat(format)) {
    // date format
    if (cellValue != null && isFinite(cellValue)) {
      const editingFormat = getLocaleDateEditingFormat(locale, cellValue);
      editingValue = formatValue(editingFormat, cellValue);
      if (editingValue === '#VALUE!' && typeof cellValue === 'number') {
        // refused to format (presumably negative) number as date, so edit it as a number, not as the string '#VALUE!'
        // FIXME: address this case in printCell or numfmt rather than work around it here
        editingValue = String(cellValue);
      }
    }
  }
  else {
    // non-date format (such as a percentage)
    const percentageFormat = isPercentFormat(format);
    const { decimal: decimalMarker } = getLocale(locale);
    if (cellValue == null || cellValue === '') {
      editingValue = '';
    }
    else if (typeof cellValue === 'number') {
      editingValue = percentageFormat
        ? +(cellValue * 100).toFixed(13) + '%'
        : String(cellValue);

      editingValue = editingValue.replace('.', decimalMarker);
    }
  }
  return editingValue;
}

/**
 * Determine and return a new caret position for an input for when its value changes to editing format.
 *
 * The editing caret position is wherever the user clicked to focus the input if the focus happened via mouse click
 * or tap and the editing-formatted value is identical to the previous formatted value up to that point (so that it
 * really is “the same point”), else at the end (or before the % symbol, in the case of percentage formats).
 *
 * So e.g. if the real value is 3.49% and the formatted value is 3.5%, then:
 * * if the user clicks after 3. or earlier, then we put the caret in the same place
 * * if the user clicks after the 4, then we put the caret before the % symbol.
 *
 * Excel conformance notes:
 *
 * We don't leave the caret _after_ the percent symbol (even if the user clicks there) as it's very unlikely that
 * the user really means to delete the percent symbol, changing e.g. 25% to 2500% (and in the rare event that they
 * do mean to do that, it's still not hard).
 *
 * In Excel, when you select a percentage-formatted cell and start typing, your typing starts from an empty value but
 * with a % symbol after it, so you're typing a number from scratch which is a percentage, unless you delete the %
 * symbol.
 *
 * Here, when you select a cell, it still has its value (though possibly reformatted) and you get a caret in it,
 * from which your typing will proceed. That is the custom in web browsers.
 *
 * This seems to be an acceptable compromise:
 * * we depart from what users are used to in Excel (but stick to what users are used to in most web apps and
 *   other software) in that the existing value is not discarded when typing starts
 * * we do like Excel in that the % symbol stays in place
 * * we gently nudge the user away from inadvertently removing the % symbol.
 *
 * We _could_ get closer to Excel (and further from the more common conventions) by selecting the whole number
 * (but not the %) on focus, so that when you start typing you're replacing the number unless you first click or
 * hit an arrow key to get rid of the selection. But that's departing from the more common behavior of an HTML
 * input element and feels more obtrusive.
 */
export function getCaretPosition (pos, presentationValue, editingValue, locale = 'en-US') {
  // is a date
  if (/^(\d\d\/\d\d\/\d{4,}|\d\d\/\d\d\/\d{4,}\s\d\d:\d\d:\d\d|\d\d:\d\d:\d\d)$/.test(editingValue)) {
    const endPosition = editingValue.length;
    if (pos >= presentationValue.length) {
      // user clicked at end of displayed value, so put cursor at end of editing value
      return endPosition;
    }
    const keepPosition = pos < endPosition && editingValue.slice(0, pos) === presentationValue.slice(0, pos);
    return keepPosition ? pos : endPosition;
  }
  // is not a date
  else {
    // prefixed strings
    if (editingValue.startsWith("'")) {
      return pos + (editingValue === "'" + presentationValue ? 1 : 0);
    }
    // percentages
    let lastPosition = editingValue.length;
    if (/%$/.test(editingValue)) {
      lastPosition--;
    }
    const { group } = getLocale(locale);
    const ungroupedPresentationValue = presentationValue.replace(new RegExp('\\' + group, 'g'), '');
    let newpos = 0;
    if (pos >= presentationValue.length ||
        /%\s*$/.test(presentationValue) && pos >= presentationValue.lastIndexOf('%')) {
      // user clicked at end of displayed value or just before the %, so put cursor at corresponding place in editing value
      return lastPosition;
    }
    for (; newpos < lastPosition && newpos < ungroupedPresentationValue.length && newpos < pos; newpos += 1) {
      if (presentationValue[newpos] === group) {
        pos -= 1;
        if (newpos === pos) {
          break;
        }
      }
      else if (editingValue[newpos] !== ungroupedPresentationValue[newpos]) {
        return lastPosition;
      }
    }
    return newpos;
  }
}

