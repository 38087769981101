import React from 'react';
import PropTypes from 'prop-types';

import { ANNOTATION_DEFAULTS } from '@/grid/ChartTheme';
import { printCell } from '@/grid/utils';
import { clamp } from '@/grid/utils/clamp';

import ChartLabel from '../utils/ChartLabel';
import { Axis } from '../utils/prepAxis';

const EMPTY_CELL = { v: null };
Object.freeze(EMPTY_CELL);

/**
 * @typedef {{ (i?: number): import('@/grid/types').Cellish; size: number; }} AccessorFunction
 */
/**
 * @param {import('@/grid/types').CellArray | string | number | boolean} value
 * @return {AccessorFunction}
 */
function getAccessor (value) {
  if (Array.isArray(value)) {
    /** @type {AccessorFunction} */
    let fn;
    const w = value[0].length - (value.emptyRight || 0);
    const h = value.length - (value.emptyBottom || 0);
    if (w * h === 0) {
      fn = Object.assign(() => EMPTY_CELL, { size: 1 });
    }
    else if (w === 1 && h === 1) {
      // TODO: don't quite know if this is safe --- need better tests
      fn = Object.assign(() => value[0][0] ?? EMPTY_CELL, { size: h });
    }
    else if (w === 1 || w < h) {
      fn = Object.assign(
        /** @param {number} [i] */
        i => (i != null && value[i] ? value[i][0] : EMPTY_CELL),
        { size: h },
      );
    }
    else {
      fn = Object.assign(
        /** @param {number} [i] */
        i => value[0][i ?? -1] ?? EMPTY_CELL,
        { size: w },
      );
    }
    return fn;
  }
  const fn = () => ({ v: value });
  fn.size = 1;
  return fn;
}

function renderGradients (id = 'anno-grad') {
  return (
    <defs>
      <linearGradient id={id + '-y'}>
        <stop offset="0%" stopColor="#F5F5F5" stopOpacity={0} />
        <stop offset="20%" stopColor="#D8D9E5" stopOpacity={0.2} />
        <stop offset="80%" stopColor="#D8D9E5" stopOpacity={0.2} />
        <stop offset="100%" stopColor="#F5F5F5" stopOpacity={0} />
      </linearGradient>
      <linearGradient id={id + '-x'} gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#F5F5F5" stopOpacity={0} />
        <stop offset="20%" stopColor="#D8D9E5" stopOpacity={0.2} />
        <stop offset="80%" stopColor="#D8D9E5" stopOpacity={0.2} />
        <stop offset="100%" stopColor="#F5F5F5" stopOpacity={0} />
        {/* XXX: add an XY for area? */}
      </linearGradient>
    </defs>
  );
}

const flippedType = {
  xrange: 'yrange',
  yrange: 'xrange',
  xline: 'yline',
  yline: 'xline',
};
export function prepAnnotations (annotationData, { flipXY } = { flipXY: false }) {
  const X1 = flipXY ? 'y1' : 'x1';
  const X2 = flipXY ? 'y2' : 'x2';
  const Y1 = flipXY ? 'x1' : 'y1';
  const Y2 = flipXY ? 'x2' : 'y2';
  // annotation arrays are only read in 1 direction
  const allAnnotationItems = [];
  let idCounter = 1;
  annotationData.forEach(note => {
    // basic settings
    const { type, title, visible } = note;
    const titleAttr = getAccessor(title);
    const visibleAttr = getAccessor(visible);
    // find the required dimension settings for this type
    /** @type {Array<[ string, AccessorFunction ]>} */
    const reqAttr = [];
    if (type === 'point' || type === 'area' || type === 'xline' || type === 'xrange') {
      reqAttr.push([ X1, getAccessor(note.x1) ]);
    }
    if (type === 'point' || type === 'area' || type === 'yline' || type === 'yrange') {
      reqAttr.push([ Y1, getAccessor(note.y1) ]);
    }
    if (type === 'xrange' || type === 'area') {
      reqAttr.push([ X2, getAccessor(note.x2) ]);
    }
    if (type === 'yrange' || type === 'area') {
      reqAttr.push([ Y2, getAccessor(note.y2) ]);
    }
    if (reqAttr.length) {
      const noteCount = Math.max(...reqAttr.map(a => a[1].size));
      for (let i = 0; i < noteCount; i++) {
        const baseNote = {
          type: flipXY ? flippedType[type] ?? type : type,
          // we're purposely incrementing for non-adds to keep IDs stable for React
          id: idCounter++,
        };
        let ok = 0;
        for (let j = 0; j < reqAttr.length; j++) {
          const [ key, accessor ] = reqAttr[j];
          const cell = accessor(i);
          baseNote[key] = cell.v;
          if (cell.v == null) {
            break;
          }
          ok += 1;
        }
        if (ok === reqAttr.length) {
          const text = titleAttr(i);
          baseNote.title = (!text || text.v == null) ? null : printCell(text);
          const vis = visibleAttr(i);
          baseNote.visible = vis == null ? false : !!(vis.v ?? true);
          allAnnotationItems.push(baseNote);
        }
      }
    }
  });
  return allAnnotationItems;
}

// align, vAlign, x, y
const labelPosition = {
  NE: [ 'left', 'bottom', 1, -1 ],
  N:  [ 'center', 'bottom', 0, -1 ],
  NW: [ 'right', 'bottom', -1, -1 ],
  W:  [ 'right', 'middle', -1, 0 ],
  SW: [ 'right', 'top', -1, 1 ],
  S:  [ 'center', 'top', 0, 1 ],
  SE: [ 'left', 'top', 1, 1 ],
  E:  [ 'left', 'middle', 1, 0 ],
};
function getLabelPos (prefer, textRect, boundaryRect, padding = 10) {
  const [ x, y, w, h ] = textRect;
  const [ bX, bY, bW, bH ] = boundaryRect;
  for (let i = 0; i < prefer.length; i++) {
    const pos = labelPosition[prefer[i]];
    const left = x - (w * 0.5) + pos[2] * (w * 0.5 + padding);
    const top = y - (h * 0.5) + pos[3] * (h * 0.5 + padding);
    const right = left + w;
    const bottom = top + h;
    if (left >= bX && right < (bX + bW) && top >= bY && bottom < (bY + bH)) {
      return pos;
    }
  }
  return labelPosition[prefer[0]] || labelPosition.N;
}

export default function Annotations (props) {
  const { x, y, data, visible } = props;
  if (!visible || !data || !data.length) {
    return null;
  }
  const DEBUG = !!props.debug;
  const isBar = props.pointMark === 'bar';
  const isColumn = props.pointMark === 'column';
  const isDot = !isBar && !isColumn;
  const bandWidth = props.bandWidth;

  const xRange = x.scale.range();
  const yRange = y.scale.range();
  const yMax = Math.max(...yRange);
  const yMin = Math.min(...yRange);
  const xMax = Math.max(...xRange);
  const xMin = Math.min(...xRange);
  const maxWidth = (xMax - xMin) / 4;
  const maxHeight = (yMax - yMin) * 0.45;

  // XXX: detect here if maxWidth is small enough to disable the annotation?

  const styles = props.styles?.annotation ?? ANNOTATION_DEFAULTS;

  const fontSize = styles.fontSize ?? 14;
  const fontFace = styles.fontFamily || props.styles.fontStack;
  const hasRange = data.some(d => d.type === 'xrange' || d.type === 'yrange');

  return (
    <g className="annotations" pointerEvents="none">
      {hasRange && (renderGradients())}
      {data.map(note => {
        const type = note.type;
        if (note.visible === false) {
          return null;
        }
        let x1 = x.scaleByValue(note.x1);
        let x2 = x.scaleByValue(note.x2);
        if (x2 < x1) {
          [ x1, x2 ] = [ x2, x1 ];
        }
        let y1 = y.scaleByValue(note.y1);
        let y2 = y.scaleByValue(note.y2);
        if (y2 < y1) {
          [ y1, y2 ] = [ y2, y1 ];
        }
        if (type === 'point') {
          if ((x1 < xMin || x1 > xMax) ||
              (y1 < xMin || y1 > yMax) ||
              (isNaN(x1) || isNaN(y1))) {
            return null; // out of bounds
          }
          // determine label placement
          const prefer = isColumn
            ? [ 'E', 'W', 'N', 'S', 'NE',  'NW', 'SW',  'SE' ]
            : [ 'N', 'S', 'E', 'W', 'NE',  'NW', 'SW',  'SE' ];
          const [ hAlign, vAlign, xMul, yMul ] = getLabelPos(
            prefer,
            [ x1, y1, maxWidth, maxHeight ],
            [ xMin, yMin, xMax - xMin, yMax - xMin ],
          );
          return (
            <g key={'note-' + note.id} shapeRendering="geometricPrecision">
              {isColumn && (
                <>
                  <rect
                    rx={3}
                    fill={styles.bar.background}
                    fillOpacity={styles.bar.backgroundOpacity}
                    x={x1 - bandWidth / 2 - 4}
                    y={y1 - 2}
                    width={bandWidth + 8}
                    height={4}
                    />
                  <line
                    stroke={styles.bar.color}
                    strokeDasharray={styles.bar.dashArray}
                    strokeOpacity={styles.bar.opacity}
                    strokeWidth={1.3}
                    y1={y1}
                    y2={y1}
                    x1={x1 - bandWidth / 2}
                    x2={x1 + bandWidth / 2}
                    />
                </>
              )}
              {isBar && (
                <>
                  <rect
                    rx={3}
                    fill={styles.bar.background}
                    fillOpacity={styles.bar.backgroundOpacity}
                    x={x1 - 2}
                    y={y1 - bandWidth / 2 - 4}
                    width={4}
                    height={bandWidth + 8}
                    />
                  <line
                    stroke={styles.bar.color}
                    strokeDasharray={styles.bar.dashArray}
                    strokeOpacity={styles.bar.opacity}
                    strokeWidth={1.3}
                    y1={y1 - bandWidth / 2}
                    y2={y1 + bandWidth / 2}
                    x1={x1}
                    x2={x1}
                    />
                </>
              )}
              {isDot && (
                <circle
                  r={8}
                  fill={styles.point.color}
                  fillOpacity={styles.point.opacity}
                  cx={x1}
                  cy={y1}
                  />
              )}
              <ChartLabel
                size={fontSize}
                font={fontFace}
                lineHeight={1.15}
                x={x1 + (8 * xMul) + (isColumn ? xMul * bandWidth / 2 : 0)}
                y={y1 + (8 * yMul) + (isBar ? yMul * bandWidth / 2 : 0)}
                padding={8}
                color={styles.label.color}
                opacity={styles.label.opacity}
                backgroundColor={styles.label.background}
                backgroundOpacity={styles.label.backgroundOpacity}
                vAlign={vAlign}
                align={hAlign}
                textAlign="left"
                width={maxWidth}
                height={maxHeight}
                text={note.title}
                debug={DEBUG}
                overflowLine="ellipsis"
                overflow="ellipsis"
                />
            </g>
          );
        }
        else if (type === 'yline') {
          if (y1 < yMin || y1 > yMax || isNaN(y1)) {
            return null; // out of bounds
          }
          // determine label placement
          const [ hAlign, vAlign ] = getLabelPos(
            [ 'N', 'NW', 'NE', 'S',  'SE', 'SW' ],
            [ xMax, y1, maxWidth, maxHeight ],
            [ xMin, yMin, xMax - xMin, yMax - xMin ],
          );
          return (
            <g key={'note-' + note.id}>
              <line
                className={'note-' + type}
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={y1}
                y2={y1}
                x1={xMin}
                x2={xMax}
                />
              <ChartLabel
                size={fontSize}
                font={fontFace}
                lineHeight={1.15}
                x={xMax}
                y={y1}
                padding={8}
                color={styles.label.color}
                opacity={styles.label.opacity}
                text={note.title}
                debug={DEBUG}
                backgroundColor={styles.label.background}
                backgroundOpacity={styles.label.backgroundOpacity}
                vAlign={vAlign}
                align={hAlign}
                textAlign="left"
                width={maxWidth}
                height={maxHeight}
                overflowLine="ellipsis"
                overflow="ellipsis"
                />
            </g>
          );
        }
        else if (type === 'xline') {
          if (x1 < xMin || x1 > xMax || isNaN(x1)) {
            return null; // out of bounds
          }
          // determine label placement
          const [ hAlign, vAlign ] = getLabelPos(
            [ 'SW',  'SE', 'S', 'E', 'W' ],
            [ x1, yMin, maxWidth, maxHeight ],
            [ xMin, yMin, xMax - xMin, yMax - xMin ],
          );
          return (
            <g key={'note-' + note.id}>
              <line
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={yMin}
                y2={yMax}
                x1={x1}
                x2={x1}
                />
              <ChartLabel
                size={fontSize}
                font={fontFace}
                lineHeight={1.15}
                x={x1}
                y={yMin}
                padding={8}
                color={styles.label.color}
                opacity={styles.label.opacity}
                text={note.title}
                debug={DEBUG}
                backgroundColor={styles.label.background}
                backgroundOpacity={styles.label.backgroundOpacity}
                vAlign={vAlign}
                align={hAlign}
                textAlign="left"
                width={maxWidth}
                height={maxHeight}
                overflowLine="ellipsis"
                overflow="ellipsis"
                />
            </g>
          );
        }
        else if (type === 'yrange') {
          if ((isNaN(y1) || isNaN(y2)) || (y1 < yMin || y1 > yMax) && (y2 < yMin || y2 > yMax)) {
            return null; // out of bounds
          }
          y1 = clamp(yMin, y1, yMax);
          y2 = clamp(yMin, y2, yMax);
          return (
            <g key={'note-' + note.id}>
              <polygon
                // fill={fillColor}
                fill="url(#anno-grad-y)"
                points={`${xMin},${y1} ${xMax},${y1} ${xMax},${y2} ${xMin},${y2}`}
                />
              <line
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={y1}
                y2={y1}
                x1={xMin}
                x2={xMax}
                />
              <line
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={y2}
                y2={y2}
                x1={xMin}
                x2={xMax}
                />
              <ChartLabel
                size={fontSize}
                font={fontFace}
                x={xMax}
                y={y1 - 1 - 5 + (y2 - y1) / 2}
                padding={5}
                color={styles.label.color}
                opacity={styles.label.opacity}
                vAlign="middle"
                align="right"
                textAlign="left"
                text={note.title}
                debug={DEBUG}
                />
            </g>
          );
        }
        else if (type === 'xrange') {
          if ((isNaN(x1) || isNaN(x2)) || (x1 < xMin || x1 > xMax) && (x2 < xMin || x2 > yMax)) {
            return null; // out of bounds
          }
          x1 = clamp(xMin, x1, xMax);
          x2 = clamp(xMin, x2, xMax);
          return (
            <g key={'note-' + note.id}>
              <polygon
                fill="url(#anno-grad-x)"
                points={`${x1},${yMin} ${x1},${yMax} ${x2},${yMax} ${x2},${yMin}`}
                />
              <line
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={yMin}
                y2={yMax}
                x1={x1}
                x2={x1}
                />
              <line
                stroke={styles.line.color}
                strokeDasharray={styles.line.dashArray}
                strokeOpacity={styles.line.opacity}
                y1={yMin}
                y2={yMax}
                x1={x2}
                x2={x2}
                />
              <ChartLabel
                size={fontSize}
                font={fontFace}
                x={x1 + (x2 - x1) / 2}
                y={y1 + 5}
                padding={5}
                color={styles.label.color}
                opacity={styles.label.opacity}
                vAlign="middle"
                align="center"
                textAlign="left"
                text={note.title}
                debug={DEBUG}
                />
            </g>
          );
        }
        else if (type === 'area') {
          if (
            ((y1 < yMin || y1 > yMax) && (y2 < yMin || y2 > yMax)) ||
            ((x1 < xMin || x1 > xMax) && (x2 < xMin || x2 > yMax)) ||
            (isNaN(y1) || isNaN(y2) || isNaN(x1) || isNaN(x2))
          ) {
            return null; // out of bounds
          }
          x1 = clamp(xMin, x1, xMax);
          x2 = clamp(xMin, x2, xMax);
          y1 = clamp(yMin, y1, yMax);
          y2 = clamp(yMin, y2, yMax);
          return (
            <g key={'note-' + note.id}>
              <polygon
                // fill={fillColor}
                fill="url(#anno-grad-xy)"
                stroke={styles.line.color}
                points={`${x1},${y1} ${x1},${y2} ${x2},${y2} ${x2},${y1}`}
                />
              <ChartLabel
                size={fontSize}
                font={fontFace}
                x={x2}
                y={y1 - 1 - 5}
                padding={5}
                color={styles.label.color}
                opacity={styles.label.opacity}
                vAlign="middle"
                align="right"
                textAlign="left"
                text={note.title}
                debug={DEBUG}
                />
            </g>
          );
        }
        else {
          // unknown annotation type
        }
        return null;
      })}
    </g>
  );
}

Annotations.propTypes = {
  visible: PropTypes.bool,
  debug: PropTypes.bool,
  x: PropTypes.instanceOf(Axis).isRequired,
  y: PropTypes.instanceOf(Axis).isRequired,
  styles: PropTypes.object,
  data: PropTypes.array,
  pointMark: PropTypes.oneOf([ 'bar', 'column', 'dot' ]),
  bandWidth: PropTypes.number,
};

Annotations.defaultProps = {
  pointMark: 'dot',
  visible: true,
};
