/**
 * The set of elements available in Grid documents.
 *
 * The `analyticsLabel` property should always be Title Cased and should not be changed if `label`
 * is changed.
 *
 * A control's `search` property can be:
 *
 * - A string with space-separated search aliases
 * - An array of search aliases, each of which is object that has an `alias` (mandatory) and a
 *   `minLength` (optional) property. If given, `minLength` means the alias should only match
 *   against input of at least `minLength` chars
 */

/**
 * @typedef {object} SearchAliases
 * @property {string} alias
 * @property {number} [minLength]
 */

const groupWeights = {
  Interaction: 6,
  Display: 5,
  Actions: 4,
  Charts: 3,
  Text: 2,
  Other: 1,
};

/** @typedef {keyof typeof groupWeights} ElementCategoriesType */
/** @typedef {[ string, ("hide" | "disable") ]} ElementRestriction */

/**
 * Describes the construction parameters for an element.
 * @typedef {object} ElementData
 * @property {import(".").GridElementTypes} type - The type of the grid element.
 * @property {string} [id] - The unique identifier for the element.
 * @property {'left' | 'center' | 'right'} [align] - The horizontal alignment for in-line elements
 * @property {Record<string, any>} [defaultProps] - Default properties for the element.
 */

/**
 * An array of columns, where each column is represented as an array of elements.
 * Each inner array defines a column, and the elements within are organized vertically
 * in that column. This structure allows for specifying the layout and content of each
 * column within a row.
 * @typedef {ElementData[][]} DocumentData
*/
/**
 * @typedef {object} ElementMeta
 * @property {Record<string, any>} [defaultProps]
 * @property {string | SearchAliases[]} [search]
 * @property {string} analyticsLabel
 * @property {string} description
 * @property {ElementCategoriesType} group
 * @property {string} icon
 * @property {string} label
 * @property {'grid:block' | 'grid:inline'| 'p' | 'quote'| 'code'| 'h1'| 'h2'| 'h3'| 'ol'| 'ul' | 'row' | 'hr' | 'slidebreak' | 'pagebreak'} name
 * @property {string} value
 * @property {boolean} [canAutoSelect]
 * @property {boolean} [canResize]
 * @property {string} [disabledText]
 * @property {string} [parent]
 * @property {boolean} [hidden]
 * @property {string} [panelAutoFocus]
 * @property {boolean} [supportsQueryUI]
 * @property {boolean} [suppressPanel]
 * @property {boolean} [suppressSpreadsheetPanel]
 * @property {boolean} [useClickSpan]
 * @property {ElementRestriction} [restrictByFeature] The feature that enables the element, as well as what to do if it is disabled.
 * @property {string} [restrictByFlag] The flag that enables the element.
 * @property {DocumentData} [documentData]
 */

/**
 * @typedef {(ElementMeta & {disabledFeature:boolean})} ElementMetaTagged
 * @property {string | import('react').ReactNode} label
 */

/**
 * @property {'Layout Element'} analyticsLabel
 * @property {'row'} value
 * @property {'row'} name
 * @property {string} icon
 * @property {string} search
 * @property {string} disabledText
 * */

/** @type {ElementMeta[]} */
const controls = [
  {
    label: 'Column chart',
    analyticsLabel: 'Column Chart',
    value: 'column',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows comparative size, rank or trend in numerical values',
    icon: 'column',
    canAutoSelect: true,
    supportsQueryUI: true,
  },
  {
    label: 'Bar chart',
    analyticsLabel: 'Bar Chart',
    value: 'bar',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows comparative size or rank of numerical values',
    icon: 'bar',
    canAutoSelect: true,
    supportsQueryUI: true,
  },
  {
    label: 'Area chart',
    analyticsLabel: 'Area Chart',
    value: 'area',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows sums of numerical data, usually over time',
    icon: 'area',
    defaultProps: {
      stacked: true,
    },
    canAutoSelect: true,
    supportsQueryUI: true,
  },
  {
    label: 'Pie chart',
    analyticsLabel: 'Pie Chart',
    value: 'pie',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows numerical proportions of a whole',
    icon: 'pie',
    canAutoSelect: true,
    supportsQueryUI: true,
  },
  {
    label: 'Combo chart',
    analyticsLabel: 'Combo Chart',
    value: 'combo',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows data as columns and lines in one chart',
    icon: 'combo',
    defaultProps: {
      axisValue2: {
        min: 0,
      },
    },
  },
  {
    label: 'Line chart',
    analyticsLabel: 'Line Chart',
    value: 'line',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows trends in numerical data, usually over time',
    icon: 'line',
    canAutoSelect: true,
    supportsQueryUI: true,
  },
  {
    label: 'Scatter plot',
    analyticsLabel: 'Scatter Plot',
    value: 'scatter',
    name: 'grid:block',
    group: 'Charts',
    description: 'Compares numerical data on 2 or 3 dimensions',
    search: [ { alias: 'bubbles', minLength: 3 }, { alias: 'chart' } ],
    icon: 'scatter',
    canAutoSelect: true,
  },
  {
    label: 'Waterfall chart',
    analyticsLabel: 'Waterfall Chart',
    value: 'waterfall',
    name: 'grid:block',
    group: 'Charts',
    description: 'Shows a running total as values are added or subtracted',
    icon: 'waterfall',
  },
  {
    label: 'Table',
    analyticsLabel: 'Table',
    value: 'table',
    name: 'grid:block',
    group: 'Display',
    defaultProps: {
      striped: false,
    },
    description: 'Displays data in an easily readable table',
    icon: 'table',
    supportsQueryUI: true,
  },
  {
    label: 'Sheet',
    analyticsLabel: 'Sheet',
    value: 'sheet',
    name: 'grid:block',
    group: 'Display',
    description: 'Displays data in a spreadsheet-like data-grid',
    icon: 'sheet',
  },
  {
    label: 'Formula output',
    analyticsLabel: 'Formula Output',
    value: 'text',
    name: 'grid:inline',
    group: 'Display',
    description: 'Displays output from formula or reference in text',
    search: 'text fx',
    icon: 'text',
  },
  {
    label: 'Cell value',
    analyticsLabel: 'Cell Value',
    value: 'text',
    name: 'grid:inline',
    group: 'Display',
    description: 'Displays the value of a specific cell in a spreadsheet',
    search: 'text fx',
    icon: 'text',
  },
  {
    label: 'Canvas',
    analyticsLabel: 'Canvas',
    value: 'canvas',
    name: 'grid:block',
    group: 'Display',
    description: 'A free-form dynamic drawing element',
    icon: 'canvas',
    restrictByFlag: 'test-elements',
    panelAutoFocus: 'url',
  },
  {
    label: 'Slider',
    analyticsLabel: 'Slider',
    value: 'slider',
    name: 'grid:inline',
    group: 'Interaction',
    defaultProps: {
      width: 'large',
      size: 'medium',
    },
    description: 'Changes numerical cell values by dragging',
    icon: 'slider',
    useClickSpan: true,
    canResize: true,
  },
  {
    label: 'Dropdown',
    analyticsLabel: 'Dropdown Selection',
    value: 'dropdown',
    name: 'grid:inline',
    group: 'Interaction',
    defaultProps: {
      width: 'large',
      size: 'medium',
    },
    description: 'Select cell value from a set of options',
    search: 'selection',
    icon: 'dropdown',
    useClickSpan: true,
    canResize: true,
  },
  {
    label: 'Input field',
    analyticsLabel: 'Input Field',
    value: 'input',
    name: 'grid:inline',
    group: 'Interaction',
    defaultProps: {
      width: 'large',
      size: 'medium',
      disableHelper: true,
    },
    description: 'Direct entry of data into a cell',
    icon: 'input',
    useClickSpan: true,
    canResize: true,
  },
  {
    label: 'Radio buttons',
    analyticsLabel: 'Radio Selection',
    value: 'radio',
    name: 'grid:inline',
    group: 'Interaction',
    description: 'Selects cell value from a small set of options',
    search: 'checkbox select',
    icon: 'radio',
    useClickSpan: true,
  },
  {
    label: 'Button',
    analyticsLabel: 'Button',
    value: 'button',
    name: 'grid:inline',
    group: 'Interaction',
    defaultProps: {
      size: 'medium',
    },
    description: 'Sets the value of a cell, or a group of cells',
    icon: 'button',
    useClickSpan: true,
  },
  {
    label: 'Checkbox',
    analyticsLabel: 'Checkbox',
    value: 'checkbox',
    name: 'grid:inline',
    group: 'Interaction',
    description: 'Flips the value of a cell between two options',
    icon: 'checkbox',
    useClickSpan: true,
  },
  {
    label: 'Timer',
    analyticsLabel: 'Timer',
    value: 'timer',
    name: 'grid:inline',
    group: 'Other',
    defaultProps: {
      size: 'medium',
    },
    description: 'Changes a cell value at a configurable interval',
    icon: 'timer',
    useClickSpan: true,
  },
  {
    label: 'Interactive value',
    analyticsLabel: 'Tangle',
    value: 'tangle',
    name: 'grid:inline',
    group: 'Interaction',
    description: 'Change numerical cell values by dragging your mouse',
    icon: 'tangle',
    search: 'tangle scrubbable',
  },
  {
    label: 'KPI',
    analyticsLabel: 'Kpi',
    value: 'kpi',
    name: 'grid:inline',
    group: 'Display',
    description: 'Displays a number and its most recent change',
    icon: 'kpi',
    search: 'change',
  },
  {
    analyticsLabel: 'Layout Element',
    value: 'row',
    name: 'row',
    group: 'Other',
    icon: 'layout',
    search: 'layout row split column section arrange',
    disabledText: 'Cannot add a column within a column',
    label: 'Layout element',
    description: 'Arranges content into 2 or more columns',
    documentData: Array(2).fill([]),
  },
  {
    label: 'Paragraph',
    analyticsLabel: 'Paragraph',
    value: 'p',
    name: 'p',
    group: 'Text',
    description: 'A text paragraph',
    search: 'text',
    icon: 'paragraph',
  },
  {
    label: 'Equation',
    analyticsLabel: 'LaTeX Math Equation',
    value: 'latex',
    name: 'grid:inline',
    group: 'Other',
    description: 'Displays a math equation using LaTeX notation',
    search: 'formula latex math',
    icon: 'math',
    panelAutoFocus: 'equation',
  },
  {
    label: 'Quote',
    analyticsLabel: 'Quote',
    value: 'quote',
    name: 'quote',
    group: 'Text',
    description: 'A paragraph formatted to indicate a quote',
    search: 'text',
    icon: 'quote',
  },
  {
    label: 'Heading 1',
    analyticsLabel: 'Heading 1',
    value: 'h1',
    name: 'h1',
    group: 'Text',
    description: 'A large heading, e.g. a title',
    search: 'text h1',
    icon: 'h1',
  },
  {
    label: 'Heading 2',
    analyticsLabel: 'Heading 2',
    value: 'h2',
    name: 'h2',
    group: 'Text',
    description: 'A medium sized heading',
    search: 'text h2',
    icon: 'h2',
  },
  {
    label: 'Heading 3',
    analyticsLabel: 'Heading 3',
    value: 'h3',
    name: 'h3',
    group: 'Text',
    description: 'A small heading',
    search: 'text h3',
    icon: 'h3',
  },
  {
    label: 'Code',
    analyticsLabel: 'Code',
    value: 'code',
    name: 'code',
    group: 'Text',
    description: 'Monospaced text',
    search: 'text',
    icon: 'code',
  },
  {
    label: 'Ordered list',
    analyticsLabel: 'Ordered List',
    value: 'ol',
    name: 'ol',
    group: 'Text',
    description: 'An numbered list item',
    search: 'text',
    icon: '',
    hidden: true,
  },
  {
    label: 'Unordered list',
    analyticsLabel: 'Unordered List',
    value: 'ul',
    name: 'ul',
    group: 'Text',
    description: 'A bulleted list item',
    search: 'text',
    icon: '',
    hidden: true,
  },
  {
    label: 'Source link',
    analyticsLabel: 'Source link',
    value: 'source',
    name: 'grid:inline',
    group: 'Other',
    description: 'A link to the project\'s data source',
    search: 'workbook spreadsheet',
    icon: 'source',
  },
  {
    label: 'Image',
    analyticsLabel: 'Image',
    value: 'image',
    name: 'grid:inline',
    group: 'Other',
    description: 'Upload or link an image/gif',
    search: 'picture icon jif gifs giphy',
    icon: 'image',
    suppressPanel: true,
    canResize: true,
    panelAutoFocus: 'url',
  },
  {
    label: 'Video',
    analyticsLabel: 'Video',
    value: 'embed',
    name: 'grid:block',
    group: 'Other',
    description: 'An embedded video from link',
    search: 'embed',
    icon: 'embed',
    suppressSpreadsheetPanel: true,
    useClickSpan: true,
    panelAutoFocus: 'url',
  },
  {
    label: 'Divider',
    analyticsLabel: 'Horizontal Rule',
    value: 'hr',
    name: 'hr',
    group: 'Other',
    description: 'A horizontal divider for visual separation',
    search: 'horizontal ruler hr',
    icon: 'ruler',
  },
  {
    label: 'Submit',
    analyticsLabel: 'Submit Button',
    value: 'submitbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
    },
    description: 'Submits input information via email',
    search: 'button',
    icon: 'button',
    suppressSpreadsheetPanel: true,
    panelAutoFocus: 'type',
    useClickSpan: true,
    hidden: true,
  },
  {
    label: 'Submit',
    analyticsLabel: 'Submit Button',
    value: 'submitbutton2',
    name: 'grid:inline',
    group: 'Actions',
    description: 'Send form submissions to email or Zapier',
    search: 'button',
    defaultProps: {
      emailEnabled: 'true',
      size: 'medium',
    },
    icon: 'button',
    suppressSpreadsheetPanel: true,
    panelAutoFocus: 'type',
    useClickSpan: true,
  },
  {
    label: 'Save to PDF',
    analyticsLabel: 'Save to PDF Button',
    value: 'actionbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
      buttonType: 'pdf',
    },
    description: 'Exports the content to PDF',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
    parent: 'Action button',
  },
  {
    label: 'Reset',
    analyticsLabel: 'Reset Button',
    value: 'resetbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
    },
    description: 'Resets the document to its default state',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
  },
  {
    label: 'Recalculate',
    analyticsLabel: 'Recalculate Button',
    value: 'recalcbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
    },
    description: 'Recalculates random & time-sensitive functions',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
  },
  {
    label: 'Open URL',
    analyticsLabel: 'Open URL Button',
    value: 'actionbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
      buttonType: 'openurl',
    },
    description: 'Opens a URL in a new tab',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
    parent: 'Action button',
  },
  {
    label: 'Copy URL',
    analyticsLabel: 'Copy URL Button',
    value: 'actionbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
      buttonType: 'copyurl',
    },
    description: 'Copies a URL to the clipboard',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
    parent: 'Action button',
  },
  {
    label: 'Goal Seek',
    analyticsLabel: 'Goal Seek Button',
    value: 'goalseekbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
    },
    description: 'Seeks a value in one cell to produce a value in another',
    search: 'button',
    icon: 'button',
    restrictByFeature: [ 'can_use_goal_seek', 'hide' ],
    useClickSpan: true,
  },
  {
    label: 'Action button',
    analyticsLabel: 'Action Button',
    value: 'actionbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
    },
    description: '',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
    // Hidden as this type of button is not directly selectable by the user, instead it is used as a parent for other buttons
    hidden: true,
  },
  {
    label: 'Present',
    analyticsLabel: 'Present Button',
    value: 'actionbutton',
    name: 'grid:inline',
    group: 'Actions',
    defaultProps: {
      size: 'medium',
      buttonType: 'present',
    },
    description: 'Opens the content in full screen mode',
    search: 'button',
    icon: 'button',
    useClickSpan: true,
    parent: 'Action button',
  },
  {
    label: 'Tooltip',
    analyticsLabel: 'Tooltip',
    value: 'tooltip',
    name: 'grid:inline',
    group: 'Other',
    description: 'Icon indicating additional information',
    search: 'tooltip',
    icon: 'tooltip',
    suppressSpreadsheetPanel: true,
  },
  {
    label: 'Slide break',
    analyticsLabel: 'Slide Break',
    value: 'slidebreak',
    name: 'slidebreak',
    group: 'Other',
    description: 'Splits content horizontally in full screen mode',
    search: 'break slide',
    icon: 'slidebreak',
    disabledText: 'Cannot add a slide break within a column',
  },
  {
    label: 'Page break',
    analyticsLabel: 'Page Break',
    value: 'pagebreak',
    name: 'pagebreak',
    group: 'Other',
    description: 'Adds a page break for printing or PDF export',
    search: 'break page',
    icon: 'slidebreak',
    disabledText: 'Cannot add a page break within a column',
  },
  {
    label: 'Debug ruler',
    analyticsLabel: 'Debug Ruler',
    value: 'debugline',
    name: 'grid:inline',
    group: 'Other',
    description: 'Developer debug utility element',
    search: 'developer',
    icon: 'ruler',
    restrictByFlag: 'devs-doc-features',
  },
];

controls.sort((a, b) => {
  const weightA = groupWeights[a.group] || 0;
  const weightB = groupWeights[b.group] || 0;
  return weightB - weightA;
});
const denyAll = () => false;

/**
 * @param {{restrictByFeature?: ElementRestriction, restrictByFlag?: string, hidden?: boolean }} item
 * @param {Record<string, boolean>} [enabledFeatures={}] Which features user is allowed to access
 * @param {function} [can = ()=>false] Should the "restricted elements" be allowed?
 * @returns {{ disable: boolean, hide: boolean }} How the element should be presented to user.
 */
export function isElementAllowed (item, enabledFeatures = {}, can = denyAll) {
  const isRestricted = item.restrictByFeature || item.restrictByFlag;
  if (!item.hidden && !isRestricted) {
    // element is not hidden or restricted
    return { disable: false, hide: false };
  }
  else if (!item.hidden && isRestricted) {
    let flagOkay = true;
    if (item.restrictByFlag) {
      let flag = item.restrictByFlag;
      let expectation = true;
      if (flag.startsWith('!')) {
        flag = flag.slice(1);
        expectation = false;
      }
      flagOkay = can(flag) === expectation;
    }
    // eslint-disable-next-line prefer-const
    let [ requiredFeature, action ] = item.restrictByFeature || [ '', '' ];
    let featureSetting = true;
    if (requiredFeature.startsWith('!')) {
      requiredFeature = requiredFeature.slice(1);
      featureSetting = false;
    }
    if (flagOkay && (!item.restrictByFeature || enabledFeatures[requiredFeature] === featureSetting)) {
      // user has clearance to use this element
      return { disable: false, hide: false };
    }
    else if (flagOkay && action === 'disable') {
      // user is not allowed to use this element, but can see that it exists
      return { disable: true, hide: false };
    }
  }
  // user should not see this element (disable is true because it is safer)
  return { disable: true, hide: true };
}

/**
 * @param {ElementMeta[]} elements
 * @param {Record<string, boolean>} [enabledFeatures={}] Which features user is allowed to access
 * @param {function} [can = ()=>false] Should the "restricted elements" be allowed?
 * @returns {ElementMetaTagged[]}
 */
export function filterElementList (elements, enabledFeatures = {}, can = denyAll) {
  const filteredList = [];
  for (const item of elements) {
    const restrictions = isElementAllowed(item, enabledFeatures, can);
    if (!restrictions.hide) {
      filteredList.push({ ...item, disabledFeature: restrictions.disable });
    }
  }
  return filteredList;
}

export default controls;

export const gridElements = controls
  .filter(d => d.name.startsWith('grid:'));

export const gridElementMap = new Map(gridElements.map(e => [ e.value, e ]));
