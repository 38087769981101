import React from 'react';
import PropTypes from 'prop-types';

import { MAX_FONT_SIZE, MIN_FONT_SIZE } from '../constants';
import { elementClasses } from '../elementClasses';
import modelProp from '../modelProp';
import { color, elementVisibility, fontSize, textAlign } from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { canBlock } from '../utils/canBlock';

import styles from './TextBlock.module.scss';

function isEmpty (node) {
  const num = node?.children?.length || 0;
  const first = num && node.children[0];
  return num < 2 && (!num || first.text === '');
}

function isCollapsible (node) {
  // block is collapsible if:
  // - it contains at least 1 GRID element
  // - it contains only GRID elements and whitespace
  // - its elements have visible prop set (to something other than "true")
  let gridElmsCount = 0;
  for (const child of node.children) {
    const { type, data } = child;
    // is text node?
    if (!type && 'text' in child) {
      if (child.text && /\S/.test(child.text)) {
        // child is text other than whitespace
        return false;
      }
    }
    else if (!type || !type.startsWith('grid:')) {
      // child is not a grid element (might be a link)
      return false;
    }
    else {
      if (!data.visible || /^=?true$/i.test(data.visible)) {
        // visible attribute is unset or "true"
        return false;
      }
      gridElmsCount++;
    }
  }
  return !!gridElmsCount;
}

function maybeCollapse (elm) {
  elm && elm.classList.toggle('collapsed', !elm.children.length);
}

export default class TextBlock extends React.PureComponent {
  static propTypes = {
    model: modelProp.isRequired,
    id: PropTypes.string.isRequired,
    indent: PropTypes.number,
    isEditor: PropTypes.bool,
    isFocusTarget: PropTypes.bool,
    disableElementClasses: PropTypes.bool,
    children: PropTypes.node,
    element: PropTypes.object,
    attributes: PropTypes.object,
    onMouseDown: PropTypes.func,
    pageBreakAfter: PropTypes.bool,
  };

  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    const model = props.model;
    // only update when writes happen in the model, or when in editor
    if (!props.isEditor && model && model.lastWrite === state.modelId) {
      // same as it ever was!
      return null;
    }
    const type = props?.element?.type || 'p';

    // Empty paragraphs are shown in view mode if they are deliberatly left empty.
    // In cases where paragraphs are empty because an element has now been set hidden
    // they are collapsed as is normal in HTML.
    // See: https://app.clickup.com/t/2559602/CLIENT-565
    const empty = isEmpty(props.element);
    /** @type {Record<string, any>} */
    const newState = {
      type: type,
      isEmpty: empty,
      // This is basically emulating what CSS4 will do with :empty and
      // until that becomes the standard, we're stuck with this solution
      // See: https://app.clickup.com/t/2559602/ENGINE-117
      isCollapsible: canBlock(type, 'collapse') && !props.isEditor && !empty && isCollapsible(props.element),
      modelId: model.lastWrite,
      align: null,
      fontSize: null,
      textColor: null,
      indent: null,
    };

    // find correct HTML element
    if (type === 'quote') {
      newState.tagName = 'blockquote';
    }
    else if (type === 'code') {
      newState.tagName = 'pre';
    }
    else if (type === 'ul' || type === 'ol') {
      newState.tagName = 'div';
    }
    else if (/^h[1-6]$/.test(type)) {
      newState.tagName = type;
    }
    else {
      newState.tagName = 'p';
    }

    // determine "rich" features
    if (canBlock(type, 'align') && textAlign.isSet(props)) {
      newState.align = textAlign.read(props);
    }
    if (canBlock(type, 'fontSize') && fontSize.isSet(props)) {
      let fsize = fontSize.read(props);
      fsize = fsize ? Math.min(MAX_FONT_SIZE, Math.max(fsize, MIN_FONT_SIZE)) : null;
      newState.fontSize = fsize;
    }
    if (canBlock(type, 'color') && color.isSet(props)) {
      newState.textColor = color.read(props);
    }
    if (canBlock(type, 'indent') && isFinite(props.indent)) {
      newState.indent = props.indent;
    }

    return newState;
  }

  constructor (props) {
    super(props);
    this.state = {
      isEmpty: false,
      isCollapsible: false,
      modelId: null,
    };
  }

  componentDidMount () {
    maybeCollapse(this.elm);
  }

  componentDidUpdate () {
    maybeCollapse(this.elm);
  }

  render () {
    const { children, attributes, isFocusTarget, isEditor, disableElementClasses, pageBreakAfter } = this.props;
    const { theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);

    let invisible = false;
    if (!elementVisibility.read(this.props)) {
      if (isEditor) {
        invisible = true;
      }
      else {
        return null;
      }
    }

    const { tagName, type, align, fontSize, textColor, indent, isEmpty, isCollapsible } = this.state;
    const isSlate = isEditor || !!attributes;
    const style = {};
    const cls = [];

    if (pageBreakAfter) {
      cls.push('pageBreakAfter');
    }

    if (!disableElementClasses) {
      cls.push(elementClasses(type, indent));
    }

    if (invisible) {
      cls.push(type, styles.invisibleTextBlock);
    }

    // add "rich" feature styles
    if (align && align !== 'left') {
      cls.push('align-' + align);
    }
    if (fontSize) {
      const fs = Math.min(150, Math.max(fontSize, 8));
      style.fontSize = fs + 'px'; // 18px
      // this equation keeps line height at 1.5 at 18px and lower
      // but falls to 1.1 around 40px (and higher)
      style.lineHeight = Math.max(1.1, 1.5 - ((fs > 18) ? (fs - 18) / 55 : 0));
    }
    if (textColor) {
      style.color = theme.resolveColor(textColor);
    }
    // indent is added by elementClasses (above)
    if (isEmpty) {
      cls.push('empty');
    }
    if (isFocusTarget) {
      cls.push('focusTarget');
    }

    const baseProps = {
      'id': this.props.id,
      'className': cls.length ? cls.join(' ') : null,
      'ref': isCollapsible ? elm => (this.elm = elm) : null,
      'data-testid': this.props.element?.data?.testId,
      'data-type': type,
      'onMouseDown': this.props.onMouseDown,
    };
    if (type === 'code') {
      return React.createElement('div', baseProps, (
        React.createElement(tagName, {
          style,
          ...(isSlate ? { 'data-editblock': true } : null),
          ...attributes,
        }, children)
      ));
    }
    return React.createElement(tagName, {
      ...baseProps,
      style,
      ...(isSlate ? { 'data-editblock': true } : null),
      ...attributes,
    }, children);
  }
}
