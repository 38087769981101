import React from 'react';
import PropTypes from 'prop-types';

import printCell from '@/grid/utils/printCell';
import { parseHref } from '@/grid/utils/urlState';

// We limit matches to domain names with any top level domain when they include
// a trailing slash or path ("domain.example/foo"), or to domain names with a
// top level domain from the following list ("domain.info"):
const okDomains = [
  'com', 'net', 'org', 'edu', 'gov', 'biz', 'int', 'mil', 'info', 'name',
  'museum', 'app', 'ly', 'es', 'uk', 'us', 'ru', 'de', 'au', 'fr', 'jp',
  'cn', 'tk', 'nl', 'pl', 'ca', 'in', 'eu',
];
// eslint-disable-next-line prefer-regex-literals
const reDomain = new RegExp(
  `^(?:[a-z\\d-]{1,63}\\.)+(${okDomains.join('|')}|[a-z]{2,63}\\/[^\\s]*)$`,
  'i',
);

export default function TableValue ({ cell, format, locale, isAuthenticated, color, testId = null }) {
  const value = printCell(cell, format, locale);

  // if we have a href or a string over 4 chars we determine if this is usable
  // href props go through the same process as everything else so we don't emit
  // broken links and minimise the exposing a link attack platform
  let href = null;
  if (cell && (cell.href || (typeof cell.v === 'string' && value.length >= 5))) {
    // if cell already has a href we'll try to use that
    const cand = cell.href || value;
    // "valid" url (it either starts with http://, https://, //, or mailto: and contains no whitespace)
    if (/^(?:(?:https?:)?\/\/|mailto:(?=.*@))[^\s]+$/.test(cand)) {
      href = cand;
    }
    // domain name (or domain name with path...)
    else if (reDomain.test(cand)) {
      href = '//' + cand;
    }
    // email (limited to support for common characters)
    else if (/^(?!\.)[a-z\d_+-]+@(?:[a-z\d-]{1,63}\.)+[a-z]{2,63}$/i.test(cand)) {
      href = 'mailto:' + cand;
    }
  }

  if (href) {
    const link = parseHref(href, isAuthenticated);
    return (
      <a data-testid={testId} {...link.props} style={{ color }}>
        {value}
      </a>
    );
  }

  return <span data-testid={testId} style={{ color }}>{value}</span>;
}

TableValue.propTypes = {
  cell: PropTypes.object,
  format: PropTypes.string,
  color: PropTypes.string,
  locale: PropTypes.string,
  testId: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};
