/* globals process */
import React from 'react';
import PropTypes from 'prop-types';

import ChartLabel from './ChartLabel';
import { Axis, prepAxisData, prepAxisValue } from './prepAxis';

/**
 * Render debug rectangles on the axis.
 *
 * Given the complexity of the axes we decided it was better to
 * let the debugging functionality live on in the project.
 *
 * @param {Record<string, any>} data Axis data
 * @return {import('react').ReactNode}
 */
// eslint-disable-next-line no-unused-vars
let renderDebugRects = data => null;

if (process.env.NODE_ENV !== 'production') {
  // excluded from prod builds
  renderDebugRects = function (data) {
    const hz = data.orient === 'bottom';
    const rh = data.orient === 'right';
    const width = data.width;
    const height = data.height;
    const gutter = data.style.gutterSize;
    const tickSpace = data.tickSpace;
    const titleSpace = data.titleSpace;
    const labelSpace = hz ? data.labelMaxHeight : data.labelMaxWidth;

    let titleSpaceX, gutterX, labelSpaceX;
    if (hz) {
      titleSpaceX = 0;
      gutterX = 0;
      labelSpaceX = 0;
    }
    else if (rh) {
      titleSpaceX = width - titleSpace;
      gutterX = tickSpace;
      labelSpaceX = tickSpace + gutter;
    }
    else {
      titleSpaceX = -width;
      gutterX = -tickSpace - gutter;
      labelSpaceX = -tickSpace - gutter - labelSpace;
    }

    return (
      <g>
        <rect
          x={hz || rh ? 0 : -width}
          y={0}
          width={width}
          height={height}
          fill="none"
          stroke="red"
          strokeDasharray="3"
          strokeOpacity={0.5}
          />
        <rect
          x={hz || rh ? 0 : -tickSpace}
          y={0}
          width={hz ? width : tickSpace}
          height={hz ? tickSpace : height}
          fill="green"
          fillOpacity={0.2}
          />
        <rect
          x={titleSpaceX}
          y={hz ? height - titleSpace : 0}
          width={hz ? width : titleSpace}
          height={hz ? titleSpace : height}
          fill="blue"
          fillOpacity={0.2}
          />
        <rect
          x={gutterX}
          y={hz ? tickSpace : 0}
          fill="orange"
          fillOpacity={0.6}
          height={hz ? gutter : height}
          width={hz ? width : gutter}
          />
        <rect
          x={labelSpaceX}
          y={hz ? tickSpace + gutter : 0}
          fill="pink"
          fillOpacity={0.5}
          height={hz ? labelSpace : height}
          width={hz ? width : labelSpace}
          />
      </g>
    );
  };
}

const needTitle = tick => tick.lines && (tick.lines.hasLineOverflow || tick.lines.hasOverflow);

// Axes share a renderer
export function BaseAxis (props) {
  const data = props.data;
  const styles = data.style;

  if (data.disabled) {
    return null;
  }

  // domain is only available when ticks are being used
  const haveTicks = data.hasTicks;

  const haveDomain = haveTicks && styles.domainTicks;
  const gutterSize = data.style.gutterSize;
  const labelPos = data.tickSpace + gutterSize;

  const centerTitle = styles.titlePosition === 'center';

  const titleSpace = data.titleSpace;
  const { lineHeight, fontSize, color } = styles;
  const { scale, orient, height } = data;

  const r = scale.range();
  const transform = `translate(${props.x || 0},${props.y || 0})`;

  const title = data.title && String(data.title);
  const ticks = data.getTicks();
  const space = r[1] - r[0];

  const tickSize = haveTicks ? styles.tickSize : 0;
  let tickBase = 0;
  if (styles.ticks === 'across') {
    tickBase = -tickSize / 2;
  }
  else if (styles.ticks === 'inside') {
    tickBase = (orient === 'bottom' || orient === 'right') ? -tickSize : 0;
  }
  else if (styles.ticks === 'outside') {
    tickBase = (orient === 'bottom' || orient === 'right') ? 0 : -tickSize;
  }

  if (orient === 'bottom') {
    const tickW = (space - (ticks.length * 7)) / ticks.length;
    return (
      <g className="axis" transform={transform}>
        <ChartLabel
          align={centerTitle ? 'center' : 'right'}
          x={centerTitle ? r[0] + space * 0.5 : r[1]}
          y={height - data.titleSpace * 0.5}
          vAlign="middle"
          size={styles.titleFontSize}
          color={color}
          text={title}
          font={styles.fontStack}
          debug={props.debug}
          />
        {styles.axisLine && (
          <line
            stroke={color}
            className="baseline"
            strokeLinecap="round"
            x2={r[1]}
            />
        )}
        {haveDomain && (
          <g className="domain" stroke={color}>
            <line strokeLinecap="round" x1={r[0]} x2={r[0]} y1={tickBase} y2={tickBase + tickSize} />
            <line strokeLinecap="round" x1={r[1]} x2={r[1]} y1={tickBase} y2={tickBase + tickSize} />
          </g>
        )}

        {props.debug && renderDebugRects(data)}

        {ticks.map(tick => {
          return (
            <g
              key={'x:' + tick.index}
              className="tick"
              opacity="1"
              fill={color}
              transform={`translate(${tick.pos},0)`}
              >
              {needTitle(tick) ? <title>{tick.label}</title> : null}
              {haveTicks && (
                <line
                  strokeLinecap="round"
                  stroke={color}
                  y1={tickBase}
                  y2={tickBase + tickSize * (tick.minor ? 0.5 : 1)}
                  />
              )}
              {/* is this a Textbox instance? */}
              {tick.lines
                ? (
                  <g
                    fill={color}
                    transform={`translate(${-tickW / 2},${labelPos})`}
                    >
                    {tick.lines.render()}
                  </g>
                ) : (
                  <ChartLabel
                    size={fontSize}
                    font={styles.fontStack}
                    y={labelPos + data.labelMaxHeight / 2}
                    color={color}
                    vAlign="middle"
                    align="center"
                    text={tick.label}
                    />
                )
              }
            </g>
          );
        })}
      </g>
    );
  }

  if (orient === 'left') {
    return (
      <g className="axis" transform={transform}>
        <ChartLabel
          align={centerTitle ? 'center' : 'left'}
          vAlign={centerTitle ? 'middle' : null}
          x={centerTitle ? -data.width + titleSpace - styles.titleFontSize * 0.5 : 0}
          y={centerTitle ? r[0] + space * 0.5 : -5}
          angle={centerTitle ? -90 : null}
          size={styles.titleFontSize}
          text={title}
          color={color}
          font={styles.fontStack}
          debug={props.debug}
          />
        {haveDomain && (
          <g className="domain" stroke={color}>
            <line strokeLinecap="round" y1={r[0]} y2={r[0]} x1={tickBase} x2={tickBase + tickSize} />
            <line strokeLinecap="round" y1={r[1]} y2={r[1]} x1={tickBase} x2={tickBase + tickSize} />
          </g>
        )}

        {styles.axisLine && (
          <line
            stroke={color}
            strokeLinecap="round"
            className="baseline"
            y1={r[0]}
            y2={r[1]}
            />
        )}

        {props.debug && renderDebugRects(data)}

        {/* ticks*/}
        {ticks.map(tick => {
          const y = tick.pos;
          const lines = tick.lines || {};
          const lh = (lines.length || 1) * fontSize * lineHeight;
          return (
            <g key={'y:' + tick.index} className="tick" opacity="1" transform={`translate(0,${y})`}>
              {needTitle(tick) ? <title>{tick.label}</title> : null}
              {haveTicks && (
                <line
                  strokeLinecap="round"
                  stroke={color}
                  x1={tick.minor ? tickBase + tickSize * 0.5 : tickBase}
                  x2={tickBase + tickSize}
                  />
              )}
              {tick.lines
                ? <g fill={color} transform={`translate(${-labelPos},${-lh / 2})`}>{lines.render()}</g>
                : <ChartLabel size={fontSize} font={styles.fontStack} x={-labelPos} color={color} vAlign="middle" align="right" text={tick.label} />
              }
            </g>
          );
        })}
      </g>
    );
  }

  if (orient === 'right') {
    return (
      <g className="axis" transform={transform}>
        <ChartLabel
          align={centerTitle ? 'center' : 'right'}
          vAlign={centerTitle ? 'middle' : null}
          x={centerTitle ? data.width - titleSpace + styles.titleFontSize * 0.5 : 0}
          y={centerTitle ? r[0] + space * 0.5 : -5}
          angle={centerTitle ? 90 : null}
          size={styles.titleFontSize}
          text={title}
          color={color}
          font={styles.fontStack}
          debug={props.debug}
          />
        {haveDomain && (
          <g className="domain" stroke={color}>
            <line strokeLinecap="round" y1={r[0]} y2={r[0]} x1={tickBase} x2={tickBase + tickSize} />
            <line strokeLinecap="round" y1={r[1]} y2={r[1]} x1={tickBase} x2={tickBase + tickSize} />
          </g>
        )}
        {styles.axisLine && (
          <line
            stroke={color}
            strokeLinecap="round"
            className="baseline"
            y1={r[0]}
            y2={r[1]}
            />
        )}

        {props.debug && renderDebugRects(data)}

        {/* ticks*/}
        {ticks.map(tick => {
          const y = tick.pos;
          const lines = tick.lines || {};
          const lh = (lines.length || 1) * fontSize * lineHeight;
          return (
            <g key={'y:' + tick.index} className="tick" opacity="1" transform={`translate(0,${y})`}>
              {needTitle(tick) ? <title>{tick.label}</title> : null}
              {haveTicks && (
                <line
                  strokeLinecap="round"
                  stroke={color}
                  x1={tick.minor ? tickBase + tickSize * 0.5 : tickBase}
                  x2={tickBase + tickSize}
                  />
              )}
              {tick.lines
                ? (
                  <g
                    fill={color}
                    transform={`translate(${labelPos},${-lh / 2})`}
                    >
                    {lines.render()}
                  </g>
                )
                : (
                  <ChartLabel
                    size={fontSize}
                    font={styles.fontStack}
                    x={labelPos}
                    color={color}
                    vAlign="middle"
                    align="left"
                    text={tick.label}
                    />
                )
              }
            </g>
          );
        })}
      </g>
    );
  }

  return <text>TBD</text>;
}

BaseAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  data: PropTypes.instanceOf(Axis).isRequired,
  debug: PropTypes.bool,
};

// DataAxis abstraction wrapper
export function DataAxis (props) {
  return <BaseAxis type="data" {...props} />;
}
DataAxis.prepare = prepAxisData;
DataAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  data: PropTypes.instanceOf(Axis).isRequired,
  debug: PropTypes.bool,
};

// ValueAxis abstraction wrapper
export function ValueAxis (props) {
  return <BaseAxis type="value" {...props} />;
}
ValueAxis.prepare = prepAxisValue;
ValueAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  data: PropTypes.instanceOf(Axis).isRequired,
  debug: PropTypes.bool,
};

