import React from 'react';
import { range } from 'd3-array';
import PropTypes from 'prop-types';

import { getCursorPosition } from '@/utils/offset';

import { VALUE_LABELS_MIN } from '../constants';
import modelProp from '../modelProp';
import {
  axisClip,
  axisFormat,
  axisMax,
  axisMin,
  axisReverse,
  axisTitle,
  axisType,
  changeColors,
  colorByPoint,
  data as dataExpr,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  layoutWidth,
  legend as legendOption,
  legendVisible,
  seriesOrientation,
  totalValues,
  valueLabels,
  xAxisLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { lowerCase, printCell, validExpr } from '../utils';
import BaseChart from './BaseChart';
import Clip from './layers/Clip';
import ColumnLayer from './layers/ColumnLayer';
import { getColorScale, isEmptyRange, prepLabels } from './utils';
import { DataAxis, ValueAxis } from './utils/ChartAxis';
import ChartLabel from './utils/ChartLabel';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';
import { getOrderedRange } from './utils/sorting';
import { toSeries } from './utils/toSeries';

const INCREASE = 0;
const DECREASE = 1;
const ABSOLUTE = 2;
const DEFAULT_LEGEND = [
  [ // this is in line with Excel's order (and we want a sane Legend)
    { v: 'Increase' },
    { v: 'Decrease' },
    { v: 'Total' },
  ],
];

const elementOptions = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  labels: xAxisLabels,
  legend: legendOption,
  legendVisible: legendVisible,
  format: format,
  dir: seriesOrientation,
  visible: elementVisibility,
  changeColors: changeColors,
  totalValues: totalValues,
  valueLabels: valueLabels,
  size: layoutWidth,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisDim: {
    title: axisTitle,
    format: axisFormat,
    reverse: axisReverse,
  },
};

export default class WaterfallChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
  };

  static chartType = 'column';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    const table = dataExpr.readCropped(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      dir = w < h ? 'col' : 'row';
    }
    let size = dir === 'row' ? [ w, h ] : [ h, w ];

    const locale = props.locale;
    const axXformat = props.axisDim && format.read({ model: props.model, locale: props.locale, ...props.axisDim });

    const labelData = xAxisLabels.read(props);
    const labels = prepLabels(labelData, dir, axXformat, locale);

    let data;
    if (!validExpr(props.expr)) {
      const l = labels ? labels.length : 0;
      size = [ l, 1 ];
      data = range(l).map(() => ({ v: null }));
    }
    else {
      data = range(h * w).map(i => {
        const a = ~~(i % size[1]);
        const b = ~~(i / size[1]);
        const cell = (dir === 'row') ? table[a][b] : table[b][a];
        return (cell == null || cell instanceof Error) ? null : cell;
      });
    }

    const totals = totalValues.read(props);
    let base = 0;
    const valueaxisDim = [];
    /** @type {[ object[], object[], object[] ]} */
    const series = [ [], [], [] ];
    const connectors = [];
    // only keep the first series
    const [ preSeries ] = toSeries(table, size, dir);
    preSeries[0]?.forEach((d, i, ser) => {
      let isAbsolute = false;
      if (totals === 'both') {
        isAbsolute = i === 0 || i === ser.length - 1;
      }
      else if (totals === 'first') {
        isAbsolute = i === 0;
      }
      else if (totals === 'last') {
        isAbsolute = i === ser.length - 1;
      }
      // ... else we assume "none" which would be equivalent to "" / null
      if (isAbsolute) {
        d.base = 0;
        d.series = ABSOLUTE;
        valueaxisDim.push(d);
        connectors.push({ start: base, end: d.v, category: i });
        base = d.v;
      }
      else {
        d.base = base;
        d.series = (d.v >= 0) ? INCREASE : DECREASE;
        connectors.push({ start: base, end: base, category: i });
        base += d.v;
        valueaxisDim.push({ v: base });
      }
      series[d.series].push(d);
    });

    // discard first connector
    connectors.shift();

    const isLegendSet = legendOption.isSet(props);
    const isLegendVisibleSet = legendVisible.isSet(props);

    const legendRaw = isLegendSet ? legendOption.read(props) : DEFAULT_LEGEND;
    const isLegendVisible = isLegendVisibleSet ? legendVisible.read(props) : !isEmptyRange(legendRaw);

    return {
      dir,
      legendRaw,
      labels,
      size,
      data,
      series,
      connectors,
      isLegendVisibleSet,
      isUsingTotals: (totals === 'both' || totals === 'first' || totals === 'last'),
      showValues: valueLabels.read(props),
      legendVisible: isLegendVisible,
      valueAxis: ValueAxis.prepare({
        props,
        data: valueaxisDim,
        locale: locale,
        orient: 'left',
        needZero: true,
      }),
      dimAxis: DataAxis.prepare({
        props,
        domain: getOrderedRange(size, data, props),
        length: size[0],
        labels: labelData,
        locale: locale,
        orient: 'bottom',
      }),
      format: format.read(props),
      modelId: props.model && props.model.lastWrite,
      lastLocale: locale,
    };
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }
    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    // data/props
    const { chartTheme, theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { dir, legendRaw, legendVisible, valueAxis, dimAxis, isLegendVisibleSet,
      showValues, isUsingTotals, labels, size, series, format, connectors } = this.state;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const palette = changeColors.read(props, {
      minColors: 3,
      autoExtend: false,
      default: chartTheme.changePalette,
    }).map(theme.resolveColor);
    const legend = Legend.prepare({
      labels: legendRaw,
      dir,
      length: 3,
      locale: props.locale,
      style: chartTheme?.legend,
    });
    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme?.footnote,
    });

    // colors
    const colorAxis = getColorScale({
      byPoint: colorByPoint.read(props),
      backgroundColor: theme.backgroundColor,
      palette,
    });
    valueAxis.applyStyle(chartTheme.valueAxis);
    dimAxis.applyStyle(chartTheme.dataAxis);

    // dimensions
    const outerWidth = this.state.width;
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth, [ dimAxis, valueAxis ], chartTitle, chartSubtitle, undefined, theme.chartFontStack,
    );
    const numSeries = isUsingTotals + 1;
    legend.forEach((d, i) => {
      d.color = colorAxis(i);
      if (isLegendVisibleSet && legendVisible) {
        d.hidden = i > numSeries;
      }
      else {
        d.hidden = series[i].length === 0;
      }
    });
    legend.maxWidth = outerWidth;
    margin.bottom += !legendVisible ? 0 : (chartTheme.legend?.margin ?? 0) + legend.height;

    footnote.maxWidth = outerWidth - margin.right;

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    dimAxis.range([ 0, width ]);
    valueAxis.range([ height, 0 ]);

    const invalidChartError = valueAxis.error;
    const categoryWidth = dimAxis.bandwidth();
    // connector lines start to be counterproductive once the bars are thin
    // so we disable them for many-bars and small charts
    const connectorLineWidth = categoryWidth > 25 ? 1 : 0;

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Waterfall Chart"
        role="img"
        >
        {title && <g transform="translate(1,0)" fill={theme.color}>{title.render()}</g>}
        {subtitle && <g transform={`translate(1,${title?.height ?? 0})`} fill={theme.color}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`}>
          <Grid
            styles={chartTheme}
            x={dimAxis}
            y={valueAxis}
            />
          {invalidChartError && (
            <g>
              <ChartLabel
                y={0}
                x={width / 2}
                height={height}
                width={width - (width * 0.15)}
                align="center"
                vAlign="middle"
                color={theme.color}
                size={14}
                text={'Invalid data: ' + invalidChartError}
                />
              <rect />
            </g>
          )}
          <Clip
            id={'clip-' + props.id}
            active={valueAxis.isClipped}
            width={width}
            height={height}
            >
            <ColumnLayer
              visible={!invalidChartError}
              series={series}
              x={dimAxis.scale}
              y={valueAxis.scale}
              fill={d => colorAxis(d.series, d.category)}
              overlap={1}
              bandWidth={categoryWidth}
              zero={valueAxis.zero}
              onPoint={(d, e) => {
                this.setState({
                  hover: {
                    title: labels ? labels[d.category] : String(d.category + 1),
                    dimspec: {
                      [legend[d.series].text]: printCell(d.cell, format, props.locale),
                    },
                    simple: size[1] === 1,
                    cursorPosition: getCursorPosition(e),
                  },
                });
              }}
              onUnPoint={() => this.setState({ hover: null })}
              showValues={this.state.width > VALUE_LABELS_MIN ? showValues : valueLabels.NONE}
              styles={chartTheme}
              label={d => printCell(d.cell, format, props.locale)}
              width={width}
              height={height}
              />
            {connectorLineWidth && (
              <g
                className="connectors"
                stroke={theme.color}
                strokeWidth={connectorLineWidth}
                strokeOpacity={1}
                strokeLinecap="round"
                fill="none"
                >
                {connectors.map(d => {
                  const x1 = dimAxis.scale(d.category - 1);
                  const x2 = dimAxis.scale(d.category);
                  const y1 = valueAxis.scale(d.start);
                  const y2 = valueAxis.scale(d.end);
                  const dx = categoryWidth * 0.49;
                  // x-axis can be reversed so there are 2 variations:
                  const connectorPath = (x2 > x1)
                    ? `M${x1 - dx},${y1} L${x1 + dx},${y1} L${x2 - dx},${y2} L${x2 + dx},${y2}`
                    : `M${x2 - dx},${y2} L${x2 + dx},${y2} L${x1 - dx},${y1} L${x1 + dx},${y1}`;
                  return (
                    <path
                      key={d.category}
                      d={connectorPath}
                      />
                  );
                })}
              </g>
            )}
          </Clip>
          <DataAxis
            data={dimAxis}
            y={height}
            />
          <ValueAxis
            data={valueAxis}
            />
        </g>
        <Footnote
          data={footnote}
          y={footnoteY}
          />
        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
