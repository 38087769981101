import React from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { commonButtonUI, disabled, elementVisibility, targetCell, titleLabel, value } from '../propsData';
import BaseButton from './common/BaseButton';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  value: value,
  visible: elementVisibility,
  disabled: disabled,
  ...commonButtonUI,
};

export default function GridButton (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  const hasExpr = targetCell.isFx(props);
  const isDisabled = disabled.read(props);
  let title = 'Button';
  if (hasExpr) {
    title = titleLabel.isSet(props)
      ? titleLabel.read(props)
      : 'Set value';
  }
  return (
    <BaseButton
      {...props}
      label={title}
      disabled={!hasExpr || isDisabled}
      onClick={() => {
        if (hasExpr && !isDisabled) {
          props.track('interact', { elementType: 'button' });
          targetCell.write(props, value.readCells(props));
        }
      }}
      />
  );
}

GridButton.options = elementOptions;
GridButton.requiredOption = 'expr';
GridButton.isInput = true;

GridButton.propTypes = {
  parentKey: PropTypes.string,
  error: PropTypes.string,
  locale: PropTypes.string,
  isEditor: PropTypes.bool,
  track: PropTypes.func,
  model: modelProp.isRequired,
};
