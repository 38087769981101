import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { backgroundColor, elementVisibility, optBorderColor, optBoxShadow, optCornerRoundingLayout, optHorizontalPadding, optVerticalPadding, verticalAlign } from '../propsData';
import { ThemesProvider, useLocalTheme } from '../ThemesContext';
import { BORDER_WIDTH } from './row';

import styles from './layout.module.scss';

export default function GridCol (props) {
  const fill = backgroundColor.read(props);
  const { theme, chartTheme, themeStyles } = useLocalTheme(fill);
  const visible = elementVisibility.read(props);
  const align = verticalAlign.read(props);

  let borderRadius = null;
  if (optCornerRoundingLayout.isSet(props)) {
    borderRadius = optCornerRoundingLayout.readAsPercentOfMax(props, 18);
  }

  const hasShadow = optBoxShadow.read(props);
  const hasBorder = optBorderColor.isSet(props);
  const vPad = optVerticalPadding.read(props);
  const hPad = optHorizontalPadding.read(props);

  const border = hasBorder
    ? `${BORDER_WIDTH}px solid ${optBorderColor.read(props)}`
    : '';

  const classNames = csx(
    styles.col,
    hasShadow && styles.shadow,
    props.size ? styles[`col-${props.size}`] : styles['col-1-1'],
    align === 'middle' && styles.alignMiddle,
    align === 'bottom' && styles.alignBottom,
    (!props.isEditor && !visible) && styles.hidden,
    (props.isEditor && !visible) && styles.invisible,
    props.isBeingDragged && styles.colBeingDragged,
    !!hasBorder && styles.hasBorder,
    !!themeStyles && styles.filled,
  );

  return (
    <div
      id={props.id}
      className={classNames}
      {...props.slateAttr}
      contentEditable={!props.isEditor && !visible ? false : null}
      style={{
        ...themeStyles,
        border,
        '--border-width': BORDER_WIDTH + 'px',
        '--inner-radius': borderRadius == null
          ? undefined
          : Math.max(0, borderRadius) + 'px',
      }}
      data-testid={props.testId}
      data-type="col"
      data-grid-col-size={props.size}
      >
      <ThemesProvider
        theme={theme}
        chartTheme={chartTheme}
        >
        <div
          className={csx('column', styles.colInner)}
          style={{
            padding: vPad || hPad ? `${vPad}px ${hPad}px` : undefined,
          }}
          >
          {(visible || props.isEditor) ? props.children : null}
        </div>
      </ThemesProvider>
    </div>
  );
}

GridCol.options = {
  [backgroundColor.name]: backgroundColor,
  [elementVisibility.name]: elementVisibility,
  [optBorderColor.name]: optBorderColor,
  [optBoxShadow.name]: optBoxShadow,
  [optCornerRoundingLayout.name]: optCornerRoundingLayout,
  [verticalAlign.name]: verticalAlign,
  [optHorizontalPadding.name]: optHorizontalPadding,
  [optVerticalPadding.name]: optVerticalPadding,
};

GridCol.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  isEditor: PropTypes.bool,
  isBeingDragged: PropTypes.bool,
  testId: PropTypes.string,
  theme: PropTypes.object,
  slateAttr: PropTypes.object,
  model: PropTypes.object,
  children: PropTypes.node,
};
