import React from 'react';
import PropTypes from 'prop-types';

import { deepCompare, functor } from '@/grid/utils';
import { relativeMouse } from '@/utils/offset';

function dist (a, b) {
  return Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);
}

/**
 * @param {any[]} series A series of points
 * @param {number[]} point The point to measure distance from
 * @param {Function} x Function that derives X-axis coordinate from point
 * @param {Function} y Function that derives Y-axis coordinate from point
 * @param {number} maxDist The maximum distance to consider
 * @param {Function} isDefined Function that derives "defined-ness" of points
 * @return {[ number, number ] | null} the closest point, if any
 */
export function closest (series, point, x, y, maxDist = Infinity, isDefined = () => true) {
  let currDist = Infinity;
  let currPoint = null;
  if (series && series.length) {
    series.forEach(data => {
      data.forEach(p => {
        if (isDefined(p)) {
          const pointXY = [ x(p), y(p) ];
          const d = dist(pointXY, point);
          if (d < maxDist && d < currDist) {
            currDist = d;
            currPoint = p;
          }
        }
      });
    });
  }
  return currPoint;
}

export default class HoverLayer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    defined: PropTypes.func,
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
    r: PropTypes.oneOfType([ PropTypes.func, PropTypes.number ]),
    fill: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
    stroke: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),
    series: PropTypes.array,
    shape: PropTypes.oneOf([ 'circle', 'line' ]),
    strokeWidth: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    onPoint: PropTypes.func,
    onUnPoint: PropTypes.func,
  };

  static defaultProps = {
    visible: true,
    onPoint: () => {},
    onUnPoint: () => {},
    shape: 'circle',
    r: 3,
  };

  constructor (props) {
    super(props);
    this.state = {
      target: null,
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    // if this was invisible and will be, don't bother
    if (!nextProps.visible && !this.props.visible) {
      return false;
    }
    return deepCompare(this, nextProps, nextState);
  }

  onPoint = e => {
    const m = relativeMouse(e, e.target);
    const { x, y } = this.props;

    const mpos = [ m.left || 0, m.top || 0 ];
    const pt = closest(this.props.series, mpos, x, y, 40, this.props.defined);
    if (pt) {
      this.setState({ target: pt });
      this.props.onPoint(pt, e);
    }
    else {
      this.setState({ target: null });
      this.props.onUnPoint(null, e);
    }
  };

  onUnPoint = e => {
    this.setState({ target: null });
    this.props.onUnPoint(null, e);
  };

  render () {
    const { width, height, x, y, r, fill, stroke, shape, visible } = this.props;
    if (!visible) {
      return null;
    }
    const { target } = this.state;

    let strokeWidth = this.props.strokeWidth;
    if (strokeWidth == null) {
      strokeWidth = (shape === 'line') ? 1.3 : 1;
    }

    const _r = functor(r, 3);
    const _fill = functor(fill, 'currentColor');
    const _stroke = functor(stroke, undefined);
    const rad = (target && _r(target)) || 0;

    return (
      <g
        pointerEvents="all"
        onMouseMove={this.onPoint}
        onMouseLeave={this.onUnPoint}
        >
        <rect
          width={width}
          height={height}
          fill="none"
          pointerEvents="all"
          />
        {!!target && shape === 'line' && (
          <line
            x1={x(target)}
            x2={x(target)}
            y1={y(target) - rad}
            y2={y(target) + rad}
            stroke={_stroke(target)}
            strokeWidth={strokeWidth}
            strokeOpacity={0.8}
            r={rad}
            />
        )}
        {!!target && shape === 'circle' && (
          <circle
            cx={x(target)}
            cy={y(target)}
            fill={_fill(target)}
            stroke={_stroke(target)}
            strokeWidth={strokeWidth}
            r={rad}
            />
        )}
      </g>
    );
  }
}
