import React from 'react';

import TableInput from './TableInput';

/**
 * @param {import('./TableInput').TableInputProps} props
 * @return {React.ReactElement}
 */
export default function TableInputBox (props) {
  return <TableInput boxed {...props} />;
}
