import { scaleLinear } from 'd3-scale';
import { format as formatValue } from 'numfmt';

import { maybeZero, snapZero } from '../axistools';
import { measureText } from '../measureText';
import { Axis } from './Axis';

export default class ValueAxis extends Axis {
  preferGrid = true;
  /** @type {number | null} */
  clipMin = null;
  /** @type {number | null} */
  clipMax = null;

  constructor (name, style) {
    super(name, style);
    this.scale = scaleLinear();
  }

  get zero () {
    return 0;
  }

  get labelMaxHeight () {
    const lineHeight = this.style.fontSize * this.style.lineHeight;
    return lineHeight;
  }

  get labelMaxWidth () {
    const ticks = this.getTicks();
    if (!ticks.length) {
      return 0;
    }
    let longest = 0;
    const font = this.font;
    for (const tick of ticks) {
      const w = measureText(tick.label, font);
      if (w > longest) {
        longest = Math.ceil(w);
      }
    }
    return longest;
  }

  getTicks () {
    const scale = this.scale;
    const space = this.size;
    const formatPattern = this.format || '';
    const formatOpts = { locale: this.locale, throws: false, nbsp: true };

    let numticks = 5;
    // vertical or horizontal?
    if (this.horizontal) {
      if (space < 350) {
        numticks = 3;
      }
      if (space < 250) {
        numticks = 2;
      }
      if (space < 150) {
        return [];
      }
      if (space < 200) {
        // flush the labels
        const dom = scale.domain();
        if (!isFinite(dom[0]) || !isFinite(dom[0])) {
          return [];
        }
        return [
          {
            index: 0,
            value: dom[0],
            minor: false,
            pos: this.scale(dom[0]),
            // XXX: reconsider nbsp here, this may be causing bad linebreaks in the axis
            label: formatValue(formatPattern, dom[0], formatOpts),
            align: 'left',
          }, {
            index: 1,
            value: dom[1],
            minor: false,
            pos: this.scale(dom[1]),
            label: formatValue(formatPattern, dom[1], formatOpts),
            align: 'right',
          },
        ];
      }
    }
    else {
      if (space < 250) {
        numticks = 4;
      }
      if (space < 200) {
        numticks = 3;
      }
      if (space < 150) {
        numticks = 2;
      }
      if (space < 100) {
        numticks = 0;
      }
    }

    return scale.ticks(numticks).map((d, i) => {
      return {
        index: i,
        value: d,
        minor: false,
        pos: this.scale(d),
        label: formatValue(formatPattern, d, formatOpts),
      };
    });
  }

  get isClipped () {
    return this.clipMin != null || this.clipMax != null;
  }

  domain (extent, needZero) {
    if (!extent) {
      const dom = this.scale.domain();
      return [ this.clipMin ?? dom[0], this.clipMax ?? dom[1] ];
    }
    const dom = needZero ? snapZero(extent) : maybeZero(extent);
    if (this.clipMin != null) {
      dom[0] = this.clipMin;
    }
    if (this.clipMax != null) {
      dom[1] = this.clipMax;
    }
    this.scale.domain(this.reverse ? dom.reverse() : dom);
  }

  range (range) {
    if (!range) {
      return this.scale.range();
    }
    this.scale.rangeRound(range);
  }
}
