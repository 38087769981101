import React from 'react';
import Textbox from '@borgar/textbox';
import PropTypes from 'prop-types';

import { FOOTNOTE_DEFAULTS } from '@/grid/ChartTheme';

export function Footnote (props) {
  const { data: { lines, href, style }, y } = props;
  if (!lines) {
    return null;
  }

  const commonProps = {
    className: 'footnote',
    transform: `translate(0,${y})`,
    fontSize: style.fontSize,
    fontFamily: style.fontFamily,
  };

  if (href) {
    return (
      <a href={href} rel="noopener nofollow noreferrer" target="_blank" {...commonProps}>
        {lines.render()}
      </a>
    );
  }
  else {
    return <g fill={style.color} {...commonProps}>{lines.render()}</g>;
  }
}

Footnote.propTypes = {
  data: PropTypes.object,
  href: PropTypes.string,
  y: PropTypes.number,
};

class FootnoteData {
  constructor (text, href, style) {
    const { fontFamily, fontSize, lineHeight } = FOOTNOTE_DEFAULTS;
    this.text = text;
    this.href = href;
    this.style = style;
    this.lines = null;
    this._font = `${fontSize}px/${lineHeight} ${fontFamily}`;
    this._maxWidth = 100;
  }

  get font () {
    return this._font;
  }

  set font (value) {
    this._font = value;
    this.update();
  }

  get maxWidth () {
    return this._maxWidth;
  }

  set maxWidth (value) {
    this._maxWidth = value;
    this.update();
  }

  get height () {
    return this.lines?.height ?? 0;
  }

  update () {
    if (this.text) {
      const footnoteBox = new Textbox({
        font: this.font,
        width: this.maxWidth,
        align: 'right',
        createElement: React.createElement,
      });
      this.lines = footnoteBox.linebreak(this.text);
    }
    else {
      this.lines = null;
    }
    return this;
  }
}

Footnote.prepare = ({ text, href, style }) => {
  let footnoteText = '';
  if (text) {
    footnoteText = text;
  }
  else if (href) {
    footnoteText = href.length > 50 ? href.slice(0, 49) + '…' : href;
  }
  return new FootnoteData(footnoteText, href, style).update();
};

