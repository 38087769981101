import { color as d3_color } from 'd3-color';
import { interpolateLab } from 'd3-interpolate';

const fadeSteps = 6;

export default function getColorScale ({ byPoint, palette, backgroundColor }) {
  const colorAxis = n => palette[(n || 0) % palette.length];
  if (byPoint) {
    const bg = d3_color(backgroundColor || '#fff');
    return (ser, cat) => {
      const fg = colorAxis(cat);
      const s = (ser % fadeSteps) * (1 / fadeSteps);
      return interpolateLab(fg, bg)(s);
    };
  }
  return colorAxis;
}
