import React from 'react';
import csx from 'classnames';
import { range } from 'd3-array';
import { getFormatInfo, isDateFormat, isPercentFormat, isTextFormat } from 'numfmt';
import PropTypes from 'prop-types';

import { isNumber, printCell, validExpr } from '@/grid/utils';
import { getCaretPosition, parseInputValue, renderInputValue } from '@/grid/utils/editValues';
import stepNormalize from '@/grid/utils/stepNormalize';

import { TYPING_DELAY } from '../constants';
import modelProp from '../modelProp';
import {
  disabled,
  disableHelper,
  elementVisibility,
  format,
  max,
  min,
  optInlineSize,
  optInlineType,
  options,
  step,
  targetCell,
  titleLabel,
  width,
} from '../propsData';
import Wrapper from '../Wrapper';
import BaseInput from './common/BaseInput';
import InputHelper from './common/InputHelper';

import styles from './input.module.scss';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  options: options,
  format: format,
  inline: optInlineType,
  width: width,
  min: min,
  max: max,
  step: step,
  visible: elementVisibility,
  disabled: disabled,
  disableHelper: disableHelper,
  inputDelay: TYPING_DELAY,
  [optInlineSize.name]: optInlineSize,
};

export default class GridInput extends React.Component {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
    isEditor: PropTypes.bool,
    expr: PropTypes.string,
    inputDelay: PropTypes.number,
    track: PropTypes.func,
  };

  static defaultProps = {
    inputDelay: TYPING_DELAY,
  };

  static options = elementOptions;
  static requiredOption = 'expr';
  static isInput = true;

  static getDerivedStateFromProps (props, state) {
    const model = props.model;
    // only update when writes happen in the model, or when in editor
    if (!props.isEditor && model && model.lastWrite === state.modelId) {
      // same as it ever was!
      return null;
    }

    const stepValue = step.read(props);
    const minValue = min.read(props);
    const maxValue = max.read(props);

    const haveExpression = validExpr(props.expr);
    const error = haveExpression ? '' : 'Input';

    let cell = null;

    if (haveExpression) {
      cell = targetCell.read(props);
    }

    const formatStr = format.isSet(props) ? format.read(props) : (cell?.z || '');

    // figure out what type of input this is
    let inputType = 'text';
    if (cell && typeof cell.v === 'boolean') {
      inputType = 'boolean';
    }
    else if (!cell || typeof cell.v === 'number' || cell.v == null) {
      if (cell && (typeof cell.v === 'number' || formatStr)) {
        inputType = 'number';
      }
      if (formatStr) {
        if (isTextFormat(formatStr)) {
          inputType = 'text';
        }
        else if (isDateFormat(formatStr)) {
          // XXX: store dateinfo here to reduce calls to info?
          inputType = 'datetime';
        }
      }
    }
    // if the current type is a number, and this is a finite value, keep the type as-is
    // this is so user can type "0.1" without the element becoming text at "0."
    else if (typeof cell.v === 'string' && cell.v && isFinite(+cell.v) && state.inputType === 'number') {
      inputType = 'number';
    }
    // if min, max, or step are set: we'll treat this as a number (if it was detected as "string")
    if (inputType === 'text' && (stepValue !== null || minValue !== null || maxValue !== null)) {
      inputType = 'number';
    }

    const optionList = [];
    if (options.isSet(props)) {
      const table = options.read(props);
      if (table.length && table[0].length) {
        const w = table[0].length;
        const h = table.length;
        const dir = w < h ? 'col' : 'row';
        range(dir === 'row' ? w : h).forEach(i => {
          const cell = (dir === 'row') ? table[0][i] : table[i][0];
          if (cell == null || cell instanceof Error) {
            return;
          }
          if (typeof cell.v !== 'number') {
            // if any of the options are non-number, switch to text
            inputType = 'text';
          }
          optionList.push({ key: i, value: printCell(cell, null, props.locale) });
        });
      }
    }

    let textValue;
    if (!state.interacting) {
      textValue = printCell(cell, formatStr, props.locale);
    }
    else {
      textValue = state.textValue;
    }

    return {
      cell: cell,
      step: stepValue,
      format: formatStr,
      min: minValue,
      max: maxValue,
      error: error,
      title: titleLabel.read(props),
      disableHelper: disableHelper.read(props),
      disabled: !haveExpression || disabled.read(props),
      inputType: inputType,
      options: optionList.length ? optionList : null,
      modelId: model.lastWrite,
      textValue: textValue,
    };
  }

  constructor (props) {
    super(props);
    this.state = {
      interacting: false,
      /** @type {null | string} */
      inputType: null,
    };
  }

  componentDidUpdate = () => {
    // XXX: is this class used by anything other than unit tests?
    if (this.input) {
      this.input.classList.toggle('interacting', this.state.interacting);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  /**
   * Set the text value of the input to the editing value corresponding to the given cell value,
   * put the cursor at the appropriate position in that new value, and set state.interacting to true.
   *
   * The clicked cursor position is not reliably available until after the focus event handling has
   * completed but this method is being called during the focus event handling. Thus the setting of the
   * editing value and cursor position is deferred using a 0-second timeout and a setState callback.
   *
   * @param {string|number|boolean} cellValue the cell value to set an appropriate editing value for
   * @param {HTMLInputElement} [input] the input to act on, falling back to `this.input`
   */
  setEditingValueAndCursorPosition = (cellValue, input) => {
    if (!input) {
      input = this.input;
    }
    if (!input) {
      return;
    }
    const locale = this.props.locale;
    const editingValue = renderInputValue(cellValue, this.state.format, locale);
    return new Promise(resolve => {
      this.timeout = setTimeout(() => {
        const caretPosition = getCaretPosition(input.selectionStart, input.value, editingValue, locale);
        this.setState({ textValue: editingValue, interacting: true }, () => {
          input.setSelectionRange(caretPosition, caretPosition);
          resolve(null);
        });
      }, 0);
    });
  };

  onKeyDown = e => {
    if (this.state.inputType === 'number' || this.state.inputType === 'datetime') {
      if (e.keyCode === 38) { // UP
        e.preventDefault();
        this.step(1);
      }
      if (e.keyCode === 40) { // DOWN
        e.preventDefault();
        this.step(-1);
      }
    }
  };

  /**
   * Enter editing mode in a text input. Determine the editing format based on the cell format and document locale,
   * overwrite the presentation value (existing text value of the input) with the cell value represented in that editing
   * format, and place the cursor at an appropriate initial position for editing.
   *
   * The determination of cursor position and the replacement of the text value are done asynchronously after this
   * method completes. This is done asynchronously after the focus event handling completes, because in Chrome the
   * clicked cursor position is not reliably reflected in the `selectionStart` attribute until the focus event handling
   * has completed.
   *
   * @param {FocusEvent} e the React focus event object
   */
  onInteractStart = async e => {
    await this.setEditingValueAndCursorPosition(this.state.cell.v, /** @type {HTMLInputElement} */(e.target));
    // state interacting is set inside the above call due to selectionStart ordering awkwardness
    this.props.track('focus', { elementType: 'input' });
  };

  onInteractEnd = e => {
    const value = this.interpretStringValue(e.target.value);
    const { cell, format } = this.state;
    const textValue = printCell({ v: value, z: cell.z }, format, this.props.locale);
    this.setState({ interacting: false, textValue }, () => {
      this.trackChange();
      this.writeValue(value, true, true);
      // because we've just set interacting=false, this will cause a track event
      this.props.track('blur', { elementType: 'input' });
    });
  };

  onChange = e => {
    const val = this.interpretStringValue(e.target.value);
    // the value is not step-normalized onChange as it would make it impossible to
    // write '15' in an input with step=5 (the first 1 would be stepped to 0)
    this.setState({ textValue: e.target.value });
    this.trackChange();
    this.writeValue(val, false, false);
  };

  trackChange = () => {
    if (this.state.interacting) {
      // while user is interacting, no events are sent
      this.pendingChanges = true;
    }
    else {
      // if user is not interacting, send an event
      if (this.pendingChanges) {
        this.props.track('interact', { elementType: 'input' });
      }
      this.pendingChanges = false;
    }
  };

  interpretStringValue = val => {
    return parseInputValue(val, this.state.format, this.props.locale);
  };

  // NB: This is the same code as in the TableInput component so changes here
  //     should likely also occur there!
  writeValue (value, normalizeToConstraints, forceImmediate) {
    clearTimeout(this._writeDelay);
    if (normalizeToConstraints && (value || value === 0) && typeof value === 'number') {
      const { min, max, step } = this.state;
      value = stepNormalize(value, min, max, step);
    }
    const cell = this.state.cell;
    if (value === '' && (cell._v == null || typeof cell._v === 'number')) {
      // ENGINE-246: "" should write null's like Excel does
      value = null;
    }
    if (value === cell.v) {
      // don't perform a write if it won't result in a change
      return;
    }
    // delay if new input is a number or a null...
    const newCanDelay = typeof value === 'number' || value == null;
    // and if we started with a number (but not a null)
    const oldCanDelay = typeof cell.v === 'number' || cell.v == null;
    if (newCanDelay && oldCanDelay && !forceImmediate) {
      this._writeDelay = setTimeout(() => {
        targetCell.write(this.props, value);
      }, this.props.inputDelay);
    }
    else {
      targetCell.write(this.props, value);
    }
  }

  step = (dir = 0) => {
    if (!dir) {
      return;
    }
    const { format, min, max, cell, step, interacting } = this.state;
    const isPercentageFormat = isPercentFormat(format);
    const value = cell && isNumber(cell.v) ? cell.v : 0;
    let stepSize = step;
    if (!isNumber(stepSize) && isDateFormat(format)) {
      stepSize = 1;
    }
    else if (!isNumber(stepSize)) {
      const v = Math.abs(isPercentageFormat ? value * 100 : value);
      const n = Math.max(1, (dir > 0) ? v : v - 1);
      stepSize = 10 ** Math.floor(1 + Math.log10(n)) / 100;
      // between 0 and 99 we always autostep on 1 (this is really for the 0-9 range)
      if (v >= 0 && v <= 99) {
        stepSize = Math.max(1, stepSize);
      }
      if (isPercentageFormat) {
        stepSize *= 0.01;
      }
    }
    const newValue = stepNormalize(value + (stepSize * Math.sign(dir)), min, max, step);
    if (interacting) {
      this.setEditingValueAndCursorPosition(newValue);
    }
    this.writeValue(newValue, true, true);
  };

  renderArrow (direction = 'up') {
    return (
      <svg version="1.1" width="10" height="10" viewBox="0 0 36 36">
        <g transform={direction === 'down' ? 'translate(18,18) rotate(180) translate(-18,-18)' : ''}>
          <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />
        </g>
      </svg>
    );
  }

  renderHelper (size) {
    const { inputType, disabled, disableHelper, interacting } = this.state;
    if (disableHelper) {
      return null;
    }
    const baseProps = {
      size: size,
      locale: this.props.locale,
      interacting: interacting,
      disabled: disabled,
      targetElement: this.input,
    };
    // We want to render arrow if input has options
    if (this.state.options) {
      return (
        <InputHelper
          type="select"
          {...baseProps}
          />
      );
    }
    // is number: render steppers
    else if (inputType === 'number') {
      return (
        <InputHelper
          type={inputType}
          {...baseProps}
          onClick={e => {
            this.trackChange();
            this.step(e.value);
          }}
          />
      );
    }
    // is boolean: render checkbox?
    else if (inputType === 'boolean') {
      return (
        <InputHelper
          type={inputType}
          {...baseProps}
          onClick={e => {
            this.trackChange();
            targetCell.write(this.props, e.value);
          }}
          value={this.state.cell && !!this.state.cell.v}
          />
      );
    }
    // is datetime: render picker
    else if (inputType === 'datetime') {
      const { format, min, max, step, cell } = this.state;
      const { locale } = this.props;
      let type = inputType;
      // determine real type/granularity based on formatting string
      const info = getFormatInfo(format ?? cell.z);
      if (info.type === 'date' || info.type === 'time' || info.type === 'datetime') {
        type = info.type;
      }
      return (
        <InputHelper
          type={type}
          {...baseProps}
          min={min}
          max={max}
          step={step}
          onClick={e => {
            // because the input is still in an interacting state, we have to update the
            // editing value as it trumps date from the model
            this.setState({ textValue: renderInputValue(e.value, format, locale) });
            this.trackChange();
            targetCell.write(this.props, e.value);
          }}
          value={this.state.cell?.v}
          />
      );
    }
    // is string: render nothing extra
  }

  render () {
    const props = this.props;
    const { disabled, textValue, title, options } = this.state;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const size = optInlineSize.read(props);
    return (
      <Wrapper
        {...props}
        className={csx(styles.container, `size_${size || 'small'}`)}
        isDisabled={disabled}
        inlineWidth={width.read(props)}
        inlineMode={optInlineType.read(props)}
        label={title}
        >
        <BaseInput
          inputRef={elm => (this.input = elm)}
          type={this.state.inputType}
          size={size}
          value={textValue || ''}
          options={options}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onFocus={this.onInteractStart}
          onBlur={this.onInteractEnd}
          disabled={disabled}
          error={this.state.error}
          >
          {this.renderHelper(size)}
        </BaseInput>
      </Wrapper>
    );
  }
}
