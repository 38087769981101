/* eslint-disable react/prop-types */
import React from 'react';

import ScrollPane from '@/grid/ScrollPane';

const noOp = () => {};

/**
 * @typedef SheetWindowProps
 * @prop {number} [top]
 * @prop {number} [left]
 * @prop {number} [height]
 * @prop {number} [width]
 * @prop {number} innerHeight
 * @prop {number} innerWidth
 * @prop {(pos: { left: number, top: number }) => {}} [onScroll]
 * @prop {(pos: [number, number]) => {}} [onResize]
 * @prop {React.ReactNode} [children]
 */

export class SheetWindow extends React.Component {
  static defaultProps = {
    onScroll: noOp,
    onResize: noOp,
    onDoubleClick: noOp,
    top: 0,
    left: 0,
    scrolling: true,
  };

  /**
   * @param {SheetWindowProps} props
   */
  constructor (props) {
    super(props);
    this.scrollPane = React.createRef();
  }

  componentDidMount () {
    this.updateDims();
  }

  componentDidUpdate () {
    this.updateDims();
  }

  updateDims () {
    const { height } = this.props;
    if (!height && this.root) {
      const w = this.root.clientWidth || 754;
      this.root.style.height = Math.ceil(Math.max(40, w * 0.618)) + 'px';
    }
  }

  /**
   * @param {number} x
   * @param {number} y
   */
  scrollTo (x, y) {
    if (this.scrollPane.current) {
      this.scrollPane.current.scrollTo(x, y);
    }
  }

  /**
   * @param {import('./CellRect').CellRect} rect
   */
  scrollIntoView (rect) {
    if (this.scrollPane.current && this.root) {
      const { top, left, innerWidth, innerHeight } = this.props;
      const w = this.root.clientWidth || 754;
      const h = this.root.clientHeight || w * 0.618;
      // this element needs to be able to scroll
      if (w !== Math.ceil(innerWidth + left) || h !== Math.ceil(innerHeight + top)) {
        // if the element can fit then center it, else scroll to top/left
        const x = rect.width < w ? rect.left - (w - rect.width) / 2 : rect.left;
        const y = rect.height < h ? rect.top - (h - rect.height) / 2 : rect.top;
        this.scrollPane.current.scrollTo(x, y);
      }
    }
  }

  render () {
    const { children, onScroll, onResize, top, left, height, width, innerWidth, innerHeight } = this.props;
    return (
      <div
        ref={elm => (this.root = elm)}
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: width ? `calc(min(${width + left}px, 100%))` : '100%',
          height: height ? height + top : null,
          display: 'flex',
        }}
        >
        {children}
        <ScrollPane
          ref={this.scrollPane}
          onResize={onResize}
          onScroll={onScroll}
          style={{
            height: `calc(100% - ${top}px)`,
            width: `calc(100% - ${left}px)`,
            top: top + 'px',
            left: left + 'px',
            position: 'absolute',
          }}
          >
          <div
            style={{
              height: innerHeight + 'px',
              width: innerWidth + 'px',
            }}
            />
        </ScrollPane>
      </div>
    );
  }
}
