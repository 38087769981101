import { scaleLinear } from 'd3-scale';

import { AXIS_DEFAULTS } from '@/grid/ChartTheme';

/*
** A note on how methods are named here:
**
** - "width" is used to refer to the horizontal size in pixels
** - "height" is used to refer to the vertical size in pixels
** - "space" is used to refer to either horizontal or vertical size in pixels.
**   e.g. tickSpace means the length of the ticks which may be either
**   vertical or horizontal, depending on the orientation of the axis.
*/
export class Axis {
  name = '';
  title = '';
  /** @type {string | null} */
  format = null;
  scale = scaleLinear();
  reverse = false;
  locale = 'en-US';
  /** @type {string | null} */
  orient = null;
  preferGrid = false;
  maxWidth = 150;
  disabled = false;

  /**
   * @param {string} name An identifier for the axis ("axisValue", "axisData")
   * @param {Record<string, any>} [style] The styles for this axis
   */
  constructor (name, style = undefined) {
    this.name = name;
    /** @type {Record<string, any>} */
    this.style = {};
    this.applyStyle(style);
  }

  /**
   * @param {Record<string, any>} [style]
   * @return {void}
   */
  applyStyle (style) {
    this.style = Object.assign({}, AXIS_DEFAULTS, style);
    if (this.style && !this.style.ticks) {
      this.style.ticks = 'none';
    }
  }

  // xxx: rename to "space" to be consistent with other dimension names
  // the length or height of the axis in pixels
  get size () {
    const r = this.range();
    const s = Math.abs(r[0] - r[1]);
    return isFinite(s) ? s : 0;
  }

  get hasTicks () {
    return this.style.ticks && this.style.ticks !== 'none';
  }

  get tickSpace () {
    if (this.style.ticks === 'across') {
      return Math.ceil(this.style.tickSize / 2);
    }
    if (this.style.ticks === 'inside') {
      return 0;
    }
    return (this.hasTicks ? this.style.tickSize : 0);
  }

  get titleSpace () {
    const style = this.style;
    const centerTitle = style.titlePosition === 'center';
    if (this.title && (centerTitle || this.horizontal)) {
      return style.titleFontSize * 1;
    }
    return 0;
  }

  /** @type {string | null} */
  get error () {
    return null;
  }

  get horizontal () {
    return this.orient === 'top' || this.orient === 'bottom';
  }

  get font () {
    const s = this.style;
    const leading = Math.round(s.fontSize * s.lineHeight * 10) / 10;
    return `${s.fontSize}px/${leading}px ${s.fontFamily}`;
  }

  // This method is likely to be overwritten by a subclass
  get labelMaxWidth () {
    // This number is determined to be a comfortable baseline
    // the axis will both linebreak and shorten labels to fit them
    // See: https://observablehq.com/@borgar/axis-label-linebreaking-study?collection=@borgar/textbox
    return 30;
  }

  get labelMaxHeight () {
    return this.style.fontSize * this.style.lineHeight;
  }

  get width () {
    if (this.disabled) {
      return 0;
    }
    else if (this.orient === 'left' || this.orient === 'right') {
      return (
        this.tickSpace +
        this.style.gutterSize +
        this.labelMaxWidth +
        (this.titleSpace ? this.style.gutterSize : 0) +
        this.titleSpace
      );
    }
    return this.size;
  }

  get height () {
    if (this.disabled) {
      return 0;
    }
    else if (this.orient === 'top' || this.orient === 'bottom') {
      return (
        this.tickSpace +
        this.style.gutterSize +
        this.labelMaxHeight +
        (this.titleSpace ? this.style.gutterSize : 0) +
        this.titleSpace
      );
    }
    return this.size;
  }

  getTicks () {
    return [];
  }

  scaleByValue = v => {
    return this.scale(v);
  };

  range (range) {
    if (!range) {
      return this.scale.range();
    }
    this.scale.range(range);
  }

  labelData (data) {
    if (!data) {
      return this._labelData;
    }
    this._labelData = data;
  }
}
