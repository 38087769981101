import React from 'react';
import PropTypes from 'prop-types';

import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';

import DataError from '../DataError';
import modelProp from '../modelProp';
import { elementVisibility, layoutWidth, sourceUrl } from '../propsData';
import { validExpr } from '../utils';
import Wrapper from '../Wrapper';
import getEmbedParams from './embedProviders';

import styles from './embed.module.scss';

const elementOptions = {
  url: sourceUrl,
  visible: elementVisibility,
  size: layoutWidth,
};

export default class GridEmbed extends React.Component {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
    url: PropTypes.string,
    isEditor: PropTypes.bool,
    element: PropTypes.object,
  };

  static options = elementOptions;
  static requiredOption = 'url';

  constructor (props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  static getDerivedStateFromProps (props, state) {
    if (!props.isEditor && props.model && props.model.lastWrite === state.modelId) {
      return null;
    }
    const src = sourceUrl.read(props);
    return {
      modelId: props.model.lastWrite,
      embedParams: getEmbedParams(src),
    };
  }

  onLoad = () => {
    this.setState({ loading: false });
  };

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const havedata = validExpr(props.url);
    if (!havedata) {
      return (
        <Wrapper {...props}>
          <DataError
            title="No source found"
            message="Your video will appear here after you add the Source URL"
            {...props}
            />
        </Wrapper>
      );
    }

    const { embedParams, loading } = this.state;
    if (!embedParams) {
      return (
        <Wrapper {...props}>
          <DataError
            title="Unknown source"
            message="We currently support videos from YouTube, Vimeo, TED, Twitch, DailyMotion, and Loom."
            {...props}
            />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        className={styles.video}
        {...props}
        >
        <div
          className={styles.embedWrap}
          ref={elm => (this.wrapper = elm)}
          data-testid="embed"
          style={{
            position: 'relative',
            paddingBottom: (embedParams.aspect * 100) + '%',
            background: '#ddd',
            height: 0,
            overflow: 'hidden',
          }}
          >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            onLoad={this.onLoad}
            ref={elm => (this.iframe = elm)}
            src={embedParams.url}
            frameBorder="0"
            allowFullScreen
            className={styles.iframe}
            />
          {loading ? <LoadingSpinner color="#75767d" className={styles.spinner} /> : null}
        </div>
      </Wrapper>
    );
  }
}
