import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { elementClasses } from './elementClasses';
import { gridElementMap } from './elements';
import Label from './Label';
import { elementVisibility, layoutWidth } from './propsData';
import { widthClass } from './utils';
import { layoutWidthClass } from './utils/layoutWidthClass';

import styles from './Wrapper.module.scss';

const noPropagation = e => e.preventDefault();

export default function Wrapper (props) {
  const {
    isSelected,
    isInSelection,
    subRef,
    children,
    type,
    attributes,
    listeners,
    isEditor,
    extraControls,
    element,
    style,
    slateChildren,
    isPlaceholder,
    clickSpan,
    pageBreakAfter,
    inlineMode,
    inlineWidth,
  } = props;

  const isInline = gridElementMap.get(type)?.name !== 'grid:block';
  const isVisible = !props.model || elementVisibility.read(props);
  const blockType = props.element?.type ?? (isInline ? 'grid:inline' : 'grid:block');

  const classNames = csx(
    styles.grid,
    props.className,
    'element_' + element?.data?.type,
    isEditor && styles.editor,
    isEditor && 'editmode',
    isInline ? styles.inline : styles.block,
    !isVisible && styles.invisible,
    elementClasses(type),
    !children && styles.empty,
    isSelected && isInSelection && 'selection',
    isPlaceholder && styles.placeholder,
    layoutWidthClass(blockType, layoutWidth.read(props)),
    pageBreakAfter && 'pageBreakAfter',
    clickSpan && styles.clicktrap,
    isInline && widthClass(inlineWidth, inlineMode),
  );

  const commonProps = {
    ...attributes,
    ...listeners,
    'id': props.id || element?.id,
    'className': classNames,
    'title': props.htmlTitle,
    'style': style,
    'data-grid-element': true,
    'data-type': element?.data?.type,
    'data-obid': 'grid-' + element?.data?.type,
    'data-editblock': isInline ? null : true,
    'data-testid': element?.data?.testId,
    'data-selected': isSelected || null,
    'onMouseDown': clickSpan ? noPropagation : null,
    'onClick': props.onEditorClick,
  };

  if (isInline) {
    // don't use a label for checkboxes as they already contain labels
    const InlineWrapper = inlineMode ? 'label' : 'span';
    return (
      <span {...commonProps}>
        <InlineWrapper ref={subRef} contentEditable={false} className="ctrl">
          {(props.label || inlineMode === 'row') ? (
            <Label
              disabled={props.isDisabled}
              className="label"
              text={props.label || '\u200B'}
              inline
              />
          ) : null}
          <span className="value">
            {children}
          </span>
        </InlineWrapper>
        {slateChildren}
        {extraControls}
      </span>
    );
  }

  return (
    <div {...commonProps}>
      <div ref={subRef} contentEditable={false}>
        {children}
      </div>
      {slateChildren}
      {extraControls}
    </div>
  );
}

Wrapper.propTypes = {
  id: PropTypes.string, // XXX want this to be .isRequired,
  attributes: PropTypes.object,
  listeners: PropTypes.object,
  slateChildren: PropTypes.node,
  children: PropTypes.node,
  label: PropTypes.string,
  inlineMode: PropTypes.oneOf([ 'col', 'row', 'text' ]),
  inlineWidth: PropTypes.oneOf([ '', 'x-small', 'small', 'medium', 'large', 'x-large', 'full' ]),
  className: PropTypes.string,
  style: PropTypes.object,
  htmlTitle: PropTypes.string,
  isDisabled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSelected: PropTypes.bool,
  isInSelection: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  clickSpan: PropTypes.bool,
  pageBreakAfter: PropTypes.bool,
  emit: PropTypes.func,
  subRef: PropTypes.func,
  onEditorClick: PropTypes.func,
  extraControls: PropTypes.node,
  type: PropTypes.string,
  model: PropTypes.object,
  visible: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  element: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      testId: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
};
