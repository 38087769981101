import React from 'react';
import { range } from 'd3-array';
import { scaleOrdinal } from 'd3-scale';
import PropTypes from 'prop-types';

import { getCursorPosition, relativeMouse } from '@/utils/offset';

import modelProp from '../modelProp';
import {
  axisClip,
  axisFormat,
  axisMax,
  axisMin,
  axisReverse,
  axisTitle,
  axisType,
  blanks,
  chartColors,
  data as dataExpr,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  interpolate,
  layoutWidth,
  legend as legendOption,
  legendVisible,
  optAnnotations,
  optShowDots,
  seriesOrientation,
  xAxisLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { enumProp, lowerCase, printCell, validExpr } from '../utils';
import { clamp } from '../utils/clamp';
import { isBlankCell } from '../utils/isBlankCell';
import BaseChart from './BaseChart';
import Annotations, { prepAnnotations } from './layers/Annotations';
import Clip from './layers/Clip';
import DotLayer from './layers/DotLayer';
import LineLayer from './layers/LineLayer';
import { hasNumVal, isEmptyRange, prepLabels } from './utils';
import { DataAxis, ValueAxis } from './utils/ChartAxis';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';

const elementOptions = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  labels: xAxisLabels,
  legend: legendOption,
  legendVisible: legendVisible,
  format: format,
  dir: seriesOrientation,
  blanks: blanks,
  interpolate: interpolate,
  visible: elementVisibility,
  chartColors: chartColors,
  annotations: optAnnotations,
  size: layoutWidth,
  [optShowDots.name]: optShowDots,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisDim: {
    title: axisTitle,
    format: axisFormat,
    reverse: axisReverse,
  },
};

function dist (a, b) {
  return Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);
}

/**
 * @typedef {[ number, number ] | [ number, number, object ]} Point
 */

/**
 * Find the closest of a set of points to a given target point
 * @param {Point[]} points The points to search through
 * @param {Point} targetPoint The target point to measure against
 * @param {number} [maxDist] The maximum distance allowed before ignoring a candiate
 * @return {null | Point}
 */
function closest (points, targetPoint, maxDist = Infinity) {
  let currDist = Infinity;
  let currPoint = null;
  points.forEach(p => {
    const d = dist(p, targetPoint);
    if (d < maxDist && d < currDist) {
      currDist = d;
      currPoint = p;
    }
  });
  return currPoint;
}

const gapTypes = {
  gap: 'gap',
  zero: 'zero',
  span: 'span',
};

export default class LineChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
  };

  static chartType = 'line';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    let table = dataExpr.readCropped(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      dir = w < h ? 'col' : 'row';
    }
    let size = dir === 'row' ? [ w, h ] : [ h, w ];

    const locale = props.locale;
    const axXformat = props.axisDim && format.read({ model: props.model, locale: props.locale, ...props.axisDim });

    const labelData = xAxisLabels.read(props);
    const labels = prepLabels(labelData, dir, axXformat, locale);

    const rawValues = [];
    if (!validExpr(props.expr)) {
      const l = labels ? labels.length : 0;
      size = [ l, 1 ];
      table = [ [] ];
    }

    const gapHandling = enumProp(blanks.read(props), gapTypes, 'gap');

    const valueaxisDim = [];
    const series = range(size[1]).map(() => []);
    range(h * w).forEach(i => {
      const a = ~~(i % size[1]);
      const b = ~~(i / size[1]);
      const cell = (dir === 'row') ? table[a][b] : table[b][a];
      /** @type {import('@/grid/types').Cellish | null} */
      let fact = cell || null;
      if (isBlankCell(cell)) {
        fact = null;
      }
      else if (hasNumVal(cell)) {
        fact = cell;
      }
      else {
        fact = { v: 0 };
      }
      if (fact) {
        valueaxisDim.push(fact);
        rawValues.push([ a, b, fact, cell ]);
        series[a].push([ a, b, fact ]);
      }
      else if (gapHandling === 'zero') {
        const fakeZero = { v: 0 };
        // FIXME: only do this once
        valueaxisDim.push(fakeZero);
        rawValues.push([ a, b, cell || fakeZero, cell ]);
        series[a].push([ a, b, null ]);
      }
      else if (gapHandling === 'gap') {
        series[a].push([ a, b, null ]);
      }
    });

    const legendRaw = legendOption.read(props);
    const dimAxis = DataAxis.prepare({
      props,
      length: size[0],
      labels: labelData,
      locale: locale,
      orient: 'bottom',
    });
    const valueAxis = ValueAxis.prepare({
      props,
      data: valueaxisDim,
      locale: locale,
      orient: 'left',
    });
    return {
      legend: Legend.prepare({
        labels: legendRaw,
        dir,
        length: size[1],
        locale: props.locale,
      }),
      labels,
      size,
      series,
      rawValues,
      gapHandling,
      annotations: prepAnnotations(optAnnotations.read(props)),
      legendVisible: legendVisible.isSet(props) ? legendVisible.read(props) : !isEmptyRange(legendRaw),
      valueAxis: valueAxis,
      dimAxis: dimAxis,
      transformX: d => dimAxis.scale(d[1]),
      transformY: d => valueAxis.scale(d[2] ? d[2].v : 0),
      format: format.read(props),
      modelId: props.model && props.model.lastWrite,
      lastLocale: locale,
    };
  }

  onPoint = e => {
    const m = relativeMouse(e, e.target);
    const pt = closest(this.points || [], [ m.left ?? NaN, m.top ?? NaN ], 40);
    if (pt) {
      const { labels, legend, size, format } = this.state;
      // eslint-disable-next-line
      const [ ser, cat, fact, cell ] = pt[2];
      let textValue = printCell(cell, format, this.props.locale);
      if (cell && !/\S/.test(textValue)) {
        textValue = '"' + textValue + '"';
      }
      this.setState({
        focusKnot: pt,
        hover: {
          title: labels ? labels[cat] : String(cat + 1),
          dimspec: { [legend[ser].text]: textValue },
          simple: size[1] === 1,
          cursorPosition: getCursorPosition(e),
        },
      });
    }
    else {
      this.onUnPoint();
    }
  };

  onUnPoint = () => {
    this.setState({ focusKnot: null, hover: null });
  };

  isDefined = d => {
    return (this.state.gapHandling === 'gap' ? d[2] != null : true);
  };

  render () {
    const props = this.props;

    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }
    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    // --- data/props ---
    const { chartTheme, theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { legend, legendVisible, size, series, rawValues, valueAxis, dimAxis } = this.state;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const palette = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);
    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme.footnote,
    });

    // --- axis ---
    const colorScale = scaleOrdinal(palette).domain(range(size[1]));
    legend.applyStyle(chartTheme.legend);
    legend.colorize(colorScale);
    valueAxis.applyStyle(chartTheme.valueAxis);
    dimAxis.applyStyle(chartTheme.dataAxis);

    // --- dimensions ---
    const outerWidth = this.state.width;
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth, [ dimAxis, valueAxis ], chartTitle, chartSubtitle, undefined, theme.chartFontStack,
    );
    legend.maxWidth = outerWidth;
    footnote.maxWidth = outerWidth - margin.right;

    margin.bottom += !legendVisible ? 0 : (chartTheme.legend?.margin ?? 0) + legend.height;

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    // save for later so we can point/unpoint values
    // these only ever get used by the mouse events
    this.points = rawValues.map(d => {
      return [ dimAxis.scale(d[1]), valueAxis.scale(d[2].v), d ];
    });
    const focusKnot = this.state.focusKnot;

    const pointDensity = dimAxis.size / dimAxis.domain().length;
    let useDots = pointDensity > 30 && series.length < 9;
    if (optShowDots.read(props) === 'false') {
      useDots = false;
    }
    const lineWidth = clamp(1.2, 1 + 0.3 * (pointDensity ** 0.5), 2.8);
    const haveData = validExpr(props.expr);

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Line Chart"
        role="img"
        >
        {title && <g fill={theme.color} transform="translate(1,0)">{title.render()}</g>}
        {subtitle && <g fill={theme.color} transform={`translate(1,${title?.height ?? 0})`}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`} style={{ display: '' }}>
          <g
            pointerEvents="all"
            onMouseMove={this.onPoint}
            onMouseLeave={this.onUnPoint}
            >
            {/* needed for pointerEvents to work correctly */}
            <rect width={width} height={height} fill="none" />

            <Grid
              styles={chartTheme}
              x={dimAxis}
              y={valueAxis}
              />

            <Clip
              id={'clip-' + props.id}
              active={valueAxis.isClipped}
              width={width}
              height={height}
              >
              <LineLayer
                visible={haveData}
                width={width}
                series={series}
                x={this.state.transformX}
                y={this.state.transformY}
                colorScale={d => colorScale(d[0])}
                interpolate={interpolate.read(props)}
                defined={this.isDefined}
                lineWidth={lineWidth}
                />
              <DotLayer
                visible={haveData && useDots}
                width={width} // to trigger reflow
                series={series}
                x={this.state.transformX}
                y={this.state.transformY}
                r={lineWidth * 1.2}
                fill={d => colorScale(d[0])}
                stroke={theme.background}
                defined={this.isDefined}
                />
              {focusKnot && (
                <circle
                  cx={focusKnot[0]}
                  cy={focusKnot[1]}
                  fill={colorScale(focusKnot[2][0])}
                  strokeWidth="1"
                  stroke={theme.background}
                  r={lineWidth * 1.8}
                  />
              )}
              <Annotations
                visible={haveData}
                styles={chartTheme}
                x={dimAxis}
                y={valueAxis}
                data={this.state.annotations}
                />
            </Clip>

          </g>
          <DataAxis
            data={dimAxis}
            y={height}
            />
          <ValueAxis
            data={valueAxis}
            />
        </g>

        <Footnote
          data={footnote}
          y={footnoteY}
          />

        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
