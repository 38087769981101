export const VIEW_TIME = 1;
export const VIEW_DATE = 2;
export const VIEW_YEAR = 4;
export const VIEW_MONTH = 8;
export const VIEW_DATETIME = VIEW_TIME | VIEW_DATE;

export const formatOptions = {
  leap1900: false,
  dateSpanLarge: true,
};
