import React from 'react';
import PropTypes from 'prop-types';

import GridError from './error';
import Wrapper from './Wrapper';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    editorline: PropTypes.number,
    emit: PropTypes.func,
  };

  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch (err) {
    this.setState({ hasError: true }, () => {
      if (this.props.emit) {
        this.props.emit('boundarycatch', err);
      }
    });
  }

  renderError () {
    return (
      <Wrapper {...this.props}>
        <GridError />
      </Wrapper>
    );
  }

  render () {
    if (this.state.hasError) {
      return this.renderError();
    }
    return this.props.children;
  }
}
