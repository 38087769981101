/* globals process */
/* eslint-disable react/prop-types, newline-per-chained-call, no-unused-vars */
import React from 'react';

import styles from './DesignGrid.module.scss';

/**
 * @param {object} props The visible
 * @param {boolean} [props.visible=false] The visible
 * @param {number} [props.columns=10] The columns
 * @return {JSX.Element | null}
 */
let DesignGrid = ({ visible = false, columns = 10 }) => null;

if (process.env.NODE_ENV !== 'production') {
  DesignGrid = function ({ visible = false, columns = 10 }) {
    if (!visible || columns < 1) {
      return null;
    }
    return (
      <div className={styles.grid}>
        {Array(10).fill(false).map((d, i) => (
          <div key={i} className={styles.track} />
        ))}
        <div className={styles.leading}>
          {Array(50).fill(false).map((d, i) => (
            <div key={i} className={styles.line} />
          ))}
        </div>
      </div>
    );
  };
}

export default DesignGrid;

