import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Label.module.scss';

export default function Label (props) {
  if (!props.text) {
    return null;
  }
  const cls = csx(
    props.inline ? styles.innerlabel : styles.label,
    props.disabled && styles.disabled,
    props.className,
  );
  return (
    <span className={cls}>
      {String(props.text).trim()}
    </span>
  );
}

Label.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  text: PropTypes.string,
};
