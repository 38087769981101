import { measureText } from './measureText';

// always include 0 on the axis
export function snapZero (ext, zero = 0) {
  const [ min, max ] = ext;
  if (max < zero) {
    return [ min, zero ];
  }
  else if (min > zero) {
    return [ zero, max ];
  }
  return ext;
}

// extend the axis to 0 if it either extremity is close enough
export function maybeZero (ext) {
  const [ min, max ] = ext;
  if (min > 0 && min / max < 0.2) {
    return [ 0, max ];
  }
  if (max < 0 && max / min < 0.2) {
    return [ min, 0 ];
  }
  return ext;
}

export function longestLabel (labels, font = '') {
  const minWidth = 0;
  const maxWidth = Infinity;
  let currWidth = minWidth;
  if (labels) {
    for (let i = 0; i < labels.length; i++) {
      if (labels[i] != null) {
        const width = measureText(labels[i], font);
        if (width > maxWidth) {
          // shortcut: we've maxed out
          return maxWidth;
        }
        if (width > currWidth) {
          currWidth = width;
        }
      }
    }
  }
  return currWidth;
}

export function logSpan (scale) {
  const [ d0, d1 ] = scale.domain();
  const p0 = Math.floor(Math.log(Math.abs(d0) + 1e-6) / Math.LN10);
  const p1 = Math.floor(Math.log(Math.abs(d1) + 1e-6) / Math.LN10);
  return p1 - p0;
}

export function axisFormatString (scale) {
  if (!scale.ticks) {
    return 'General';
  }
  const ticks = scale.ticks();
  const step = Math.abs((ticks[1] || 0) - (ticks[0] || 0));
  const digits = Math.max(0, -Math.floor(Math.log10(step) + 0.01));
  let fmt = '#,##0';
  if (digits) {
    fmt += '.000000'.slice(0, 1 + Math.min(digits, 6));
  }
  return fmt + ';-' + fmt + ';0';
}

