import React from 'react';
import csx from 'classnames';
import { range } from 'd3-array';
import PropTypes from 'prop-types';

import { prepLabels } from '@/grid/chart/utils';
import { compareValues, printCell } from '@/grid/utils';

import styles from './TableDropdown.module.scss';

export default class TableDropdown extends React.PureComponent {
  static propTypes = {
    cell: PropTypes.object,
    format: PropTypes.string,
    locale: PropTypes.string,
    options: PropTypes.array,
    labels: PropTypes.array,
    onInput: PropTypes.func,
    track: PropTypes.func,
  };

  static getDerivedStateFromProps (props) {
    const { options, labels, format, locale } = props;
    const table = options || [ [] ];
    const w = table[0].length;
    const h = table.length;
    const dir = w < h ? 'col' : 'row';
    const optionLabels = labels && prepLabels(labels, dir, format, locale);
    let optionList = [];
    if (w * h) {
      optionList = range(dir === 'row' ? w : h)
        .map(i => {
          const cell = (dir === 'row') ? table[0][i] : table[i][0];
          const option = (cell == null || cell instanceof Error) ? null : cell;
          return {
            index: i,
            value: option ? option.v : null,
            label: optionLabels && (optionLabels.length >= i + 1)
              ? optionLabels[i] || ''
              : printCell(option, format, locale),
          };
        });
    }
    return { optionList };
  }

  constructor (props) {
    super(props);
    /** @type {object[]} */
    const optionList = [];
    this.state = { optionList: optionList };
  }

  onFocusBlur = e => {
    if (this.props.track) {
      this.props.track(e.type, { elementType: 'table.dropdown' });
    }
  };

  onChange = e => {
    const index = +e.target.value;
    const option = this.state.optionList[index];
    if (this.props.track) {
      this.props.track('interact', { elementType: 'table.dropdown' });
    }
    this.props.onInput(this.props.cell.id, option.value);
  };

  render () {
    const { cell, options } = this.props;
    const optionList = this.state.optionList;
    const missingOptions = !options || !optionList.length;
    const valueIndex = optionList.findIndex(d => compareValues(cell.v, d && d.value));

    // longest option label is used to give dropdown a width
    let longestLabel = null;
    optionList.forEach(d => {
      if (longestLabel === null || d.label.length > longestLabel.length) {
        longestLabel = d.label;
      }
    });
    const label = (missingOptions)
      ? 'No options'
      : optionList[valueIndex]?.label;

    return (
      <label className={csx(styles.dropdownWrap, missingOptions && styles.disabled)}>
        <span className={styles.widget} aria-hidden="true">
          <span className={styles.value}>
            <span className={styles.currentLabel}>
              {label || '\u00A0'}
            </span>
            <span className={styles.spacer}>
              {longestLabel || '\u00A0'}
            </span>
          </span>
          <svg version="1.1" width="10" height="10" viewBox="0 0 36 36">
            <g transform="translate(18,18) rotate(180) translate(-18,-18)">
              <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />
            </g>
          </svg>
        </span>
        <select
          className={styles.dropdown}
          value={valueIndex === -1 ? '' : valueIndex}
          onChange={this.onChange}
          onFocus={this.onFocusBlur}
          onBlur={this.onFocusBlur}
          disabled={missingOptions}
          >
          {valueIndex === -1 && <option key="blank" value="">{'\u00A0'}</option>}
          {this.state.optionList.map((option, i) => (
            <option key={i} value={i}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  }
}
