import { color as d3_color } from 'd3-color';
import { scaleLinear } from 'd3-scale';

import { optCfMaxColor, optCfMidColor, optCfMinColor, optColorScale } from '../propsData';
import { clamp } from './clamp';

/**
 * @typedef {object} ColorStop
 * @property {string} type
 * @property {number} value
 * @property {string} backgroundColor
 */

/**
 * Reads a table condition formatting.
 *
 * @param {Record<string, any>} props The properties
 * @param {import('@/grid/types').Cellish[][]} dataTable The data table
 * @param {import('@/grid/Theme').default} theme
 * @return {{ stops: ColorStop[], scale: null | Function }}
 */
export function readTableCondFormatting (props, dataTable, theme) {
  if (optColorScale.isSet(props)) {
    const firstRule = optColorScale.read(props)[0];
    /** @type {ColorStop} */
    const min = {
      type: firstRule.minType,
      value: firstRule.minValue,
      backgroundColor: theme.resolveColor(firstRule.minBackgroundColor || optCfMinColor.defaultValue),
    };
    /** @type {ColorStop} */
    const mid = {
      type: firstRule.midType,
      value: firstRule.midValue,
      backgroundColor: theme.resolveColor(firstRule.midBackgroundColor || optCfMidColor.defaultValue),
    };
    /** @type {ColorStop} */
    const max = {
      type: firstRule.maxType,
      value: firstRule.maxValue,
      backgroundColor: theme.resolveColor(firstRule.maxBackgroundColor || optCfMaxColor.defaultValue),
    };

    const stops = [ min, mid, max ]
      .filter(d => d.type !== '' && d.type !== 'none');

    if (stops.length >= 2) {
      const isOnlyNum = stops.every(d => d.type === 'num');
      let data = [];
      if (!isOnlyNum) {
        data = dataTable
          .flat()
          .filter(d => d && typeof d.v === 'number')
          .map(d => d.v)
          .sort((a, b) => a - b);
      }

      let validScale = true;
      stops.forEach(stop => {
        if (stop.type === 'min') {
          stop.value = data.at(0);
        }
        else if (stop.type === 'max') {
          stop.value = data.at(-1);
        }
        else if (stop.type === 'percent') {
          const percent = clamp(0, stop.value, 1);
          const delta = data.at(-1) - data.at(0);
          stop.value = data.at(0) + (delta * percent);
        }
        else if (stop.type === 'percentile') {
          const rank = (data.length - 1) * clamp(0, stop.value, 1);
          const rank0 = Math.floor(rank);
          const rank1 = Math.ceil(rank);
          if (rank0 === rank1) {
            stop.value = data.at(rank);
          }
          else {
            stop.value = (
              data.at(rank0) * (rank1 - rank) +
              data.at(rank1) * (rank - rank0)
            );
          }
        }
        if (typeof stop.value !== 'number' || !isFinite(stop.value)) {
          validScale = false;
        }
      });

      let scale = null;
      if (validScale) {
        const _scale = scaleLinear(
          stops.map(d => d.value),
          stops.map(d => d3_color(d.backgroundColor)),
        );
        const minStop = /** @type ColorStop */(stops.at(0));
        const maxStop = /** @type ColorStop */(stops.at(-1));
        scale = v => {
          if (v < minStop.value) {
            return minStop.backgroundColor;
          }
          if (v >= maxStop.value) {
            return maxStop.backgroundColor;
          }
          return _scale(v);
        };
      }
      return { stops, scale };
    }
  }

  return { stops: [], scale: null };
}
