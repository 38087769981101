import React, { lazy, Suspense } from 'react';

import { color, elementVisibility, equation, fontSize, optInlineLegacy } from '../propsData';

const LaTeX = lazy(() => import('./latex'));

const LazyLaTeX = props => {
  return (
    <Suspense fallback={<span>{equation.read(props)}</span>}>
      <LaTeX {...props} />
    </Suspense>
  );
};

LazyLaTeX.requiredOption = equation.name;

LazyLaTeX.options = {
  [color.name]: color,
  [elementVisibility.name]: elementVisibility,
  [equation.name]: equation,
  [fontSize.name]: fontSize,
  [optInlineLegacy.name]: optInlineLegacy,
};

export default LazyLaTeX;
