import React from 'react';
import PropTypes from 'prop-types';

import Checkmark from '@/grid/Checkmark';
import { compareValues } from '@/grid/utils';

import styles from './TableCheckbox.module.scss';

export default class TableCheckbox extends React.PureComponent {
  static propTypes = {
    cell: PropTypes.object,
    value: PropTypes.any,
    valueOff: PropTypes.any,
    onInput: PropTypes.func,
    track: PropTypes.func,
  };

  static getDerivedStateFromProps (props) {
    const { cell, value: valueOn, valueOff } = props;
    // determine if the checkbox is checked or not
    const currentValue = cell && cell.v;
    let isChecked = currentValue === true;
    if (valueOn != null) {
      isChecked = compareValues(currentValue, valueOn);
    }
    // determine what value to write if it gets clicked
    let writeValue = valueOn ?? true;
    if (isChecked) {
      if (valueOff != null) {
        writeValue = valueOff ?? false;
      }
      else {
        writeValue = writeValue === true ? false : '';
      }
    }
    return { isChecked, writeValue };
  }

  constructor (props) {
    super(props);
    this.state = {
      isChecked: false,
      writeValue: true,
    };
  }

  onInput = () => {
    const { cell, onInput, track } = this.props;
    if (track) {
      track('interact', { elementType: 'table.checkbox' });
    }
    if (onInput && cell) {
      onInput(cell.id, this.state.writeValue);
    }
  };

  render () {
    return (
      <span className={styles.checkbox}>
        <Checkmark
          checked={this.state.isChecked}
          size={14}
          onClick={this.onInput}
          />
      </span>
    );
  }
}
