import { dateToSerial, parseDate } from 'numfmt';

/**
 * @param {number | string | Date} d
 * @param {boolean} [fallback=false]
 * @return {null | number}
 */
export function ensureSerialDate (d, fallback = false) {
  if (typeof d === 'number') {
    return d;
  }
  else if (typeof d === 'string') {
    const p = parseDate(d);
    if (p) {
      return +p.v;
    }
  }
  else if (d instanceof Date && isFinite(d.valueOf())) {
    return dateToSerial(d);
  }
  if (fallback) {
    return dateToSerial(new Date());
  }
  return null;
}

/**
 * @param {number} value The value
 * @param {number} base The base
 * @param {number} step The step
 * @return {number}
 */
export function roundByStep (value, base, step) {
  return base + (step * Math.round((value - base) / step));
}

/**
 * @param {number | string | Date} value
 * @param {number | string | Date} min
 * @param {number | string | Date} max
 * @param {number} step
 * @param {boolean} [fallback=false]
 * @return {number}
 */
export function boundDate (value, min, max, step, fallback = false) {
  const minDate = ensureSerialDate(min) ?? -Infinity;
  const maxDate = ensureSerialDate(max) ?? Infinity;
  const stepSize = isFinite(step) ? step * 1 : null;
  const _v = ensureSerialDate(value, fallback);
  const date = Math.max(minDate, Math.min(_v || 0, maxDate));
  // if there is step, we need to ensure that the value is rounded to step
  if (stepSize) {
    const rounded = roundByStep(date, min ? minDate : 0, stepSize);
    let clamped;
    if (rounded > maxDate) {
      clamped = rounded - stepSize;
    }
    else if (rounded < minDate) {
      clamped = rounded + stepSize;
    }
    else {
      clamped = rounded;
    }
    // BÞ: I don't think this can actually happen but html spec assumes it can
    if (clamped < minDate || clamped > maxDate) {
      return date;
    }
    return clamped;
  }
  return date;
}

/** @return {number | null} */
export function todayAsSerial () {
  const d = new Date(Date.now());
  return dateToSerial([ d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate() ]);
}

/**
 * @param {HTMLElement} elmToMove The elm to move
 * @param {HTMLElement} anchorElm The anchor elm
 * @param {number} [margin=3] The margin
 */
export function alignElement (elmToMove, anchorElm, margin = 3) {
  if (!elmToMove || !anchorElm) {
    return;
  }
  elmToMove.style.top = 'unset';
  elmToMove.style.left = 'unset';
  const mRect = elmToMove.getBoundingClientRect();
  const aRect = anchorElm.getBoundingClientRect();
  const viewport = new DOMRect(
    0, 0,
    document.documentElement.clientWidth,
    document.documentElement.clientHeight,
  );
  const fitsInViewport = rect => !(
    rect.left < viewport.left ||
    rect.right > viewport.right ||
    rect.top < viewport.top ||
    rect.bottom > viewport.bottom
  );
  const toAbsolute = rect => {
    return new DOMRect(
      rect.left + aRect.left, rect.top + aRect.top,
      rect.width, rect.height,
    );
  };
  const positions = [
    // bottom right
    new DOMRect(0, aRect.height + margin, mRect.width, mRect.height),
    // bottom left
    new DOMRect(aRect.width - mRect.width, aRect.height + margin, mRect.width, mRect.height),
    // top right
    new DOMRect(0, -mRect.height - margin, mRect.width, mRect.height),
    // top left
    new DOMRect(aRect.width - mRect.width, -mRect.height - margin, mRect.width, mRect.height),
  ];
  // find the first (most prefered) position where element fits on screen
  let pos = positions.find(d => fitsInViewport(toAbsolute(d)));
  // if there was no fit, just use the first one
  if (!pos) {
    pos = positions[0];
  }
  const deltaTop = aRect.top - mRect.top;
  const deltaLeft = aRect.left - mRect.left;
  elmToMove.style.top = (deltaTop + pos.top) + 'px';
  elmToMove.style.left = (deltaLeft + pos.left) + 'px';
  elmToMove.style.display = '';
}
