import React from 'react';
import PropTypes from 'prop-types';

export default function Clip ({ id, width, height, children, active = false }) {
  if (!active) {
    return children;
  }
  return (
    <>
      <defs>
        <clipPath id={id}>
          <rect x="0" y="0" width={width} height={height} />
        </clipPath>
      </defs>
      <g clipPath={`url(#${id})`}>
        {children}
      </g>
    </>
  );
}

Clip.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  active: PropTypes.bool,
};
