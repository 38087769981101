import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { linkUrl } from '../propsData';
import { applyStateFromURL, parseHref } from '../utils/urlState';

export default function GridLink (props) {
  const { title, href, documentId, attributes, children, model, testId, isAuthenticated } = props;
  const realHref = linkUrl.read({ href, model });

  // pulling all the properties of a url is quite expensive and links re-render quite a lot
  const link = useMemo(() => parseHref(realHref, isAuthenticated), [ realHref, isAuthenticated ]);

  const onClick = e => {
    if (documentId && link.docId === documentId) {
      e.preventDefault();
      applyStateFromURL(model, link.url);
    }
    props.track('interact', { elementType: 'link', targetUrl: link.url });
  };

  return (
    <a
      {...link.props}
      title={title}
      id={props.id}
      onClick={onClick}
      data-testid={testId}
      {...attributes}
      >
      {children}
    </a>
  );
}

GridLink.options = {
  [linkUrl.name]: linkUrl,
};

GridLink.propTypes = {
  model: PropTypes.object,
  documentId: PropTypes.string,
  track: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  target: PropTypes.string,
  testId: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
};
