import React from 'react';
import csx from 'classnames';
import { format } from 'numfmt';
import PropTypes from 'prop-types';

import { formatOptions } from './constants';

import styles from './CalendarButton.module.scss';

export default function CalendarButton ({
  date,
  onClick,
  isSelectable,
  today,
  locale,
  selectedDate,
  currentMonth,
}) {
  const selectable = isSelectable(date);
  return (
    <span
      role="button"
      className={csx(
        styles.button,
        !selectable && styles.disabled,
        !currentMonth && styles.overflow,
        (date === Math.trunc(selectedDate)) && styles.selected,
        (date === today) && styles.today,
      )}
      onClick={selectable ? onClick : null}
      data-date={date}
      aria-disabled={!selectable}
      >
      {format('d', date, { locale, nbsp: true, ...formatOptions })}
    </span>
  );
}

CalendarButton.propTypes = {
  date: PropTypes.number.isRequired,
  isSelectable: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  today: PropTypes.number,
  locale: PropTypes.string,
  selectedDate: PropTypes.number,
  currentMonth: PropTypes.bool,
};

CalendarButton.defaultProps = {
  onClick: () => {},
  locale: 'us-EN',
};
