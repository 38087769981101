import React, { useContext } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { optButtonColor, optButtonFontSize, optCornerRounding, optInlineSize, optWidthAuto } from '@/grid/propsData';
import { ThemesContext } from '@/grid/ThemesContext';
import { calcInputSizes } from '@/grid/utils/calcInputSizes';
import Wrapper from '@/grid/Wrapper';

import styles from './BaseButton.module.scss';

export default function BaseButton ({ disabled, onClick, label, ...props }) {
  const { theme } = useContext(ThemesContext);

  const dynamicStyle = {};
  if (!disabled && optButtonColor.isSet(props)) {
    const bgColor = optButtonColor.read(props);
    if (bgColor) {
      dynamicStyle.borderColor = theme.borderColor(bgColor);
      dynamicStyle.background = theme.resolveColor(bgColor);
      dynamicStyle.color = theme.textColorFor(bgColor);
    }
  }

  const dim = calcInputSizes(
    optInlineSize.read(props),
    optButtonFontSize.isSet(props) ? optButtonFontSize.read(props) : null,
    optCornerRounding.read(props) ?? 0.5,
  );
  dynamicStyle.fontSize = Math.round(dim.fontSize) + 'px';
  dynamicStyle.lineHeight = (dim.height - 2) + 'px';
  dynamicStyle.padding = `0 ${dim.buttonPadding}px`;
  dynamicStyle.borderRadius = dim.borderRadius;

  return (
    <Wrapper
      {...props}
      className={csx(styles.button)}
      inlineWidth={optWidthAuto.read(props)}
      inlineMode="text"
      htmlTitle={props.title}
      >
      <button
        type="button"
        onClick={disabled ? undefined : onClick}
        className={csx(styles.buttonElm, disabled ? styles.disabled : undefined)}
        style={dynamicStyle}
        aria-disabled={disabled || false}
        >
        {typeof label === 'string' ? label.trim() || '\u00a0' : label}
      </button>
      {props.children}
    </Wrapper>
  );
}

BaseButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.node,
  title: PropTypes.string,
};
