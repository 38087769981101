/**
 * @class CellRect (name)
 */
export class CellRect {
  /**
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   */
  constructor (x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  get top () {
    return this.y;
  }

  get left () {
    return this.x;
  }

  get bottom () {
    return this.y + this.height;
  }

  get right () {
    return this.x + this.width;
  }

  get size () {
    return [ this.width, this.height ];
  }

  resize (width, height) {
    return new CellRect(this.x, this.y, width, height);
  }

  translate (x = 0, y = 0) {
    return new CellRect(this.x + x, this.y + y, this.width, this.height);
  }

  /**
   * @return {Array<[ [ number, number ], [ number, number ] ]>} The borders.
   */
  getBorders () {
    const { x, y } = this;
    const r = x + this.width;
    const b = y + this.height;
    return [
      [ [ x, y ], [ r, y ] ],
      [ [ x, b ], [ r, b ] ],
      [ [ x, y ], [ x, b ] ],
      [ [ r, y ], [ r, b ] ],
    ];
  }
}
