import React from 'react';
import PropTypes from 'prop-types';

const paths = [
  '',
  'M0,7L5,2L10,7Z',
  'M0,3L5,8L10,3Z',
  'M7,0L2,5L7,10Z',
  'M3,0L8,5L3,10Z',
];

export default function Arrow ({ dir = 0 }) {
  if (!dir) {
    return null;
  }
  return (
    <svg viewBox="0 0 10 10" height="1em">
      <path fill="currentColor" d={paths[dir]} />
    </svg>
  );
}

Arrow.HIDDEN = 0;
Arrow.UP = 1;
Arrow.DOWN = 2;
Arrow.LEFT = 3;
Arrow.RIGHT = 4;

Arrow.propTypes = {
  dir: PropTypes.oneOf([ 0, 1, 2, 3, 4 ]),
};
