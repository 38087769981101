import React from 'react';
import Textbox from '@borgar/textbox';
import { interpolateLab } from 'd3-interpolate';
import { arc as d3_arc } from 'd3-shape';
import { format as formatValue, isPercentFormat } from 'numfmt';
import PropTypes from 'prop-types';

import { contrast } from '@grid-is/color-utils';

import { getCursorPosition } from '../../utils/offset';
import { VALUE_LABELS_MIN } from '../constants';
import modelProp from '../modelProp';
import {
  chartColors,
  data as dataExpr,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  layoutWidth,
  legend as legendOption,
  legendVisible,
  piehole,
  seriesOrientation,
  sortOrderPie,
  valueLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { lowerCase, printCell, validExpr } from '../utils';
import BaseChart from './BaseChart';
import { hasNumVal, isEmptyRange } from './utils';
import ChartLabel from './utils/ChartLabel';
import { Footnote } from './utils/Footnote';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';
import { selectivePieLabels } from './utils/selectiveLabels';

function sortByWeight (data) {
  const pie_l = [];
  const pie_r = [];
  let r_weight = 0;
  let l_weight = 0;
  data = data.sort((a, b) => {
    return b.v - a.v;
  });
  data.forEach(d => {
    if (r_weight <= l_weight) {
      r_weight += d.v;
      pie_r.push(d);
    }
    else {
      l_weight += d.v;
      pie_l.unshift(d);
    }
  });
  return pie_r.concat(pie_l);
}

const options = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  legend: legendOption,
  legendVisible,
  sortOrder: sortOrderPie,
  size: layoutWidth,
  format: format,
  piehole: piehole,
  dir: seriesOrientation,
  visible: elementVisibility,
  chartColors: chartColors,
  valueLabels: valueLabels,
};

export default class PieChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
  };

  static chartType = 'pie';
  static options = options;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    const table = dataExpr.read(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      if (w === 1) {
        dir = 'row';
      }
      else if (h === 1) {
        dir = 'col';
      }
      else {
        dir = w < h ? 'col' : 'row';
      }
    }
    const size = dir === 'row' ? [ w, h ] : [ h, w ];

    let error;
    let data = [];
    let sum = 0;
    // convert table to series
    const [ , numSlices ] = size;
    for (let y = 0; y < numSlices; y++) {
      const x = 0;
      const cell = (dir === 'row') ? table[y][x] : table[x][y];
      if (hasNumVal(cell)) {
        const v = cell.v;
        // detect negatives and deal with them
        if (v < 0) {
          error = 'Cannot draw pie chart because the data contains a negative number.';
        }
        data.push({
          v: v,
          cell: cell,
          index: y,
          percent: 0,
        });
        sum += v;
      }
    }

    if (!data.length || sum <= 0) {
      error = 'Cannot draw pie chart because the data has no positive numbers.';
    }

    // find percentages
    data.forEach(d => (d.percent = d.v / sum));

    // sort the slices
    const sort = sortOrderPie.read(props);
    if (sort === 'descending' || sort === 'desc') {
      data = data.sort((a, b) => b.v - a.v);
    }
    else if (sort === 'ascending' || sort === 'asc') {
      data = data.sort((a, b) => a.v - b.v);
    }
    else if (sort === 'helium') {
      data = sortByWeight(data);
    }

    // arcs are computed
    data.reduce((angle, d) => {
      const a = d.percent * Math.PI * 2;
      d.startAngle = angle;
      d.midAngle = angle + a / 2;
      d.endAngle = angle + a;
      return d.endAngle;
    }, 0);

    const legendRaw = legendOption.read(props);

    return {
      dir,
      legendRaw,
      size,
      data,
      error,
      numSlices,
      showValues: valueLabels.read(props),
      isDonut: piehole.read(props),
      showLegend: legendVisible.isSet(props) ? legendVisible.read(props) : !isEmptyRange(legendRaw),
      modelId: props.model && props.model.lastWrite,
      format: format.read(props),
      lastLocale: props.locale,
    };
  }

  contrastColor (color) {
    if (!color) {
      return 'white';
    }
    if (color.toLowerCase() === 'currentcolor') {
      const ctx = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
      return contrast(ctx.theme.color, 'white', 'black');
    }
    return contrast(color, 'white', 'black');
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const { chartTheme, theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { locale } = props;

    // --- data/props ---
    const { dir, legendRaw, showLegend, data, format, error, isDonut, numSlices } = this.state;
    const showValues = this.state.width > VALUE_LABELS_MIN ? this.state.showValues : valueLabels.NONE;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);

    const colorFade = interpolateLab(theme.backgroundColor || '#fff', theme.color || 'black');

    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    const colors = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);

    data.forEach(d => {
      d.color = colors[d.index % colors.length];
    });

    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme.footnote,
    });
    const legend = Legend.prepare({
      labels: legendRaw,
      dir,
      length: numSlices,
      locale: props.locale,
      style: chartTheme.legend,
    });
    legend.forEach((d, i) => (d.color = colors[i % colors.length]));

    // --- dimensions ---
    const outerWidth = this.state.width;
    const { margin, width, height, title, subtitle } = prepDimensions(
      outerWidth,
      [],
      chartTitle,
      chartSubtitle,
      undefined,
      theme.chartFontStack,
    );
    legend.maxWidth = outerWidth;
    footnote.maxWidth = outerWidth - margin.right;
    if (showLegend && legend) {
      margin.bottom += (chartTheme.legend?.margin ?? 0) + legend.height;
    }

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + (chartTheme.footnote?.margin ?? 0);
    }

    const printValue = (d, withPercent = false) => {
      let value = printCell(d.cell, format, locale);
      // don't show this if format of Value is already a %
      if (withPercent && !isPercentFormat(format || d.cell.z || '')) {
        value += formatValue('" ("#,##0.0#%")"', d.percent, { locale, nbsp: true });
      }
      return value;
    };

    const prepHover = (fact, cursorPosition) => {
      const title = legend[fact.index]?.text ?? 'Series1';
      return {
        title,
        dimspec: { value: printValue(fact, true) },
        simple: true,
        cursorPosition,
      };
    };
    const radius = Math.min(width, height) / 2 - 1;
    const arc = d3_arc()
      .innerRadius(isDonut ? radius / 2 : 0)
      .outerRadius(radius);
    const arcLabel = d3_arc()
      .innerRadius(radius)
      .outerRadius(radius * 0.5);

    // kill label display if the chart shrinks down to X width
    data.forEach(d => {
      if (showValues === valueLabels.NONE) {
        d.label = null;
      }
      else {
        const text = printValue(d);
        const [ x, y ] = arcLabel.centroid(d);
        const color = this.contrastColor(d.color);
        d.label = {
          width: Textbox.measureText(text),
          color: color,
          dark: color === 'black',
          height: 14,
          text,
          x,
          y,
        };
      }
    });

    if (showValues === valueLabels.SOME) {
      const lightDocBg = contrast(theme.background, 'white', 'black') === 'black';
      selectivePieLabels(data, radius, isDonut, lightDocBg);
    }

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Pie Chart"
        role="img"
        >
        {title && <g transform="translate(1,0)" fill={theme.color}>{title.render()}</g>}
        {subtitle && <g transform={`translate(1,${title?.height ?? 0})`} fill={theme.color}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`}>
          {error ? (
            <g>
              <circle
                r={radius}
                cx={width / 2}
                cy={height / 2}
                fill="none"
                stroke={colorFade(0.3)}
                strokeDasharray="4 3"
                strokeWidth={1.5}
                />
              <ChartLabel
                y={height / 2}
                x={width / 2}
                height={height}
                width={width - (width * 0.15)}
                align="center"
                vAlign="middle"
                color={theme.color}
                font={theme.chartFontStack}
                size={14}
                text={'Invalid data: ' + error}
                />
              <rect />
            </g>
          ) : (
            <g transform={`translate(${width / 2},${height / 2})`}>
              {data.map((d, i) => (
                <path
                  key={i}
                  fill={d.color}
                  strokeWidth={0.5}
                  d={arc(d)}
                  onMouseOver={e => {
                    const cursorPosition = getCursorPosition(e);
                    this.setState({ hover: prepHover(d, cursorPosition) });
                  }}
                  onMouseMove={e => {
                    const cursorPosition = getCursorPosition(e);
                    this.setState({ hover: prepHover(d, cursorPosition) });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover: null });
                  }}
                  />
              ))}
              {data.map((d, i) => {
                return !!(d.label && !d.label.hidden) && (
                  <g key={i} pointerEvents="none">
                    <ChartLabel
                      x={d.label.x}
                      y={d.label.y}
                      align="center"
                      vAlign="middle"
                      font={theme.chartFontStack}
                      color={d.label.color}
                      size={14}
                      text={d.label.text}
                      />
                  </g>
                );
              })}
            </g>
          )}
        </g>
        <Footnote
          data={footnote}
          y={footnoteY}
          />
        <Legend
          data={(showLegend && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
