/* globals process */
import React from 'react';
import Textbox from '@borgar/textbox';
import PropTypes from 'prop-types';

const fontSize = 14;
const fontFamily = 'Source Serif Pro, Roboto, Arial, Helvetica, sans-serif';
const isDev = process.env.NODE_ENV !== 'production';

export default function ChartLabel (props) {
  if (!props.text) {
    return null;
  }
  const { align, textAlign, vAlign, width, height, lineHeight, text, angle, color, onClick } = props;
  const padding = props.padding || 0;
  const x = props.x || 0;
  const y = props.y || 0;

  let dy = 0;
  let paddingY = -padding;
  if (vAlign === 'top') {
    dy = 0.71;
    paddingY = padding;
  }
  if (vAlign === 'middle') {
    dy = 0.32;
    paddingY = 0;
  }

  let ta = 'start';
  let paddingX = padding;
  if (align === 'center') {
    ta = 'middle';
    paddingX = 0;
  }
  if (align === 'right') {
    ta = 'end';
    paddingX = -padding;
  }

  const size = props.size || fontSize;
  const font = props.font || fontFamily;

  // the simple case: text with no width
  if (!width) {
    return (
      <>
        <text
          fontSize={size + 'px'}
          fontFamily={font}
          textAnchor={ta}
          dy={dy + 'em'}
          x={x + paddingX}
          y={y + paddingY}
          onClick={onClick}
          fill={color || 'currentColor'}
          transform={angle ? `rotate(${angle}, ${x || 0}, ${y || 0}) ` : undefined}
          >
          {text}
        </text>
        {isDev && props.debug && (
          <circle
            cx={x}
            cy={y}
            r={2}
            fill="none"
            stroke="red"
            />
        )}
      </>
    );
  }

  const boxOpts = {
    width: width,
    height: height,
    align: textAlign || align,
    valign: vAlign,
    font: `${size}px/${size * (lineHeight || 1.2)}px ${font}`,
    createElement: React.createElement,
    parser: props.format || 'html',
  };
  if (props.overflow) {
    boxOpts.overflow = props.overflow;
  }
  if (props.overflowLine) {
    boxOpts.overflowLine = props.overflowLine;
  }
  const box = new Textbox(boxOpts);
  const lines = box.linebreak(text);

  let adjustX = 0;
  if (align === 'center') {
    adjustX = lines.width / 2;
  }
  if (align === 'right') {
    adjustX = lines.width;
  }

  let bgY = 0;
  let adjustY = 0;
  if (vAlign === 'bottom') {
    adjustY = height;
    bgY = (height - lines.height);
  }
  else if (vAlign === 'middle') {
    adjustY = height * 0.5;
    bgY = (height / 2 - lines.height / 2);
  }

  const bgPadding = Math.max(3, padding * 0.5);

  return (
    <>
      <g
        transform={(
          (angle ? `rotate(${angle}, ${x || 0}, ${y || 0})` : '') +
          `translate(${(x || 0) - adjustX + paddingX},${(y || 0) - adjustY + paddingY})`
        )}
        fill={color || 'currentColor'}
        stroke={props.strokeColor || 'none'}
        strokeLinejoin="round"
        strokeWidth={props.strokeColor ? props.strokeWidth ?? 2 : null}
        paintOrder="stroke"
        onClick={onClick}
        >
        {props.backgroundColor && (
          <rect
            rx={6}
            x={-bgPadding}
            y={-bgPadding + bgY}
            width={lines.width + bgPadding * 2}
            height={lines.height + bgPadding * 2}
            fill={props.backgroundColor}
            fillOpacity={props.backgroundOpacity}
            stroke="none"
            />
        )}
        <g fillOpacity={props.opacity}>
          {box.render(lines)}
        </g>
      </g>
      {isDev && props.debug && (
        <circle
          cx={x}
          cy={y}
          r={2}
          fill="none"
          stroke="red"
          />
      )}
    </>
  );
}

ChartLabel.defaultProps = {
  opacity: 1,
  backgroundOpacity: 1,
};

ChartLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  color: PropTypes.string,
  strokeColor: PropTypes.string,
  opacity: PropTypes.number,
  strokeWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number,
  vAlign: PropTypes.oneOf([ 'top', 'bottom', 'middle' ]),
  align: PropTypes.oneOf([ 'left', 'right', 'center' ]),
  textAlign: PropTypes.oneOf([ 'left', 'right', 'center' ]),
  lineHeight: PropTypes.number,
  size: PropTypes.number,
  width: PropTypes.number,
  angle: PropTypes.number,
  padding: PropTypes.number,
  height: PropTypes.number,
  overflow: PropTypes.string,
  overflowLine: PropTypes.string,
  font: PropTypes.string,
  debug: PropTypes.bool,
  format: PropTypes.oneOf([ 'html', 'latex', 'text' ]),
  onClick: PropTypes.func,
};

