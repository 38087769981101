import React from 'react';
import csx from 'classnames';
import { range } from 'd3-array';
import { color as d3_color } from 'd3-color';
import PropTypes from 'prop-types';

import { a1ToRowColumn, colFromOffs, ERROR_CALC, Reference } from '@grid-is/apiary';

import { MAX_TABLE_CELLS } from '@/grid/constants';
import { TargetOption } from '@/grid/options/handlers/TargetOption';

import DataError from '../DataError';
import modelProp from '../modelProp';
import {
  columnHeaders,
  data,
  elementVisibility,
  format,
  layoutWidth,
  optButtonFontSize,
  optColorScale,
  optFooter,
  optFooterTitle,
  optHeaderTitle,
  optIncludeEmpty,
  optTableInputs,
  rowHeaders,
  searchable,
  showHiddenCells,
  sortable,
  sortBy,
  sortOrder,
  striped,
  tableDensity,
  tableHeight,
  tableLayout,
  tableSubtitle,
  tableTitle,
  useCellStyles,
} from '../propsData';
import ScrollPane from '../ScrollPane';
import { ThemesContext } from '../ThemesContext';
import { lowerCase, printCell, stableSort, transpose, validExpr } from '../utils';
import { readTableCondFormatting } from '../utils/readTableCondFormatting';
import { removeHidden } from '../utils/removeHidden';
import Wrapper from '../Wrapper';
import TableCheckbox from './tableInputs/TableCheckbox';
import TableDropdown from './tableInputs/TableDropdown';
import TableInput from './tableInputs/TableInput';
import TableInputBox from './tableInputs/TableInputBox';
import TableSlider from './tableInputs/TableSlider';
import TableTangle from './tableInputs/TableTangle';
import TableValue from './tableInputs/TableValue';

import styles from './table.module.scss';

const ZERO_SPACE = '\u200B';
const orderMap = { ascending: 1, asc: 1, descending: -1, desc: -1 };

// this is a dummy option used to perform writes
const targetCell = new TargetOption({ name: 'expr', label: 'Dummy' });

const inputTypes = {
  slider: {
    handler: TableSlider,
    textAlign: 'center',
  },
  input: {
    handler: TableInput,
  },
  inputbox: {
    handler: TableInputBox,
  },
  tangle: {
    handler: TableTangle,
  },
  checkbox: {
    handler: TableCheckbox,
    textAlign: 'center',
  },
  dropdown: {
    handler: TableDropdown,
    textAlign: 'center',
  },
};

const elementOptions = {
  expr: data,
  title: tableTitle,
  subtitle: tableSubtitle,
  labels: rowHeaders,
  legend: columnHeaders,
  [optFooter.name]: optFooter,
  [optFooterTitle.name]: optFooterTitle,
  [optHeaderTitle.name]: optHeaderTitle,
  [optColorScale.name]: optColorScale,
  [optIncludeEmpty.name]: optIncludeEmpty,
  [optButtonFontSize.name]: optButtonFontSize,
  size: layoutWidth,
  format: format,
  sortable: sortable,
  searchable: searchable,
  striped: striped,
  sortBy: sortBy,
  sortOrder: sortOrder,
  series: optTableInputs,
  tableLayout: tableLayout,
  tableHeight: tableHeight,
  tableDensity: tableDensity,
  visible: elementVisibility,
  useCellStyles: useCellStyles,
  showHiddenCells: showHiddenCells,
};

function sortIcon (i, c, d) {
  return (
    <svg viewBox="0 0 20 25" width="12" height="12" style={{ display: 'inline-block' }}>
      <path className={i === c && d === -1 ? styles.active : ''} d="M0,10 L10,0 L20,10 L0,10" />
      <path className={i === c && d === 1 ? styles.active : ''} d="M0,15 L10,25 L20,15 L0,15" />
    </svg>
  );
}

function cellSpan (span, max) {
  let s;
  if (span && span > 1) {
    s = Math.min(span, max);
    if (s < 2) {
      s = undefined;
    }
  }
  return s;
}

/**
 * @param {import('@/grid/types').Cellish} cell A cell to read styles from
 * @param {object} [opt] The allow fonts
 * @param {boolean} [opt.allowFonts=true] Should font styles be read from the cell
 * @param {boolean} [opt.allowAlignment=true] The allow alignment
 * @param {Record<string, any>} [defaults={}] Style defaults to use if cell does not provide any
 * @return {Record<string, any>}
 */
function buildCellStyle (cell, { allowFonts = true, allowAlignment = true } = {}, defaults = {}) {
  /** @type {Record<string,any>} */
  const out = Object.assign({}, defaults);
  if (!cell) {
    return out;
  }
  const { v, s } = cell;

  // defaults are not overwritten by auto-detect
  if (!out.textAlign) {
    if (typeof v === 'number') {
      out.textAlign = 'right';
    }
    if (v instanceof Error || v === true || v === false) {
      out.textAlign = 'center';
    }
  }

  // alignment
  if (s && allowAlignment) {
    const hAlign = s['horizontal-alignment'];
    if (hAlign && (hAlign === 'left' || hAlign === 'center' || hAlign === 'right')) {
      out.textAlign = hAlign;
    }
    const vAlign = s['vertical-alignment'];
    if (vAlign && (vAlign === 'top' || vAlign === 'center' || vAlign === 'bottom')) {
      out.verticalAlign = vAlign;
    }
  }

  // bold/italic/underline
  if (s && allowFonts) {
    if (s.italic) {
      out.fontStyle = 'italic';
    }
    if (s.bold) {
      out.fontWeight = 'bold';
    }
    if (s.underline) {
      out.textDecoration = 'underline';
    }
    if (s['font-color']) {
      out.color = s['font-color'];
    }
  }
  return out;
}

function getColumnWidth (sheet, colIndex) {
  const ix = 1 + colIndex;
  const w = sheet.columns.find(c => c.begin >= ix && c.end <= ix);
  return (w && w.width) ?? (sheet?.defaults?.col_width || 12);
}

function getCellMergeArea (workbook, cell) {
  if (cell && cell.M) {
    // FIXME: assumes there is only one workbook in the model
    const sheet = workbook?.getSheetByIndex(cell.sheetIndex);
    if (sheet) {
      return sheet.merges[cell.M];
    }
  }
  return null;
}

function getTD (workbook, cell, x, y) {
  let rowSpan = null;
  let colSpan = null;
  let skipRender = false;

  const area = getCellMergeArea(workbook, cell);
  if (area) {
    // this is the merge anchor cell
    if (cell.M === cell.id) {
      colSpan = area[0];
      rowSpan = area[1];
    }
    // this cell is part of a merge
    else {
      const oM = Reference.parse(cell.M);
      const oC = Reference.parse(cell.id);
      let c = 0;
      let r = 0;
      if (oM && oM.range && oC && oC.range) {
        c = oM.range.left - oC.range.left;
        r = oM.range.top - oC.range.top;
      }
      if (x + c >= 0 && y + r >= 0) {
        // this cell is a part of a merge that is
        // covered by another rendered table-cell
        skipRender = true;
      }
      else {
        // this cell is a part of a merge that extends
        // into the rendered range
        cell = { v: null };
      }
    }
  }
  return { cell, rowSpan, colSpan, skipRender };
}

function prepRowLabels (labels, maxRows = Infinity) {
  // don't accept garbage input
  if (!labels || !Array.isArray(labels[0])) {
    return null;
  }
  // if labels are wider than they are tall, transpose them
  let arr = labels;
  if (labels.length < labels[0].length) {
    arr = transpose(labels);
  }
  if (isFinite(maxRows)) {
    arr = arr.slice(0, maxRows);
  }
  arr.sheetName = labels.sheetName;
  arr.workbookName = labels.workbookName;
  return arr;
}

function prepColLabels (labels, maxCols = Infinity) {
  // don't accept garbage input
  if (!labels || !Array.isArray(labels[0])) {
    return null;
  }
  let arr = labels;
  // if labels are taller than they are wide, transpose them
  if (arr.length > arr[0].length) {
    if (isFinite(maxCols)) {
      arr = arr.slice(0, maxCols);
    }
    arr = transpose(arr);
  }
  else if (isFinite(maxCols)) {
    arr = arr.map(row => row.slice(0, maxCols));
  }
  if (arr) {
    arr.sheetName = labels.sheetName;
    arr.workbookName = labels.workbookName;
  }
  return arr;
}

function prepInputs (seriesData, numColumns) {
  const allInputs = Array(numColumns).fill(null);
  seriesData.forEach(item => {
    const index = item.index - 1;
    if (index > -1 && index < numColumns) {
      allInputs[index] = item;
    }
  });
  return allInputs;
}

export default class GridTable extends React.Component {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
    isEditor: PropTypes.bool,
    expr: PropTypes.string,
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    emit: PropTypes.func,
    element: PropTypes.object,
    track: PropTypes.func,
  };

  static chartType = 'table';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (!props.isSelected && props.model && props.model.lastWrite === state.modelId) {
      return null;
    }
    const columnOptions = optTableInputs.read(props);
    const minColumns = Math.max(...columnOptions.map(d => d.index || 1));

    const rawTable = optIncludeEmpty.read(props)
      ? data.readFilled(props)
      : data.readCropped(props);

    // If we find that the table has been cropped and we have more column definitions
    // than we have columns, then we pad the right of the table with "blank cells"
    // to accomodate, but don't exceed the width of the original data option. This way
    // the table does not collapse when it is editable and nulls are written at the
    // bottom or right boundaries.
    const tableRight = rawTable.right || 0;
    if (rawTable[0].length < minColumns && tableRight > (rawTable.dataRight || 0)) {
      let fillWidth = tableRight - (rawTable.left || 0) + 1;
      if (minColumns < fillWidth) {
        fillWidth = minColumns;
      }
      for (const row of rawTable) {
        while (row.length < fillWidth) {
          row.push({ v: null });
        }
      }
    }

    const showHidden = showHiddenCells.read(props);
    let table = removeHidden(rawTable, props.model, showHidden, showHidden);
    if (table.length === 0 || table[0].length === 0) {
      table = [ [ { v: ERROR_CALC.detailed('All cells are hidden') } ] ];
    }
    const size = [ table.length, table[0].length ];
    // Read the table's row headers from the model.
    const labels = prepRowLabels(
      removeHidden(rowHeaders.readCropped(props), props.model, showHidden, showHidden),
      size[0],
    );
    // Read the table's column headers from the model.
    const legend = prepColLabels(
      removeHidden(columnHeaders.readCropped(props), props.model, showHidden, showHidden),
      size[1],
    );

    // Read the table's column/row headers header from the model.
    // the top-left-corner table cell
    const headerTitle = optHeaderTitle.readCell(props);

    // Read the table's column headers from the model.
    // bottom-left corner table cell
    const footerTitle = optFooterTitle.readCell(props);

    // Read the table footer from the model.
    const footer = prepColLabels(
      removeHidden(optFooter.readCropped(props), props.model, showHidden, showHidden),
      size[1],
    );
    const layout = tableLayout.read(props);
    // determine column widths
    const colWidths = [];
    let colWidthsDataStart = 0;
    const labelColCount = labels && labels[0] ? labels[0].length : 0;
    if (layout === 'sheet') {
      // find row header widths
      if (labelColCount) {
        const sheet = props.model.getWorkbook(labels.workbookName)?.getSheet(labels.sheetName);
        if (sheet) {
          labels[0].forEach(cell => {
            colWidths.push(getColumnWidth(sheet, a1ToRowColumn(cell.id)[1]));
          });
          colWidthsDataStart = colWidths.length;
        }
      }
      // find table cell widths
      const sheet = props.model.getWorkbook(rawTable.workbookName)?.getSheet(rawTable.sheetName);
      if (sheet) {
        const left = rawTable.left || 0;
        const right = Math.max(minColumns, rawTable.dataRight || size[1]);
        for (let i = left; i <= right; i++) {
          colWidths.push(getColumnWidth(sheet, i));
        }
      }
    }
    else if (layout === 'even') {
      colWidthsDataStart = labelColCount;
      const left = rawTable.left || 0;
      const right = Math.max(minColumns, rawTable.dataRight || size[1]);
      colWidths.length = 1 + colWidthsDataStart + right - left;
      colWidths.fill(1);
    }
    const colWidthTotal = colWidths.reduce((a, c) => a + c, 0);

    return {
      size,
      legend,
      footer,
      labels,
      headerTitle,
      footerTitle,
      data: table,
      dataPos: {
        writeable: !!(rawTable.workbookName && rawTable.sheetName),
        workbookName: rawTable.workbookName,
        sheetName: rawTable.sheetName,
        top: rawTable.top,
        left: rawTable.left,
      },
      series: prepInputs(columnOptions, size[1]),
      density: tableDensity.read(props),
      colWidths: colWidths.map(d => d / colWidthTotal),
      colWidthsDataStart: colWidthsDataStart,
      height: tableHeight.read(props),
      formatStr: format.read(props),
      zebra: striped.read(props),
      useCellStyles: useCellStyles.read(props),
      showHiddenCells: showHidden,
      modelId: props.model && props.model.lastWrite,
    };
  }

  constructor (props) {
    super(props);
    this.state = {
      sortBy: null,
      sortOrder: null,
      filter: '',
    };
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  componentDidMount () {
    this.updateHeadSize();
  }

  componentDidUpdate () {
    this.updateHeadSize();
  }

  onSort (e) {
    const col = +e.currentTarget.dataset.col;
    const { sortOrder, sortBy } = this.state;
    const isCurrentTarget = sortBy === col;
    if (isCurrentTarget && sortOrder === -1) {
      this.setState({
        sortBy: null,
        sortOrder: null,
      });
    }
    else {
      this.setState({
        sortBy: col,
        sortOrder: isCurrentTarget ? -1 : 1,
      });
    }
  }

  onFilter (e) {
    this.setState({ filter: e.currentTarget.value });
  }

  onPanelRezize = () => {
    this.updateHeadSize();
  };

  onInputWrite = (cellId, value) => {
    const dataPos = this.state.dataPos;
    if (cellId && dataPos.writeable) {
      const ref = Reference.from(cellId, {
        sheetName: dataPos.sheetName,
        workbookName: dataPos.workbookName,
      });
      const writeProps = { expr: '=' + ref, model: this.props.model };
      if (value === '') {
        value = null;
      }
      else if (typeof value === 'string' && isFinite(+value)) {
        value = +value;
      }
      targetCell.write(writeProps, value);
    }
  };

  updateHeadSize () {
    const theadSize = this.tableHead?.getBoundingClientRect().height ?? 0;
    const tfootSize = this.tableFoot?.getBoundingClientRect().height ?? 0;
    if (this.tableHead && (theadSize !== this.state.theadSize || tfootSize !== this.state.tfootSize)) {
      this.setState({ theadSize, tfootSize });
    }
  }

  renderLabelRow (rowIndex, rID, allowMerges = false) {
    const { labels, useCellStyles, colWidths } = this.state;
    const labelCols = labels ? labels[0].length : 0;
    if (!labelCols) {
      return null;
    }
    const labelRow = labels && labels[rowIndex];
    if (!labelRow) {
      return <th scope="row" colSpan={labelCols} />;
    }
    const height = this.state.size[0];
    const width = labelRow.length;
    const workbook = this.props.model.getWorkbook(labels.workbookName);

    return labelRow.map((cell, i) => {
      let td = { cell };
      if (allowMerges) {
        td = getTD(workbook, cell, i, rowIndex);
        if (td.skipRender) {
          return null;
        }
      }
      const thStyle = buildCellStyle(cell, {
        allowFonts: false,
        allowAlignment: useCellStyles,
      }, { textAlign: 'left' });
      if (colWidths.length) {
        thStyle.width = (colWidths[i] * 100) + '%';
      }
      return (
        <th
          style={thStyle}
          scope="row"
          colSpan={cellSpan(td.colSpan, width - i)}
          rowSpan={cellSpan(td.rowSpan, height - rowIndex)}
          key={rID + 'h' + i}
          >
          {printCell(td.cell, undefined, this.props.locale)}
        </th>
      );
    });
  }

  renderHeader (labels, sortBy, sortOrder, isSortable) {
    const { legend, useCellStyles, headerTitle } = this.state;
    const labelRows = legend ? legend.length : 0;
    const labelCols = labels ? labels[0].length : 0;

    if (labelRows <= 0) {
      return;
    }

    const width = this.state.size[1];
    const height = legend.length;
    const defaultStyles = { textAlign: 'center' };
    const workbook = this.props.model.getWorkbook(legend.workbookName);
    return (
      <thead className={csx(isSortable && styles.sortable)} ref={elm => (this.tableHead = elm)}>
        {legend.map((row, ri) => {
          let cornerCell = null;
          if (labelCols > 0) {
            const reachesBottom = ri >= labelRows - 1;
            cornerCell = (
              <th
                className={csx(styles.corner, reachesBottom && styles.bottomRow)}
                colSpan={labelCols}
                rowSpan={1}
                style={buildCellStyle(headerTitle, {
                  allowFonts: false,
                  allowAlignment: useCellStyles,
                }, { textAlign: 'left' })}
                >
                {reachesBottom ? printCell(headerTitle, undefined, this.props.locale) : null}
              </th>
            );
          }
          return (
            <tr key={'rh' + ri}>
              {cornerCell}
              {range(width).map(ci => {
                const td = getTD(workbook, row[ci], ci, ri);
                const { cell, skipRender, colSpan, rowSpan } = td;
                if (skipRender) {
                  return null;
                }
                // determine if this cell reaches the bottom of the theader
                const reachesBottom = (
                  (ri >= labelRows - 1) ||
                  (ri + rowSpan >= (labelRows))
                );
                const cellText = (
                  printCell(cell, undefined, this.props.locale)
                    .replace(/([/])/g, `$1${ZERO_SPACE}`)
                );
                /** @type {React.ReactNode} */
                let content = cellText;
                if (isSortable && ri === labelRows - 1) {
                  let split = 0;
                  const m = cellText.match(/(?:[\s;\xAD%?…]|,(?!\d))/);
                  if (m) {
                    split = m[0].length + (m.index || 0);
                  }
                  content = (
                    <>{cellText.slice(0, split)}
                      <span className={styles.wordJoin}>
                        {cellText.slice(split)}
                        {sortIcon(ci + 1, sortBy, sortOrder)}
                      </span>
                    </>
                  );
                }
                return (
                  <th
                    key={'h' + (ci + 1)}
                    data-col={ci + 1}
                    scope="col"
                    colSpan={cellSpan(colSpan, width - ci)}
                    rowSpan={cellSpan(rowSpan, height - ri)}
                    onClick={isSortable ? this.onSort : undefined}
                    className={reachesBottom ? styles.bottomRow : undefined}
                    style={buildCellStyle(cell, {
                      allowFonts: false,
                      allowAlignment: useCellStyles,
                    }, defaultStyles)}
                    >
                    <span className={styles.th}>
                      {content}
                    </span>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
    );
  }

  renderFooter (labels) {
    const { footer, useCellStyles, footerTitle } = this.state;
    const labelRows = footer ? footer.length : 0;
    const labelCols = labels ? labels[0].length : 0;

    if (labelRows <= 0) {
      return;
    }
    const width = this.state.size[1];
    const height = footer.length;
    const workbook = this.props.model.getWorkbook(footer.workbookName);
    return (
      <tfoot ref={elm => (this.tableFoot = elm)}>
        {footer.map((row, ri) => {
          let cornerCell = null;
          if (labelCols > 0) {
            const isTopRow = ri === 0;
            cornerCell = (
              <th
                className={csx(styles.corner, isTopRow && styles.topRow)}
                colSpan={labelCols}
                rowSpan={1}
                style={buildCellStyle(footerTitle, {
                  allowFonts: false,
                  allowAlignment: useCellStyles,
                })}
                >
                {isTopRow && printCell(footerTitle, undefined, this.props.locale)}
              </th>
            );
          }
          return (
            <tr key={'rf' + ri}>
              {cornerCell}
              {range(width).map(ci => {
                const td = getTD(workbook, row[ci], ci, ri);
                const { cell, skipRender, colSpan, rowSpan } = td;
                if (skipRender) {
                  return null;
                }
                const cellText = (
                  printCell(cell, undefined, this.props.locale)
                    .replace(/([/])/g, `$1${ZERO_SPACE}`)
                );
                /** @type {React.ReactNode} */
                const content = cellText;
                return (
                  <th
                    key={'h' + (ci + 1)}
                    data-col={ci + 1}
                    scope="col"
                    colSpan={cellSpan(colSpan, width - ci)}
                    rowSpan={cellSpan(rowSpan, height - ri)}
                    className={ri === 0 ? styles.topRow : undefined}
                    style={buildCellStyle(cell, {
                      allowFonts: false,
                      allowAlignment: useCellStyles,
                    })}
                    >
                    {content}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </tfoot>
    );
  }

  renderCellContent (cell, style, columnIndex, rowIndex) {
    const { series, dataPos } = this.state;
    const props = series && series[columnIndex];
    const renderType = props && props.type;
    const CellHandler = inputTypes[renderType]?.handler;
    if (CellHandler && dataPos.writeable) {
      // cell may be undefined here because apiary does not "fill" read ranges
      // but we *must* send a cell onwards because there needs to be a target
      // to write to...
      const _cell = cell || { v: null };
      if (!_cell.id) {
        _cell.id = (
          colFromOffs(dataPos.left + columnIndex) +
          (dataPos.top + rowIndex + 1)
        );
      }
      return (
        <CellHandler
          cacheKey={this.props.model.lastWrite}
          color={style.color}
          id={_cell.id}
          value={_cell.v}
          format={props.format || this.state.formatStr}
          locale={this.props.locale}
          cell={_cell}
          onInput={this.onInputWrite}
          track={this.props.track}
          {...props}
          />
      );
    }
    return (
      <TableValue
        key={this.props.model.lastWrite}
        cell={cell}
        color={style.color}
        format={this.state.formatStr}
        locale={this.props.locale}
        isAuthenticated={this.props.isAuthenticated}
        />
    );
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const state = this.state;
    const havedata = validExpr(props.expr) && state.data;

    if (!havedata) {
      return (
        <Wrapper className={styles.table} {...props}>
          {/* @ts-expect-error */}
          <DataError {...props} />
        </Wrapper>
      );
    }

    const data = state.data;
    const cellCount = (data.bottom - data.top) * (data.right - data.left);
    if (cellCount > MAX_TABLE_CELLS) {
      return (
        <Wrapper className={styles.table} {...props}>
          <DataError
            title="Too many cells"
            message={`The number of cells (${cellCount}) exceeds the maximum of ${MAX_TABLE_CELLS}`}
            {...props}
            />
        </Wrapper>
      );
    }

    const [ tableHeight, tableWidth ] = this.state.size;

    const labels = this.state.labels;

    const title = tableTitle.read(props);
    const subtitle = tableSubtitle.read(props);

    // need to perform ordering?
    const isSortable = havedata && sortable.read(props);
    const sortOrderUsed = sortOrder.isSet(props);
    const tableSortOrder = this.state.sortOrder || orderMap[lowerCase(sortOrder.read(props))];
    let tableSortBy = this.state.sortBy || sortBy.read(props);

    let rowOrder = range(tableHeight);
    // XXX: this is nearly identical to the function getOrderedRange in sorting.js and needs merge/refactor
    if (tableSortOrder) {
      if (!tableSortBy || !isFinite(tableSortBy) || tableSortBy < 0 || tableSortBy > tableWidth || tableSortBy % 1) {
        // ignore non integers that don't directly translate to a serie
        tableSortBy = 1;
      }
      else {
        const colIndex = Math.max(1, tableSortBy) - 1;
        // this builds a re-ordering index which is used to pull the rows from the table
        // the point of this is so we can use the same index to order row labels as well
        rowOrder = stableSort(rowOrder, tableSortOrder, d => {
          const cell = data[d][colIndex];
          return cell ? cell.v : null;
        });
      }
    }

    // filtering
    const isSearchable = havedata && searchable.read(props);
    const filter = (this.state.filter || '').trim().toLowerCase();
    const doFilter = {};
    if (isSearchable && filter) {
      data.forEach((row, i) => {
        // also filter by labels if we have them
        const all = labels ? [ ...(labels[i] || []), ...row ] : row;
        for (let ci = 0; ci < all.length; ci++) {
          const val = all[ci] ? String(all[ci].v).toLowerCase() : null;
          if (val && val.includes(filter)) {
            // should be included
            return;
          }
        }
        // hide this row
        doFilter[i] = true;
      });
    }

    let spacing = '';
    let rowPixelHeight = 32;
    if (state.density === 'compact') {
      spacing = styles.compact;
      rowPixelHeight = 24.7;
    }
    if (state.density === 'comfortable') {
      spacing = styles.comfortable;
      rowPixelHeight = 41.5;
    }

    const k = (props.parentKey || props.id) + ':';
    const workbook = this.props.model.getWorkbook(data.workbookName);
    const { useCellStyles, series } = this.state;
    const cellStyleOptions = {
      allowFonts: useCellStyles,
      allowAlignment: useCellStyles,
    };

    const { theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const backgroundColorizer = readTableCondFormatting(props, state.data, theme).scale;
    const fontSize = optButtonFontSize.isSet(props) ? optButtonFontSize.read(props) : null;

    const allowRowHeaderMerges = (
      !this.state.zebra && !isSortable && !isSearchable
    );
    const hasFooter = this.state.footer && this.state.footer.length;
    return (
      <Wrapper className={csx(styles.table, spacing)} {...props}>
        <div className={styles.head}>
          {isSearchable && (
            /*
              data-slate-editor attribute added because of a bug in slate
              https://github.com/ianstormtaylor/slate/issues/3426#issuecomment-573939245
            */
            <div className={styles.filter} data-slate-editor>
              <span className={styles.inputWrap}>
                <input
                  type="search"
                  value={this.state.filter}
                  onChange={this.onFilter}
                  placeholder="Search this table"
                  />
              </span>
            </div>
          )}
          {(title || subtitle) ? (
            <div className={styles.caption}>
              {title && (<div role="heading" aria-level={3} className={styles.title}>{title}</div>)}
              {subtitle && (<div role="heading" aria-level={4} className={styles.subtitle}>{subtitle}</div>)}
            </div>
          ) : null}
        </div>
        <ScrollPane
          className={styles.tableContainer}
          // if height is here, and nonzero we set the scroll height
          // as (height * row px height) + "a bit more"
          maxHeight={this.state.height ? this.state.height * rowPixelHeight + 15 : null}
          offsetTop={this.state.theadSize || 0}
          offsetBottom={this.state.tfootSize || 0}
          onResize={this.onPanelRezize}
          ref={elm => (this.scrollPane = elm)}
          >
          <table
            className={state.useCellStyles ? styles.cellStyles : undefined}
            style={{ fontSize: fontSize ?? undefined }}
            >
            {this.renderHeader(labels, tableSortBy, tableSortOrder, isSortable)}
            <tbody className={this.state.zebra ? styles.zebra : undefined}>
              {range(tableHeight).map(ri => {
                const oIndex = rowOrder[ri];
                if (doFilter[oIndex]) {
                  return null;
                }
                const rID = k + 'r' + (oIndex + 1);
                const row = data[oIndex];
                return (
                  <tr key={rID} className={hasFooter ? styles.withFooter : undefined}>
                    {this.renderLabelRow(oIndex, rID, allowRowHeaderMerges)}
                    {row.map((cell, ci) => {
                      let td = { cell };
                      if (!isSearchable && !isSortable && !sortOrderUsed) {
                        td = getTD(workbook, cell, ci, ri);
                        if (td.skipRender) {
                          return null;
                        }
                      }
                      const renderType = series?.[ci]?.type;
                      const renderer = renderType && inputTypes[renderType];
                      const style = renderer
                        ? buildCellStyle(td.cell, cellStyleOptions, renderer)
                        : buildCellStyle(td.cell, cellStyleOptions);
                      if (!ri && state.colWidths.length) {
                        style.width = (state.colWidths[ci + state.colWidthsDataStart] * 100) + '%';
                      }
                      if (backgroundColorizer && !renderer && cell) {
                        const c = backgroundColorizer(cell.v);
                        if (c) {
                          style.backgroundColor = c;
                          style.color = theme.textColorFor(c);
                          style.borderColor = d3_color(c).darker(0.25);
                        }
                      }
                      return (
                        <td
                          key={rID + 'c' + (ci + 1)}
                          colSpan={cellSpan(td.colSpan, tableWidth - ci)}
                          rowSpan={cellSpan(td.rowSpan, tableHeight - ri)}
                          style={style}
                          className={renderType ? styles.inputCell : undefined}
                          >
                          {this.renderCellContent(cell, style, ci, ri)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {this.renderFooter(labels)}
          </table>
        </ScrollPane>
      </Wrapper>
    );
  }
}
