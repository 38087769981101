import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import DatePickerControl from './DatePickerControl.jsx';
import { alignElement } from './utils.js';

import styles from './DatePicker.module.scss';

export function DatePicker ({
  value,
  visible,
  min,
  max,
  step,
  locale,
  connectedInput,
  onSelect,
}) {
  /**
   * @type {React.MutableRefObject<HTMLSpanElement | null>}
   */
  const ref = useRef(null);

  useEffect(() => {
    function supressClick (e) {
      if (ref.current?.contains(e.target)) {
        e.preventDefault();
        // stopPropagation is needed so picker works in edit mode
        e.stopPropagation();
      }
    }
    function reposition () {
      if (ref.current) {
        alignElement(ref.current, connectedInput);
      }
    }
    if (visible) {
      reposition();
      window.addEventListener('resize', reposition);
      document.addEventListener('scroll', reposition, { capture: true });
      document.addEventListener('mousedown', supressClick, { capture: true });
    }
    return () => {
      window.removeEventListener('resize', reposition);
      document.removeEventListener('scroll', reposition, { capture: true });
      document.removeEventListener('mousedown', supressClick, { capture: true });
    };
  }, [ visible, connectedInput ]);

  if (!visible || typeof document === 'undefined') {
    return null;
  }
  const container = document.getElementById('grid-doc-body');
  if (!container) {
    return null;
  }

  const picker = (
    <div data-testid="date-picker">
      <span
        className={styles.root}
        ref={ref}
        >
        <DatePickerControl
          min={min}
          max={max}
          step={step}
          value={value}
          onSelect={onSelect}
          locale={locale}
          />
      </span>
    </div>
  );

  return createPortal(picker, container);
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  visible: PropTypes.bool,
  locale: PropTypes.string,
  min: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  max: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  step: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  connectedInput: PropTypes.object, // HTMLElement
  onSelect: PropTypes.func,
};
