import React from 'react';

import HoverInfo from '@/grid/chart/utils/HoverInfo';

import DataError from '../DataError';
import Tooltip from '../Tooltip';
import Wrapper from '../Wrapper';

const WIDTH_UPDATE_DELAY = 80;

export default class BaseChart extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      hover: null,
      width: 754,
    };
    this._widthTime = 0;
    /** @type {NodeJS.Timeout} */
    this._widthTimer;
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();

    this.observer = new MutationObserver(this.updateWidth);
    this.observer.observe(document, {
      childList: true,
      attributes: true,
      subtree: true,
      attributeFilter: [ 'style', 'class' ],
    });
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWidth);
    this.observer && this.observer.disconnect();
  }

  getContainerWidth () {
    if (this.wrapper) {
      const w = Math.round(this.wrapper.offsetWidth);
      return Math.max(w, 100);
    }
    return null;
  }

  isWidthCurrent () {
    return (this.getContainerWidth() === this.state.width);
  }

  realUpdateWidth = () => {
    if (!this.isWidthCurrent() && this.wrapper) {
      this.setState({ width: this.getContainerWidth() });
    }
    this._widthTime = performance.now();
  };

  updateWidth = () => {
    // if there is no change to the width, we're done already!
    if (this.isWidthCurrent()) {
      return;
    }
    const deltaTime = performance.now() - this._widthTime;
    // has it been long since we've called update?
    if (!this._widthTime || deltaTime > WIDTH_UPDATE_DELAY) {
      clearTimeout(this._widthTimer);
      // @ts-expect-error
      this._widthTimer = (typeof queueMicrotask !== 'undefined')
        ? queueMicrotask(this.realUpdateWidth)
        : setTimeout(this.realUpdateWidth, 0);
    }
    // debounce
    else {
      clearTimeout(this._widthTimer);
      this._widthTimer = setTimeout(this.realUpdateWidth, WIDTH_UPDATE_DELAY - deltaTime);
    }
  };

  wrap (children) {
    return (
      <Wrapper
        subRef={elm => (this.wrapper = elm)}
        className="chart"
        {...this.props}
        >
        <Tooltip
          // @ts-expect-error
          cursorPosition={this.state.hover ? this.state.hover.cursorPosition : null}
          hoverNode={<HoverInfo {...(this.state.hover || {})} />}
          >
          {children}
        </Tooltip>
      </Wrapper>
    );
  }

  renderError (title, message) {
    return this.wrap(
      <DataError
        title={title}
        message={message}
        isChart
        {...this.props}
        />,
    );
  }

  /** @return {import('react').ReactNode} */
  render () {
    return null;
  }
}
