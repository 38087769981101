import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { DatePicker } from '@/grid/DatePicker';
import { isMobile } from '@/utils';

import styles from './InputHelper.module.scss';

const REPEAT_DELAY = 500;
const REPEAT_RATE = 50;

function renderSVGArrow (direction = 'up') {
  return (
    <svg version="1.1" width="10" height="10" viewBox="0 0 36 36">
      <g transform={direction === 'down' ? 'translate(18,18) rotate(180) translate(-18,-18)' : ''}>
        <path d="M29.52,22.52,18,10.6,6.48,22.52a1.7,1.7,0,0,0,2.45,2.36L18,15.49l9.08,9.39a1.7,1.7,0,0,0,2.45-2.36Z" />
      </g>
    </svg>
  );
}

function renderSVGCheckmark () {
  return (
    <svg version="1.1" width="16" height="16" viewBox="0 0 16 16" shapeRendering="geometricPrecision">
      <path d="M4.5,8.3 L7,10.8 L11.5,6.3" strokeWidth="1.5" strokeLinecap="round" stroke="black" fill="none" />
    </svg>
  );
}

const currentDay = new Date().getUTCDate();
function renderSVGDateIcon () {
  return (
    <svg width="16" height="16" viewBox="0 0 16 15" fill="currentColor">
      <path d="M14.3 2.6H12.8V3.5H14.2V13.3H1.7V3.5H3.1V2.6H1.6C1.6 2.7 1.5 2.7 1.4 2.7C1.3 2.8 1.2 2.8 1.1 2.9C1 2.9 0.9 3 0.9 3.2C0.9 3.3 0.9 3.4 0.8 3.5V13.4C0.9 13.5 0.9 13.6 0.9 13.7C0.9 13.8 1 13.9 1.1 13.9C1.2 14 1.27 14.1 1.4 14.1C1.4 14.1 1.5 14.2 1.6 14.2H14.3C14.4 14.2 14.5 14.1 14.6 14.1C14.7 14.1 14.8 14.0 14.8 13.9C14.9 13.9 15.0 13.8 15.0 13.7C15.0 13.6 15.1 13.5 15.1 13.4V3.4C15.1 3.3 15.0 3.2 15.0 3.1C15.0 3.0 14.9 2.9 14.8 2.9C14.8 2.8 14.7 2.7 14.6 2.7C14.5 2.6 14.4 2.6 14.3 2.6Z" />
      <text fontFamily="var(--controls-font)" fontWeight={500} fontSize={7} x={7.95} y={11} textAnchor="middle">{currentDay}</text>
      <path d="M4.4 4.4C4.5 4.4 4.6 4.3 4.7 4.3C4.8 4.2 4.8 4.1 4.8 4.0V1.3C4.8 1.2 4.8 1.1 4.7 1.0C4.6 0.9 4.5 0.8 4.4 0.8C4.3 0.8 4.2 0.9 4.1 1.0C4.0 1.1 4.0 1.2 4.0 1.3V4.0C4.0 4.1 4.0 4.2 4.1 4.3C4.2 4.3 4.3 4.4 4.4 4.4Z" />
      <path d="M11.5 4.4C11.6 4.4 11.7 4.3 11.8 4.3C11.9 4.2 12 4.1 12 4.0V1.3C12 1.2 11.9 1.1 11.8 1.0C11.7 0.9 11.6 0.8 11.5 0.8C11.4 0.8 11.3 0.9 11.2 1.0C11.1 1.1 11.1 1.2 11.1 1.3V4.0C11.1 4.1 11.1 4.2 11.2 4.3C11.3 4.3 11.4 4.4 11.5 4.4Z" />
      <path d="M10.2 2.6H5.7V3.5H10.2V2.6Z" />
    </svg>
  );
}

export default class InputHelper extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    targetElement: PropTypes.object, // XXX: instanceOf... what?
    value: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    focusInput: PropTypes.bool,
    interacting: PropTypes.bool,
    locale: PropTypes.string,
  };

  constructor (props) {
    super(props);
    this.state = {
      helperOpen: false,
    };
  }

  componentDidMount () {
    this.tagParent(true);
    document.addEventListener('contextmenu', this.onContextMenu);
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.interacting && this.props.interacting) {
      this.setState({ helperOpen: true });
    }
    if (prevProps.interacting && !this.props.interacting) {
      this.setState({ helperOpen: false });
    }
    this.tagParent(true);
  }

  componentWillUnmount () {
    this.tagParent(false);
    document.removeEventListener('contextmenu', this.onContextMenu);
  }

  getParentInput () {
    if (this.helper && this.helper.parentNode) {
      return this.helper.parentNode.querySelector('input');
    }
    return null;
  }

  onStepperClick = e => {
    if (isMobile()) {
      // on mobile, if the input does not have focus: we move the
      // focus into the input but do not cause a step
      const input = this.getParentInput();
      if (input && input !== document.activeElement) {
        input.focus();
        return;
      }
    }
    const { onClick } = this.props;
    const step = 1 * e.currentTarget.dataset.step;
    if (isFinite(step) && onClick) {
      onClick({ value: step });
    }
  };

  onMouseDown = e => {
    // helper should never steal focus
    e.preventDefault();
    // unless it is to move it into the target input
    const input = this.getParentInput();
    if (input && this.props.focusInput) {
      if (input && input.focus) {
        input.focus();
      }
    }
  };

  onMouseDownStepper = e => {
    e.preventDefault();
    if (e.button === 0) {
      this._repeatTarget = e.currentTarget;
      this._repeatTimer = setTimeout(this.onRepeat, REPEAT_DELAY);
      document.addEventListener('pointerup', this.onMouseUpStepper, { capture: true });
      document.addEventListener('touchend', this.onMouseUpStepper, { capture: true });
      document.addEventListener('touchcancel', this.onMouseUpStepper, { capture: true });
    }
  };

  onMouseUpStepper = () => {
    document.removeEventListener('pointerup', this.onMouseUpStepper, { capture: true });
    document.removeEventListener('touchend', this.onMouseUpStepper, { capture: true });
    document.removeEventListener('touchcancel', this.onMouseUpStepper, { capture: true });
    delete this._repeatTarget;
    clearTimeout(this._repeatTimer);
    delete this._repeatTimer;
  };

  onContextMenu = e => {
    if (isMobile()) {
      // kill "long press" on mobile
      e.preventDefault();
    }
  };

  onRepeat = () => {
    if (this._repeatTarget) {
      const fakeEvent = { currentTarget: this._repeatTarget };
      this.onStepperClick(fakeEvent);
      this._repeatTimer = setTimeout(this.onRepeat, REPEAT_RATE);
    }
  };

  tagParent (on = false) {
    if (this.helper && this.helper.parentElement) {
      this.helper.parentElement.classList.toggle(styles.hasHelper, on);
    }
  }

  render () {
    const { onClick, type, disabled, value } = this.props;
    let inner = null;
    let outerClass = null;

    if (type === 'select') {
      outerClass = styles.selectHelper;
      inner = (
        <span className={csx(styles.arrow, disabled && styles.disabled)}>
          {renderSVGArrow('down')}
        </span>
      );
    }
    else if (type === 'date' || type === 'datetime') {
      outerClass = styles.dateHelper;
      inner = (
        <>
          <button
            type="button"
            tabIndex={-1}
            className={styles.calendar}
            onMouseDown={this.onMouseDown}
            disabled={disabled}
            onClick={() => {
              this.setState(() => ({ helperOpen: !this.state.helperOpen }));
              this.props.targetElement?.focus();
            }}
            >
            {renderSVGDateIcon()}
          </button>
          <DatePicker
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            value={value}
            locale={this.props.locale}
            connectedInput={this.props.targetElement}
            visible={this.state.helperOpen}
            onSelect={value => {
              this.setState({ helperOpen: false });
              onClick && onClick({ value: value });
            }}
            />
        </>
      );
    }
    else if (type === 'number') {
      outerClass = styles.numberHelper;
      inner = (
        <>
          <button
            type="button"
            tabIndex={-1}
            data-step={1}
            className={styles.up}
            onMouseDown={this.onMouseDown}
            onPointerDown={this.onMouseDownStepper}
            onClick={this.onStepperClick}
            disabled={disabled}
            aria-label="Increase value"
            >
            {renderSVGArrow('up')}<span />
          </button>
          <button
            type="button"
            tabIndex={-1}
            data-step={-1}
            className={styles.down}
            onMouseDown={this.onMouseDown}
            onPointerDown={this.onMouseDownStepper}
            onClick={this.onStepperClick}
            disabled={disabled}
            aria-label="Decrease value"
            >
            {renderSVGArrow('down')}<span />
          </button>
        </>
      );
    }
    else if (type === 'boolean') {
      outerClass = styles.booleanHelper;
      inner = (
        <button
          type="button"
          className={value ? styles.checked : undefined}
          onMouseDown={this.onMouseDown}
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            onClick && onClick({ value: !value });
          }}
          >
          {renderSVGCheckmark()}
        </button>
      );
    }

    return inner && (
      <span
        className={csx(
          styles.helper,
          isMobile() && styles.mobileHelper,
          outerClass,
        )}
        ref={elm => (this.helper = elm)}
        >
        {inner}
      </span>
    );
  }
}
