import React from 'react';
import csx from 'classnames';
import { range } from 'd3-array';
import PropTypes from 'prop-types';

import { prepLabels } from '../chart/utils';
import Checkmark from '../Checkmark';
import Label from '../Label';
import modelProp from '../modelProp';
import { disabled, elementVisibility, format, optInlineType, optionLabels, options, optWidthAuto, targetCell, titleLabel } from '../propsData';
import { compareValues, printCell, uid, validExpr } from '../utils';
import Wrapper from '../Wrapper';

import styles from './radio.module.scss';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  options: options,
  labels: optionLabels,
  format: format,
  inline: optInlineType,
  visible: elementVisibility,
  disabled: disabled,
  width: optWidthAuto,
};

export default function GridRadio (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  const formatStr = format.read(props);

  const haveOptions = options.isSet(props);
  const table = options.read(props) || [ [] ];
  const w = table[0].length;
  const h = table.length;
  const dir = w < h ? 'col' : 'row';
  let optionList = range(dir === 'row' ? w : h).map(i => {
    const cell = (dir === 'row') ? table[0][i] : table[i][0];
    return (cell == null || cell instanceof Error) ? null : cell;
  });
  if (!validExpr(props.expr)) {
    optionList = [ { v: 'Radio button' } ];
  }
  else if (!haveOptions) {
    optionList = [ { v: 'Options not selected' } ];
  }

  let labels = null;
  if (optionLabels.isSet(props)) {
    const labelCells = optionLabels.read(props);
    labels = prepLabels(labelCells, dir, formatStr, props.locale);
  }

  // try to find the current cell value among the options
  const valueCell = targetCell.read(props);
  const value = optionList.findIndex(d => {
    return compareValues(valueCell.v, d && d.v);
  });

  const isDisabled = !validExpr(props.expr) || !haveOptions || !optionList.length || disabled.read(props);

  return (
    <Wrapper
      {...props}
      className={csx(styles.radio)}
      inlineMode={optInlineType.read(props)}
      label={titleLabel.read(props)}
      isDisabled={isDisabled}
      inlineWidth={optWidthAuto.read(props)}
      >
      {optionList.map((option, i) => {
        const label = labels ? labels[i] || '' : printCell(option, formatStr, props.locale);
        const id = uid();
        const writeBack = isDisabled ? undefined : () => {
          try {
            document.getElementById(id)?.focus();
          }
          catch (err) {
            // ignore focus failures
          }
          props.track('interact', { elementType: 'radio' });
          targetCell.write(props, option ? option.v : null);
        };
        return (
          <span key={i} className={styles.item}>
            <label onClick={writeBack}>
              <span className={styles.noBreak}>
                <Checkmark
                  id={id}
                  disabled={isDisabled}
                  type="radio"
                  checked={value === i}
                  />
              </span>
              <Label
                text={label}
                disabled={isDisabled}
                className={styles.baseFont}
                inline
                />
            </label>
          </span>
        );
      })}
    </Wrapper>
  );
}

GridRadio.options = elementOptions;
GridRadio.requiredOption = 'expr';
GridRadio.isInput = true;
GridRadio.propTypes = {
  parentKey: PropTypes.string,
  error: PropTypes.string,
  model: modelProp.isRequired,
  isEditor: PropTypes.bool,
  expr: PropTypes.string,
  locale: PropTypes.string,
  track: PropTypes.func,
};
