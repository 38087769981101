import { range } from 'd3-array';

import { sortBy, sortOrder } from '@/grid/propsData';
import { lowerCase, stableSort } from '@/grid/utils';

const orderMap = {
  ascending: 1,
  asc: 1,
  descending: -1,
  desc: -1,
};

export function getOrderedRange (size, data, props, reverse = false) {
  let r = null;
  // need to perform ordering?
  const order = lowerCase(sortOrder.read(props));
  const serCount = size[1];
  // need to perform ordering?
  if (order in orderMap) {
    let serNum = sortBy.read(props);
    if (!serNum || !isFinite(serNum) || serNum < 0 || serNum > serCount || serNum % 1) {
      // ignore non integers that don't directly translate to a serie
      serNum = 1;
    }
    const direction = orderMap[order] || orderMap.desc;
    r = stableSort(range(size[0]), direction, d => {
      const val = data[Math.floor((d * serCount) + Math.max(1, serNum || 0) - 1)];
      return val ? val.v : null;
    });
  }

  if (r && reverse) {
    r = r.reverse();
  }

  return r;
}
