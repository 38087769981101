import React from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { commonButtonUI, disabled, elementVisibility, titleLabel } from '../propsData';
import BaseButton from './common/BaseButton';

const elementOptions = {
  title: titleLabel,
  visible: elementVisibility,
  disabled: disabled,
  ...commonButtonUI,
};

export default function GridRecalcButton (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  const isDisabled = disabled.read(props);
  return (
    <BaseButton
      {...props}
      label={titleLabel.read(props) || 'Recalculate'}
      disabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          props.track('interact', { elementType: 'recalcbutton' });
          props.model.recalculate();
        }
      }}
      />
  );
}

GridRecalcButton.options = elementOptions;
GridRecalcButton.requiredOption = null;
GridRecalcButton.propTypes = {
  isEditor: PropTypes.bool,
  track: PropTypes.func,
  model: modelProp.isRequired,
};
