/**
 * @param {import('../types').Cellish | undefined | null} cell
 * @return {'left' | 'right' | 'center'}
 */
export function alignmentFromCell (cell) {
  /** @type {'left' | 'right' | 'center'} */
  let alignment = 'left';
  if (cell) {
    if (typeof cell.v === 'number') {
      alignment = 'right';
    }
    if (cell.v instanceof Error || cell.v === true || cell.v === false) {
      alignment = 'center';
    }
    if (cell.s) {
      /** @type {unknown} */
      const hAlign = cell.s['horizontal-alignment'];
      if (hAlign === 'left' || hAlign === 'center' || hAlign === 'right') {
        alignment = hAlign;
      }
    }
  }
  return alignment;
}
