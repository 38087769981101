export const TEXT_ALIGN = 0b1;
export const TEXT_SIZE = 0b10;
export const TEXT_COLOR = 0b100;
export const TEXT_INDENT = 0b1000;
export const TEXT_COLLAPSE = 0b10000;

/**
 * Text blocks are all handled by central components (TextBlock, RichTextMenu)
 * This table dictates which "rich text" abilities each type of text block has.
 */
const textBlockAbilities = {
  'h1': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'h2': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'h3': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'h4': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'h5': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'h6': TEXT_ALIGN | TEXT_COLOR | TEXT_COLLAPSE,
  'ol': TEXT_COLOR | TEXT_INDENT | TEXT_COLLAPSE,
  'ul': TEXT_COLOR | TEXT_INDENT | TEXT_COLLAPSE,
  'quote': TEXT_ALIGN | TEXT_SIZE | TEXT_COLOR | TEXT_COLLAPSE,
  'code': 0,
  'p': TEXT_ALIGN | TEXT_SIZE | TEXT_COLOR | TEXT_COLLAPSE,
  'p:hidden': TEXT_ALIGN | TEXT_SIZE | TEXT_COLOR | TEXT_COLLAPSE,
};

const abilities = {
  align: TEXT_ALIGN,
  alignment: TEXT_ALIGN,
  size: TEXT_SIZE,
  fontsize: TEXT_SIZE,
  color: TEXT_COLOR,
  indent: TEXT_INDENT,
  collapse: TEXT_COLLAPSE,
};

/**
 * Determine if a block as a rich text ability (takes alignment or color).
 *
 * @param {keyof typeof textBlockAbilities} type The type of element ('quote', 'h3')
 * @param {number | string} ability The ability to test for ('align', 'color')
 * @return {boolean} True if able to block, False otherwise.
 */
export function canBlock (type, ability) {
  const wanted = typeof ability === 'string' ? abilities[ability.toLowerCase()] : ability;
  const supported = textBlockAbilities[type] || 0;
  return !!(supported & wanted);
}
