import React from 'react';
import PropTypes from 'prop-types';

import Checkmark from '../Checkmark';
import Label from '../Label';
import modelProp from '../modelProp';
import { disabled, elementVisibility, targetCell, titleLabel, value, valueOff } from '../propsData';
import { compareValues, uid, validExpr } from '../utils';
import Wrapper from '../Wrapper';

import styles from './checkbox.module.scss';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  value: value,
  valueOff: valueOff,
  visibility: elementVisibility,
  disabled: disabled,
};

export default function GridCheckbox (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  const valueCell = targetCell.read(props);
  const currentValue = valueCell && valueCell.v;
  const valueTrue = value.read(props);

  let checkstate = currentValue === true;
  if (value.isSet(props)) { // have a value property?
    checkstate = compareValues(currentValue, valueTrue);
  }

  const targetMissing = !validExpr(props.expr);

  const id = uid();
  const isDisabled = targetMissing || disabled.read(props);
  // eslint-disable-next-line no-nested-ternary
  let labelText = targetMissing ? 'Checkbox' : '';
  if (titleLabel.isSet(props)) {
    labelText = titleLabel.read(props);
  }

  const toggleFn = isDisabled ? undefined : () => {
    try {
      document.getElementById(id)?.focus();
    }
    catch (err) {
      // ignore focus failures
    }
    props.track('interact', { elementType: 'checkbox' });
    // we are switching FROM checked
    if (checkstate) {
      let writeValue;
      if (valueOff.isSet(props)) {
        // whatever this resolves to (defaulting to false in the handler)
        writeValue = valueOff.readCells(props);
      }
      else {
        // if options has not been set, switch TRUE→FALSE, [else]→""
        writeValue = valueTrue === true ? false : '';
      }
      targetCell.write(props, writeValue);
    }
    // we are switching TO checked
    else {
      const writeValue = value.readCells(props);
      targetCell.write(props, writeValue);
    }
  };

  return (
    <Wrapper className={styles.checkbox} {...props}>
      <Checkmark
        id={id}
        checked={checkstate}
        disabled={isDisabled}
        onClick={toggleFn}
        />
      <label htmlFor={id} onClick={toggleFn}>
        <Label text={labelText} disabled={isDisabled} inline />
      </label>
    </Wrapper>
  );
}

GridCheckbox.options = elementOptions;
GridCheckbox.requiredOption = 'expr';
GridCheckbox.isInput = true;
GridCheckbox.propTypes = {
  isEditor: PropTypes.bool,
  expr: PropTypes.string,
  parentKey: PropTypes.string,
  error: PropTypes.string,
  track: PropTypes.func,
  model: modelProp.isRequired,
};
