import React from 'react';
import PropTypes from 'prop-types';

import { Axis } from './prepAxis';

const drawDirections = {
  both: { x: true, y: true },
  none: { x: false, y: false },
  x: { x: true, y: false },
  y: { x: false, y: true },
};

export default function Grid (props) {
  const { x, y } = props;
  const xRange = x.scale.range();
  const yRange = y.scale.range();
  const draw = drawDirections[props.style] || { x: !!x.preferGrid, y: !!y.preferGrid };
  const k = 'grid:';
  const yTicks = draw.y ? y.getTicks() : [];
  const xTicks = draw.x ? x.getTicks() : [];
  const styles = props.styles?.grid ?? {};

  return (
    <g className="grid" fill="none" stroke={props.color}>
      {yTicks.map(tick => {
        if (tick.grid === false) {
          return null;
        }
        const zero = !(!y.scale.ticks || tick.value);
        return (
          <line
            key={k + 'y:' + tick.index}
            className="line y"
            stroke={zero ? styles.zeroColor : styles.color}
            strokeLinecap="round"
            strokeDasharray={zero ? styles.zeroDashArray : styles.dashArray}
            y1={tick.pos}
            y2={tick.pos}
            x1={xRange[0]}
            x2={xRange[1]}
            />
        );
      })}
      {xTicks.map(tick => {
        if (tick.grid === false) {
          return null;
        }
        const zero = !(!x.scale.ticks || tick.value);
        return (
          <line
            key={k + 'x:' + tick.index}
            className="line x"
            stroke={zero ? styles.zeroColor : styles.color}
            strokeLinecap="round"
            strokeDasharray={zero ? styles.zeroDashArray : styles.dashArray}
            y1={yRange[0]}
            y2={yRange[1]}
            x1={tick.pos}
            x2={tick.pos}
            />
        );
      })}
    </g>
  );
}

Grid.propTypes = {
  x: PropTypes.instanceOf(Axis).isRequired,
  y: PropTypes.instanceOf(Axis).isRequired,
  style: PropTypes.oneOf([ 'both', 'none', 'x', 'y' ]),
  color: PropTypes.string,
  styles: PropTypes.object,
};
