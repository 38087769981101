import { printCell } from '@/grid/utils';

import ValueLogAxis from './axes/ValueLogAxis';
export { default as getColorScale } from './getColorScale';
export { readSingleSeries } from './readSingleSeries';

export const hasVal = cell => {
  const t = cell && typeof cell.v;
  if (t === 'string' || t === 'number' || t === 'boolean') {
    if (cell.v !== '') {
      return true;
    }
  }
  return null;
};

// bools are treated as numbers
export const hasNumVal = cell => {
  const t = cell && typeof cell.v;
  if (t === 'number' || t === 'boolean') {
    return true;
  }
  return null;
};

export const isEmptyRange = data => {
  return !(data && data.length && data[0].length);
};

export function getLabelArray (labels, dir = 'row') {
  // don't accept garbage input
  if (!labels || !Array.isArray(labels[0])) {
    return null;
  }
  if (labels.length === 1 && labels[0].length === 0) {
    return [];
  }
  else if (labels.length > 1 && labels[0].length === 1) {
    // single row or col get automatically transposed
    return labels.map(r => r[0]);
  }
  else if (labels.length === 1 && labels[0].length > 1) {
    return labels[0].map(d => d);
  }
  // read the labels from the area as directed by dir
  if (dir === 'row') {
    return labels.map(r => r[r.length - 1]);
  }
  else if (dir === 'col') {
    return labels[labels.length - 1].concat([]);
  }
  return [];
}

/**
 * Prepare a list of labels for use by chart drivers.
 *
 * @param {object[][]} labelData The label data
 * @param {string} [dir='row'] The direction to read data series ('row' or 'col')
 * @param {string} [fmtStr] The format string
 * @param {string} [locale=null] The locale
 * @return {string[] | null}
 */
export function prepLabels (labelData, dir = 'row', fmtStr = undefined, locale = undefined) {
  const labels = getLabelArray(labelData, dir);
  return labels ? labels.map(d => printCell(d, fmtStr, locale)) : labels;
}

/**
 * Does as `prepLabels` does, but converts every blank label into "Category X", where X matches the
 * position of the first blank label in the unique array of labels (but 1-indexed rather than
 * 0-indexed). All blank labels use the same value of X, so there can only ever be one empty
 * category. If there aren't enough categories, blank "Category X" labels are added to match the
 * minimum length required.
 *
 * @param {object[][]} labels The labels
 * @param {'row'|'col'} [dir='row'] The dir
 * @param {string} [fmtStr] The format string
 * @param {string} [locale] The locale
 * @param {number} [minLength] The minimum length
 * @return {string[] | null}
 */
export function prepCategories (labels, dir = 'row', fmtStr = undefined, locale = undefined, minLength = undefined) {
  const categories = prepLabels(labels, dir, fmtStr, locale);
  if (categories) {
    // `Array.from(new Set())` is used below because we need the position of the blank category within
    // the unique list of categories. In ["A", "A", "A", "B", "B", "C"], the value for "C" should be
    // 2, not 5.
    const uniqueCategories = new Set(categories);
    let blankCategory = Array.from(uniqueCategories).indexOf('');

    let catLength = categories.length;
    if (minLength && categories.length > 0 && minLength > categories.length) {
      // We have fewer categories than data values, so we'll need to fill the list of categories out.
      if (blankCategory === -1) {
        blankCategory = uniqueCategories.size;
      }
      catLength = minLength;
    }
    if (blankCategory !== -1) {
      for (let i = 0; i <= catLength; i++) {
        if (!categories[i] || categories[i] === '') {
          categories[i] = `Category ${blankCategory + 1}`;
        }
      }
    }
  }
  return categories;
}

export function stackSeries (series, size) {
  const [ mX, mY ] = size;
  const valueaxisDim = [];
  let minV = Infinity;
  let maxV = -Infinity;
  for (let x = 0; x < mX; x++) {
    let sumPos = 0;
    let sumNeg = 0;
    for (let y = 0; y < mY; y++) {
      const fact = series[y][x];
      const v = fact.v;
      if (v < 0) {
        fact.base = sumNeg;
        sumNeg += v;
      }
      else {
        fact.base = sumPos;
        sumPos += v;
      }
      if (minV > v) {
        minV = v;
      }
      if (maxV < v) {
        maxV = v;
      }
    }
    sumPos && valueaxisDim.push({ v: sumPos });
    sumNeg && valueaxisDim.push({ v: sumNeg });
  }
  // we need to include the min and max values found or else
  // we cannot determine the logarithmic "bottom" of the scale
  if (isFinite(minV)) {
    valueaxisDim.push({ v: minV });
  }
  if (isFinite(maxV)) {
    valueaxisDim.push({ v: maxV });
  }
  // add a "zero"
  const zero = valueaxisDim.reduce((a, c) => {
    const v = ValueLogAxis.logZero(c.v);
    return (Math.abs(v) < Math.abs(a.v)) ? { v: v } : a;
  }, { v: Infinity });
  if (isFinite(zero.v)) {
    valueaxisDim.push(zero);
  }

  return valueaxisDim;
}

export function interpolateGaps (series) {
  series.forEach(ser => {
    for (let startIndex = 0; startIndex < ser.length; startIndex++) {
      if (ser[startIndex].v == null) {
        let stopIndex = startIndex;
        // found a gap in the series, seek the next non-gap
        for (; stopIndex < ser.length; stopIndex++) {
          const dest = ser[stopIndex];
          if (!dest.gap) {
            break;
          }
        }
        // only interpolate if this is not start or end of the series
        // e.g.: [null null, 10, 5] or [5, 10, null, null]
        if (startIndex > 0 && stopIndex < ser.length) {
          // interpolate values into the gap
          const a = ser[startIndex - 1].v;
          const b = ser[stopIndex].v;
          const len = stopIndex - startIndex;
          for (let i = 0; i < len; i++) {
            const t = (i + 1) / (len + 1);
            ser[startIndex + i].v = (a * (1 - t) + b * t);
          }
        }
        // skip over gaps
        startIndex = stopIndex;
      }
    }
  });
}
