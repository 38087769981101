import React from 'react';
import PropTypes from 'prop-types';

import { MODE_CANVAS } from '@grid-is/apiary';

import DataError from '../DataError';
import modelProp from '../modelProp';
import { elementVisibility, layoutWidth, sourceUrl } from '../propsData';
import Wrapper from '../Wrapper';

const elementOptions = {
  url: sourceUrl,
  visible: elementVisibility,
  size: layoutWidth,
};

const isURL = /^(?:https?:)?\/\//i;

function parseDrawing (raw) {
  return (raw || '')
    .split(/\n(?! )/)
    .map(d => {
      // remove leading whitespace, trailing whitespace and ;
      d = d.replace(/\n +/g, '').trimStart()
        .replace(/[\s;]+$/, '');
      if (!d || /^--/.test(d)) {
        return null;
      } // allow comments (for now)
      return d;
    })
    .filter(Boolean);
}

export default class GridCanvas extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    model: modelProp.isRequired,
    url: PropTypes.string,
    isEditor: PropTypes.bool,
    emit: PropTypes.func,
    element: PropTypes.object,
  };

  static options = elementOptions;
  static requiredOption = 'url';

  constructor (props) {
    super(props);
    this.state = {
      src: sourceUrl.read(props),
      loading: true,
      height: 1,
      width: 1,
      error: null,
    };
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
    this.loadSource();

    this.observer = new MutationObserver(this.updateWidth);
    this.observer.observe(
      document, { attributes: true, subtree: true, attributeFilter: [ 'style', 'class' ] },
    );
  }

  // didupdate should reload source if it has changed
  componentDidUpdate (prevProps) {
    if (this.props.url !== prevProps.url && this.props.model) {
      const src = sourceUrl.read(this.props);
      if (src !== this.state.src) {
        /* eslint-disable react/no-direct-mutation-state */
        this.state.src = src;
        this.state.loading = true;
        /* eslint-enable react/no-direct-mutation-state */
        this.loadSource();
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWidth);
  }

  loadSource = () => {
    if (this.state.src && isURL.test(this.state.src)) {
      fetch(this.state.src)
        .then(d => {
          if (!d.ok) {
            throw d;
          }
          return d.text();
        })
        .then(d => {
          // process and set to state
          this.setState({
            drawing: parseDrawing(d),
            loading: false,
            error: null,
          });
        })
        .catch(d => this.setState({ loading: false, error: d }));
    }
  };

  updateWidth = () => {
    if (this.wrapper) {
      const w = this.wrapper.offsetWidth;
      if (w !== this.state.width) {
        this.setState({
          width: w,
          height: Math.floor(w * 0.62),
        });
      }
    }
  };

  renderLoading () {
    return (
      <text
        x={(this.state.width / 2)}
        y={(this.state.height / 2)}
        textAnchor="middle"
        fill="currentColor"
        fillOpacity={0.5}
        >
        Loading...
      </text>
    );
  }

  renderDrawing () {
    const model = this.props.model;
    if (!model || this.state.error || !this.state.drawing) {
      return null;
    }
    const lines = this.state.drawing;

    const renderContext = {
      translate: [ 0, 0 ],
      scale: [ 0, 0 ],
      rotation: 0, // rotation angle
      fontSize: 12,
      font: 'sans-serif',
      height: this.state.height,
      width: this.state.width,
    };

    const previousMode = model.mode;
    try {
      model.mode = MODE_CANVAS;
      return runCanvasScript(lines, model, renderContext);
    }
    finally {
      model.mode = previousMode;
    }
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    if (!props.url) {
      return (
        <Wrapper {...props}>
          <DataError
            title="No source found"
            message="Set a source URL for the canvas drawing"
            {...props}
            />
        </Wrapper>
      );
    }
    if (this.state.error) {
      return (
        <Wrapper {...props}>
          <DataError
            title="Failed to fetch"
            message="This canvas element was unable to fetch its source"
            {...props}
            />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        subRef={elm => (this.wrapper = elm)}
        {...props}
        >
        <svg
          data-test
          role={this.state.loading ? 'status' : 'img'}
          ref={elm => (this.svg = elm)}
          viewBox={`0 0 ${this.state.width} ${this.state.height}`}
          >
          {this.state.loading ? this.renderLoading() : this.renderDrawing()}
        </svg>
      </Wrapper>
    );
  }
}

function runCanvasScript (lines, model, renderContext) {
  const newElms = [];
  lines.forEach((line, lineIndex) => {
    let subIndex = 0;
    try {
      model.runFormula(line, {
        canvasOption: (key, value) => {
          if (value !== undefined) {
            renderContext[key] = value;
          }
          return renderContext[key];
        },
        canvasElement: (name, props, innerText) => {
          // apply context transformations
          let t = props.transform || '';
          const [ tx, ty ] = renderContext.translate;
          if (tx || ty) {
            t += `translate(${tx},${ty})`;
          }
          const [ sx, sy ] = renderContext.scale;
          if (sx || sy) {
            t += `scale(${sx},${sy})`;
          }
          if (renderContext.rotation) {
            t += `rotate(${renderContext.rotation})`;
          }
          if (t) {
            props.transform = t;
          }
          props.key = 'cv:' + lineIndex + ':' + (subIndex++);
          // create the element
          newElms.push(
            React.createElement(name, props, innerText),
          );
        },
      });
    }
    catch (err) {
      // if a line causes engine to throw, we just continue...
    }
  });
  return newElms;
}
