import React from 'react';
import Textbox from '@borgar/textbox';
import PropTypes from 'prop-types';

import { contrast } from '@grid-is/color-utils';

import { valueLabels } from '@/grid/propsData';
import { deepCompare, functor } from '@/grid/utils';

import ChartLabel from '../utils/ChartLabel';

const INNER_PADDING = 0.016;

export default class BarLayer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    series: PropTypes.array,
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
    overlap: PropTypes.number,
    zero: PropTypes.number,
    fill: PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]).isRequired,
    horizontal: PropTypes.bool,
    onPoint: PropTypes.func,
    onUnPoint: PropTypes.func,
    // this is not named well: this is really the width in which "a category of bars" need to fit
    bandWidth: PropTypes.number,
    // needed only for showing labels:
    showValues: PropTypes.oneOf([ valueLabels.NONE, valueLabels.SOME, valueLabels.ALL ]),
    centerLabels: PropTypes.bool,
    styles: PropTypes.object,
    label: PropTypes.func,
    height: PropTypes.number,
    width: PropTypes.number,
  };

  static defaultProps = {
    visible: true,
    horizontal: true,
    overlap: 0,
    width: 754,
    zero: 0,
  };

  static getDerivedStateFromProps (props) {
    return {
      fill: functor(props.fill, 'currentColor'),
      showValues: props.showValues !== valueLabels.NONE,
      selectiveValues: props.showValues === valueLabels.SOME,
    };
  }

  constructor (props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate (nextProps, nextState) {
    // if this was invisible and will be, don't bother
    if (!nextProps.visible && !this.props.visible) {
      return false;
    }
    return deepCompare(this, nextProps, nextState);
  }

  contrast (color) {
    if (color.toLowerCase() === 'currentcolor') {
      return this.props.styles?.background;
    }
    return contrast(color);
  }

  calcVerticalBar (d, barWidth) {
    const { x, y, zero } = this.props;
    const { category, v } = d;
    const base = d.base || 0;
    let barPos, barSize;
    if (v < 0) {
      barPos = y(base || zero);
      barSize = y(base + v) - barPos;
    }
    else {
      barPos = y(base + v);
      barSize = y(base || zero) - barPos;
    }
    return {
      // We need to know if rect can be drawn because we can still have NaN bars with labels
      valid: isFinite(barSize) && isFinite(barPos),
      x: x(category),
      y: barSize < 0 ? barPos + barSize : barPos,
      height: Math.abs(barSize),
      width: barWidth,
      pos: barPos,
      size: barSize,
      thickness: barWidth,
      color: this.state.fill(d),
    };
  }

  calcHorizontalBar (d, barWidth) {
    const { x, y, zero } = this.props;
    const { category, v } = d;
    const base = d.base || 0;
    let barPos, barSize;
    if (v < 0) {
      barPos = x(base || zero);
      barSize = x(base + v) - barPos;
    }
    else {
      barPos = x(base + v);
      barSize = x(base || zero) - barPos;
    }
    return {
      // We need to know if rect can be drawn because we can still have NaN bars with labels
      valid: isFinite(barSize) && isFinite(barPos),
      x: barSize < 0 ? barPos + barSize : barPos,
      y: y(category),
      width: Math.abs(barSize),
      height: barWidth,
      pos: barPos,
      size: barSize,
      thickness: barWidth,
      color: this.state.fill(d),
    };
  }

  renderLabel (d, bar, centered) {
    const { showValues, selectiveValues } = this.state;
    const { x, y, label, horizontal, zero, width, height, styles } = this.props;
    const fonts = styles?.fontStack;
    const fontSize = 14;
    const padding = 5;
    const text = label && this.props.label(d);
    const fontDecl = fontSize + 'px ' + fonts;
    if (!showValues || !label || !text) {
      return null;
    }
    let textX, textY, align, vAlign, color;
    if (centered) {
      textX = bar.x + bar.width / 2;
      textY = bar.y + bar.height / 2;
      color = this.contrast(bar.color);
      align = 'center';
      vAlign = 'middle';
      if (selectiveValues) {
        if (bar.height <= 16) {
          return null;
        }
        const textWidth = Textbox.measureText(text, fontDecl);
        if (bar.width <= (textWidth + 4)) {
          return null;
        }
      }
    }
    else if (horizontal) {
      const vZero = x(zero);
      const stretch = isFinite(d.v) ? x(d.v) - vZero : 0;
      textX = (d.v < 0) ? bar.pos + bar.size : bar.pos;
      textY = y(d.category) + bar.thickness / 2;
      color = styles?.color;
      let textLeftAlign = false;
      const textWidth = Textbox.measureText(text, fontDecl);
      if ((!isFinite(d.v) || !d.v)) {
        // place the label on the right if there is space
        textLeftAlign = vZero < (width - (textWidth + padding));
      }
      else {
        textLeftAlign = (stretch >= 0)
          ? (width - textX) > (textX - vZero)
          : textX < (vZero - textX);
        // label is inside the bar?
        if (textLeftAlign !== (stretch >= 0)) {
          // if there isn't enough space inside the bar, we move to beyond 0-line
          if (Math.abs(stretch) < (textWidth + padding * 2)) {
            textX = vZero;
          }
          else {
            color = this.contrast(bar.color);
          }
        }
      }
      align = textLeftAlign ? 'left' : 'right';
      vAlign = 'middle';
    }
    else {
      const vZero = y(zero);
      const stretch = isFinite(d.v) ? y(d.v) - vZero : 0;
      textX = x(d.category) + bar.thickness / 2;
      textY = (d.v < 0) ? bar.pos + bar.size : bar.pos;
      color = styles?.color;
      let textTopAlign = true;
      // zeros and infinites must be placed on top of the zero-line unless the
      // zero-line is at the top of the plot area, in which case we use bottom
      if ((!isFinite(d.v) || !d.v)) {
        textTopAlign = vZero < (fontSize + padding);
      }
      else {
        textTopAlign = (stretch >= 0)
          ? (height - textY) > (textY - vZero)
          : textY < (vZero - textY);
        // label is inside the bar?
        if (textTopAlign !== (stretch >= 0)) {
          // if there isn't enough space inside the bar, we move to beyond 0-line
          if (Math.abs(stretch) < (fontSize + padding)) {
            textY = vZero;
          }
          else {
            color = this.contrast(bar.color);
          }
        }
      }
      align = 'center';
      vAlign = textTopAlign ? 'top' : 'bottom';
      if (selectiveValues) {
        const textWidth = Textbox.measureText(text, fontDecl);
        if (bar.width <= (textWidth + 4)) {
          return null;
        }
      }
    }

    return (
      <ChartLabel
        x={textX}
        y={textY}
        align={align}
        vAlign={vAlign}
        padding={padding}
        color={color}
        size={fontSize}
        text={text}
        font={fonts}
        />
    );
  }

  render () {
    const { series, visible, overlap, bandWidth, horizontal, centerLabels } = this.props;
    if (!visible) {
      return null;
    }
    const minBarWidth = bandWidth / series.length;
    const maxBarWidth = bandWidth;
    let barWidth = minBarWidth + ((maxBarWidth - minBarWidth) * overlap);
    const leadShift = (barWidth * INNER_PADDING) / 2;
    const xCenter = bandWidth / 2;
    const shift = (1 - overlap) * barWidth;
    barWidth *= (1 - INNER_PADDING);

    return series.map((values, seriesIndex) => {
      const transform = horizontal
        ? `translate(0,${leadShift + (shift * seriesIndex) - xCenter})`
        : `translate(${leadShift + (shift * seriesIndex) - xCenter},0)`;
      return (
        <g
          key={seriesIndex}
          transform={transform}
          >
          {values.map(d => {
            const bar = horizontal
              ? this.calcHorizontalBar(d, barWidth)
              : this.calcVerticalBar(d, barWidth);
            return (
              <g
                key={d.category}
                onMouseMove={e => this.props?.onPoint(d, e)}
                onMouseLeave={this.props.onUnPoint}
                >
                {bar.valid && (
                  <rect
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    />
                )}
                {this.renderLabel(d, bar, centerLabels)}
              </g>
            );
          })}
        </g>
      );
    });
  }
}
