import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ChartTheme from '@/grid/ChartTheme';
import Theme, { defaultTheme } from '@/grid/Theme';

const blankChartTheme = new ChartTheme(ChartTheme.defaultName);

/**
 * @typedef ThemeContext
 * @property {Theme} theme
 * @property {ChartTheme} chartTheme
 * @property {Record<string, any>} [themeStyles]
 * @property {string} [lastThemeId]
 */

export const ThemesContext = createContext(/** @type {ThemeContext} */({
  theme: defaultTheme,
  chartTheme: blankChartTheme,
}));

export const ThemesProvider = ({
  theme,
  chartTheme,
  updateBackground = false,
  children,
}) => {
  if (!theme) {
    theme = defaultTheme;
  }
  if (!chartTheme) {
    chartTheme = blankChartTheme;
  }

  // We have to apply the background color to body in order to color the whole page
  useEffect(() => {
    if (updateBackground) {
      document.body.style.backgroundColor = theme.background;
      return () => {
        document.body.style.backgroundColor = '';
      };
    }
  }, [ updateBackground, theme.background ]);

  return (
    <ThemesContext.Provider
      value={{
        theme: theme,
        chartTheme: chartTheme,
      }}
      >
      {children}
    </ThemesContext.Provider>
  );
};

ThemesProvider.propTypes = {
  theme: PropTypes.instanceOf(Theme),
  chartTheme: PropTypes.instanceOf(ChartTheme),
  updateBackground: PropTypes.bool,
};

/**
 * @param backgroundColor The background color of the theme
 * @return {ThemeContext}
 */
export function useLocalTheme (backgroundColor) {
  const { chartTheme, theme } = useContext(ThemesContext);
  const [ local, setLocal ] = useState(/** @type {ThemeContext} */({
    theme, chartTheme,
  }));

  const resolvedBackgroundColor = theme.resolveColor(backgroundColor);

  useEffect(() => {
    const { theme: localTheme, themeStyles: localStyles, lastThemeId } = local;
    if (!backgroundColor) {
      // if there is no fill present the local theme should be the parent theme
      if (localStyles || theme !== localTheme) {
        setLocal({ theme, chartTheme });
      }
    }
    else if (
      lastThemeId !== theme.id ||
      resolvedBackgroundColor !== localTheme.backgroundColor
    ) {
      // when there is a fill, we set up a new theme
      const subTheme = new Theme({ ...theme.saveData(), backgroundColor: resolvedBackgroundColor });
      const subChartTheme = new ChartTheme(chartTheme.name, {
        foreground: subTheme.color,
        background: subTheme.background,
        font: subTheme.chartFont,
      });
      setLocal({
        theme: subTheme,
        chartTheme: subChartTheme,
        themeStyles: subTheme.subStyles(theme),
        lastThemeId: theme.id,
      });
    }
  }, [ resolvedBackgroundColor, backgroundColor, theme, local, chartTheme ]);

  return local;
}
