import { scaleLog } from 'd3-scale';
import { format as formatValue } from 'numfmt';

import { logSpan } from '../axistools';
import { measureText } from '../measureText';
import ValueAxis from './ValueAxis';

function isValidDomain (lo, hi) {
  return lo && hi && (lo < 0 === hi < 0);
}

export default class ValueLogAxis extends ValueAxis {
  preferGrid = true;

  constructor (name, style) {
    super(name, style);
    this.scale = scaleLog();
  }

  get zero () {
    // find the number closer to zero
    const [ a, b ] = this.scale.domain();
    return (Math.abs(a) < Math.abs(b))
      ? ValueLogAxis.logZero(a)
      : ValueLogAxis.logZero(b);
  }

  get error () {
    const d = this.scale.domain();
    return !isValidDomain(d[0], d[1]) ? 'A logarithmic axis may not include a zero.' : null;
  }

  getTicks () {
    const scale = this.scale;

    let nTicks;
    if (!this.horizontal) {
      nTicks = 4;
    }
    let ticks = scale.ticks(nTicks);

    // axis "size" (width or height)
    const r = scale.range();
    const space = Math.max(r[0], r[1]) - Math.min(r[0], r[1]);
    const formatPattern = this.format || '';

    if (this.error) {
      return [
        {
          index: 0,
          value: NaN,
          minor: false,
          pos: space / 2,
          label: '#DIV/0!',
        },
      ];
    }

    let logMaxLabel = 1;
    const span = Math.abs(logSpan(scale)); // domain in exponents
    const gapSize = space / (span + 1);
    if (gapSize > 100) {
      logMaxLabel = 5;
    }
    let labelCount = 0;
    const formatOpts = { locale: this.locale, throws: false, nbsp: true };
    ticks = ticks.map((d, i) => {
      const n = Math.abs(d);
      const p = 10 ** Math.floor(Math.log(n + 1e-6) / Math.LN10);
      const major = (n / p) <= 1;
      const labeled = major || ((n / p) <= logMaxLabel);
      if (labeled) {
        labelCount++;
      }
      return {
        index: i,
        value: d,
        minor: !major,
        grid: major || logMaxLabel !== 1,
        pos: this.scale(d),
        label: labeled ? formatValue(formatPattern, d, formatOpts) : '',
      };
    });
    // log axis can hit a case where it has no labels
    // so then we just label first and last
    if (!labelCount && ticks.length) {
      const l = ticks.length - 1;
      ticks[0].label = formatValue(formatPattern, ticks[0].value, formatOpts);
      ticks[l].label = formatValue(formatPattern, ticks[l].value, formatOpts);
    }

    // for horizontal axes we remove any overlapping labels
    else if (this.horizontal && ticks.length > 1) {
      const minLabelGap = 8;
      let removing = false;
      // ensure we're working only with labeled ticks
      const cand = ticks.filter(d => d.label);
      cand.forEach(d => {
        d.width = measureText(d.label, this.font);
      });
      // label overlap removal
      let last = cand[0];
      for (let i = 1; i < cand.length; i++) {
        const curr = cand[i];
        if (removing) {
          // in removing mode all minor labels are removed
          // until we hit a major one again
          if (curr.minor) {
            curr.label = '';
            continue;
          }
          else {
            removing = false;
          }
        }
        const gap = (curr.pos - (curr.width / 2)) - (last.pos + (last.width / 2));
        if (gap < minLabelGap) {
          // if there is a major/minor difference, use that
          if (curr.minor && !last.minor) {
            curr.label = '';
            removing = true;
          }
          else if (!curr.minor && last.minor) {
            last.label = '';
            removing = true;
          }
          // which ever has the higher value should be removed
          else if (curr.value > last.value) {
            curr.label = '';
            removing = true;
          }
          else if (curr.value < last.value) {
            last.label = '';
            removing = true;
          }
        }
        // if current had its label removed, keep last as is
        if (curr.label) {
          last = curr;
        }
      }
    }

    return ticks;
  }

  domain (extent, needZero) {
    if (!extent) {
      const dom = this.scale.domain();
      return [ this.clipMin ?? dom[0], this.clipMax ?? dom[1] ];
    }
    // it should really be 1 to the lowest exponent - 1
    const lo = Math.min(...extent);
    const hi = Math.max(...extent);
    const dom = [ lo, hi ];
    if (isValidDomain(lo, hi)) {
      // see zero getter for comments
      const n = Math.min(Math.abs(lo), Math.abs(hi));
      const e = Math.floor(Math.log10(Math.abs(n)));
      const near = n / 10 ** (e + 1) < 0.25;
      if (needZero || near) {
        dom[Math.sign(lo) < 0 ? 1 : 0] = 10 ** e * Math.sign(lo);
      }
    }
    if (this.clipMin != null) {
      dom[0] = this.clipMin;
    }
    if (this.clipMax != null) {
      dom[1] = this.clipMax;
    }
    this.scale.domain(this.reverse ? dom.reverse() : dom);
  }
}

ValueLogAxis.logZero = function (v) {
  // find the exponent
  const e = Math.floor(Math.log10(Math.abs(v)));
  // "zero" is 10 the power of e with whatever sign the value has
  return 10 ** e * Math.sign(v);
};
