/**
 * Determines if a string is made up of emojis only.
 *
 * This uses a fairly horrifying RegExp to which the only
 * alternative is an enormous database of unicode codepoints.
 *
 * Emojis are built in a variety of ways:
 *
 * Legacy: ☃️ [ '☃' + '\uFE0F' ]
 * Emoji: 👋 [ '👋' ]
 * Emoji with modifier: 👌🏽 [ '👌' + '🏽' ]
 * Emojis with joiner: 👨‍👦 [ '👨' + '\u200D' + '👨' ]
 * ...
 * Sequences: 🏳️‍⚧️ [ '🏳' + '\uFE0F' + '\u200D' + '⚧' + '\uFE0F']
 */
export default function isEmoji (str) {
  if (typeof str !== 'string' || str.length < 2) {
    return false;
  }
  // XXX: commented out is a more accurate expression
  // it is unfortunately vulnerable to super-linear runtime due to backtracking
  // we can fix it at some point but are shipping a simplified expression because
  // the consequences for false positives are nearly none.
  // return /^(?:\p{Emoji}\uFE0F|(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)(?:\u200D|\p{Emoji_Modifier}|\p{Emoji_Presentation}|\p{Emoji}\uFE0F)*)+$/u.test(str);
  try {
    // eslint-disable-next-line prefer-regex-literals
    return new RegExp('^(\\p{Emoji}\\uFE0F|\\p{Emoji_Presentation}|\\u200D|\\p{Emoji_Modifier})+$', 'u').test(str);
  }
  catch (e) {
    return false;
  }
}
