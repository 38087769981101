const C = 0x1;
const R = 0x10000;
const CM = 0x3fff; // max number of columns is 0x3fff
const RM = 0xfffff; // max number of rows is 0xfffff

/**
 * @param {number} col column index
 * @param {number} row row index
 * @return {number} integer position ID
 */
export function packID (col, row) {
  return ((col & CM) * C) + ((row & RM) * R);
}

/**
 * @param {number} intId The integer identifier
 * @return {[ number, number ]} A tuple of column and row indexes, respectively.
 */
export function unpackID (intId) {
  return [
    (intId / C) & CM,
    (intId / R) & RM,
  ];
}
