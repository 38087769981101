import React from 'react';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import { commonButtonUI, disabled, elementVisibility, titleLabel } from '../propsData';
import BaseButton from './common/BaseButton';

const elementOptions = {
  title: titleLabel,
  visible: elementVisibility,
  disabled: disabled,
  ...commonButtonUI,
};

export default function GridResetButton (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  const isDisabled = disabled.read(props);
  return (
    <BaseButton
      {...props}
      label={titleLabel.read(props) || 'Restore defaults'}
      disabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          props.track('interact', { elementType: 'resetbutton' });
          props.model.reset();
        }
      }}
      />
  );
}

GridResetButton.options = elementOptions;
GridResetButton.requiredOption = null;
GridResetButton.propTypes = {
  isEditor: PropTypes.bool,
  track: PropTypes.func,
  model: modelProp.isRequired,
};
