/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from 'react';
import csx from 'classnames';

import Tooltip from '@/grid/Tooltip';
import { clamp } from '@/grid/utils/clamp';
import stepNormalize from '@/grid/utils/stepNormalize';

import { SliderTicks } from './SliderTicks';

import styles from './Slider.module.scss';

function mousePos (e, target) {
  const style = target.currentStyle || window.getComputedStyle(target, null);
  const borderLeft = parseInt(style.borderLeftWidth, 10);
  const borderTop = parseInt(style.borderTopWidth, 10);
  const rect = target.getBoundingClientRect();
  const pos = e.touches ? e.touches[0] : e;
  return [
    pos.clientX - borderLeft - rect.left,
    pos.clientY - borderTop - rect.top,
  ];
}

/**
 * @param {any[]} args
 * @return {null | number}
 */
function oneOf (...args) {
  for (let i = 0; i < args.length; i++) {
    if (typeof args[i] === 'number') {
      return args[i];
    }
  }
  return null;
}

/**
 * @param {string} key
 * @return {number}
 */
function keyToDelta (key) {
  if (key === 'ArrowRight') {
    return 1;
  }
  if (key === 'ArrowUp') {
    return 1;
  }
  if (key === 'ArrowLeft') {
    return -1;
  }
  if (key === 'ArrowDown') {
    return -1;
  }
  if (key === 'PageUp') {
    return 10;
  }
  if (key === 'PageDown') {
    return -10;
  }
  if (key === 'End') {
    return Infinity;
  }
  if (key === 'Home') {
    return -Infinity;
  }
  return 0;
}

function getDelta (e, pos) {
  const p = e.touches ? e.touches[0] : e;
  const deltaX = pos ? p.pageX - pos[0] : 0;
  const deltaY = pos ? p.pageY - pos[1] : 0;
  return [ deltaX, deltaY ];
}

/**
 * @param {object} props The properties
 * @param {number} props.value
 * @param {number} [props.defaultValue]
 * @param {number} [props.min]
 * @param {number} [props.max]
 * @param {number} [props.step]
 * @param {boolean} props.disabled
 * @param {boolean} [props.showValue]
 * @param {boolean} [props.showTooltip]
 * @param {string} [props.size] ('small' | 'medium' | 'large')
 * @param {string} props.labelledby
 * @param {string} props.label
 * @param {Function} props.onDragStart
 * @param {Function} props.onDragEnd
 * @param {Function} props.onChange
 * @return {React.ReactElement}
 */
export default function Slider (props) {
  /** @type {React.MutableRefObject<HTMLSpanElement | null>} */
  const thumbRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLSpanElement | null>} */
  const trackRef = useRef(null);

  const [ pos, setPos ] = useState([ 0, 0 ]);
  const [ startValue, setStartValue ] = useState(0);
  const [ dragging, setDragging ] = useState(false);

  // step 1: let's see if we can set a default value
  let value = oneOf(props.value, props.defaultValue);
  // step 2: what are the min/max bounds of the selectable range
  let max = /** @type {number} */(oneOf(props.max, 100));
  let min = /** @type {number} */(oneOf(props.min, 0));
  // step 3: range and step should be sane
  if (min > max) {
    [ min, max ] = [ max, min ];
  }
  if (value != null && props.min == null && min > value) {
    min = value;
  }
  if (value != null && props.max == null && max < value) {
    max = value;
  }
  let step = props.step;
  if (!step) {
    step = Math.abs(step || 1);
  }
  // step 4: if value is still not set pick a midpoint between min/max (which we now have)
  if (value == null) {
    value = (max < min) ? min : min + (max - min) / 2;
  }
  value = stepNormalize(value, min, max, step);

  const getThumbPercent = () => {
    const range = (max - min);
    let p = (value - min) / range;
    if (value == null || !isFinite(value)) {
      p = 0.5; // center the thumb when value is not present
    }
    return (p * 100) + '%';
  };

  const updateValue = useCallback((v, fromKeyboard = false) => {
    const stepped = stepNormalize(v, min, max, step);
    if ((dragging || fromKeyboard) && props.onChange && value !== stepped) {
      props.onChange(stepped);
    }
  }, [ props, dragging, value, min, max, step ]);

  const onKeyDown = useCallback(/** @param {React.KeyboardEvent} e */ e => {
    let delta = keyToDelta(e.key);
    if (delta !== 0) {
      e.preventDefault();
      if (e.shiftKey) {
        delta *= 10;
      }
      updateValue(value + (delta * step), true);
    }
  }, [ updateValue, value, step ]);

  const onClick = useCallback(e => {
    e.preventDefault();
    const [ x ] = mousePos(e, trackRef.current);
    const mx = trackRef.current?.getBoundingClientRect().width ?? 1;
    const v = min + ((x / mx) * (max - min));
    updateValue(v, true);
  }, [ min, max, updateValue ]);

  const onInteractStart = useCallback(e => {
    e.preventDefault();
    if (e.target.setPointerCapture) {
      e.target.setPointerCapture(e.pointerId);
    }
    thumbRef.current?.focus();
    const p = e.touches ? e.touches[0] : e;
    const [ x ] = mousePos(e, trackRef.current);
    const mx = trackRef.current?.getBoundingClientRect().width ?? 1;
    const v = min + ((x / mx) * (max - min));
    setPos([ p.pageX, p.pageY ]);
    setStartValue(v);
    setDragging(true);
    document.body.classList.add('slider_active');
    if (props.onDragStart) {
      props.onDragStart([ p.pageX, p.pageY ]);
    }
  }, [ props, thumbRef, min, max ]);

  const onInteractEnd = useCallback(e => {
    setDragging(false);
    document.body.classList.remove('slider_active');
    if (e.target.releasePointerCapture) {
      e.target.releasePointerCapture(e.pointerId);
    }
    if (props.onDragEnd) {
      props.onDragEnd();
    }
  }, [ props ]);

  const onDragMovement = useCallback(e => {
    if (dragging) {
      const [ dx ] = getDelta(e, pos);
      const width = trackRef.current?.getBoundingClientRect().width ?? 1;
      const percent = (startValue - min) / (max - min);
      const px = clamp(0, width * percent + dx, width) / width;
      const value = min + ((max - min) * px);
      updateValue(value);
    }
  }, [ startValue, pos, min, max, dragging, updateValue ]);

  const { size, disabled, showValue, labelledby, label, showTooltip } = props;

  /** @type {import('@/grid/types').CSSPropertiesWithVars} */
  const style = { '--thumb-size': '20px' };
  if (size === 'medium') {
    style['--thumb-size'] = '22px';
  }
  if (size === 'large') {
    style['--thumb-size'] = '24px';
  }

  return (
    <span
      className={csx(
        styles.sliderUI,
        disabled && styles.disabled,
        dragging && styles.active,
        showValue && styles.labelspace,
      )}
      style={style}
      >
      <span
        className={styles.track}
        data-obid="grid-slider-track"
        onPointerDown={disabled ? undefined : onInteractStart}
        onPointerUp={disabled ? undefined : onInteractEnd}
        onPointerMove={disabled ? undefined : onDragMovement}
        onClick={disabled ? undefined : onClick}
        >
        <span className={styles.trackFill} />
        <span className={styles.ticks}>
          <SliderTicks min={min} max={max} step={step} />
        </span>
        <span
          className={styles.trackOverlay}
          ref={trackRef}
          />
        {showValue ? (<span className={styles.label}>{label}</span>) : null}
        <span className={styles.thumbWrap}>
          <Tooltip
            hoverNode={label}
            cursorPosition={dragging && showTooltip && label ? 'static' : null}
            showAnchor
            >
            <span
              className={styles.thumb}
              role="slider"
              tabIndex={disabled ? undefined : 0}
              style={{ left: getThumbPercent() }}
              ref={thumbRef}
              aria-valuemax={max}
              aria-valuemin={min}
              aria-valuenow={value}
              aria-disabled={disabled ? 'true' : undefined}
              aria-labelledby={labelledby}
              onKeyDown={disabled ? undefined : onKeyDown}
              >
              <span className={styles.thumbStroke} />
              <span className={styles.thumbFill} />
            </span>
          </Tooltip>
        </span>
      </span>
    </span>
  );
}

Slider.defaultProps = {
  showValue: false,
  showTooltip: true,
  size: 'small',
};
