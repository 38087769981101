import { Range } from '@grid-is/apiary';

import { CellRect } from './CellRect';
import { ColumnWidthGetter } from './ColumnWidthGetter';
import { RowHeightGetter } from './RowHeightGetter';

/**
 * @typedef {object} Dims
 * @property {[ number, number ]} index
 * @property {[ number, number ]} size
 * @property {[ number, number ]} start
 * @property {[ number, number ]} end
 */

/**
 * @typedef {object} PlaneMeasure
 * @property {number} index
 * @property {number} size
 * @property {number} start
 * @property {number} end
 */

/**
 * @typedef {object} MergeInfo
 * @property {[ [ number, number ], [ number, number ] ]} indexes
 * @property {number} startX
 * @property {number} startY
 * @property {number} width
 * @property {number} height
 */

/**
 * @param {{ get(i:number):number }} getter The getter
 * @param {number} length The length
 * @return {PlaneMeasure[]}
 */
function getMeasures (getter, length) {
  const sizes = [];
  let sum = 0;
  for (let i = 0; i < length; i++) {
    const size = getter.get(i);
    sizes[i] = {
      index: i,
      start: sum,
      size: size,
      end: sum + size,
    };
    sum = sum + size;
  }
  return sizes;
}

/**
 * Plane describes a 2-dimensional surface mapping between euclidian-space
 * and grid-space allowing for simpler conversion between the two.
 *
 * Note: linear space coords are referred to as width/height/x/y,
 *       grid space coords are referred to as rows/cols/indexes,
 *       cells have start/end/size/index
 */
export class Plane {
  defaultCellHeight = 21;
  defaultCellWidth = 65 * 1.17;

  /** @type {[ number, number ]} */
  tilt = [ 0, 0 ];

  viewBox = new Range({
    top: 0,
    left: 0,
    bottom: Range.MAX_ROW,
    right: Range.MAX_COL,
  });

  /**
   * @param {object} opts
   * @param {number} opts.top
   * @param {number} opts.left
   * @param {number} opts.bottom
   * @param {number} opts.right
   */
  // constructor (width = 1, height = 1, rangeOffset = [ 0, 0 ]) {
  constructor ({ top, left, bottom, right }) {
    // this.rangeOffset = rangeOffset;
    this.viewBox = new Range({
      top: top,
      left: left,
      bottom: bottom,
      right: right,
    });

    /** @type {PlaneMeasure[]} */
    this.col = [];
    this.width = 0;
    /** @type {PlaneMeasure[]} */
    this.row = [];
    this.height = 0;
  }

  get viewWidth () {
    const topLeft = this.getCellRect(this.viewBox.left, this.viewBox.top);
    const bottomRight = this.getCellRect(this.viewBox.right, this.viewBox.bottom);
    return bottomRight.right - topLeft.left;
  }

  get viewHeight () {
    const topLeft = this.getCellRect(this.viewBox.left, this.viewBox.top);
    const bottomRight = this.getCellRect(this.viewBox.right, this.viewBox.bottom);
    return bottomRight.bottom - topLeft.top;
  }

  get viewLeft () {
    return this.getCol(this.viewBox.left).start;
  }

  get viewTop () {
    return this.getCol(this.viewBox.left).start;
  }

  get rows () {
    return this.row.length;
  }

  get columns () {
    return this.col.length;
  }

  /**
   * Given an array of numbers (dimensions) the function returns a new array with the
   *  cumulative sums of the dimensions array.
   *
   * @param {number} offset the length of the new array containing the cumulative
   * sums for dimensions array provided in second argument
   * @param {array} dimensions an array of numbers or undefined.
   * @param {number} defaultDimension the dimension to use give that a certain dimensions array
   * element is undefined
   * @returns  array of length offset containing the cumulative sums of dimensions array provided. where array index N -1 is the smallest sum
   * and 0 is the largest
   */
  _offsetDims (offset, dimensions, defaultDimension) {
    const offsetDimensions = new Array(offset);
    for (let x = offset - 1; x >= 0; x--) {
      const size = dimensions[x] || defaultDimension;
      if (x === offset - 1) {
        offsetDimensions[x] = size;
      }
      else {
        offsetDimensions[x] = size + offsetDimensions[x + 1];
      }
    }
    return offsetDimensions;
  }

  /**
   * @param {number} px
   * @param {PlaneMeasure[]} dimension
   * @param {number} dimensionSize
   * @param {number} [defaultSize=10]
   * @return {PlaneMeasure}
   */
  toMeasure (px, dimension, dimensionSize, defaultSize = 10) {
    if (px < 0) {
      px = 0;
    }
    if (px <= dimensionSize) {
      const r = dimension.find(d => px >= d.start && px <= d.end);
      if (r) {
        return r;
      }
    }
    const last = dimension[dimension.length - 1] || { end: 0, index: 0 };
    const remainingWidth = px - last.end;
    const index = Math.ceil(remainingWidth / defaultSize) + last.index;
    return {
      index: index,
      start: (index * defaultSize) - defaultSize,
      size: defaultSize,
      end: index * defaultSize,
    };
  }

  /**
   * @param {number} colIndex
   * @return {PlaneMeasure}
   */
  getCol (colIndex) {
    const w = this.columns;
    if (colIndex < w) {
      return this.col[colIndex];
    }
    let osx = 0;
    if (w) {
      osx = this.col[w - 1].end;
    }
    const offs = osx + ((colIndex - w) * this.defaultCellWidth);
    return {
      index: colIndex,
      size: this.defaultCellWidth,
      start: offs,
      end: offs + this.defaultCellWidth,
    };
  }

  /**
   * @param {number} xPx
   * @return {PlaneMeasure}
   */
  toCol (xPx) {
    return this.toMeasure(xPx, this.col, this.width, this.defaultCellWidth);
  }

  /**
   * @param {number} rowIndex
   * @return {PlaneMeasure}
   */
  getRow (rowIndex) {
    const h = this.rows;
    if (rowIndex < h) {
      return this.row[rowIndex];
    }
    let osy = 0;
    if (h) {
      osy = this.row[h - 1].end;
    }
    const offs = osy + ((rowIndex - h) * this.defaultCellHeight);
    return {
      index: rowIndex,
      size: this.defaultCellHeight,
      start: offs,
      end: offs + this.defaultCellHeight,
    };
  }

  /**
   * @param {number} yPx
   * @return {PlaneMeasure}
   */
  toRow (yPx) {
    return this.toMeasure(yPx, this.row, this.height, this.defaultCellHeight);
  }

  /**
   * @param {number} colIndex column index
   * @param {number} rowIndex row index
   * @return {Dims}
   */
  dims (colIndex, rowIndex) {
    const col = this.getCol(colIndex);
    const row = this.getRow(rowIndex);
    return {
      index: [ colIndex, rowIndex ],
      size: [ col.size, row.size ],
      start: [ col.start, row.start ],
      end: [ col.end, row.end ],
    };
  }

  /**
   * @param {number} colIndex
   * @param {number} rowIndex
   * @returns {CellRect}
   */
  getCellRect (colIndex, rowIndex) {
    const tcol = this.getCol(colIndex);
    const trow = this.getRow(rowIndex);
    const rcol = this.getCol(this.viewBox.left);
    const rrow = this.getRow(this.viewBox.top);
    return new CellRect(
      tcol.start - rcol.start,
      trow.start - rrow.start,
      tcol.size,
      trow.size,
    );
  }

  /**
   * @param {number} colIndex
   * @returns {PlaneMeasure}
   */
  getColSize (colIndex) {
    const col = this.getCol(colIndex);
    const rel = this.getCol(this.viewBox.left);
    return {
      index: col.index,
      size: col.size,
      start: col.start - rel.start,
      end: col.end - rel.end,
    };
  }

  /**
   * @param {number} rowIndex
   * @returns {PlaneMeasure}
   */
  getRowSize (rowIndex) {
    const col = this.getRow(rowIndex);
    const rel = this.getRow(this.viewBox.top);
    return {
      index: col.index,
      size: col.size,
      start: col.start - rel.start,
      end: col.end - rel.end,
    };
  }

  /**
   * @param {Range} range
   * @returns {CellRect}
   */
  rangeToRect (range) {
    const col0 = this.getCol(range.left);
    const col1 = range.right === range.left ? col0 : this.getCol(range.right);
    const row0 = this.getRow(range.top);
    const row1 = range.top === range.bottom ? row0 : this.getRow(range.bottom);
    const colR = this.getCol(this.viewBox.left);
    const rowR = this.getRow(this.viewBox.top);
    return new CellRect(
      col0.start - colR.start, row0.start - rowR.start,
      col1.end - col0.start, row1.end - row0.start,
    );
  }

  /**
   * @param {CellRect} rect
   * @returns {Range}
   */
  rectToRange (rect) {
    const top = this.toRow(rect.top).index;
    const left = this.toCol(rect.left).index;
    const bottom = Math.max(0, this.toRow(rect.bottom).index);
    const right = Math.max(0, this.toCol(rect.right).index);
    return new Range({ top, left, bottom, right });
  }

  /**
   * @param {number} x
   * @param {number} y
   * @param {number} w
   * @param {number} h
   */
  setView (x, y, w, h) {
    const viewPort = new CellRect(x, y, w, h);
    this.viewBox = this.rectToRange(viewPort);
    this.tilt = [
      this.getCol(this.viewBox.left).start - x,
      this.getRow(this.viewBox.top).start - y,
    ];
  }

  /**
   * @param {RowHeightGetter} heightGetter
   * @param {number} numRows
   */
  addHeights (heightGetter, numRows) {
    this.row = getMeasures(heightGetter, numRows);
    this.defaultCellHeight = heightGetter.getDefault();
    this.height = this.row.at(-1)?.end || 0;
  }

  /**
   * @param {ColumnWidthGetter} widthGetter
   * @param {number} numCols
   */
  addWidths (widthGetter, numCols) {
    this.col = getMeasures(widthGetter, numCols);
    this.defaultCellWidth = widthGetter.getDefault();
    this.width = this.col.at(-1)?.end || 0;
  }
}

/**
 * @param {import('@grid-is/apiary').Reference} ref
 * @param {import('@grid-is/apiary').Workbook} workbook
 * @returns {Plane}
 */
Plane.from = function (ref, workbook) {
  const scale = new Plane(ref.range);
  const sheet = workbook.getSheet(ref.sheetName);
  if (sheet) {
    const columnWidthGetter = new ColumnWidthGetter(sheet);
    const rowHeightGetter = new RowHeightGetter(sheet);
    const [ sheetWidth, sheetHeight ] = sheet.getSize();
    scale.addHeights(rowHeightGetter, sheetHeight);
    scale.addWidths(columnWidthGetter, sheetWidth);
  }
  return scale;
};
