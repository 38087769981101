/**
 * @typedef {(
 *  "none" | "dashDot" | "dashDotDot" | "dashed" | "dotted" | "double" |
 *  "hair" | "medium" | "mediumDashDot" | "mediumDashDotDot" | "mediumDashed" |
 *  "slantDashDot" | "thick" | "thin" | "default"
 * )} BorderType
 */

/**
 * @typedef {object} BorderDetails
 * @property {number} lineWidth
 * @property {number} [gap=0]
 * @property {number} [slant=0]
 * @property {null | number[]} lineDash
 */

/**
 * @typedef {object} BorderDef
 * @property {string} [color] hex-encoded RGBA value (#000)
 * @property {BorderType} [style]
 */

/**
 * @type {Record<BorderType, BorderDetails>}
 */
export const borderStyles = {
  dashDot: {
    lineWidth: 0.5,
    lineDash: [ 7, 3, 3, 3 ],
  },
  dashDotDot: {
    lineWidth: 0.5,
    lineDash: [ 7, 3, 3, 3, 3, 3 ],
  },
  dashed: {
    lineWidth: 0.5,
    lineDash: [ 3, 3, 3, 3 ],
  },
  dotted: {
    lineWidth: 0.5,
    lineDash: [ 3, 3, 3, 3 ],
  },
  double: {
    lineWidth: 0.8,
    lineDash: null,
    gap: 1,
    slant: 0,
  },
  hair: {
    lineWidth: 0.5,
    lineDash: null,
  },
  medium: {
    lineWidth: 1.5,
    lineDash: null,
  },
  mediumDashDot: {
    lineWidth: 2,
    lineDash: [ 7, 3, 3, 3 ],
  },
  mediumDashDotDot: {
    lineWidth: 1.5,
    lineDash: [ 7, 3, 3, 3, 3, 3 ],
  },
  mediumDashed: {
    lineWidth: 1.5,
    lineDash: [ 7, 3 ],
  },
  slantDashDot: {
    lineWidth: 1,
    lineDash: [ 7, 1, 3, 1 ],
    gap: 0,
    slant: 0.5,
  },
  thick: {
    lineWidth: 3,
    lineDash: null,
  },
  thin: {
    lineWidth: 0.5,
    lineDash: null,
  },
  none: {
    lineWidth: 0,
    lineDash: null,
  },
  default: {
    lineWidth: 1,
    lineDash: null,
  },
};
