/**
 * @typedef {{type: string, aspect: number, url: string, thumb?: string}} EmbedData
 */

/**
 * @callback HandlerFunc
 * @param {string[]} match
 * @returns {EmbedData}
 */

/**
 * @type {Array<{matcher: RegExp, handler: HandlerFunc}>}
 */
const providers = [
  {
    // YouTube
    // 'https://*.youtube.com/watch*'
    // 'https://*.youtube.com/shorts/*'
    // 'https://*.youtube.com/v/*'
    // 'https://youtu.be/*'
    matcher: /^(?:https?:)?\/\/(?:www\.)?(?:youtube\.com\/(?:watch|shorts|v)|youtu\.be)?(?:\/|\?.*?v=)([a-zA-Z0-9_-]+)/,
    handler: match => {
      return {
        type: 'video',
        aspect: 0.5625,
        thumb: `https://i.ytimg.com/vi/${match[1]}/hqdefault.jpg`,
        url: `https://www.youtube.com/embed/${match[1]}?title=0&byline=0&portrait=0`,
      };
    },
  },
  {
    // Vimeo
    // 'https://vimeo.com/*',
    // 'https://vimeo.com/album/*/video/*',
    // 'https://vimeo.com/channels/*/*',
    // 'https://vimeo.com/groups/*/videos/*',
    // 'https://vimeo.com/ondemand/*/*',
    // 'https://player.vimeo.com/video/*',
    matcher: /^(?:https?:)?\/\/(?:player\.)?vimeo\.com\/(?:(?:album|channels|groups|ondemand)\/[a-zA-Z0-9]+\/)?(?:videos?\/)?([a-zA-Z0-9]+)$/,
    handler: match => {
      return {
        type: 'video',
        aspect: 0.5625,
        url: `https://player.vimeo.com/video/${match[1]}`,
      };
    },
  },
  {
    // Twitch
    // 'https://www.twitch.tv/videos/*',
    matcher: /^(?:https?:)?\/\/(?:www\.)?twitch\.tv\/videos\/([a-zA-Z0-9]+)$/,
    handler: match => {
      const xhost = typeof location !== 'undefined' ? `&parent=${location.hostname}` : '';
      return {
        type: 'video',
        aspect: 0.5625,
        url: `https://player.twitch.tv/?video=${match[1]}&autoplay=false&iframe=1&parent=grid.is${xhost}`,
      };
    },
  },
  {
    // TED:
    // "http://ted.com/talks/*",
    // "https://ted.com/talks/*",
    // "https://www.ted.com/talks/*"
    matcher: /^(?:https?:)?\/\/(?:www\.)?ted\.com\/talks\/([^/?]+)$/,
    handler: match => {
      return {
        type: 'video',
        aspect: 0.5642857142857143,
        url: `https://embed.ted.com/talks/${match[1]}`,
      };
    },
  },
  {
    // DailyMotion:
    // "https://www.dailymotion.com/video/*"
    matcher: /^(?:https?:)?\/\/(?:www\.)?dailymotion\.com\/video\/([^/?]+)$/,
    handler: match => {
      return {
        type: 'video',
        aspect: 0.5625,
        url: `https://www.dailymotion.com/embed/video/${match[1]}`,
      };
    },
  },
  // Loom:
  // "https://www.loom.com/share/*"
  // "https://www.loom.com/embed/*"
  {
    matcher: /^(?:https?:)?\/\/(?:www\.)?loom\.com\/(?:share|embed)\/([^/?]+)(?:\?.*)?$/,
    handler: match => {
      return {
        type: 'video',
        aspect: 0.5625,
        url: `https://www.loom.com/embed/${match[1]}`,
      };
    },
  },
  // Google maps:
  // "https://<iframe src="https://www.google.com/maps/embed?...*
  // "https://www.google.com/maps/embed?*"
  {
    // because of the way UrlOptions read values, we'll get the iframe code prefixed with "http://"
    matcher: /^(?:http:\/\/<iframe .*?)?https:\/\/(?:www\.)?google\.com\/maps\/embed\?([^"?/]+)/,
    handler: match => {
      return {
        type: 'map',
        aspect: 0.75,
        url: `https://www.google.com/maps/embed?${match[1]}`,
      };
    },
  },
];

const reURL = /^(?:https?:)?\/\/([^/]+)\//i;

/**
 * @param {string} src
 * @return {null | EmbedData}
 */
export default function getEmbedParams (src) {
  const isURL = src && reURL.test(src);
  if (isURL) {
    // remove hash from URL
    src = src.replace(/#.*?$/, '');
    for (const { handler, matcher } of providers) {
      const m = matcher.exec(src);
      if (m) {
        return handler(m);
      }
    }
  }
  return null;
}
