let _measureText;
try {
  const ctx = document.createElement('canvas').getContext('2d');
  _measureText = (text, font = '14px Roboto, Arial, Helvetica') => {
    if (ctx) {
      ctx.font = font;
      return ctx.measureText(text).width;
    }
    return String(text).length * 5.5;
  };
}
catch (err) {
  // measureText is going to be pretty abysmal. :(
  _measureText = text => String(text).length * 5.5;
}

export const measureText = _measureText;
