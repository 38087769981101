let app;

export function getStyles (elm) {
  try {
    return window.getComputedStyle(elm);
  }
  catch (err) {
    return {};
  }
}

export function getStyle (elm, styleProp) {
  return getStyles(elm)[styleProp];
}

export function scrollingDocument () {
  if (!app) {
    app = document.getElementById('__next');
  }
  if (getStyle(app, 'overflow')) {
    return app;
  }
  return document.scrollingElement || document.documentElement;
}

/** @return {HTMLElement | null} */
export function scrollingEditor () {
  if (typeof document === 'undefined') {
    return null;
  }

  const editor = document.getElementById('editor');
  if (editor) {
    return editor;
  }
  // @ts-expect-error
  return document.scrollingElement || document.documentElement;
}

export function getOffsetParent (elm) {
  let currElm = elm.offsetParent || elm.parentNode;
  if (elm.ownerSVGElement) { // if SVG child: return its DOM container
    currElm = elm.ownerSVGElement.parentNode;
  }

  while (
    currElm &&
    currElm.tagName &&
    currElm !== document.documentElement &&
    getStyle(currElm, 'position') === 'static'
  ) {
    currElm = currElm.offsetParent;
  }

  if (!currElm) {
    currElm = scrollingEditor();
  }

  return currElm;
}

/**
 * @param {MouseEvent | TouchEvent | Touch | React.MouseEvent} event The event
 * @return {{clientX: number, clientY: number} }
 */
export function getCursorPosition (event) {
  if ('changedTouches' in event) {
    event = event.changedTouches[0];
  }
  return {
    clientX: event.clientX,
    clientY: event.clientY,
  };
}

/**
 * @param {SVGGraphicsElement | HTMLElement} element The element
 * @return {DOMMatrix} The dom matrix.
 */
function screenCTM (element) {
  // start by building a chain of elements to use
  const lineage = [];
  /** @type {null | SVGGraphicsElement | HTMLElement} */
  let curr = element;
  while (curr && curr.nodeType === 1) {
    lineage.push(curr);
    if ('ownerSVGElement' in curr && curr.ownerSVGElement) {
      curr = curr.ownerSVGElement;
    }
    // @ts-expect-error
    curr = curr.parentNode;
  }

  // define initial matrix
  const ident = [ 1, 0, 0, 1, 0, 0 ];
  if (curr && curr.nodeType === 9) {
    // offsetLeft and offsetTop don't reflect margins on the body element
    // @ts-expect-error
    const style = getStyles(curr.body);
    ident[4] = parseFloat(style.marginLeft);
    ident[5] = parseFloat(style.marginTop);
  }
  let ctm = new DOMMatrix(ident);

  // run through the element lineage and transform as we go
  for (let i = lineage.length - 1; i >= 0; i--) {
    const curr = lineage[i];
    // SVG elements
    if ('ownerSVGElement' in curr && curr.ownerSVGElement) {
      ctm = ctm.multiply(curr.getCTM() ?? undefined);
    }
    // HTML elements
    else {
      const style = getStyles(curr);
      const transform = style.transform;
      const origin = style['transform-origin'];
      const scrollLeft = curr.scrollLeft || 0;
      const scrollTop = curr.scrollTop || 0;

      // because offsetTop & offsetLeft are not reliable, calculate offsets
      // by multiplying the bounding box top/left position by inverse ctm
      const bbox = curr.getBoundingClientRect();
      const p = new DOMPoint(bbox.x, bbox.y).matrixTransform(ctm.inverse());
      const dx = p.x - scrollLeft;
      const dy = p.y - scrollTop;

      // has transform CSS active
      if (transform && transform !== 'none') {
        let ox = 0;
        let oy = 0;
        if (origin !== 'none') {
          const coords = origin.split(' ');
          ox = parseFloat(coords[0]);
          oy = parseFloat(coords[1]);
        }
        ctm = ctm.multiply(new DOMMatrix([ 1, 0, 0, 1, ox + dx, oy + dy ]));
        ctm = ctm.multiply(new DOMMatrix(transform));
        ctm = ctm.multiply(new DOMMatrix([ 1, 0, 0, 1, -ox, -oy ]));
      }

      // has position relative or scrolling offset
      else if ((p.x || p.y) && (scrollLeft || scrollTop || style.position === 'relative')) {
        ctm = ctm.multiply(new DOMMatrix([ 1, 0, 0, 1, dx, dy ]));
      }
    }
  }

  // return the eventual matrix
  return ctm;
}

export function relativeMouse (mPos, elm) {
  if (typeof DOMMatrix === 'undefined') {
    return {};
  }
  const ctm = screenCTM(elm);
  const pt = new DOMPoint(mPos.clientX, mPos.clientY)
    .matrixTransform(ctm.inverse());
  return { left: pt.x, top: pt.y };
}

/**
 * Gets the position of an element within its container
 *
 * @param {HTMLElement} elm The element to measure
 * @return {{ left: number, top: number }}
 */
export function getPosition (elm) {
  const offsParent = getOffsetParent(elm);
  const elmRect = elm.getBoundingClientRect();
  const offsRect = offsParent ? offsParent.getBoundingClientRect() : {};
  return {
    left: (offsParent.scrollLeft || 0) + (elmRect.left - offsRect.left),
    top: (offsParent.scrollTop || 0) + (elmRect.top - offsRect.top),
  };
}
