import React, { useCallback, useEffect, useRef, useState } from 'react';
import { format as formatValue, isDateFormat } from 'numfmt';
import PropTypes from 'prop-types';

import Tooltip from '@/grid/Tooltip';
import { isNumber, scale } from '@/grid/utils';
import { getAutoStep } from '@/grid/utils/getAutoStep';
import stepNormalize from '@/grid/utils/stepNormalize';

import styles from './TableSlider.module.scss';

export default function TableSlider ({
  track,
  cell,
  onInput,
  min,
  max,
  step,
  format,
}) {
  /** @type {React.MutableRefObject<HTMLInputElement | null>} */
  const sliderRef = useRef(null);
  const [ bounds, setBounds ] = useState(/** @type {number[] | undefined} */(undefined));

  const onChange = useCallback(() => {
    if (track) {
      track('interact', { elementType: 'table.slider' });
    }
    setBounds(undefined);
  }, [ track ]);

  const onInputLocal = useCallback(e => {
    if (onInput && cell) {
      const v = e.target.valueAsNumber;
      if (isFinite(v)) {
        onInput(cell.id, stepNormalize(v, min, max, step));
      }
    }
  }, [ min, max, step, cell, onInput ]);

  useEffect(() => {
    const sliderElm = sliderRef.current;
    sliderElm?.addEventListener('change', onChange);
    return () => {
      sliderElm?.removeEventListener('change', onChange);
    };
  });

  const v = isFinite(cell.v) ? cell.v || 0 : 0;

  let cMax = max;
  let cMin = min;

  // Automatically pick slider scale defaults if they are missing.
  if (!isNumber(max)) {
    cMax = (v < 0) ? 0 : scale(v) || Math.max(1, step || 0);
  }
  if (!isNumber(min)) {
    cMin = (v < 0) ? -scale(-v) : 0;
  }
  if (!isNumber(step)) {
    if (isDateFormat(format ?? cell.z)) {
      step = 1;
    }
    else {
      step = getAutoStep(v, format, min, max);
    }
  }

  const usedMin = bounds ? bounds[0] : cMin;
  const usedMax = bounds ? bounds[1] : cMax;
  return (
    <span className={styles.sliderWrap}>
      <Tooltip
        cursorPosition={bounds ? 'static' : null}
        showAnchor
        hoverNode={formatValue(format, v)}
        sliderShift={(v - usedMin) / (usedMax - usedMin)}
        sliderWidthAdjust={-16}
        gap={12}
        >
        <input
          ref={sliderRef}
          type="range"
          className={styles.slider}
          disabled={!isFinite(v)}
          value={v}
          step={step}
          min={usedMin}
          max={usedMax}
          onInput={onInputLocal}
          onMouseDown={() => {
            setBounds([ cMin, cMax ]);
          }}
          onMouseUp={() => {
            setBounds(undefined);
          }}
          />
      </Tooltip>
    </span>
  );
}

TableSlider.propTypes = {
  cell: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  format: PropTypes.string,
  onInput: PropTypes.func,
  track: PropTypes.func,
};
