import React from 'react';
import PropTypes from 'prop-types';

import { createDocumentState } from '@/api/document';
import { MIN_BUTTON_ANIM_TIME } from '@/grid/constants';
import { printObject as printModelState, toObject as modelStateToObject } from '@/utils/modelState';

import modelProp from '../modelProp';
import { commonButtonUI, disabled, elementVisibility, submitButtonSubject, submitButtonType, targetCell, titleLabel, value } from '../propsData';
import BaseButton from './common/BaseButton';
import ButtonStateIcon from './common/ButtonStateIcon';

const elementOptions = {
  title: titleLabel,
  value: value,
  expr: targetCell,
  visible: elementVisibility,
  buttonType: submitButtonType,
  subject: submitButtonSubject,
  disabled: disabled,
  ...commonButtonUI,
};

const INITIAL = 0;
const LOADING = 1;
const SUBMITTED = 2;

const stateHover = {
  [INITIAL]: { message: '', icon: '' },
  [LOADING]: { message: 'Sending data...', icon: 'spinner' },
  [SUBMITTED]: { message: 'Successfully submitted!', icon: 'checkmark' },
};

export default function GridSubmitButton (props) {
  const [ state, setState ] = React.useState(INITIAL);
  const [ lastWrite, setLastWrite ] = React.useState(null);

  React.useEffect(() => {
    if (state === SUBMITTED && lastWrite != null && lastWrite !== props.model.lastWrite) {
      setState(INITIAL);
    }
  }, [ state, lastWrite, props.model.lastWrite ]);

  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  const buttonType = submitButtonType.read(props);
  let title = titleLabel.read(props);
  if (!title) {
    title = buttonType
      ? 'Submit'
      : 'Action type not selected';
  }

  const isDisabled = (
    disabled.read(props) ||
    !props.canUse('can_use_form_submissions') ||
    state === LOADING ||
    state === SUBMITTED ||
    buttonType === ''
  );

  const minSendDelay = props.minDelay || MIN_BUTTON_ANIM_TIME;
  const writeBackFn = props.createDocumentState || createDocumentState;

  const onClick = async () => {
    if (props.isEditor || state === LOADING || isDisabled) {
      return;
    }
    props.track('interact', { elementType: 'submitbutton' });

    const documentId = props.documentId;
    const modelState = props.model.writes();
    const stateObject = modelStateToObject(modelState);
    const printedModelState = modelState.length ? printModelState(stateObject) : '';

    const data = {
      state: modelState,
      s: printedModelState,
    };

    const subject = submitButtonSubject.read(props);
    if (subject) {
      data.subject = subject;
    }

    const writeLocal = val => {
      // target is only written to if it is set
      if (targetCell.isSet(props)) {
        targetCell.write(props, val);
      }
    };
    // start by writing a `false` to the target
    // this allows us to detect a loading state
    writeLocal(false);

    const startedAt = Date.now();
    setLastWrite(props.model.lastWrite);
    setState(LOADING);
    try {
      await writeBackFn(documentId, buttonType, data);
      props.track('submit', {
        elementType: 'submitbutton',
        submitData: data,
      });
    }
    catch (e) {
      props.onError(e);
    }
    finally {
      const elapsedMs = (Date.now() - startedAt) / 1000;
      if (elapsedMs < minSendDelay) {
        setTimeout(() => {
          setState(SUBMITTED);
          writeLocal(value.readCells(props));
        }, minSendDelay - elapsedMs);
      }
      else {
        setState(SUBMITTED);
        writeLocal(value.readCells(props));
      }
    }
  };

  return (
    <BaseButton
      {...props}
      label={title}
      disabled={isDisabled}
      onClick={onClick}
      >
      <ButtonStateIcon
        message={stateHover[state].message}
        icon={stateHover[state].icon}
        />
    </BaseButton>
  );
}

GridSubmitButton.options = elementOptions;
GridSubmitButton.requiredOption = null;
GridSubmitButton.propTypes = {
  minDelay: PropTypes.number,
  isEditor: PropTypes.bool,
  model: modelProp.isRequired,
  documentId: PropTypes.string,
  canUse: PropTypes.func,
  track: PropTypes.func,
  onError: PropTypes.func,
  createDocumentState: PropTypes.func,
};
