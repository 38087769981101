import React from 'react';
import { format, isDateFormat } from 'numfmt';
import PropTypes from 'prop-types';

import { submitForm } from '@/api/formSubmission';
import { MIN_BUTTON_ANIM_TIME } from '@/grid/constants';
import modelProp from '@/grid/modelProp';
import {
  commonButtonUI,
  disabled,
  elementVisibility,
  optEmailAddress,
  optEmailEnabled,
  optEmailSubject,
  optFormFields,
  optHubSpotEnabled,
  optHubSpotIntegrationId,
  targetCell,
  titleLabel,
  value,
} from '@/grid/propsData';
import { isEmail } from '@/utils/email';
import { printObject as printModelState, toObject as modelStateToObject } from '@/utils/modelState';

import BaseButton from './common/BaseButton';
import ButtonStateIcon from './common/ButtonStateIcon';

const elementOptions = {
  title: titleLabel,
  value: value,
  expr: targetCell,
  visible: elementVisibility,
  disabled: disabled,
  emailEnabled: optEmailEnabled,
  emailAddress: optEmailAddress,
  emailSubject: optEmailSubject,
  fields: optFormFields,
  [optHubSpotEnabled.name]: optHubSpotEnabled,
  ...commonButtonUI,
};

const INITIAL = 0;
const LOADING = 1;
const SUBMITTED = 2;
const ERROR = 3;

const stateHover = {
  [INITIAL]: { message: '', icon: '' },
  [LOADING]: { message: 'Sending data...', icon: 'spinner' },
  [SUBMITTED]: { message: 'Successfully submitted!', icon: 'checkmark' },
  [ERROR]: { message: 'Form submission failed', icon: 'warning' },
};

// Configuration states
const CONFIG_VALID = 0;
const NO_FORM_FIELDS = 1;
const NO_EMAIL_FIELD = 2;

const configErrorInfo = {
  [NO_FORM_FIELDS]: {
    title: 'Configure form fields',
    hover: 'Form submission requires at least one form field',
  },
  [NO_EMAIL_FIELD]: {
    title: 'Email field missing',
    hover: 'HubSpot syncing only works if the form contains a field named "email"',
  },
};

/**
 * @param {import('@/grid/types').Cellish} valueCell
 * @returns {import('@/api/formSubmission').FormValues['value']}
 */
function extractValue (valueCell) {
  /** @type {import('@/api/formSubmission').FormValues['value']} */
  const formValue = { value: null };
  if (valueCell) {
    if (typeof valueCell.v === 'number') {
      formValue.value = valueCell.v;
      const z = valueCell.z;
      if (z) {
        formValue.number_format = z;
        formValue.formatted_number = format(z, valueCell.v);
        formValue.is_date = isDateFormat(z);
      }
    }
    else if (typeof valueCell.v === 'boolean') {
      formValue.value = valueCell.v;
    }
    else if (valueCell.v !== null) {
      formValue.value = String(valueCell.v);
    }
  }
  return formValue;
}

/**
 * @param {any} value
 * @returns {value is string | number | boolean}
 */
function isPrimitiveValue (value) {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

/**
 * Converts an array of objects, each containing 'name' and 'value' keys with type Cell,
 * into a FormData object by extracting values from the Cell objects.
 *
 * @param {Array<Record<string, import('@/grid/types').Cellish>>} formFields - The array of objects to process.
 * @returns {import('@/api/formSubmission').FormValues}} - An object containing key-value pairs extracted from the Cell objects.
 */
function extractFormData (formFields) {
  /** @type {import('@/api/formSubmission').FormValues} */
  const data = {};
  for (const { name, value } of formFields) {
    // ensure that the field name is a scalar value
    if (name && isPrimitiveValue(name.v) && name.v !== '') {
      data[String(name.v)] = extractValue(value);
    }
  }
  return data;
}

/** @param {import('@/grid/types').CellArray} arrayOfCells */
function getEmailAddresses (arrayOfCells) {
  const emails = [];
  for (const row of arrayOfCells) {
    for (const cell of row) {
      if (cell && cell.v) {
        // XXX: this logic should probably be handled by the PropsData Email field
        // once we have that. For now we read it here.
        const verifiedEmails = String(cell.v)
          .split(/[,;]/)
          .map(email => email.trim())
          .filter(email => isEmail(email));
        emails.push(...verifiedEmails);
      }
    }
  }
  return emails;
}

/** @param {import('@grid-is/apiary').Model} model */
function getModelState (model) {
  const modelState = model.writes();
  const stateObject = modelStateToObject(modelState);
  const printedModelState = modelState.length
    ? printModelState(stateObject)
    : '';
  return {
    state: modelState,
    s: printedModelState,
  };
}

/**
 * @param {() => void} work
 * @param {number} elapsed
 * @param {number} minDelay
 */
function delayIfNeeded (work, elapsed, minDelay) {
  if (elapsed < minDelay) {
    setTimeout(work, minDelay - elapsed);
  }
  else {
    work();
  }
}

/**
 * @param {Record<string, any>} props - The button props
 * @return {boolean} Whether the form fields include an 'email' field
 */
function hasEmailField (props) {
  const formFields = optFormFields.readCell(props);
  return formFields.some(field => field.name.v === 'email');
}

export default function GridSubmitButton (props) {
  const [ state, setState ] = React.useState(INITIAL);
  const [ lastModelState, setLastModelState ] = React.useState('');

  React.useEffect(() => {
    if (state === SUBMITTED) {
      const currentState = getModelState(props.model).s;
      if (currentState !== lastModelState) {
        // It appears that the user has made a change to the model, re-enable the button
        setState(INITIAL);
      }
    }
  // We only need to enter this hook when the write counter changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.model.lastWrite ]);

  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  /** @type {('email' | 'hubspot')[]} */
  const destinations = [];
  if (optEmailEnabled.read(props)) {
    destinations.push('email');
  }
  if (optHubSpotEnabled.read(props)) {
    destinations.push('hubspot');
  }

  // set the title or fallback
  const title = titleLabel.read(props) || 'Submit';

  // determine if there is an error
  let error = CONFIG_VALID;
  if (!optFormFields.isSet(props)) {
    error = NO_FORM_FIELDS;
  }
  else if (destinations.includes('hubspot') && !hasEmailField(props)) {
    error = NO_EMAIL_FIELD;
  }

  // element is disabled when there is an error
  const isDisabled = (
    disabled.read(props) ||
    state === LOADING ||
    state === SUBMITTED ||
    !!error
  );

  const minSendDelay = props.minDelay || MIN_BUTTON_ANIM_TIME;
  /** @type {import('@/api/formSubmission').submitForm} */
  const writeBackFn = props.submitForm || submitForm;

  const onClick = async () => {
    if (props.isEditor || state === LOADING || isDisabled) {
      return;
    }
    props.track('interact', { elementType: 'submitbutton' });
    const successValue = value.readCells(props);
    const originalValue = targetCell.isSet(props) ? targetCell.read(props).v : null;
    const writeLocal = (val, skipRecalc = false) => {
      // target is only written to if it is set
      if (targetCell.isSet(props)) {
        targetCell.write(props, val, skipRecalc);
      }
    };

    // Start by writing the success value to target.
    // The recalc of the model is deferred here to prevent any potential
    // "flash of content" happening if the model happens to be slow.
    writeLocal(successValue, true);

    // Grab the model and fields state in "success mode" so that the
    // email/PDF sent reflects the end result of what the user saw.
    const modelState = getModelState(props.model);
    const formFields = optFormFields.readCell(props);
    const data = extractFormData(formFields);

    // Now write a `false` to the target, which allows us to detect
    // or set up a loading state for the doc if we desire.
    writeLocal(false);

    const startedAt = Date.now();
    setLastModelState(modelState.s);
    setState(LOADING);
    try {
      const subject = optEmailSubject.read(props);
      const emails = getEmailAddresses(optEmailAddress.readCells(props));
      const hubspotIntegrationId = optHubSpotIntegrationId.read(props);
      await writeBackFn(
        props.documentId,
        destinations,
        data,
        modelState,
        subject,
        emails,
        hubspotIntegrationId,
      );
      props.track('submit', {
        elementType: 'submitbutton',
        submitData: data,
      });
      const elapsedMs = (Date.now() - startedAt) / 1000;
      delayIfNeeded(() => {
        setState(SUBMITTED);
        writeLocal(successValue);
      }, elapsedMs, minSendDelay);
    }
    catch (e) {
      setState(ERROR);
      writeLocal(originalValue);
      props.onError && props.onError(e);
    }
  };

  return (
    <BaseButton
      {...props}
      label={configErrorInfo[error]?.title || title}
      disabled={isDisabled}
      onClick={onClick}
      >
      <ButtonStateIcon
        message={configErrorInfo[error]?.hover || stateHover[state].message}
        icon={error ? 'warning' : stateHover[state].icon}
        />
    </BaseButton>
  );
}

GridSubmitButton.options = elementOptions;
GridSubmitButton.requiredOption = null;
GridSubmitButton.propTypes = {
  minDelay: PropTypes.number,
  isEditor: PropTypes.bool,
  model: modelProp.isRequired,
  documentId: PropTypes.string,
  track: PropTypes.func.isRequired,
  onError: PropTypes.func,
  submitForm: PropTypes.func,
};
