import React, { useRef, useState } from 'react';
import { dateFromSerial } from 'numfmt';
import PropTypes from 'prop-types';

import Calendar from './Calendar.jsx';
import { VIEW_DATE, VIEW_MONTH, VIEW_YEAR } from './constants.js';
import MonthList from './MonthList.jsx';
import PickerHeader from './PickerHeader.jsx';
import { boundDate, ensureSerialDate } from './utils.js';
import YearList from './YearList.jsx';

import styles from './DatePickerControl.module.scss';

export default function DatePickerControl ({ min, max, step, value, locale, onSelect }) {
  const refContainer = useRef(null);
  const [ currentView, setCurrentView ] = useState(VIEW_DATE);
  const [ anchorDate, setAnchorDate ] = useState(boundDate(value, min, max, step, true));
  const selectedDate = ensureSerialDate(value);

  /**
   * @param {number} d
   * @param {'y' | 'm' | 'd'} gran
   * @return {boolean}
   */
  const isSelectable = (d, gran = 'd') => {
    const subjectYMD = dateFromSerial(d);
    if (gran === 'y') {
      const boundedYMD = dateFromSerial(boundDate(d, min, max, 1));
      return subjectYMD[0] === boundedYMD[0];
    }
    if (gran === 'm') {
      const boundedYMD = dateFromSerial(boundDate(d, min, max, 1));
      return subjectYMD[0] === boundedYMD[0] && subjectYMD[1] === boundedYMD[1];
    }
    return d === boundDate(d, min, max, step);
  };

  return (
    <span
      onClick={e => e.preventDefault()}
      className={styles.date}
      ref={refContainer}
      >
      <PickerHeader
        view={currentView}
        locale={locale}
        anchorDate={anchorDate}
        onView={d => setCurrentView(d)}
        onChange={d => setAnchorDate(boundDate(d, min, max, step, true))}
        isSelectable={isSelectable}
        />
      <span className={styles.selection}>
        {!!(currentView & VIEW_YEAR) && (
          <YearList
            anchorDate={anchorDate}
            selectedDate={selectedDate}
            onView={d => setCurrentView(d)}
            onChange={d => setAnchorDate(boundDate(d, min, max, step, true))}
            isSelectable={isSelectable}
            />
        )}
        {!!(currentView & VIEW_MONTH) && (
          <MonthList
            locale={locale}
            anchorDate={anchorDate}
            selectedDate={selectedDate}
            onView={d => setCurrentView(d)}
            onChange={d => setAnchorDate(boundDate(d, min, max, step, true))}
            isSelectable={isSelectable}
            />
        )}
        {!!(currentView & VIEW_DATE) && (
          <Calendar
            locale={locale}
            firstDay={0}
            selectedDate={selectedDate}
            anchorDate={anchorDate}
            isSelectable={isSelectable}
            onSelect={onSelect}
            />
        )}
      </span>
    </span>
  );
}

DatePickerControl.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  step: PropTypes.number,
  locale: PropTypes.string,
  onSelect: PropTypes.func,
};

