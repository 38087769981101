export function readSingleSeries (dataTable, dir = null) {
  // don't accept garbage input
  if (!dataTable || !Array.isArray(dataTable[0])) {
    return [];
  }
  const width = dataTable[0].length;
  const height = dataTable.length;
  // read the dataTable from the area as directed by dir
  if (dir === 'row') {
    return dataTable.map(r => r[0]);
  }
  else if (dir === 'col') {
    return dataTable[0];
  }
  // it's a single row or col
  else if (height > 1 && width === 1) {
    return dataTable.map(r => r[0]);
  }
  else if (height === 1 && width > 1) {
    return dataTable[0];
  }
  // read along edge in whichever is the longer dimension
  else if (width < height) {
    return dataTable[0];
  }
  return dataTable.map(r => r[0]);
}
