import React from 'react';
import PropTypes from 'prop-types';

import styles from './error.module.scss';

const defaultError = 'Something went wrong trying to render this section!';

export default function GridError (props) {
  if (props.inline) {
    return (
      <span
        className={styles.error}
        contentEditable={false}
        style={{
          color: '#900',
          textDecoration: 'underline',
          userSelect: 'none',
        }}
        >
        {props.message || defaultError}
      </span>
    );
  }
  return (
    <div
      className={styles.error}
      contentEditable={false}
      style={{
        color: '#900',
        border: '1px dotted red',
        padding: '4px 8px',
        borderRadius: '3px',
        userSelect: 'none',
      }}
      >
      💥 {props.message || defaultError} 💥
    </div>
  );
}

GridError.propTypes = {
  inline: PropTypes.bool,
  message: PropTypes.string,
};
