import React from 'react';
import csx from 'classnames';
import { range } from 'd3-array';
import PropTypes from 'prop-types';

import { prepLabels } from '../chart/utils';
import modelProp from '../modelProp';
import { disabled, elementVisibility, format, optInlineSize, optInlineType, optionLabels, options, targetCell, titleLabel, width } from '../propsData';
import { compareValues, printCell, validExpr } from '../utils';
import Wrapper from '../Wrapper';
import BaseInput from './common/BaseInput';
import InputHelper from './common/InputHelper';

import styles from './dropdown.module.scss';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  options: options,
  labels: optionLabels,
  format: format,
  inline: optInlineType,
  width: width,
  visible: elementVisibility,
  disabled: disabled,
  [optInlineSize.name]: optInlineSize,
};

export default function GridDropDown (props) {
  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }

  const formatStr = format.read(props);

  const table = options.read(props) || [ [] ];
  const w = table[0].length;
  const h = table.length;
  const dir = w < h ? 'col' : 'row';
  const optionList = range(dir === 'row' ? w : h).map(i => {
    const cell = (dir === 'row') ? table[0][i] : table[i][0];
    return (cell == null || cell instanceof Error) ? null : cell;
  });
  let labels = null;
  if (optionLabels.isSet(props)) {
    const labelCells = optionLabels.read(props);
    labels = prepLabels(labelCells, dir, formatStr, props.locale);
  }

  const onChange = e => {
    const index = +e.target.value;
    const option = optionList[index];
    props.track('interact', { elementType: 'dropdown' });
    targetCell.write(props, option ? option.v : null);
  };

  const onFocusBlur = e => {
    props.track(e.type, { elementType: 'dropdown' });
  };

  // try to find the current cell value among the options
  const valueCell = targetCell.read(props);
  const value = optionList.findIndex(d => {
    return compareValues(valueCell.v, d && d.v);
  });
  let isDisabled = false;
  let disabledMessage = '';

  if (!validExpr(props.expr)) {
    isDisabled = true;
    disabledMessage = 'Dropdown';
  }
  else if (!options.isSet(props) || !optionList.length) {
    isDisabled = true;
    disabledMessage = 'Options not selected';
  }
  else if (disabled.read(props)) {
    isDisabled = true;
  }

  const size = optInlineSize.read(props);
  return (
    <Wrapper
      {...props}
      className={csx(
        styles.dropdown,
        `size_${size || 'small'}`,
      )}
      label={titleLabel.read(props)}
      inlineMode={optInlineType.read(props)}
      inlineWidth={width.read(props)}
      isDisabled={isDisabled}
      >
      <BaseInput
        type="select"
        size={size}
        value={value}
        options={optionList.map((option, i) => ({
          index: i,
          value: i,
          title: labels
            ? labels[i] || ''
            : printCell(option, formatStr, props.locale),
        }))}
        onChange={onChange}
        onFocus={onFocusBlur}
        onBlur={onFocusBlur}
        disabled={isDisabled}
        error={disabledMessage}
        >
        <InputHelper
          type="select"
          size={size}
          disabled={isDisabled}
          />
      </BaseInput>
    </Wrapper>
  );
}

GridDropDown.options = elementOptions;

GridDropDown.propTypes = {
  parentKey: PropTypes.string,
  error: PropTypes.string,
  model: modelProp.isRequired,
  isEditor: PropTypes.bool,
  locale: PropTypes.string,
  expr: PropTypes.string,
  track: PropTypes.func,
};

GridDropDown.requiredOption = 'expr';
GridDropDown.isInput = true;
