export function layoutWidthClass (type, width) {
  if (type === 'grid:block' || type === 'row') {
    if (width === 'wide') {
      return 'lw_wide';
    }
    if (width === 'full') {
      return 'lw_full';
    }
  }
  return '';
}

