import { gridElementMap } from './elements';

const textLayoutBlocks = {
  h1: 1,
  h2: 1,
  h3: 1,
  h4: 1,
  h5: 1,
  h6: 1,
  p: 1,
  quote: 1,
};

/**
 * Emit a document margin layout class to be used by the document css
 * for ensuring correct line spacing of elements. See styles/document.scss.
 *
 * @param {string} name The name (or type) of the element
 * @param {string} [indent] Indentation amount of list elements
 * @return {string} global (unminified) className to be added to element
 */
export function elementClasses (name, indent) {
  if (name === 'row' || name === 'col' || name === 'hr') {
    return 'ws-' + name;
  }
  else if (name === 'ul' || name === 'ol') {
    return 'ws-text ws-' + name + ' ' + name + ' list indent-' + (indent || 0);
  }
  else if (name === 'code') {
    return 'grid-block ws-text ws-' + name;
  }
  else if (name in textLayoutBlocks) {
    return 'ws-text ws-' + name;
  }
  else if (name === 'grid:block') {
    return 'ws-block grid-block';
  }
  else if (name === 'chartAssistant') {
    return 'ws-block';
  }
  else if (name === 'slidebreak') {
    return 'ws-break';
  }
  else if (name !== 'grid:inline') {
    const elmData = gridElementMap.get(name);
    if (elmData && elmData.name === 'grid:block') {
      return 'ws-block ws-' + name;
    }
  }
  return 'grid-inline';
}
