import React, { useEffect, useMemo, useState } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { getMetadata } from '@/api/document';

import { parseHref } from '../utils/urlState';

import styles from './mention.module.scss';

const getDocURLTokens = url => {
  return url
    .replace(/([?#].*)/g, '')
    .split(/[ /-]/g)
    .filter(Boolean);
};

export default function GridMention (props) {
  const { isEditor, isSelected, id, attributes, value, children, isAuthenticated } = props;
  const [ title, setTitle ] = useState(/** @type {string|null} */(null));
  const [ isLoading, setIsLoading ] = useState(false);
  const type = props.type || 'user';
  const fetchMeta = props.getMetadata || getMetadata;

  const isDocMention = type === 'document';
  const isUserMention = type !== 'document'; // type === 'user' || type == null
  let text = '';

  useEffect(() => {
    if (isDocMention && title == null && !isLoading) {
      setIsLoading(true);
      fetchMeta(getDocURLTokens(value).pop())
        .then(d => {
          setTitle(d.title);
          setIsLoading(false);
        })
        .catch(() => {
          setTitle('Unable to access document');
          setIsLoading(false);
        });
    }
  }, [ isDocMention, isLoading, value, title, fetchMeta ]);

  if (isDocMention) {
    if (title) {
      text = title;
    }
    else {
      text = getDocURLTokens(value)
        .slice(1, -1)
        .join(' ');
    }
  }
  else {
    text = value || '@';
  }

  let href = value;
  if (isUserMention) {
    href = '/' + value;
  }

  /** @type {Record<string, any>}*/
  const linkProps = { ...useMemo(() => parseHref(href, isAuthenticated), [ href, isAuthenticated ]).props };
  if (isEditor) {
    linkProps.href = undefined;
  }
  linkProps.onClick = () => props.track('interact', { elementType: 'mention', targetUrl: href });

  return (
    <a
      className={csx(
        styles.mention,
        isDocMention && styles.document,
        isUserMention && styles.user,
        isSelected && styles.selected,
      )}
      {...linkProps}
      contentEditable={isEditor ? false : null}
      title={isEditor ? `You mentioned ${value}` : null}
      id={id}
      key={id}
      data-testid="mention"
      {...attributes}
      >
      {isDocMention && (
        <svg viewBox="0 0 36 36" height="16px">
          <path fill="currentColor" d="M31.432 13.9697C30.9201 17.9943 25.8456 17.9081 23.4546 17.4418C18.2072 16.4207 14.7769 13.5033 9.18164 10.4732C2.50941 6.86623 1.55876 20.6288 15.7852 25.5177C17.8384 26.1966 19.9965 26.5049 22.1583 26.4283C26.9203 26.2802 32.1589 21.9439 31.432 13.9697Z" />
          <path fill="currentColor" d="M21.609 27.6064C17.2125 27.6374 15.2026 27.5026 13.7844 28.6253C12.7384 29.4541 11.7412 30.8863 11.7412 33.9673C15.7499 33.9673 19.5548 31.8765 21.609 27.6064Z" />
          <path fill="currentColor" d="M31.098 12.1373C30.092 8.05735 27.4505 4.75539 25.5337 3.26796C23.8584 1.96618 21.7665 1.04013 20.6075 2.50988C19.6768 3.68568 20.0735 6.37101 21.0064 8.49938C21.8197 10.3493 22.89 13.1738 23.3088 16.1266C25.3675 16.5421 30.7124 16.8449 31.098 12.1373Z" />
          <path fill="currentColor" d="M31.4321 13.9695C30.9202 17.9942 25.8455 17.908 23.4545 17.4416C23.4722 17.7157 23.5032 23.188 22.167 26.4281C26.9203 26.28 32.1589 21.9437 31.4321 13.9695Z" />
        </svg>
      )}
      {isLoading ? (
        <span className={styles.loading}>
          <span className={styles.spacer}>{text}</span>
          <span className={styles.indicator}>...</span>
        </span>
      ) : (
        text
      )}
      {isEditor ? children : null}
    </a>
  );
}

GridMention.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  attributes: PropTypes.object,
  isEditor: PropTypes.bool,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  track: PropTypes.func,
  model: PropTypes.object,
  element: PropTypes.object,
  children: PropTypes.node,
  getMetadata: PropTypes.func,
};
