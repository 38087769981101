import React from 'react';
import Textbox from '@borgar/textbox';
import { scaleBand } from 'd3-scale';
import { format as formatValue } from 'numfmt';

import { printCell } from '@/grid/utils';

import { longestLabel } from '../axistools';
import { measureText } from '../measureText';
import { Axis } from './Axis';

/**
 * @typedef {[number, number]} NumPair
 */

/**
 * @typedef Scale
 * @property {(number) => number} scale
 * @property {() => number} bandwidth
 * @property {(d?: NumPair) => NumPair} domain
 * @property {(d?: NumPair) => NumPair} range
 * @property {(d?: number) => number[]} ticks
 */

export default class OrdinalAxis extends Axis {
  constructor (name, style) {
    super(name, style);

    const _scale = scaleBand()
      .paddingOuter(0.1)
      .paddingInner(0.1);
    /** @type {Scale} */
    this.scale = function (d) {
      return _scale(d) + _scale.bandwidth() * 0.5;
    };
    this.scale.bandwidth = () => {
      return _scale.bandwidth();
    };
    this.scale.domain = _ => {
      return _ ? _scale.domain(_) : _scale.domain();
    };
    this.scale.range = _ => {
      return _ ? _scale.range(_) : _scale.range();
    };
    this.scale.ticks = _ => {
      return _scale.ticks(_);
    };
  }

  get labelMaxWidth () {
    const ticks = this.getTicks();
    let width = 0;
    ticks.forEach(d => {
      if (d.lines) {
        if (d.lines.width > width) {
          width = d.lines.width;
        }
      }
      else {
        // FIXME: measure label
      }
    });
    return Math.ceil(width);
  }

  get labelMaxHeight () {
    const lines = this.getTicks().reduce((a, c) => {
      return Math.max(a, c.lines ? c.lines.length : 1);
    }, 0);
    const lineHeight = this.style.fontSize * this.style.lineHeight;
    return lineHeight * lines;
  }

  scaleByValue = v => {
    if (v == null) {
      return NaN;
    }
    if (this._labelData) {
      const n = this._labelData.findIndex(d => d && d.v === v);
      return this.scale(n);
    }
    return this.scale(v - 1);
  };

  domain (dom) {
    if (!dom) {
      return this.scale.domain();
    }
    this.scale.domain(
      this.reverse
        ? dom.reverse()
        : dom,
    );
  }

  bandwidth () {
    return this.scale.bandwidth();
  }

  getTicks () {
    const scale = this.scale;
    const padding = 10;

    // axis "size" (width or height)
    const space = this.size;
    let ticks = scale.domain();

    if (!ticks.length) {
      // if there are no ticks, we can skip the rest of this
      return [];
    }

    const minTick = ticks[0];
    const maxTick = ticks[ticks.length - 1];
    const reversed = (maxTick < minTick);

    const fmtStr = this.format || '';
    const locale = this.locale;
    const labelData = this._labelData;
    if (labelData) {
      ticks = ticks.slice(0, labelData.length);
    }
    // linear/categorical scale
    let maxLenLabel = 0;
    let nonBreaking = !!labelData && this.horizontal;
    ticks = ticks.map((d, i) => {
      const label = labelData
        ? printCell(labelData[d], fmtStr, locale)
        : formatValue(fmtStr, d + 1, { locale: locale, throws: false, nbsp: true });
      if (label.length > maxLenLabel) {
        maxLenLabel = label.length;
      }
      if (nonBreaking) {
        // characters Textbox considers breaking as of 1.2 (excepting comma)
        if (/[\s;\xAD%?…´±°¢£¤$¥\u2212]/.test(label)) {
          nonBreaking = false;
        }
      }
      return {
        index: i,
        value: d,
        minor: false,
        pos: scale(d),
        label: label,
      };
    });

    let minLabelWidth = labelData ? 40 : 25;
    if (nonBreaking) {
      const longest = ticks.find(d => d.label.length === maxLenLabel);
      minLabelWidth = longestLabel([ longest.label ], this.font);
    }

    if (this.horizontal) {
      if (labelData) {
        // is space tight?
        let xticks = ticks;
        let bandwidth = (space - (ticks.length * padding)) / ticks.length;

        if (bandwidth < minLabelWidth) {
          // ensure we are not removing single chars for no reason
          if (maxLenLabel > 1) {
            // pick some reasonable bandwidth
            const m = Math.floor(space / Math.ceil(minLabelWidth + padding));
            const step = Math.ceil(ticks.length / m);
            xticks = [];
            for (let i = 0; i < ticks.length; i += step) {
              xticks.push(ticks[i]);
            }
            bandwidth = (space - (xticks.length * padding)) / (xticks.length);
          }
        }

        const box = new Textbox({
          width: bandwidth,
          align: 'center',
          height: this.style.fontSize * this.style.lineHeight * 3,
          font: this.font,
          createElement: React.createElement,
          parser: 'text',
          overflowLine: '.',
          overflow: '.',
        });

        xticks.forEach(tick => {
          tick.lines = box.linebreak(tick.label);
          if (tick.lines.length > 1) {
            this.lines = 2;
          }
        });

        return xticks;
      }

      // generated sequences should provide "nice labels"
      else {
        // sample beginning, end, and middle lengths
        const l = ticks.length;
        const widest = longestLabel([
          ticks[0].label,
          ticks[~~(l / 2)].label,
          ticks[l - 1].label,
        ], this.font);

        if (widest + 5 > space / l) {
          // can fit about this many labels
          const nlabels = Math.floor(space / Math.ceil(widest + 8.5));
          const n = Math.ceil(l / nlabels);
          let step = n;

          // allowed tick number intervals
          //   1, 2, 5,
          //   10, 20, 25, 50,
          //   100, 150, 200, 250, 300, 400, 500,
          //   1000, 1500, 2000, 2500, 3000, 4000, 5000
          //   ....
          const e = Math.floor(Math.log(n) / Math.LN10);
          if (e >= 2) {
            const pow = 10 ** e;
            let m = n / pow;
            if (m <= 1) {
              m = 1;
            }
            else if (m <= 1.5) {
              m = 1.5;
            }
            else if (m <= 2) {
              m = 2;
            }
            else if (m <= 2.5) {
              m = 2.5;
            }
            else if (m <= 3) {
              m = 3;
            }
            else if (m <= 4) {
              m = 4;
            }
            else if (m <= 5) {
              m = 5;
            }
            else {
              m = 10;
            }
            step = pow * m;
          }
          else if (n <= 1) {
            step = 1;
          }
          else if (n <= 2) {
            step = 2;
          }
          else if (n <= 5) {
            step = 5;
          }
          else if (n <= 10) {
            step = 10;
          }
          else if (n <= 20) {
            step = 20;
          }
          else if (n <= 25) {
            step = 25;
          }
          else if (n <= 50) {
            step = 50;
          }
          else if (n <= 100) {
            step = 100;
          }

          const dom2 = reversed ? [] : [ ticks[0] ]; // include 1 if we can
          for (let i = 0; i < l + 1; i += step) {
            // sequence labels are 1 based, but arrays are 0 based
            const nth = i - 1;
            if (ticks[nth]) {
              dom2.push(
                reversed
                  ? ticks[(l - 1) - nth]
                  : ticks[nth],
              );
            }
          }

          // include 1 if we can
          if (reversed) {
            if (dom2.length > 1 && step > 2) {
              dom2.push(ticks[ticks.length - 1]);
            }
          }

          ticks = dom2;
        }
      }
    }
    // vertical
    else {
      const font = this.font;
      const widest = ticks.reduce((a, c) => {
        // +3 to give text a bit of wiggle room
        // measuring parts may not yield exactly the same value
        // as measuring a whole string (because kerning)
        return Math.max(measureText(c.label, font) + 3, a);
      }, 0);
      const w = Math.min(widest, this.maxWidth || 0);
      const box = new Textbox({
        width: w,
        height: Math.max(20, Math.round(space / (ticks.length || 1)) - 10),
        align: 'right',
        x: -w,
        font: font,
        createElement: React.createElement,
        parser: 'text',
        overflowLine: '.',
        overflow: '.',
      });
      ticks.forEach(tick => {
        tick.lines = box.linebreak(tick.label);
      });
    }

    return ticks;
  }

  get labels () {
    // if this is a "sequence" (unlabeled), emit formatted labels
    if (this._labelData) {
      const fmtStr = this.format || null; // do null and "" mean different things?
      const locale = this.locale || 'en-US';
      return this._labelData.map(d => printCell(d, fmtStr, locale));
    }
    return null;
  }
}
