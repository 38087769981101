import React, { useContext } from 'react';
import csx from 'classnames';
import { format as formatValue, isPercentFormat } from 'numfmt';
import PropTypes from 'prop-types';

import Label from '../Label';
import modelProp from '../modelProp';
import {
  changeCalcType,
  changeColors,
  elementVisibility,
  expKpi,
  exprPrev,
  format,
  formatChange,
  optInlineLegacy,
  showMovementIcon,
  titleLabel,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { printCell } from '../utils';
import Wrapper from '../Wrapper';

import styles from './kpi.module.scss';

const elementOptions = {
  expr: expKpi,
  exprPrev: exprPrev,
  changeCalcType: changeCalcType,
  title: titleLabel,
  format: format,
  formatChange: formatChange,
  inline: optInlineLegacy,
  changeColors: changeColors,
  visible: elementVisibility,
  showMovementIcon,
};

const ERROR_TEXT = '#VALUE!';

const changeDataList = [ {
  name: 'decrease',
  glyph: '\u2193',
}, {
  name: 'neutral',
  glyph: '\u25c6',
}, {
  name: 'increase',
  glyph: '\u2191',
} ];

/* eslint-disable-next-line */
function MovementIcon ({ sign = 0 }) {
  return (
    <svg width={sign ? 14 : 10} height={sign ? 12 : 10} role="img">
      <g fill="currentColor">
        {(sign > 0) ? <path d="M5.904.502a1.005 1.005 0 0 1 1.74 0l5.768 9.99a1.005 1.005 0 0 1-.87 1.508H1.006a1.005 1.005 0 0 1-.87-1.507L5.904.503Z" /> : null}
        {(sign < 0) ? <path d="M7.644 11.498c-.386.67-1.353.67-1.74 0L.136 1.508A1.005 1.005 0 0 1 1.006 0h11.536c.774 0 1.257.837.87 1.507l-5.768 9.99Z" /> : null}
        {!sign ? <path d="M4.293.707a1 1 0 0 1 1.414 0l3.586 3.586a1 1 0 0 1 0 1.414L5.707 9.293a1 1 0 0 1-1.414 0L.707 5.707a1 1 0 0 1 0-1.414L4.293.707Z" /> : null}
      </g>
    </svg>
  );
}

export default function GridKPI (props) {
  const { theme } = useContext(ThemesContext);

  if (!props.isEditor && !elementVisibility.read(props)) {
    return null;
  }
  if (!expKpi.isSet(props)) {
    return (
      <Wrapper className={styles.text} {...props}>
        <span>KPI</span>
      </Wrapper>
    );
  }
  const palette = changeColors.read(props, {
    minColors: 3,
    autoExtend: false,
    default: [ '#208428', '#DD3314', '#969696' ],
  }).map(theme.resolveColor);

  const cell = expKpi.readCell(props);
  const title = titleLabel.read(props);
  const fmtStr = format.read(props);
  const isInline = optInlineLegacy.read(props);
  const shouldShowMovementIcon = showMovementIcon.read(props);

  const valueIsNumber = isFinite(cell.v) && typeof cell.v === 'number';
  const currentValue = valueIsNumber ? cell.v : 0;

  const haveChange = exprPrev.isSet(props);
  /** @type {number|string} */
  let changeValue = 0;
  let color = palette[2];
  let changeFmtStr = null;
  let changeSign = 0;

  if (haveChange) {
    const lastValCell = exprPrev.readCell(props);
    const lastValue = lastValCell.v;
    const lastValueIsNumber = isFinite(lastValue) && lastValue != null;
    const calcType = changeCalcType.read(props);

    if (calcType === 'percent') {
      // if value is not a number, don't try to calculate anything
      changeValue = (valueIsNumber && lastValueIsNumber)
        ? (currentValue - lastValue) / lastValue
        : ERROR_TEXT;
      // if detected format is already a percent format we use that, else a percent string
      const tmpFmt = fmtStr || lastValCell?.z || '';
      changeFmtStr = isPercentFormat(tmpFmt) ? tmpFmt : '0.0#%';
    }
    else if (calcType === 'delta') {
      changeValue = (valueIsNumber && lastValueIsNumber)
        ? currentValue - lastValue
        : ERROR_TEXT;
      changeFmtStr = fmtStr || cell.z;
    }
    else { // calcType === 'none'
      changeValue = lastValue;
      changeFmtStr = fmtStr || lastValCell?.z || '';
    }

    if (formatChange.isSet(props)) {
      changeFmtStr = formatChange.read(props);
    }

    if (typeof changeValue === 'number') {
      changeSign = Math.sign(changeValue);
      if (changeValue > 0) {
        color = palette[0];
      }
      else if (changeValue < 0) {
        color = palette[1];
      }
    }
  }

  const changeData = typeof changeValue === 'number' && !isNaN(changeValue)
    ? changeDataList[1 + Math.sign(changeValue)]
    : changeDataList[2];

  const valueStr = valueIsNumber
    ? printCell(cell, fmtStr, props.locale)
    : ERROR_TEXT;

  const changeStr = haveChange
    ? formatValue(changeFmtStr || '', changeValue, { locale: props.locale, nbsp: true })
    : null;

  return (
    <Wrapper
      className={csx(styles.kpi, isInline && styles.inlineflow, isInline ? styles.kpiFlow : styles.kpiBlock)}
      {...props}
      >
      <Label
        text={title}
        inline={isInline}
        />
      <span className={styles.kpiWrap} style={{ color: color }}>
        <span className={styles.value}>
          {valueStr}
        </span>
        {haveChange && (
          <span className={styles.changeWrap}> {}
            {shouldShowMovementIcon && (
              <span className={styles.icon}>
                <span className={styles.glyph}>{changeData.glyph}</span> {}
                <MovementIcon sign={changeSign} />
              </span>
            )}
            <span className={styles.change}>
              {changeStr}
            </span>
          </span>
        )}
      </span>
    </Wrapper>
  );
}

GridKPI.options = elementOptions;
GridKPI.requiredOption = 'expr';
GridKPI.propTypes = {
  fontSize: PropTypes.string,
  isEditor: PropTypes.bool,
  parentKey: PropTypes.string,
  error: PropTypes.string,
  model: modelProp.isRequired,
  locale: PropTypes.string,
};
