/* eslint-disable react/prop-types */
import React from 'react';
import csx from 'classnames';

import styles from './Slider.module.scss';

/**
 * @param {object} props
 * @param {number} props.max
 * @param {number} props.min
 * @param {number} props.step
 * @returns {null | React.ReactElement}
 */
export function SliderTicks ({ max, min, step }) {
  const range = (max - min);
  const ticks = [];
  const numJumps = (range / step);
  if (numJumps < 21) {
    for (let i = 0; i <= numJumps; i++) {
      const p = i / numJumps;
      ticks.push({
        value: min + (p * (max - min)),
        p: p,
      });
    }
  }
  else if (min < 0 && max > 0) {
    // show 0 if it is present and not at either extremity
    ticks.push({
      value: 0,
      p: (0 - min) / range,
    });
  }
  if (ticks.length) {
    return (
      <>
        {ticks.map(tick => (
          <span
            key={'t-' + tick.value}
            className={csx(
              styles.tick,
              !tick.value && styles.tickZero,
              (tick.value === min || tick.value === max) && styles.tickEdge,
            )}
            style={{ left: (100 * tick.p) + '%' }}
            />
        ))}
      </>
    );
  }
  return null;
}
