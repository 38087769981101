import React from 'react';
import csx from 'classnames';
import { dateFromSerial, dateToSerial } from 'numfmt';
import PropTypes from 'prop-types';

import { VIEW_MONTH } from './constants.js';

import styles from './YearList.module.scss';

const perRow = 3;

export default function YearList ({ anchorDate, selectedDate, onChange, onView, isSelectable }) {
  const [ y ] = dateFromSerial(anchorDate);
  const [ currentYear ] = dateFromSerial(selectedDate);
  const decade = Math.floor(y / 10) * 10;

  const setYear = e => {
    e.preventDefault();
    const dt = dateFromSerial(anchorDate);
    dt[0] = +e.target.dataset.year;
    onChange(dateToSerial(dt));
    onView(VIEW_MONTH);
  };

  const rows = [ ...Array(12) ].reduce((/** @type {number[][]} */a, _, i) => {
    const r = Math.trunc(i / perRow);
    a[r] = a[r] ? [ ...a[r], i + decade ] : [ i + decade ];
    return a;
  }, []);

  return (
    <table className={styles.years}>
      <tbody>
        {rows.map((r, i) => (
          <tr key={i}>
            {r.map(y => {
              const disabled = !isSelectable(dateToSerial([ y, 1, 1 ]), 'y');
              const selected = y === currentYear;
              return (
                <td key={y}>
                  <span
                    role="button"
                    className={csx(
                      styles.button,
                      disabled && styles.disabled,
                      selected && styles.selected,
                    )}
                    onClick={disabled ? undefined : setYear}
                    aria-disabled={disabled}
                    data-year={y}
                    >
                    {y}
                  </span>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

YearList.propTypes = {
  anchorDate: PropTypes.number,
  selectedDate: PropTypes.number,
  onChange: PropTypes.func,
  onView: PropTypes.func,
  isSelectable: PropTypes.func,
};

YearList.defaultProps = {
  onChange: () => {},
  onView: () => {},
};
