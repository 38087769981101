const _dimensionsCache = {};

const imageMetaCache = {
  setMeta (id, img) {
    const naturalWidth = img.naturalWidth;
    const naturalHeight = img.naturalHeight;
    if (naturalWidth) {
      _dimensionsCache[id] = [ naturalWidth, naturalHeight ];
    }
  },

  getMeta (id) {
    return _dimensionsCache[id] || null;
  },

  removeMeta (id) {
    delete _dimensionsCache[id];
  },
};

export default imageMetaCache;
