import React from 'react';

import { printObject as printModelState, toObject as modelStateToObject } from '@/utils/modelState';

import {
  actionButtonTargetOrigin,
  actionButtonType,
  actionButtonUrlPrefix,
  commonButtonUI,
  disabled,
  linkUrl,
  optVisibilityNoPrint,
  titleLabel,
} from '../propsData';
import { parseHref } from '../utils/urlState';
import BaseButton from './common/BaseButton';

const elementOptions = {
  title: titleLabel,
  visible: optVisibilityNoPrint,
  buttonType: actionButtonType,
  targetOrigin: actionButtonTargetOrigin,
  urlPrefix: actionButtonUrlPrefix,
  href: linkUrl,
  disabled: disabled,
  ...commonButtonUI,
};

const titles = {
  '': 'Select action',
  'present': 'Present',
  'copyurl': 'Copy URL',
  'pdf': 'Save to PDF',
  'openurl': 'Open URL',
};

const trackEventId = {
  present: 'presentbutton',
  copyurl: 'copyurlbutton',
  pdf: 'pdfbutton',
  openurl: 'linkbutton',
};

/**
 * @param {string} buttonType
 * @param {import('@/utils/modelState').ModelStateArray} modelState
 * @param {string} [urlPrefix]
 * @param {string} [canonicalUrl]
 * @param {() => number} [timeProvider]
 */
function makeData (buttonType, modelState, urlPrefix, canonicalUrl, timeProvider = Date.now) {
  const state = modelStateToObject(modelState);
  const printedModelState = modelState.length ? printModelState(state) : '';

  let url = urlPrefix || canonicalUrl;
  if (printedModelState && buttonType !== 'openurl') {
    url = `${url}?s=${printedModelState}`;
  }

  return {
    gridMessage: timeProvider(),
    s: printedModelState,
    state,
    url,
    urlPrefix,
    type: buttonType,
  };
}

/**
 * @typedef ActionButtonProps
 * @prop {boolean} [isEditor]
 * @prop {boolean} [isAuthenticated]
 * @prop {Model} model
 * @prop {string} [canonicalUrl]
 * @prop {(eventName: string, data: object) => void} track
 * @prop {() => number} [timeProvider]
 */

/**
 * @param {ActionButtonProps} props
 * @return {React.ReactElement | null}
 */
export default function GridActionButton (props) {
  if (!props.isEditor && !optVisibilityNoPrint.read(props)) {
    return null;
  }
  const buttonType = actionButtonType.read(props);
  const missingHref = (buttonType === 'openurl' && !linkUrl.isSet(props));
  let title = titleLabel.read(props);
  if (!title) {
    title = missingHref ? 'Add a link URL' : titles[buttonType];
  }
  const isDisabled = buttonType === '' || disabled.read(props) || missingHref;
  return (
    <BaseButton
      {...props}
      label={title}
      disabled={isDisabled}
      onClick={() => {
        if (props.isEditor || isDisabled) {
          return;
        }
        if (buttonType === 'openurl') {
          const link = parseHref(linkUrl.read(props), props.isAuthenticated);
          props.track('interact', { elementType: 'linkbutton', targetUrl: link.url });
          window.open(link.props.href, link.props.target);
        }
        else {
          if (trackEventId[buttonType]) {
            props.track('interact', { elementType: trackEventId[buttonType] });
          }
          const targetOrigin = actionButtonTargetOrigin.read(props);
          const urlPrefix = actionButtonUrlPrefix.read(props);
          const modelState = props.model.writes();
          const data = makeData(buttonType, modelState, urlPrefix, props.canonicalUrl, props.timeProvider);
          window.postMessage(data, window.location.origin);
          if (window.self !== window.top) {
            window.parent.postMessage(data, targetOrigin);
          }
        }
      }}
      />
  );
}

GridActionButton.options = elementOptions;
/** @type {string | null} */
GridActionButton.requiredOption = null;
