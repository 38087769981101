import React from 'react';
import csx from 'classnames';
import { format as formatValue } from 'numfmt';
import PropTypes from 'prop-types';

import { getAutoStep } from '@/grid/utils/getAutoStep';
import stepNormalize from '@/grid/utils/stepNormalize';

import styles from './TableTangle.module.scss';

const preventDefault = e => e.preventDefault();

export default class TableTangle extends React.PureComponent {
  static propTypes = {
    cell: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    format: PropTypes.string,
    locale: PropTypes.string,
    onInput: PropTypes.func,
    track: PropTypes.func,
  };

  constructor (props) {
    super(props);

    this.state = { interacting: false };
    this.spanRef = React.createRef();
  }

  componentDidMount () {
    const elm = this.spanRef.current;
    if (elm) {
      elm.addEventListener('selectstart', preventDefault);
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1166044
      elm.addEventListener('dragstart', preventDefault);
      elm.addEventListener('touchstart', preventDefault, { passive: false });
    }
  }

  componentWillUnmount () {
    const elm = this.spanRef.current;
    if (elm) {
      elm.removeEventListener('selectstart', preventDefault);
      elm.removeEventListener('dragstart', preventDefault);
      elm.removeEventListener('touchstart', preventDefault, { passive: false });
    }
  }

  onInteractStart = event => {
    const { cell } = this.props;
    this.setState({
      interacting: true,
      startX: event.clientX,
      value: cell?.v || 0,
    });
    event.target.setPointerCapture(event.pointerId);
    if (this.props.track) {
      this.props.track('interact', { elementType: 'table.tangle' });
    }
  };

  onInteractEnd = event => {
    this.setState({ interacting: false });
    event.target.releasePointerCapture(event.pointerId);
  };

  onDragMovement = event => {
    if (this.state.interacting) {
      const { min, max, step, cell } = this.props;
      const { startX } = this.state;
      const numberFormat = this.props.format || cell.z;

      // anchorValue can be null if the initial cell was blank.
      // So we use the first non-zero value that we encounter.
      if (!this.anchorValue && this.state.value) {
        this.anchorValue = this.state.value;
      }
      const moveSize = (event.shiftKey && !step)
        ? 1
        : step || getAutoStep(this.anchorValue, numberFormat, min, max);
      const distance = event.clientX - startX;
      const delta = 6; // Drag distance between steps.
      const power = 1.2;
      let stepValue = Math.abs(distance) ** power * Math.sign(distance) * moveSize / delta;
      stepValue = Math.floor(stepValue / moveSize) * moveSize;

      let value = this.state.value + stepValue;
      value = stepNormalize(value, min, max, step);
      if (cell.v !== value) {
        this.onInput(value);
      }
    }
  };

  onInput = value => {
    const { cell, onInput } = this.props;
    if (onInput && cell) {
      onInput(cell.id, value);
    }
  };

  render () {
    const { cell, format, locale, min, max } = this.props;
    const formatOpts = { throws: false, locale: locale };

    let printedValue = '';
    let valueNow;
    let isValid = false;
    if (cell && typeof cell.v === 'number') {
      valueNow = cell.v;
      printedValue = formatValue(format || cell.z, cell.v, formatOpts);
      isValid = true;
    }
    else if (cell && 'f' in cell && cell.v == null) {
      // A target cell's been selected but it has no value.
      printedValue = formatValue(format || cell.z, 0, formatOpts);
      isValid = true;
    }
    else {
      printedValue = formatValue(format || cell.z, cell.v ?? '', formatOpts);
    }

    const blockEvents = !isValid;
    return (
      <span
        className={csx(styles.tangle, !isValid && styles.invalid)}
        role="slider"
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={blockEvents ? 'unset' : valueNow}
        aria-disabled={blockEvents ? 'true' : undefined}
        onPointerDown={blockEvents ? undefined : this.onInteractStart}
        onPointerUp={blockEvents ? undefined : this.onInteractEnd}
        onPointerMove={blockEvents ? undefined : this.onDragMovement}
        ref={this.spanRef}
        >
        {printedValue || '\u00a0'}
      </span>
    );
  }
}
