import React, { Fragment } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import styles from './HoverInfo.module.scss';

export default function HoverInfo (props) {
  const dims = props.dimspec ? Object.keys(props.dimspec) : [];
  if (props.simple && dims.length === 1) {
    const val = props.dimspec[dims[0]];
    return (
      <div className={csx(styles.simpleHoverInfo, !(props.title && props.subtitle) && styles.valOnly)}>
        {props.title && <span className={csx(styles.title, !props.subtitle && styles.titleSolo)}>{props.title}</span>}
        {props.subtitle && <span className={styles.subtitle}>{props.subtitle}</span>}
        <span className={styles.val}>{val}</span>
        {props.children && <div className={styles.children}>{props.children}</div>}
      </div>
    );
  }
  return (
    <div className={styles.hoverInfo}>
      <div className={csx(styles.titles, dims.length > 1 && styles.titlesSpaced)}>
        {props.title && <div className={styles.title}>{props.title}</div>}
        {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
        {props.swatch && <div className={csx(styles.swatch, props.subtitle && styles.subtitleSwatch)} style={{ backgroundColor: props.swatch }} />}
      </div>
      <div className={styles.dims}>
        {dims.map(key => {
          const val = props.dimspec[key];
          if (val !== 0 && !val) {
            return null;
          }
          return (
            <Fragment key={key}>
              <span className={styles.key}>{key}</span>
              <span className={styles.val}>{val}</span>
            </Fragment>
          );
        })}
      </div>
      {props.children && <div className={styles.children}>{props.children}</div>}
    </div>
  );
}

HoverInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  swatch: PropTypes.string,
  simple: PropTypes.bool,
  dimspec: PropTypes.object,
  children: PropTypes.node,
};
