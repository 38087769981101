import { extent, range } from 'd3-array';

import { DEFAULT_CHART_FONT } from '@/grid/ChartTheme';
import { axisClip, axisDisabled, axisFormat, axisMax, axisMin, axisReverse, axisTitle, axisType } from '@/grid/propsData';
import { lowerCase } from '@/grid/utils';

import { hasNumVal, hasVal } from './';
import { Axis } from './axes/Axis';
import OrdinalAxis from './axes/OrdinalAxis';
import ValueAxis from './axes/ValueAxis';
import ValueLogAxis from './axes/ValueLogAxis';
import { axisFormatString } from './axistools';

const logNames = { log: 1, logarithmic: 1 };

/**
 * @typedef {object} Cellish
 * @property {any} [v]
 */

/**
 * @param {object[][]} data The data
 * @return {object[]}
 */
function unRollLabels (data) {
  // don't accept garbage input
  if (data && Array.isArray(data[0])) {
    if (data.length === 1 && data[0].length === 0) {
      return [];
    }
    // single row or col get automatically transposed
    if (data.length > 1 && data[0].length === 1) {
      return data.map(r => r[0]);
    }
    else if (data.length === 1 && data[0].length > 1) {
      return data[0];
    }
    else {
      return data.map(r => r[r.length - 1]);
    }
  }
  return [];
}

export { Axis };

/**
 * Set up data for a DataAxis based on option properties.
 *
 * @param {object} opts
 * @param {Record<string, any>} opts.props React option properties
 * @param {import('@/grid/types').AxisPositions} opts.orient The position of the axis
 * @param {string} opts.locale The locale to use when formatting values
 * @param {number} opts.length The number of categories to include on the axis
 * @param {object[]} [opts.domain] A list of categories to make up the axis domain
 * @param {object[]} [opts.labels=[]] A list of labels for the axis categories
 * @param {string} [opts.axisName='axisDim'] The name of the axis
 * @param {string} [opts.defaultTitle=''] A default title of the axis if props does not have one
 * @return {OrdinalAxis} The ordinal axis.
 */
export function prepAxisData ({
  props,
  orient,
  locale,
  domain,
  length,
  labels = [],
  axisName = 'axisDim',
  defaultTitle = '',
}) {
  const axisProps = Object.assign({ locale: props.locale, model: props.model }, props[axisName]);
  const labelList = unRollLabels(labels).slice(0, length);
  const style = props?.chartTheme?.dataAxis ?? {};

  const axis = new OrdinalAxis(axisName, style);
  axis.disabled = axisDisabled.read(axisProps);
  axis.reverse = axisReverse.read(axisProps);
  axis.title = axisTitle.read(axisProps) || defaultTitle;
  axis.style.fontFamily = style.fontStack || DEFAULT_CHART_FONT;

  axis.domain(domain || range(length));
  if (labels && labelList.length) {
    axis.labelData(labelList);
  }
  // determine a format for this axis
  const format = axisFormat.read(axisProps);
  if (format) {
    axis.format = format;
  }

  axis.orient = orient;
  axis.locale = locale;

  return axis;
}

/**
 * Set up data for a ValueAxis (or ValueLogAxis) based on option properties.
 *
 * @param {object} opts
 * @param {Record<string, any>} opts.props React option properties
 * @param {import('@/grid/types').AxisPositions} opts.orient The position of the axis
 * @param {string} opts.locale The locale to use when formatting values
 * @param {object[]} [opts.data=[]] The data values for the axis
 * @param {boolean} [opts.needZero=false] Does the axis need a zero? (Bar chart)
 * @param {string} [opts.axisName='axisValue'] The name of the axis
 * @param {string} [opts.defaultTitle=''] A default title of the axis if props does not have one
 * @return {ValueAxis | ValueLogAxis}
 */
export function prepAxisValue ({
  props,
  orient,
  locale,
  data = [],
  needZero = false,
  axisName = 'axisValue',
  defaultTitle = '',
}) {
  const axisProps = Object.assign({ locale: props.locale, model: props.model }, props[axisName]);

  const type = lowerCase(axisType.read(axisProps));
  const clip = lowerCase(axisClip.read(axisProps));
  const axis = logNames[type] === 1
    ? new ValueLogAxis(axisName)
    : new ValueAxis(axisName);

  axis.disabled = axisDisabled.read(axisProps);
  axis.reverse = axisReverse.read(axisProps);
  axis.title = axisTitle.read(axisProps) || defaultTitle;

  const clipMin = clip === true || clip === 'true' || clip === 'min';
  const clipMax = clip === true || clip === 'true' || clip === 'max';

  // determine the domain extent
  const dom = extent(data, d => ((hasVal(d) && isFinite(d.v)) ? d.v : null));
  const max = axisMax.read(axisProps);
  const min = axisMin.read(axisProps);
  if (!clipMin && min != null && isFinite(min) && min < dom[0]) {
    dom.push(min);
  }
  if (!clipMax && max != null && isFinite(max) && max > dom[1]) {
    dom.push(max);
  }
  const ext = extent(dom);
  if (clipMin || clipMax) {
    axis.clipMin = clipMin ? min : null;
    axis.clipMax = clipMax ? max : null;
  }
  axis.domain(ext, needZero);

  // determine a format for this axis
  let format = axisFormat.read(axisProps);
  if (!format) {
    // failed attempt: first cell (can be blank)
    // failed attempt: find the first cell that has a z prop (can skip numbers and return a date)
    // find the first cell that has a non-numberic value, use its z prop
    const zCell = data.find(hasNumVal);
    format = (zCell && zCell.z) || axisFormatString(axis.scale);
  }
  axis.format = format;
  axis.orient = orient;
  axis.locale = locale;
  return axis;
}
