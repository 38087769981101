import React from 'react';
import csx from 'classnames';
import { dateFromSerial, dateToSerial, format } from 'numfmt';
import PropTypes from 'prop-types';

import { formatOptions, VIEW_DATE } from './constants.js';

import styles from './MonthList.module.scss';

const months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
const perRow = 4;

export default function MonthList ({ anchorDate, selectedDate, locale, onChange, onView, isSelectable }) {
  const opts = { locale, ...formatOptions };
  const [ y ] = dateFromSerial(anchorDate);
  const [ selYear, selMonth ] = dateFromSerial(selectedDate);

  const setMonth = e => {
    const dt = dateFromSerial(anchorDate);
    dt[1] = +e.target.dataset.mon;
    onChange(dateToSerial(dt));
    onView(VIEW_DATE);
  };

  const rows = months.reduce((/** @type {number[][]} */a, c, i) => {
    const r = Math.trunc(i / perRow);
    a[r] = a[r] ? [ ...a[r], c ] : [ c ];
    return a;
  }, []);

  return (
    <table className={styles.months}>
      <tbody>
        {rows.map((r, i) => (
          <tr key={i}>
            {r.map(m => {
              const date = dateToSerial([ y, m, 1 ]);
              const disabled = !isSelectable(date, 'm');
              const selected = selYear === y && selMonth === m;
              return (
                <td key={m}>
                  <span
                    className={csx(
                      styles.button,
                      disabled && styles.disabled,
                      selected && styles.selected,
                    )}
                    role="button"
                    aria-disabled={disabled}
                    onClick={disabled ? undefined : setMonth}
                    data-mon={m}
                    >
                    {format('mmm', date, opts)}
                  </span>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

MonthList.propTypes = {
  anchorDate: PropTypes.number.isRequired,
  isSelectable: PropTypes.func.isRequired,
  selectedDate: PropTypes.number,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  onView: PropTypes.func,
};

MonthList.defaultProps = {
  locale: 'us-EN',
  onChange: () => {},
  onView: () => {},
};
