// @ts-nocheck
import { b64decode } from '@grid-is/base64';
import { uuidFromBytes } from '@grid-is/uuid';

import { parseFromQuery } from '@/utils/modelState';

const re_gridDoc = /^\/@[a-z\d_.]+\/[A-z\d\-:]+/i;
const re_embedDoc = /^\/embed\/[A-z\d\-:]+/i;

function getLocation () {
  if (typeof location === 'undefined') {
    return {
      toString: () => 'https://grid.is',
      hostname: 'grid.is',
    };
  }
  return location;
}

export function parseHref (realHref, isAuthUser = false) {
  const _location = getLocation();
  let url = new URL(_location);
  // save location UTM params for later
  const locationSearch = new URLSearchParams(url.search);
  const locationPath = url.pathname;
  // parse the URL
  try {
    if (realHref.startsWith('?')) {
      url = new URL(_location);
      url.search = realHref;
    }
    else {
      url = new URL(realHref, _location);
    }
  }
  catch (err) {
    // invalid URL
  }
  // including localhost here because we default to document.location which is localhost in dev env.
  const isLocalhost = url.hostname === 'localhost' && _location.hostname === 'localhost';
  const isGrid = isLocalhost || /^((?:in)?grid\.is|calculatorstudio\.co(?:\/|$))/.test(url.hostname);

  // when the link is within our domains we clean out and UTM params
  // but add any UTM parameters from location...
  if (isGrid && !isAuthUser) {
    const prevSearch = new URLSearchParams(url.search);
    const newSearch = new URLSearchParams();
    for (const [ name, value ] of prevSearch) {
      if (!name.startsWith('utm_')) {
        newSearch.set(name, value);
      }
    }
    for (const [ name, value ] of locationSearch) {
      if (name.startsWith('utm_') && name !== 'utm_seq' && name !== 'utm_firstpage') {
        newSearch.set(name, value);
      }
    }
    // ensure that we have at minimum a utm_seq and utm_firstpage parameters
    const seq = parseInt(locationSearch.get('utm_seq') || '0', 10);
    newSearch.set('utm_seq', seq + 1);
    // XXX: it seems strange to add utm_firstpage if seq is higher than > 1
    newSearch.set('utm_firstpage', locationSearch.get('utm_firstpage') || locationPath);
    url.search = String(newSearch);
  }

  const isDoc = isGrid && re_gridDoc.test(url.pathname);
  const isEmbed = isGrid && re_embedDoc.test(url.pathname);
  let docId = '';
  if (isDoc || isEmbed) {
    try {
      const rawId = url.pathname.split('/')[2].split('-').pop();
      docId = uuidFromBytes(b64decode(rawId, ':_', ''));
    }
    catch (err) {
      // path or id is malformed
    }
  }

  const href = String(url);
  return {
    isGrid,
    isDoc: isGrid && re_gridDoc.test(url.pathname),
    isEmbed: isGrid && re_embedDoc.test(url.pathname),
    docId,
    url: href,
    props: {
      // these should be safe to spread to an A element
      href: href,
      target: isGrid ? '_self' : '_blank',
      // "noopener noreferrer" is to prevent tabnabbing security vulnerability
      //   -> https://app.clickup.com/t/2559602/CLIENT-521
      // "nofollow" has been [temporarily?] added to discourage link-spam
      // documents and prevent domain reputation damage from them
      //   -> https://app.clickup.com/t/2559602/ENGINE-387
      rel: 'noopener noreferrer nofollow',
    },
  };
}

export function applyStateFromURL (model, href) {
  const url = new URL(href);
  const linkState = url.searchParams.has('s')
    ? parseFromQuery({ s: url.searchParams.get('s') })
    : parseFromQuery(url.search);
  if (linkState) {
    // reset, apply any state found on the link, and recalculate all
    model.writeMultiple(linkState, { forceRecalc: true, reset: true });
  }
  else {
    // always reset, even if no link state
    model.reset();
  }
}
