/* globals process */
import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_CHART_FONT } from '@/grid/ChartTheme';

import { LegendData, prepLegend } from './prepLegend';

const fontSize = 14;
const isDev = process.env.NODE_ENV !== 'production';

const renderSymbol = (type, color, lineHeight) => {
  if (type === 'square') {
    return (
      <rect
        fill={color}
        width={lineHeight * 0.5}
        height={lineHeight * 0.5}
        y={lineHeight * 0.25}
        />
    );
  }
  else if (type === 'line') {
    return (
      <rect
        fill={color}
        width={lineHeight * 0.5}
        height={lineHeight * 0.125}
        x={lineHeight * 0}
        y={lineHeight * 0.4375}
        />
    );
  }
  else { // type === 'circle'
    return (
      <circle
        fill={color}
        r={lineHeight * 0.3}
        cx={lineHeight * 0.25}
        cy={lineHeight * 0.5}
        />
    );
  }
};

export default function ChartLegend (props) {
  const labels = props.data;
  const k = 'grid:legend:';

  if (!labels) {
    return null;
  }

  const lineHeight = labels.lineHeight;
  const linePad = labels.columnGutter;
  const columns = labels.columns;
  const style = labels.style ?? {};

  const x = props.x + Math.max(0, ((props.width - labels.width) / 2));
  const commonProps = {
    className: 'legend',
    transform: `translate(${x},${props.y})`,
    fontSize: style?.fontSize || fontSize,
    fontFamily: style?.fontStack || DEFAULT_CHART_FONT,
    textAnchor: 'start',
    fill: style.color || 'currentColor',
  };

  /**
   * @param {{ symbol?: string, color?: string, text: string }} label
   * @param {number} [x=0]
   * @param {number} [y=0]
   * @param {string | null} [key=null]
   * @return {React.ReactNode}
   */
  const renderLabel = (label, x = 0, y = 0, key = null) => {
    return (
      <g key={key} className="label" transform={`translate(${x},${y})`}>
        {renderSymbol(label.symbol || style.symbol, label.color, lineHeight)}
        <text x={15} y={lineHeight / 2} dy="0.33em">{label.text}</text>
      </g>
    );
  };

  if (!columns) {
    let x = 0;
    return (
      <g {...commonProps}>
        {isDev && props.debug && <rect x={0} y={0} width={labels.width} height={labels.height} fill="none" stroke="red" strokeDasharray={4} />}
        {labels.map((label, i) => {
          if (label.hidden) {
            return null;
          }
          const l = renderLabel(label, x, 0, k + i);
          x += label.width + linePad;
          return l;
        })}
      </g>
    );
  }
  else {
    let labelCount = 0;
    return (
      <g {...commonProps}>
        {isDev && props.debug && <rect x={0} y={0} width={labels.width} height={labels.height} fill="none" stroke="red" strokeDasharray={4} />}
        {labels.map((label, i) => {
          if (label.hidden) {
            return null;
          }
          const x = (labels.columnSize + labels.columnGutter) * ((i % columns) || 0);
          const y = (Math.floor((labelCount / columns))) * lineHeight;
          labelCount++;
          return renderLabel(label, x, y, k + i);
        })}
      </g>
    );
  }
}

ChartLegend.prepare = prepLegend;

ChartLegend.propTypes = {
  debug: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  data: PropTypes.instanceOf(LegendData),
  width: PropTypes.number,
  style: PropTypes.object,
};

ChartLegend.defaultProps = {
  x: 0,
  y: 0,
};
