import React, { useContext } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import {
  elementVisibility,
  horizontalAlign,
  rulerColor,
  rulerFullWidth,
  rulerStyle,
  rulerWeight,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';

import styles from './ruler.module.scss';

const elementOptions = {
  rulerStyle,
  rulerWeight,
  rulerFullWidth,
  rulerColor,
  visible: elementVisibility,
  align: horizontalAlign,
};

export default function GridRuler (props) {
  const { theme } = useContext(ThemesContext);
  const { id, testId, isEditor, pageBreakAfter } = props;

  const isVisible = elementVisibility.read(props);
  if (!isEditor && !isVisible) {
    return null;
  }

  const variant = rulerStyle.read(props);
  const isHeavy = rulerWeight.read(props) === 'heavy';
  const isFullWidth = rulerFullWidth.read(props);
  const alignment = horizontalAlign.read(props);

  // We only assign a color to the variable if it is directly set
  // else comes from the theme styles.
  const style = rulerColor.isSet(props)
    ? { '--ruler-color': theme.resolveColor(rulerColor.read(props)) }
    : null;

  return (
    <div
      id={id}
      data-testid={testId}
      className={csx(
        'hr',
        styles.ruler,
        styles[variant],
        isHeavy && styles.heavy,
        !isFullWidth && styles.partial,
        pageBreakAfter && 'pageBreakAfter',
        styles[alignment],
        isEditor && !isVisible && styles.invisible,
      )}
      style={/** @type {React.CSSProperties} */ (style)}
      role="separator"
      >
      <div />
    </div>
  );
}

GridRuler.options = elementOptions;
GridRuler.propTypes = {
  id: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  model: modelProp,
  testId: PropTypes.string,
  isEditor: PropTypes.bool,
  pageBreakAfter: PropTypes.bool,
};
