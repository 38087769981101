import React from 'react';
import {
  curveBasis, curveLinear, curveMonotoneX, curveStep, curveStepAfter, curveStepBefore,
  line as d3_line,
} from 'd3-shape';
import PropTypes from 'prop-types';

import { deepCompare, functor } from '@/grid/utils';

const interpolationTypes = {
  'linear': curveLinear,
  'step': curveStep,
  'step-after': curveStepAfter,
  'step-before': curveStepBefore,
  'monotone': curveMonotoneX,
  'basis': curveBasis,
};

export default class LineLayer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
    colorScale: PropTypes.func.isRequired,
    interpolate: PropTypes.string,
    defined: PropTypes.func,
    series: PropTypes.array,
    lineWidth: PropTypes.number,
  };

  static defaultProps = {
    visible: true,
    lineWidth: 2,
  };

  static getDerivedStateFromProps (props, state) {
    const interpolate = interpolationTypes[props.interpolate] ?? curveLinear;
    if (interpolate !== state.interpolate) {
      return { interpolate };
    }
    return null;
  }

  constructor (props) {
    super(props);
    this.state = {
      path: d3_line(),
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    // if this was invisible and will be, don't bother
    if (!nextProps.visible && !this.props.visible) {
      return false;
    }
    return deepCompare(this, nextProps, nextState);
  }

  render () {
    const { lineWidth, className, series, visible, x, y, defined, colorScale } = this.props;
    if (!visible) {
      return null;
    }
    const { interpolate, path } = this.state;
    const _colorScale = functor(colorScale, 'black');

    path
      .x(x)
      .y(y)
      .defined(defined ?? true)
      .curve(interpolate);

    return (
      <g fill="none" pointerEvents="none" strokeWidth={lineWidth} className={className}>
        {series.map((data, i) => {
          if (!data.length) {
            return null;
          }
          return (
            <g key={'line' + i}>
              <path
                d={path(data, i)}
                stroke={_colorScale(data[0], i)}
                strokeLinecap="square"
                />
            </g>
          );
        })}
      </g>
    );
  }
}
