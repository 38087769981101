import React from 'react';
import csx from 'classnames';
import { isDateFormat } from 'numfmt';
import PropTypes from 'prop-types';

import modelProp from '../modelProp';
import {
  disabled,
  disableTooltip,
  elementVisibility,
  format,
  max,
  min,
  optInlineSize,
  optInlineType,
  optValueVisible,
  step,
  targetCell,
  titleLabel,
  width,
} from '../propsData';
import { isNumber, printCell, scale } from '../utils';
import { getAutoStep } from '../utils/getAutoStep';
import Wrapper from '../Wrapper';
import Slider from './common/Slider';

import styles from './slider.module.scss';

const elementOptions = {
  expr: targetCell,
  title: titleLabel,
  format: format,
  valueVisible: optValueVisible,
  inline: optInlineType,
  width: width,
  min: min,
  max: max,
  step: step,
  visible: elementVisibility,
  disableTooltip: disableTooltip,
  disabled: disabled,
  [optInlineSize.name]: optInlineSize,
};

export default class GridSlider extends React.Component {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    expr: PropTypes.string,
    isEditor: PropTypes.bool,
    track: PropTypes.func,
    locale: PropTypes.string,
  };

  static options = elementOptions;
  static requiredOption = 'expr';
  static isInput = true;

  constructor (props) {
    super(props);
    this.state = { interacting: false };
  }

  static getDerivedStateFromProps (props, state, prevProps) {
    if (prevProps && props.model === prevProps.model) {
      return null;
    }
    const inlineMode = optInlineType.read(props);
    const cell = targetCell.read(props);

    const formatString = format.isSet(props) ? format.read(props) : (cell?.z || '');

    let stepValue = step.read(props);
    if (!step.isSet(props) && isDateFormat(formatString)) {
      stepValue = 1;
    }

    const exprRef = targetCell.isFx(props);
    return {
      noExpr: !exprRef,
      disabled: !exprRef || disabled.read(props),
      valueVisible: optValueVisible.isSet(props) ? optValueVisible.read(props) : inlineMode !== 'text',
      inline: inlineMode,
      title: titleLabel.read(props),
      cell: cell,
      min: min.read(props),
      max: max.read(props),
      tooltip: !disableTooltip.read(props),
      step: stepValue,
      format: formatString,
    };
  }

  getAutoValue () {
    let value;
    if (this.state.interacting) {
      // While the user is interacting with the slider, we lock to the
      // value that was there when the interaction started
      value = this.state.value;
    }
    else {
      // When not interacting, we simply read the value from the target cell
      const cell = this.state.cell;
      value = cell ? cell.v || 0 : 0;
      if (!isNumber(value)) {
        value = this.state.min || 0;
      }
    }
    // The value is overwritten with the first non-zero value encountered if that
    // value "extends the range" further than the current one. This makes the authored
    // value of the cell always remain within the range of the slider.
    if (this.anchorValueKey !== this.props.expr) {
      // expire anchor if the target changes
      this.anchorValue = null;
    }
    const anchorValue = this.anchorValue;
    if (anchorValue) {
      value = (anchorValue > 0)
        ? Math.max(value, anchorValue)
        : Math.min(value, anchorValue);
    }
    else {
      this.anchorValueKey = this.props.expr;
      this.anchorValue = value;
    }
    return value;
  }

  onInteractStart = () => {
    const props = this.props;
    const cell = targetCell.read(props);
    this.setState({
      interacting: true,
      value: cell ? cell.v || 0 : 0,
    });
    this.props.track('interact', { elementType: 'slider' });
  };

  onInteractEnd = () => {
    this.setState({ interacting: false });
  };

  onChange = value => {
    targetCell.write(this.props, value);
  };

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const cell = this.state.cell;
    let value = cell ? cell.v || 0 : 0;

    let min = this.state.min;
    let max = this.state.max;
    let step = this.state.step;
    const numberFormat = this.state.format;

    if (!isNumber(value)) {
      value = min || 0;
    }

    // Automatically pick slider scale defaults if they are missing.
    const v = this.getAutoValue();
    if (!isNumber(max)) {
      max = (v < 0) ? 0 : scale(v) || Math.max(1, step || 0);
    }
    if (!isNumber(min)) {
      min = (v < 0) ? -scale(-v) : 0;
    }
    if (!isNumber(step)) {
      step = getAutoStep(this.anchorValue, numberFormat, min, max);
    }

    const size = optInlineSize.read(props) || optInlineSize.defaultValue || 'small';
    const cls = csx(
      styles.slider,
      this.state.interacting && styles.interacting,
      this.state.valueVisible && styles.withValue,
      `size_${size}`,
    );
    return (
      <Wrapper
        {...props}
        className={cls}
        label={this.state.title}
        inlineWidth={width.read(props)}
        inlineMode={this.state.inline}
        isDisabled={this.state.disabled}
        >
        <span className={csx(styles.inputWrap, 'inputWrap')}>
          <Slider
            size={size}
            disabled={this.state.disabled}
            value={this.state.noExpr ? null : value}
            onDragStart={this.onInteractStart}
            onDragEnd={this.onInteractEnd}
            onChange={this.onChange}
            min={min}
            max={max}
            step={step}
            showValue={this.state.valueVisible}
            showTooltip={this.state.tooltip}
            label={printCell(cell, numberFormat, props.locale)}
            labelledby={props.parentKey + '_lbl'}
            />
        </span>
      </Wrapper>
    );
  }
}
