/* eslint-disable react/prop-types */
import React from 'react';
import csx from 'classnames';

import { TYPING_DELAY } from '@/grid/constants';
import { printCell } from '@/grid/utils';
import { parseInputValue, renderInputValue } from '@/grid/utils/editValues';
import stepNormalize from '@/grid/utils/stepNormalize';

import styles from './TableInput.module.scss';

/**
 * @typedef TableInputProps
 * @prop {import('@/grid/types').Cellish} cell
 * @prop {number} [min]
 * @prop {number} [max]
 * @prop {number} [step]
 * @prop {string} [format]
 * @prop {string} [locale]
 * @prop {Function} [onInput]
 * @prop {Function} [track]
 * @prop {number} [inputDelay]
 * @prop {boolean} [boxed=false]
 */

function getTextNodes (node, nodes = []) {
  if (node.nodeType === 3) {
    nodes.push(node);
  }
  else {
    for (let i = 0; i < node.childNodes.length; ++i) {
      getTextNodes(node.childNodes[i], nodes);
    }
  }
  return nodes;
}

export default class TableInput extends React.PureComponent {
  static defaultProps = {
    inputDelay: TYPING_DELAY,
  };

  /** @param {TableInputProps} props */
  constructor (props) {
    super(props);
    this.state = {
      interacting: false,
      editValue: null,
      startEditOffset: Infinity,
      savedValue: null,
      lockValue: null,
    };
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.state.interacting && !lastState.interacting && this.input) {
      // focus the control
      this.input.focus();
      if (!this.props.boxed) {
        // set cursor position
        clearTimeout(this._startTimer);
        this._startTimer = setTimeout(() => {
          const caretPosition = this.state.startEditOffset;
          this.input?.setSelectionRange(caretPosition, caretPosition);
        }, 1);
      }
    }
  }

  componentWillUnmount () {
    clearTimeout(this._startTimer);
    clearTimeout(this._transTimer);
  }

  onInput = e => {
    this.setState({
      editValue: e.target.value,
    });
    const val = parseInputValue(e.target.value, this.props.format, this.props.locale);
    // the value is not step-normalized onChange as it would make it impossible to
    // write '15' in an input with step=5 (the first 1 would be stepped to 0)
    this.writeValue(val, false, false);
  };

  onMouseDown = e => {
    // Runs through the clicked text and finds the closest text point to
    // where the click happened.
    const range = document.createRange();
    range.selectNode(e.target);
    let closest = { i: 1, dist: Infinity };
    if (range.getBoundingClientRect) {
      const origin = range.getBoundingClientRect();
      const mouse = { x: e.clientX - origin.x, y: e.clientY - origin.y };
      getTextNodes(e.target).forEach(node => {
        for (let i = 0; i < node.length + 1; i++) {
          range.setStart(node, i);
          range.setEnd(node, i);
          const caret = range.getBoundingClientRect();
          const x = caret.x - origin.x;
          const y = (caret.y + caret.height / 2) - origin.y;
          const dist = Math.hypot(x - mouse.x, y - mouse.y);
          if (closest.dist > dist) {
            closest = { i, dist };
          }
        }
      });
    }
    this.setState({ startEditOffset: closest.i });
  };

  onFocus = () => {
    clearTimeout(this._transTimer);
    const { cell, format, locale } = this.props;
    if (this.props.track) {
      this.props.track('focus', { elementType: 'table.input' });
    }
    this.setState({
      savedValue: renderInputValue(cell.v, format || cell.z, locale),
      lockValue: printCell(cell, format, locale),
      exiting: false,
      interacting: true,
    });
  };

  onEndEdit = () => {
    this.setState({ exiting: false, interacting: false });
  };

  onBlur = e => {
    const val = parseInputValue(e.target.value, this.props.format, this.props.locale);
    if (this.props.track && this.state.savedValue !== e.target.value) {
      this.props.track('interact', { elementType: 'table.input' });
    }
    this.writeValue(val, true, true);
    clearTimeout(this._startTimer);
    clearTimeout(this._transTimer);
    if (this.props.boxed) {
      // in boxed mode, we want changes to be immediate
      this.setState({ editValue: null, startEditOffset: Infinity, exiting: false, interacting: false }, () => {
        if (this.props.track) {
          this.props.track('blur', { elementType: 'table.input' });
        }
      });
    }
    else {
      // in non-boxed mode, we want changes to take effect after animation transition
      if (this.props.track) {
        this.props.track('blur', { elementType: 'table.input' });
      }
      this.setState({ exiting: true, editValue: null, startEditOffset: Infinity });
      this._transTimer = setTimeout(this.onEndEdit, parseInt(styles.transitionSpeed, 10));
    }
  };

  onKeyDown = e => {
    if (e.key === 'Escape') {
      this.onBlur({
        target: {
          value: this.state.savedValue,
        },
      });
    }
    if (e.key === 'Enter') {
      this.input?.blur();
    }
  };

  // NB: This is the same code as in the "main" input component so changes here
  //     should likely also occur there!
  writeValue (value, normalizeToConstraints = false, forceImmediate = false) {
    const { cell, onInput, min, max, step } = this.props;
    clearTimeout(this._writeDelay);
    if (normalizeToConstraints && (value || value === 0) && typeof value === 'number') {
      value = stepNormalize(value, min, max, step);
    }
    if (value === '' && (cell._v == null || typeof cell._v === 'number')) {
      // ENGINE-246: "" should write null's like Excel does
      value = null;
    }
    if (value === cell.v) {
      // don't perform a write if it won't result in a change
      return;
    }
    // delay if new input is a number or a null...
    const newCanDelay = typeof value === 'number' || value == null;
    // and if we started with a number (but not a null)
    const oldCanDelay = typeof cell.v === 'number' || cell.v == null;
    if (newCanDelay && oldCanDelay && !forceImmediate) {
      this._writeDelay = setTimeout(() => {
        onInput(cell.id, value);
      }, this.props.inputDelay);
    }
    else {
      onInput(cell.id, value);
    }
  }

  render () {
    const { cell, format, locale, boxed } = this.props;
    const { interacting, exiting, editValue, lockValue } = this.state;
    const nbsp = '\u00a0\u00a0';
    const labelClass = boxed
      ? styles.boxed
      : csx(
        styles.unboxed,
        styles.inputLabel,
        interacting && styles.interacting,
        exiting && styles.exiting,
      );
    let inputValue = '';
    if (boxed) {
      inputValue = interacting
        ? editValue ?? renderInputValue(cell.v, format || cell.z, locale)
        : printCell(cell, format, locale);
    }
    else {
      inputValue = editValue ?? renderInputValue(cell.v, format || cell.z, locale);
    }
    return (
      <label
        className={labelClass}
        onMouseDown={this.onMouseDown}
        onFocus={interacting ? undefined : this.onFocus}
        role={(interacting || boxed) ? undefined : 'textbox'}
        tabIndex={(interacting || boxed) ? -1 : 0}
        ref={elm => (this.label = elm)}
        >
        <span className={styles.value}>
          {(!interacting || exiting)
            ? printCell(cell, format, locale) || nbsp
            : lockValue || nbsp}
        </span>
        {(interacting || boxed) && (
          <input
            type="text"
            onKeyDown={this.onKeyDown}
            ref={elm => (this.input = elm)}
            className={csx(
              styles.input,
              exiting && styles.exitingInput,
            )}
            value={inputValue}
            onInput={this.onInput}
            onBlur={this.onBlur}
            />
        )}
      </label>
    );
  }
}
