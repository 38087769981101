/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import csx from 'classnames';

import Tooltip from '@/grid/Tooltip';

import styles from './DataError.module.scss';

const iconPath = 'M19.59 15.12L12.04 1.21C11.6 0.46 10.85 0 9.99 0C9.14 0 8.36 0.46 7.95 1.21L0.39 15.12C-0.00 15.84 0.00 16.73 0.42 17.44C0.84 18.15 1.61 18.59 2.44 18.58H17.54C18.36 18.58 19.12 18.15 19.54 17.44C19.96 16.74 19.98 15.87 19.59 15.14V15.12ZM8.83 5.20C8.83 4.55 9.35 4.03 10.00 4.03C10.64 4.03 11.16 4.55 11.16 5.20V10.54C11.16 11.18 10.64 11.70 10.00 11.70C9.35 11.70 8.83 11.18 8.83 10.54V5.20ZM8.69 14.34C8.69 15.07 9.29 15.67 10.03 15.67C10.76 15.67 11.36 15.07 11.36 14.34C11.36 13.60 10.76 13.00 10.03 13.00C9.29 13.00 8.69 13.60 8.69 14.34Z';

function updateWidth (elm, isChart) {
  if (elm) {
    const w = elm.getBoundingClientRect().width;
    const h = Math.min(w * 0.4645, isChart ? 350 : 172);
    elm.style.height = h + 'px';
    elm.classList.toggle(styles.small, w < 240 && w >= 170);
    elm.classList.toggle(styles.xsmall, w < 170 && w >= 120);
    elm.classList.toggle(styles.tiny, w < 120);
  }
}

/**
 * @param {object} props
 * @param {boolean} [props.isChart]
 * @param {boolean} [props.canEdit]
 * @param {string} [props.title]
 * @param {Model} [props.model]
 * @param {string} [props.message]
 * @return {React.ReactElement}
 */
export default function DataError ({ isChart, canEdit, title, model, message }) {
  /** @type {React.MutableRefObject<HTMLElement | null>} */
  const ref = useRef(null);
  const [ isHovering, setIsHovering ] = useState(false);

  useEffect(() => {
    const updateWidth_ = () => updateWidth(ref.current, isChart);
    window.addEventListener('resize', updateWidth_);
    updateWidth_();

    return () => {
      window.removeEventListener('resize', updateWidth_);
    };
  });

  if (!title) {
    title = 'No data found';
    if (!message) {
      message = (model && model.hasData)
        ? 'Select data from the spreadsheet'
        : 'Add data to your document';
    }
  }

  return (
    <span
      className={csx(
        styles.dataerror,
        isChart && styles.chartError,
        canEdit ? styles.canEdit : styles.readOnly,
      )}
      role="status"
      ref={ref}
      >
      <Tooltip
        className={styles.tooltip}
        cursorPosition={canEdit && isHovering && message ? 'static' : null}
        showAnchor
        hoverNode={message || ''}
        >
        <span
          className={styles.errorInner}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          >
          <span className={styles.title}>
            <span className={styles.twrap}>
              <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                <path d={iconPath} fillRule="evenodd" />
              </svg>
              <span>{title}</span>
            </span>
          </span>
          {canEdit && (
            <span className={styles.message}>{message}</span>
          )}
        </span>
      </Tooltip>
    </span>
  );
}
