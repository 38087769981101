const τ = Math.PI * 2;

function toPolar (x, y) {
  let a = Math.atan2(y, x) + Math.PI / 2;
  while (a < 0) {
    a += τ;
  }
  return {
    angle: a,
    radius: Math.sqrt(x * x + y * y),
  };
}

function isOverlapping (a, b, xPadding = 6) {
  return (
    a.x < b.x + b.width + xPadding &&
    a.x + a.width + xPadding > b.x &&
    a.y < b.y + b.height &&
    a.height + a.y > b.y
  );
}

function isWithinAngle (d, p) {
  return (p.angle > d.startAngle && p.angle < d.endAngle);
}

export function selectivePieLabels (wedgeData, radius = 1, isDonut = false, lightBackground = true) {
  const isWithinRadius = (d, p) => (p.radius < radius && p.radius > (isDonut ? radius * 0.5 : 0));
  // Round 1: remove labels where label color does not contrast
  //          with what is underneath (we don't want white on white)
  wedgeData.forEach(d => {
    const label = d.label;
    if (!label || label.hidden) {
      return;
    }
    // we define two points in polar coord on either side of the label
    const pStart = toPolar(label.x - label.width / 2, label.y);
    const pEnd   = toPolar(label.x + label.width / 2, label.y);
    const sInRad = isWithinRadius(d, pStart);
    const eInRad = isWithinRadius(d, pEnd);
    const sInAng = isWithinAngle(d, pStart);
    const eInAng = isWithinAngle(d, pEnd);
    // if both points are contained by the wedge, we're all good
    if (sInRad && sInAng && eInRad && eInAng) {
      return;
    }
    // if text overflows radius but background needs a different colored text
    // we do not want to show the label
    const darkText = label.dark;
    if (
      (sInAng && !sInRad && darkText !== lightBackground) ||
      (eInAng && !eInRad && darkText !== lightBackground)
    ) {
      label.hidden = true;
      return;
    }
    // if text overflows wedge but stays within radius, we'll need to determine
    // if any of of the wedges overlapped by the label have the correct text color
    if (!sInAng && sInRad || !eInAng && eInRad) {
      // find all overlapped wedges...
      const labelStartAngle = pStart.angle;
      let labelEndAngle = pEnd.angle;
      if (labelEndAngle < labelStartAngle) {
        labelEndAngle += τ;
      }
      const wedges = wedgeData.filter(w => {
        const { startAngle, endAngle } = w;
        return (
          (startAngle > labelStartAngle && endAngle < labelEndAngle) ||
          (labelStartAngle > startAngle && labelStartAngle < endAngle) ||
          (labelEndAngle > startAngle && labelEndAngle < endAngle)
        );
      });
      // ...and test if they are all the same color
      const consistentColor = wedges.every(w => w.label.dark === darkText);
      if (!consistentColor) {
        label.hidden = true;
      }
    }
  });
  // Round 2: We greedily mark overlapping labels as hidden
  wedgeData
    // We prefer bigger wedges over smaller.
    // There is no need to consider nulls, the pie driver has already
    // filtered out any non-finite and negative values
    .sort((a, b) => b.v - a.v)
    .map(d => d.label)
    .forEach((a, i, list) => {
      if (a && !a.hidden) {
        // only compare to wedges following it (in size order)
        for (let k = i + 1; k < list.length; k++) {
          const b = list[k];
          if (!b.hidden && (b !== a) && isOverlapping(a, b)) {
            // if we've found an overlap, mark the upcoming wedge as hidden
            b.hidden = true;
          }
        }
      }
    });
}
