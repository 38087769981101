import React from 'react';
import { range } from 'd3-array';
import PropTypes from 'prop-types';

import { getCursorPosition } from '../../utils/offset';
import { VALUE_LABELS_MIN } from '../constants';
import modelProp from '../modelProp';
import {
  axisClip,
  axisFormat,
  axisMax,
  axisMin,
  axisReverse,
  axisTitle,
  axisType,
  chartColors,
  colorByPoint,
  data as dataExpr,
  elementFootnote,
  elementFootnoteLink,
  elementSubtitle,
  elementTitle,
  elementVisibility,
  format,
  layoutWidth,
  legend as legendOption,
  legendVisible,
  optAnnotations,
  seriesOrientation,
  sortBy,
  sortOrder,
  stacked,
  valueLabels,
  yAxisLabels,
} from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { lowerCase, printCell, validExpr } from '../utils';
import BaseChart from './BaseChart';
import Annotations, { prepAnnotations } from './layers/Annotations';
import BarLayer from './layers/BarLayer';
import Clip from './layers/Clip';
import { getColorScale, isEmptyRange, prepLabels } from './utils';
import { DataAxis, ValueAxis } from './utils/ChartAxis';
import ChartLabel from './utils/ChartLabel';
import { Footnote } from './utils/Footnote';
import Grid from './utils/Grid';
import Legend from './utils/Legend';
import prepDimensions from './utils/prepDimensions';
import { getOrderedRange } from './utils/sorting';
import { toSeries } from './utils/toSeries';

const elementOptions = {
  expr: dataExpr,
  title: elementTitle,
  subtitle: elementSubtitle,
  footnote: elementFootnote,
  footnoteLink: elementFootnoteLink,
  stacked: stacked,
  labels: yAxisLabels,
  legend: legendOption,
  legendVisible: legendVisible,
  format: format,
  sortBy: sortBy,
  sortOrder: sortOrder,
  colorByPoint: colorByPoint,
  size: layoutWidth,
  dir: seriesOrientation,
  visible: elementVisibility,
  chartColors: chartColors,
  annotations: optAnnotations,
  valueLabels: valueLabels,
  axisValue: {
    title: axisTitle,
    format: axisFormat,
    clip: axisClip,
    type: axisType,
    min: axisMin,
    max: axisMax,
    reverse: axisReverse,
  },
  axisDim: {
    title: axisTitle,
    format: axisFormat,
    reverse: axisReverse,
  },
};

export default class BarChart extends BaseChart {
  static propTypes = {
    parentKey: PropTypes.string,
    error: PropTypes.string,
    model: modelProp.isRequired,
    locale: PropTypes.string,
  };

  static chartType = 'bar';
  static options = elementOptions;
  static requiredOption = 'expr';
  static contextType = ThemesContext;

  static getDerivedStateFromProps (props, state) {
    // only update when writes happen in the model, or when in editor
    if (props.locale === state.lastLocale &&
       !props.isSelected && props.model &&
       props.model.lastWrite === state.modelId) {
      return null;
    }

    const table = dataExpr.readCropped(props);
    const w = table[0].length - (table.emptyRight || 0);
    const h = table.length - (table.emptyBottom || 0);
    let dir = lowerCase(seriesOrientation.read(props));
    if (!dir || dir === 'auto') {
      dir = w < h ? 'col' : 'row';
    }
    let size = dir === 'row' ? [ w, h ] : [ h, w ];

    const locale = props.locale;
    const axYformat = props.axisDim && format.read({ model: props.model, locale: props.locale, ...props.axisDim });

    const labelData = yAxisLabels.read(props);
    const labels = prepLabels(labelData, dir, axYformat, locale);

    let data;
    if (!validExpr(props.expr)) {
      const l = labels ? labels.length : 0;
      size = [ l, 1 ];
      data = range(l).map(() => ({ v: null }));
    }
    else {
      data = range(h * w).map(i => {
        const a = ~~(i % size[1]);
        const b = ~~(i / size[1]);
        const cell = (dir === 'row') ? table[a][b] : table[b][a];
        return (cell == null || cell instanceof Error) ? null : cell;
      });
    }

    const isStacked = stacked.read(props);
    const [ series, valueaxisDim ] = toSeries(
      table,
      size,
      dir,
      { stackSeries: isStacked },
    );

    const legendRaw = legendOption.read(props);
    return {
      dir,
      size,
      data,
      series,
      isStacked,
      legendRaw,
      annotations: prepAnnotations(optAnnotations.read(props), { flipXY: true }),
      showValues: valueLabels.read(props),
      legendVisible: legendVisible.isSet(props) ? legendVisible.read(props) : !isEmptyRange(legendRaw),
      valueAxis: ValueAxis.prepare({
        props,
        data: valueaxisDim,
        locale: locale,
        orient: 'bottom',
        needZero: true,
      }),
      dimAxis: DataAxis.prepare({
        props,
        domain: getOrderedRange(size, data, props, true),
        length: size[0],
        labels: labelData,
        locale: locale,
        orient: 'left',
      }),
      labels: labels && labels.slice(0, size[0]),
      format: format.read(props),
      lastLocale: locale,
      modelId: props.model.lastWrite,
    };
  }

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    if (!validExpr(props.expr) || !props.model || !props.model.hasData) {
      return this.renderError();
    }

    const outerWidth = this.state.width;
    const chartTitle = elementTitle.read(props);
    const chartSubtitle = elementSubtitle.read(props);
    const { chartTheme, theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);

    const { dir, legendRaw, legendVisible, size, data, valueAxis,
      dimAxis, isStacked, labels, format, series, showValues } = this.state;

    const palette = chartColors.read(props, {
      minColors: 7,
      autoExtend: true,
      default: chartTheme.palette,
    }).map(theme.resolveColor);
    const legend = Legend.prepare({
      labels: legendRaw,
      dir,
      length: size[1],
      locale: props.locale,
      style: chartTheme?.legend,
    });
    const footnote = Footnote.prepare({
      text: elementFootnote.read(props),
      href: elementFootnoteLink.read(props),
      style: chartTheme?.footnote,
    });

    valueAxis.applyStyle(chartTheme.valueAxis);
    dimAxis.applyStyle(chartTheme.dataAxis);

    let height = 25 * (isStacked ? size[0] : data.length);
    if (!validExpr(props.expr)) {
      // ensure height to show error message
      height = Math.max(height, 200);
    }

    // wmax label width is around 50% on full width charts, but 30% on smaller
    dimAxis.maxWidth = Math.round(0.00039 * outerWidth ** 2 + 0.143 * outerWidth + 13);

    const { margin, width, title, subtitle } = prepDimensions(
      outerWidth, [ dimAxis, valueAxis ], chartTitle, chartSubtitle, height, theme.chartFontStack,
    );

    legend.maxWidth = outerWidth;
    footnote.maxWidth = outerWidth - margin.right;

    margin.bottom += legendVisible ? (chartTheme.legend?.margin ?? 0) + legend.height : 0;

    // colors
    const colorAxis = getColorScale({
      byPoint: colorByPoint.read(props),
      backgroundColor: theme.backgroundColor,
      palette,
    });
    legend.forEach((d, i) => (d.color = colorAxis(i)));

    let footnoteY = 0;
    if (footnote.height) {
      margin.bottom += footnote.height + (chartTheme.footnote?.margin ?? 0);
      footnoteY = height + margin.top + margin.bottom - footnote.height;
      if (legendVisible) {
        footnoteY -= legend.height + (chartTheme.legend?.margin ?? 0);
      }
    }

    const invalidChart = valueAxis.error;

    return this.wrap(
      <svg
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`}
        style={{ overflow: 'visible' }}
        aria-label="Bar Chart"
        role="img"
        >
        {title && <g transform="translate(1,0)" fill={theme.color}>{title.render()}</g>}
        {subtitle && <g transform={`translate(1,${title?.height ?? 0})`} fill={theme.color}>{subtitle.render()}</g>}
        <g transform={`translate(${margin.left},${margin.top})`}>
          <Grid
            x={valueAxis}
            y={dimAxis}
            styles={chartTheme}
            />
          {invalidChart && (
            <g>
              <ChartLabel
                y={0}
                x={width / 2}
                height={height}
                width={width - (width * 0.15)}
                align="center"
                vAlign="middle"
                color={theme.color}
                size={14}
                text={'Invalid data: ' + invalidChart}
                />
              <rect />
            </g>
          )}
          <Clip
            id={'clip-' + props.id}
            active={valueAxis.isClipped}
            width={width}
            height={height}
            >
            <BarLayer
              visible={!invalidChart}
              series={series}
              x={valueAxis.scale}
              y={dimAxis.scale}
              fill={d => colorAxis(d.series, d.category)}
              overlap={isStacked ? 1 : 0}
              bandWidth={dimAxis.bandwidth()}
              zero={valueAxis.zero}
              onPoint={(d, e) => {
                this.setState({
                  hover: {
                    title: labels ? labels[d.category] : String(d.category + 1),
                    dimspec: {
                      [legend[d.series].text]: printCell(d.cell, format, props.locale),
                    },
                    simple: size[1] === 1,
                    cursorPosition: getCursorPosition(e),
                  },
                });
              }}
              onUnPoint={() => this.setState({ hover: null })}
              // needed for labels:
              showValues={this.state.width > VALUE_LABELS_MIN ? showValues : valueLabels.NONE}
              centerLabels={isStacked}
              styles={chartTheme}
              label={d => printCell(d.cell, format, props.locale)}
              width={width}
              height={height}
              />
            <Annotations
              visible={!invalidChart}
              styles={chartTheme}
              x={valueAxis}
              y={dimAxis}
              data={this.state.annotations}
              pointMark="bar"
              bandWidth={dimAxis.bandwidth()}
              />
          </Clip>
          <DataAxis
            data={dimAxis}
            />
          <ValueAxis
            data={valueAxis}
            y={height}
            />
        </g>
        <Footnote
          data={footnote}
          y={footnoteY}
          />
        <Legend
          data={(legendVisible && legend) || null}
          width={outerWidth}
          y={height + margin.top + margin.bottom - legend.height}
          />
      </svg>,
    );
  }
}
