import { range } from 'd3-array';

import { LEGEND_DEFAULTS } from '@/grid/ChartTheme';

import { prepLabels } from './';
import { measureText } from './measureText';

const columnGutter = 15;
const lineHeight = 20;
const bulletWidth = 15;

export class LegendData {
  length = 0;
  columnSize = 0;
  columnGutter = columnGutter;
  lineHeight = lineHeight;
  width = 0;
  height = 0;
  columns = 0;
  style = LEGEND_DEFAULTS;

  constructor (style) {
    this._mw = 100;
    this.applyStyle(style);
  }

  add (item) {
    const i = this.length;
    this[i] = item;
    this.length += 1;
  }

  forEach (cb, ctx) {
    for (let i = 0; i < this.length; i++) {
      cb.call(ctx, this[i], i);
    }
  }

  map (cb, ctx) {
    const r = [];
    for (let i = 0; i < this.length; i++) {
      r[i] = cb.call(ctx, this[i], i);
    }
    return r;
  }

  get font () {
    const st = this.style;
    return st.fontSize + 'px ' + (st.fontStack || st.fontFamily);
  }

  get maxWidth () {
    return this._mw;
  }

  set maxWidth (w) {
    this._mw = w;
    this.update();
  }

  applyStyle (style) {
    this.style = style || LEGEND_DEFAULTS;
  }

  colorize (colorScale) {
    this.forEach((d, i) => (d.color = colorScale(i)));
  }

  update () {
    let numSeries = 0;
    const maxWidth = this._mw;
    let width = 0;
    let longest = 0;
    this.forEach(item => {
      if (!item.hidden) {
        numSeries++;
        width += item.width;
        if (item.width > longest) {
          longest = item.width;
        }
      }
    });

    this.columnSize = longest;
    const fullWidth = width + ((numSeries - 1) * columnGutter);
    if (fullWidth >= (maxWidth - 2 * columnGutter)) {
      const columns = Math.min(numSeries, Math.floor(maxWidth / (longest + columnGutter))) || 1;
      this.width = (longest * columns) + ((columns - 1) * columnGutter);
      this.height = Math.ceil(numSeries / columns) * lineHeight;
      this.columns = columns;
    }
    else {
      this.width = fullWidth;
      this.height = lineHeight;
      this.columns = 0;
    }

    return this;
  }
}

/**
 * @param {object} options
 * @param {object[][]} [options.labels]
 * @param {'row' | 'col'} [options.dir='row']
 * @param {number} [options.length=0]
 * @param {typeof LEGEND_DEFAULTS | null} [options.style=null]
 * @param {string} options.locale
 */
export function prepLegend ({
  labels = [],
  dir = 'row',
  length = 0,
  style = null,
  locale,
}) {
  const realLabels = prepLabels(labels, dir, undefined, locale) || [];
  const legend = new LegendData(style);

  range(length).forEach(i => {
    const labelText = realLabels[i] || `Series${i + 1}`;

    legend.add({
      text: labelText,
      width: measureText(labelText, legend.font) + bulletWidth,
      hidden: false,
    });
  });

  return legend.update();
}
