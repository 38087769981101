import actionbutton from './button/actionbutton';
import button from './button/button';
import goalseekbutton from './button/goalseekbutton';
import recalcbutton from './button/recalcbutton';
import resetbutton from './button/resetbutton';
import submitbutton2 from './button/submitbutton';
import submitbutton from './button/submitbuttonLegacy';
import timer from './button/timer';
import area from './chart/AreaChart';
import bar from './chart/BarChart';
import column from './chart/ColumnChart';
import combo from './chart/ComboChart';
import line from './chart/LineChart';
import pie from './chart/PieChart';
import scatter from './chart/ScatterPlot';
import waterfall from './chart/WaterfallChart';
import error from './error';
import checkbox from './input/checkbox';
import dropdown from './input/dropdown';
import input from './input/input';
import radio from './input/radio';
import slider from './input/slider';
import tangle from './input/tangle';
import col from './layout/col';
import row from './layout/row';
import canvas from './other/canvas';
import debugline from './other/debugline';
import embed from './other/embed';
import image from './other/image';
import kpi from './other/kpi';
import latex from './other/lazy-latex';
import sheet from './other/sheet';
import source from './other/source';
import table from './other/table';
import text from './other/text';
import tooltip from './other/tooltip';
import link from './text/link';
import ruler from './text/ruler';

/** @typedef {(
    'area' |
    'bar' |
    'canvas' |
    'column' |
    'combo' |
    'embed' |
    'hr' |
    'line' |
    'pie' |
    'scatter' |
    'sheet' |
    'table' |
    'waterfall'
  )} GridBlockElementTypes */

/** @typedef {(
    'actionbutton' |
    'button' |
    'checkbox' |
    'debugline' |
    'dropdown' |
    'goalseekbutton' |
    'image' |
    'input' |
    'kpi' |
    'latex' |
    'radio' |
    'recalcbutton' |
    'resetbutton' |
    'slider' |
    'source' |
    'submitbutton' |
    'submitbutton2' |
    'tangle' |
    'text' |
    'timer' |
    'tooltip'
  )} GridInlineElementTypes */

/** @typedef {(GridBlockElementTypes | GridInlineElementTypes)} GridElementTypes */

/** @type {Record<(GridElementTypes & 'error' & 'col' & 'row' & 'link'), import('react').ReactNode>} */
export default {
  text,
  input,
  resetbutton,
  recalcbutton,
  slider,
  checkbox,
  debugline,
  button,
  latex,
  kpi,
  actionbutton,
  submitbutton,
  submitbutton2,
  goalseekbutton,
  dropdown,
  radio,
  timer,
  canvas,
  sheet,
  table,
  area,
  bar,
  column,
  combo,
  line,
  scatter,
  pie,
  waterfall,
  image,
  embed,
  source,
  row,
  col,
  hr: ruler,
  link,
  error,
  tangle,
  tooltip,
};
