import React from 'react';
import csx from 'classnames';
import { dateFromSerial, dateToSerial, format } from 'numfmt';
import PropTypes from 'prop-types';

import Arrow from './Arrow.jsx';
import { VIEW_DATE, VIEW_MONTH, VIEW_YEAR } from './constants.js';

import styles from './PickerHeader.module.scss';

const granToItem = { y: 0, m: 1, d: 2 };

/**
 * @param {number} date
 * @param {'y' | 'm' | 'd'} gran
 * @param {number} amt
 * @returns {number}
 */
function adjustDate (date, gran, amt) {
  const dt = dateFromSerial(date);
  dt[granToItem[gran]] += amt;
  return dateToSerial(dt) || 0;
}

/**
 * @param {object} props
 * @param {number} props.anchorDate The anchor date
 * @param {string} props.locale The locale
 * @param {number} props.view The view
 * @param {(view: number) => void} props.onView On view
 * @param {(date: number) => void} props.onChange On change
 * @param {(date: number, gran: ('y' | 'm' | 'd')) => boolean} props.isSelectable Indicates if selectable
 * @returns React.Node
 */
export default function PickerHeader ({ anchorDate, locale, view, onView, onChange, isSelectable }) {
  const opts = { locale: locale };

  /**
   * @param {'y' | 'm' | 'd'} gran
   * @param {number} stepSize
   * @param {-1 | 1} direction
   * @param {string} unitLabel
   * @return {React.ReactNode}
   */
  const pagerButton = (gran, stepSize, direction, unitLabel) => {
    const targetDate = adjustDate(anchorDate, gran, stepSize * direction);
    const step = direction > 0 ? stepSize * direction : direction;
    const stepDate = adjustDate(anchorDate, gran, step);
    const disabled = !isSelectable(stepDate, gran);
    return (
      <span
        role="button"
        aria-disabled={disabled}
        className={csx(styles.prev, styles.button, disabled && styles.disabled)}
        onClick={disabled ? undefined : () => onChange(targetDate)}
        aria-label={(direction < 0 ? `Show previous ${unitLabel}` : `Show next ${unitLabel}`)}
        >
        <Arrow dir={direction < 0 ? Arrow.LEFT : Arrow.RIGHT} />
      </span>
    );
  };

  if (view & VIEW_YEAR) {
    const [ y ] = dateFromSerial(anchorDate);
    const decade = Math.floor(y / 10) * 10;
    return (
      <b className={styles.header}>
        {pagerButton('y', 10, -1, 'decade')}
        <span
          role="button"
          className={styles.button}
          onClick={() => onView(VIEW_MONTH)}
          >
          {decade}-{decade + 10}
        </span>
        {pagerButton('y', 10, 1, 'decade')}
      </b>
    );
  }

  if (view & VIEW_MONTH) {
    return (
      <b className={styles.header}>
        {pagerButton('y', 1, -1, 'year')}
        <span
          role="button"
          className={styles.button}
          onClick={() => onView(VIEW_YEAR)}
          >
          {format('yyyy', anchorDate, opts)}
        </span>
        {pagerButton('y', 1, 1, 'year')}
      </b>
    );
  }

  return (
    <b className={styles.header}>
      {pagerButton('m', 1, -1, 'month')}
      <span
        role="button"
        className={styles.button}
        onClick={() => onView(VIEW_MONTH)}
        >
        {format('mmmm', anchorDate, opts)}
      </span>
      <span
        role="button"
        className={styles.button}
        onClick={() => onView(VIEW_YEAR)}
        >
        {format('yyyy', anchorDate, opts)}
      </span>
      {pagerButton('m', 1, 1, 'month')}
    </b>
  );
}

PickerHeader.propTypes = {
  anchorDate: PropTypes.number.isRequired,
  isSelectable: PropTypes.func.isRequired,
  locale: PropTypes.string,
  view: PropTypes.number,
  onView: PropTypes.func,
  onChange: PropTypes.func,
};

PickerHeader.defaultProps = {
  view: VIEW_DATE,
  locale: 'en-US',
  onView: () => {},
  onChange: () => {},
};
