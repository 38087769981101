export default class TimeKeeper {
  constructor () {
    this._running = false;
    this._timers = [];
  }

  io () {
    // OFF if there are no dependents
    if (!this._timers.length) {
      this._running = false;
    }
    // ON if there are dependents and it's not allready on
    else if (!this._running) {
      this._running = true;
      this.tick();
    }
  }

  tick = () => {
    if (this._running) {
      const now = Date.now();
      const timers = this._timers.filter(timer => {
        const elapsed = now - timer.lastTick;
        return elapsed > timer.freq;
      });
      // call timer handlers
      timers.forEach((timer, index) => {
        timer.lastTick = now;
        timer.handler(index, timers.length);
      });
      requestAnimationFrame(this.tick);
    }
  };

  addTimer (callBack, freq) {
    this._timers.push({
      lastTick: Date.now(),
      handler: callBack,
      freq: freq * 1000, // freq arg is in sec but internals are milliseconds
    });
    this.io();
  }

  removeTimer (callBack) {
    this._timers = this._timers.filter(timer => timer.handler !== callBack);
    this.io();
  }
}
