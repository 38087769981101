import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { elementClasses } from '../elementClasses';
import { backgroundColor, elementVisibility, layoutWidth, optBorderColor, optBoxShadow, optCornerRoundingLayout } from '../propsData';
import { ThemesProvider, useLocalTheme } from '../ThemesContext';
import { layoutWidthClass } from '../utils/layoutWidthClass';

import styles from './layout.module.scss';

export const BORDER_WIDTH = 2;

export default function GridRow (props) {
  const fill = backgroundColor.read(props);
  const { theme, chartTheme, themeStyles } = useLocalTheme(fill);

  // a row element is considered visible if its visibility property
  // is set to true, and any one of its child columns are visible
  const cols = props.element?.children;
  const modelProp = { model: props.model };
  const rowVisibleState = elementVisibility.read(props);
  const isVisible = (rowVisibleState && cols)
    ? cols.some(c => elementVisibility.read({ ...modelProp, ...c.data }))
    : rowVisibleState;

  if (!isVisible && !props.isEditor) {
    return null;
  }

  // in view mode, we don't render rows that are empty or
  // contain only slidebreaks or p:hidden
  if (!props.isEditor && props.element && !layoutWidth.isSet(props)) {
    const cols = props.element.children;
    if (cols.length === 1) {
      const blocks = cols[0].children;
      if (!blocks.some(d => d.type !== 'slidebreak' && d.type !== 'p:hidden' && d.type !== 'pagebreak')) {
        return null;
      }
    }
  }

  const size = layoutWidth.read(props);
  const borderRadius = optCornerRoundingLayout.readAsPercentOfMax(props, 18);
  const hasShadow = optBoxShadow.read(props);
  const hasBorder = optBorderColor.isSet(props);

  const border = hasBorder
    ? `${BORDER_WIDTH}px solid ${optBorderColor.read(props)}`
    : '';

  const classNames = csx(
    styles.row,
    !isVisible && styles.invisible,
    !!themeStyles && styles.filled,
    hasShadow && styles.shadow,
    props.className,
    hasBorder && styles.hasBorder,
    !!fill && styles.hasBackground,
    'grid-layout',
    props.pageBreakAfter && 'pageBreakAfter',
    layoutWidthClass('row', size),
    elementClasses('row'),
  );

  return (
    <div
      {...props.slateAttr}
      id={props.id}
      className={classNames}
      style={{
        ...themeStyles,
        border,
        borderRadius,
        '--border-width': BORDER_WIDTH + 'px',
        '--inner-radius': borderRadius == null
          ? undefined
          : Math.max(0, borderRadius - (hasBorder ? BORDER_WIDTH : 0)) + 'px',
      }}
      data-testid={props.testId}
      data-grid-row={props.id}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-type="row"
      >
      <div className={styles.rowInner}>
        <ThemesProvider
          theme={theme}
          chartTheme={chartTheme}
          >
          {props.children}
        </ThemesProvider>
      </div>
    </div>
  );
}

GridRow.options = {
  [elementVisibility.name]: elementVisibility,
  [backgroundColor.name]: backgroundColor,
  [layoutWidth.name]: layoutWidth,
  [optBorderColor.name]: optBorderColor,
  [optBoxShadow.name]: optBoxShadow,
  [optCornerRoundingLayout.name]: optCornerRoundingLayout,
};

GridRow.propTypes = {
  id: PropTypes.string.isRequired,
  isEditor: PropTypes.bool,
  testId: PropTypes.string,
  model: PropTypes.object,
  slateAttr: PropTypes.object,
  element: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  pageBreakAfter: PropTypes.bool,
};
